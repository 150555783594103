import React, { useState } from 'react';
import {
  Dialog, DialogContent, DialogActions, Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Table } from '../../tk-ui';
import DialogTitle from '../../DialogTitle';
import { greyboxApiActions } from '../../../redux/api';
import { ALERT_CONFIGS } from '../../alert/utils';

const ReminderModal = (props) => {
  const {
    open,
    patient,
    handleClose,
    link = true,
  } = props;
  const { t, i18n } = useTranslation();
  const { reminder } = greyboxApiActions;
  const { data = [], isLoading } = reminder.list(
    { patient: patient.uuid, status: 'active' },
    { skip: !open },
  );

  const columns = [
    {
      id: 'alert_type',
      label: t('Reminders'),
      minWidth: 160,
      format: (value) => ALERT_CONFIGS.reminder.types[value].label,
    },
    {
      id: 'alert_detail',
      label: t('Details'),
      minWidth: 200,
      value: (row) => {
        if (row.alert_detail[`medication_name_${i18n.resolvedLanguage}`]) {
          return row.alert_detail[`medication_name_${i18n.resolvedLanguage}`].map((val, index) => {
            if (val === 'nan') {
              return row.alert_detail.medication_name_en[index];
            }
            return val;
          }).join(',');
        }
        return row.alert_detail[`medication_class_name_${i18n.resolvedLanguage}`] || row.alert_detail[`algorithm_name_${i18n.resolvedLanguage}`];
      },
    },
    {
      id: 'create_date',
      label: t('Date'),
      minWidth: 80,
      value: (row) => moment(row.create_date).startOf('day').toDate(),
      format: (value) => moment(value).format('YYYY-MM-DD'),
    },
  ];

  if (isLoading) {
    return null;
  }

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      onClose={(e) => handleClose(e)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle onClose={(e) => handleClose(e)}>
        {t('Treatment plan reminder')}
        {link && (
          <>
            <Link
              underline="none"
              component={RouterLink}
              to={`/patient-profile/${patient.uuid}`}
            >
              {` ${patient.firstName} ${patient.lastName}`}
            </Link>
          </>
        )}
      </DialogTitle>
      <DialogContent onClick={handleClick}>
        <Table rowId="uuid" columns={columns} rows={data} />
      </DialogContent>
      <DialogActions onClick={handleClick}>
        <Button onClick={(e) => handleClose(e)}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReminderModal;
