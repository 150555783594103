import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button, Dialog, DialogActions, DialogContent, MenuItem,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';
import FormTextField from '../form-inputs/FormTextField';
import Language from '../form-inputs/Language';
import PhoneNumber from '../form-inputs/PhoneNumber';
import FormSelect from '../form-inputs/FormSelect';
import Row from '../Row';

/**
 * Form to invite a new patient.
 */
const practitionerInvitation = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const { invitation, roles } = greyboxApiActions;
  const [redirect, setRedirect] = React.useState(false);
  const [sendInvitation] = invitation.add();
  const { clinic } = useSelector((state) => state.clinic);
  const { data = [] } = roles.list({ acc_type__in: 'PT' });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      language: 'fr',
      role: null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      if (values.email === '' && values.phoneNumber === '') {
        return {
          email: t('Must provide either email or phone number'),
          phoneNumber: t('Must provide either email or phone number'),
        };
      }
      return null;
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Required')),
      lastName: Yup.string().required(t('Required')),
      email: Yup.string().email(t('Invalid email')),
      phoneNumber: Yup.string(),
      language: Yup.string().required(t('Required')),
      role: Yup.string(),
    }),

    onSubmit: (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber.length < 7 ? null : values.phoneNumber,
        language: values.language,
        clinic: clinic.id,
        type: 'CP',
        role: values.role,
        invitationFormFields: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber === '' ? null : values.phoneNumber,
          language: values.language,
        },
      };

      sendInvitation({ body, feedback: { success: t('Invitation sent') } }).unwrap().then(() => {
        formik.setSubmitting(false);
        formik.resetForm();

        if (redirect) {
          handleClose();
        }
      }).catch((error) => {
        formik.setSubmitting(false);
        // Assuming the server returns error details in a predictable format
        if (error.status === 400 && error.data) {
          const errors = error.data;
          formik.setErrors(errors);
        }
      });
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Register a new practitioner')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Row>
            <FormTextField formik={formik} name="firstName" label={t('First name')} required />
            <FormTextField formik={formik} name="lastName" label={t('Last name')} required />
          </Row>
          <Row>
            <FormTextField formik={formik} name="email" label={t('Email')} />
            <PhoneNumber formik={formik} name="phoneNumber" />
          </Row>
          <Row>
            <Language formik={formik} />
            <FormSelect formik={formik} name="role" label={t('Role')}>
              {data.map((role) => (
                <MenuItem key={role.uuid} value={role.uuid}>
                  {role.role_name}
                </MenuItem>
              ))}
            </FormSelect>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('Cancel')}
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            sx={{ ml: 'auto' }}
            loading={formik.isSubmitting}
          >
            {t('Send and add another')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setRedirect(true)}
            sx={{ ml: 'auto' }}
            loading={formik.isSubmitting}
          >
            {t('Send')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default practitionerInvitation;
