import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { greyboxApiActions } from '../redux/api';
import QuestionnaireContainer from '../components/patient-profile/questionnaire/filler/QuestionnaireContainer';

const OnInvitationQuestionnaire = () => {
  const { tokenId } = useParams();
  const { t } = useTranslation();
  const { questionnaireToken } = greyboxApiActions;
  const { data, isLoading, isError } = questionnaireToken.get(tokenId);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return (
      <div style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      >
        <Typography variant="h4">
          {`${t('Sorry')}, ${t('this link is no longer valid')}`}
        </Typography>
        <Typography>
          {`${t('This questionnaire has already been filled or the link is too old')}`}
        </Typography>
      </div>
    );
  }

  return (
    <div style={{ width: '100%', height: '90vh' }}>
      <QuestionnaireContainer questionnaireId={data.questionnaire} clinic={data.clinic} />
    </div>
  );
};

export default OnInvitationQuestionnaire;
