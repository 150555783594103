import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import InfoIcon from '@mui/icons-material/Info';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import FormTextField from '../../form-inputs/FormTextField';
import PhoneNumber from '../../form-inputs/PhoneNumber';
import Language from '../../form-inputs/Language';
import Row from '../../Row';
import TablePaginationSkeleton from '../../skeletons/TablePaginationSkeleton';
import TableSkeleton from '../../skeletons/TableSkeleton';
import { NoData } from '../../tk-ui';
import { RootState } from '../../../redux/store';
import { QuestionnaireAnswers } from '../../../types';
import { formatPhoneNumber } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  noData: {
    transform: 'scale(4)',
  },
  noDataCell: {
    borderBottom: 'none',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  table: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

type QuestionnaireInvitationDialogProps = {
  questionnaireId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const QuestionnaireInvitationDialog = (
  { open, setOpen, questionnaireId }: QuestionnaireInvitationDialogProps,
) => {
  const { t, i18n } = useTranslation();
  const { questionnaireToken } = greyboxApiActions;
  const [sendInvitation, result] = questionnaireToken.add();
  const { uuid } = useParams();
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const [errorMessage, setErrorMessage] = useState('');
  const [showInfo, setShowInfo] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      language: i18n.language,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Required')),
      lastName: Yup.string().required(t('Required')),
      email: Yup.string().email(t('Invalid email address')),
      mobile: Yup.string(),
      language: Yup.string(),
    }),
    onSubmit: (values) => {
      setErrorMessage('');
      sendInvitation({
        body: {
          impersonator: {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            language: values.language,
            mobile: values.mobile,
          },
          questionnaire: questionnaireId,
          impersonating: uuid,
          clinic: clinic.id,
        },
        feedback: {
          success: t('Invitation sent'),
        },
      }).unwrap().then(() => {
        formik.setSubmitting(false);
        formik.resetForm();
        setOpen(false);
      }).catch((error) => {
        formik.setSubmitting(false);
        const errors = error.data;
        if (errors.error) {
          setErrorMessage(errors.error);
        }
        formik.setErrors(errors);
      });
    },
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        {t('Send Questionnaire Invitation')}
        <Tooltip title={t('Click to show additional information')}>
          <IconButton
            size="small"
            onClick={() => setShowInfo(!showInfo)}
            sx={{ ml: 1, fontSize: '0.875rem' }}
          >
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
          {showInfo && (
            <Paper elevation={1} sx={{ p: 2, mb: 2, backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
              <Typography variant="subtitle2" color="textSecondary">
                {t('Information')}
              </Typography>
              <Typography variant="body2">
                {t('The questionnaire will be sent to someone who will fill it out on behalf of the patient. Once completed, it will be available in the patient\'s record.')}
              </Typography>
            </Paper>
          )}
          <Row>
            <FormTextField formik={formik} name="firstName" label={t('First Name')} />
            <FormTextField formik={formik} name="lastName" label={t('Last Name')} />
          </Row>
          <Row>
            <FormTextField formik={formik} name="email" label={t('Email')} />
            <PhoneNumber formik={formik} name="mobile" />
          </Row>
          <Row>
            <Language formik={formik} />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="outlined">
            {t('Cancel')}
          </Button>
          <LoadingButton
            loading={result.isLoading}
            type="submit"
            variant="contained"
          >
            {t('Send Invitation')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

type Column = {
  id: string;
  label: string;
  align?: 'right' | 'left' | 'center';
  value?: (row: any) => any;
  format?: (value: any) => any;
};

type QuestionnaireInvitationTableProps = {
  questionnaireId: number;
};

type questionnaireToken = {
  data: Data;
  isFetching: boolean;
}

type Data = {
  count: number;
  results: QuestionnaireAnswers[];
}

const QuestionnaireInvitationTable = ({ questionnaireId }: QuestionnaireInvitationTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { uuid } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const { questionnaireToken } = greyboxApiActions;
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const [invitationOpen, setInvitationOpen] = useState(false);
  const [updateInvitation] = questionnaireToken.update();
  const { data, isFetching }: questionnaireToken = questionnaireToken.list(
    {
      page_size: rowsPerPage,
      page: page + 1,
      questionnaire: questionnaireId,
      impersonating: uuid,
      questionnaire__clinic: clinic.id,
      status__in: 'send,completed,pending',
    },
  );

  const status = {
    send: t('Sent'),
    completed: t('Completed'),
    pending: t('Pending'),
  };

  const handleDelete = () => {
    Promise.all(selected.map((id) => updateInvitation({
      id,
      body: { status: 'error' },
    }))).then(() => {
      setSelected([]);
    });
  };

  const columns = [
    {
      id: 'first_name',
      label: t('First Name'),
      align: 'left',
      value: (row) => row.impersonator.first_name || '',
    },
    {
      id: 'last_name',
      label: t('Last Name'),
      align: 'left',
      value: (row) => row.impersonator.last_name || '',
    },
    {
      id: 'mobile',
      label: t('Mobile'),
      align: 'left',
      value: (row) => formatPhoneNumber(row.impersonator.mobile) || '',
    },
    {
      id: 'email',
      label: t('Contact'),
      align: 'left',
      value: (row) => row.impersonator.email || '',
    },
    {
      id: 'status',
      label: t('Status'),
      align: 'left',
      format: (value) => status[value],
    },
    {
      id: 'updated',
      label: t('Last Updated'),
      align: 'left',
      format: (value) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
  ] as Column[];

  if (isFetching) {
    return (
      <>
        <div className={classes.header}>
          <TablePaginationSkeleton />
        </div>
        <TableContainer className={classes.table}>
          <TableSkeleton columns={columns} rowCount={rowsPerPage} />
        </TableContainer>
      </>
    );
  }

  const handleClick = (id) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      }
      return [...prevSelected, id];
    });
  };

  const onSelectAllClick = () => {
    if (selected.length <= data.results.length) {
      setSelected([]);
    } else {
      setSelected(data.results.map((item) => item.id));
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        pl: 1,
      }}
      >
        <Stack spacing={1} direction="row">
          <Button
            startIcon={<SendIcon />}
            variant="outlined"
            onClick={() => setInvitationOpen(true)}
            size="small"
          >
            {t('Invite')}
          </Button>
          <Button
            color="error"
            variant="outlined"
            onClick={handleDelete}
            startIcon={<DeleteIcon />}
            disabled={selected.length === 0}
          >
            {t('Delete')}
          </Button>
        </Stack>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          sx={{ ml: 'auto' }}
          count={data.count}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
        />
      </Box>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < data.results.length}
                  checked={
                    data.results.length === 0 ? false : selected.length === data.results.length
                  }
                  onClick={onSelectAllClick}
                />
              </TableCell>
              {columns.map((column, idx) => (
                <TableCell key={idx} align={column.align || 'center'}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results?.length > 0 ? (
              data.results.map((record, recordIndex) => (
                <TableRow key={record.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.includes(record.id)}
                      onClick={() => handleClick(record.id)}
                    />
                  </TableCell>
                  {columns.map((column, idx) => (
                    <TableCell key={idx} align={column.align || 'center'}>
                      {column.value ? (
                        column.value(record)
                      ) : (
                        column.format(record[column.id])
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              !isFetching && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} className={classes.noDataCell}>
                    <Box height="200px" display="flex" justifyContent="center" alignItems="center">
                      <NoData className={classes.noData} />
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <QuestionnaireInvitationDialog
        open={invitationOpen}
        setOpen={setInvitationOpen}
        questionnaireId={questionnaireId}
      />
    </Box>
  );
};

export default QuestionnaireInvitationTable;
