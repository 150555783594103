const suggested_style = {
  separator: {
    height: "2px",
    margin: "10px 20px 18px 1px",
    opacity: 0.4,
    background: "#707070",
  },
  separatorBottom: {
    height: "2px",
    margin: "30px 0 18px 0",
    opacity: 0.4,
    background: "#707070",
  },
  header: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    color: "#454545",
  },
  subTitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    color: "#707070",
    marginBottom: "11px",
    position: "relative",
    transform: "translateY(-5px)",
  },
  wrapperMedTiles: {
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap",
    justifyContent: "flex-start",
    margin: "0px 0px 20px 0px",
  },
  classWrapper: {
    display: "flex",
    wrap: "no-wrap",
    alignItems: "center",
  },
  classWrapperWarning: {
    display: "flex",
    width: "100%",
    wrap: "no-wrap",
    alignItems: "center",
  },
  // startPole: {
  //   width: "2px",
  //   height: "20px",
  //   opacity: 0.2,
  //   background: "#707070",
  //   transform: "translateY(-10px)",
  // },
  startLine: {
    width: "42px",
    height: "2px",
    opacity: 0.2,
    background: "#707070",
    transform: "translateY(-10px)",
  },
  startSmallLine: {
    width: "8px",
    height: "2px",
    opacity: 0.3,
    background: "#707070",
    transform: "translateY(-10px)",
  },
  classLine: {
    width: "100%",
    height: "2px",
    opacity: 0.2,
    background: "#707070",
    transform: "translateY(-10px)",
  },
 
  subTitles: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "20px",
    color: "#454545",
    width: "107px",
    display: "flex",
    wrap: "no-wrap",
    padding: '0 5px',
  },
  subTitlesFr: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "20px",
    color: "#454545",
    width: "200px",
    display: "flex",
    wrap: "no-wrap",
    padding: '0 5px',
  },
  diabetes: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "20px",
    color: "#454545",
    width: "240px",
    display: "flex",
    wrap: "no-wrap",
    padding: '0 5px',
  },
  diabetesFr: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "20px",
    color: "#454545",
    width: "340px",
    display: "flex",
    wrap: "no-wrap",
    padding: '0 5px',
  },
};


const none_style = {
  wrapper: {
    width: "100%",
    marginLeft: "0px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },

  box: {
    width: "735px",
    paddingBottom: "20px",
    borderRadius: "5px",
    backgroundColor: "#e0f5f5",
    position: "relative",
  },

  errorMessage: {
    fontFamily: "Roboto",
    fontSize: "16px",
    marginTop: "15px",
    marginBottom: "8px",
    fontWeight: 500,
    lineHeight: 1.17,
    marginLeft: "45px",
    textAlign: "left",
    color: "#454545",
  },
  errorSuggestion: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "45px",
    textAlign: "left",
    color: "#454545",
  },

  direct: {
    fontFamily: "Roboto",
    fontSize: "14px",
    marginTop: "4px",
    fontWeight: 400,
    marginLeft: "45px",
    textAlign: "left",
    color: "#595130",
  },
  linked: {
    color: "#09bbd0",
    lineHeight: 1,
    textDecoration: "none",
  },
};
const missing_inputs_style = {
  wrapper: {
    width: "100%",
    marginLeft: "0px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  box: {
    width: "735px",
    paddingBottom: "12px",
    borderRadius: "5px",
    backgroundColor: "#fcebdb",
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  errorMessage: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.19",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#594430",
    marginLeft: "45px",
    marginTop: "15px",
    marginBottom: "8px",
  },
  errorSuggestion: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.36",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#594430",
    marginLeft: "45px",
    marginBotton: "11px",
  },
};

const medication_list_style = {
  wrapper: {},
  rowWrapperActive: {
    display: "flex",
    position: "relative",
    marginBottom: "10px",
    marginLeft: "7px",
    marginRight: "7px",
    marginTop: "10px",
    background: "rgba(0,0,0,0)",
    padding: "4px 5px",
  },
  rowWrapper: {
    display: "flex",
    position: "relative",
    marginBottom: "10px",
    marginLeft: "7px",
    marginRight: "7px",
    marginTop: "10px",
    transition: "background 400ms ease",
    cursor: "pointer",
    padding: "4px 5px",
  },

  plusIcon: {
    lineHeight: "1.0",
    fontWeight: "300",
    fontFamily: "Roboto",
    fontSize: "25px",
    position: "absolute",
    color: "#454545",
    top: "-7px",
    right: "5px",
    padding: "7px 0px 7px 10px",
  },
  item: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.14",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#454545",
    width: "maxContent",
    maxWidth: "190px",
  },
  item_active: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: "1.14",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#454545",
    width: "maxContent",
    maxWidth: "190px",
    color: "#497632",
  },

  active_text: {
    right: "4px",
    top: "4px",
    position: "absolute",
  },
  active_check_en: {
    right: "50px",
    top: "-3px",
    position: "absolute",
  },
  active_check_fr: {
    right: "40px",
    top: "-3px",
    position: "absolute",
  },
};

const consult = {
  wrapper: {
    width: "232px",
    borderRadius: "5px",
    position: "relative",
    margin: "0px 20px 0 0px",
    backgroundColor: "#EDEDED",
  },
  headerLight: {
    width: "232px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    height: "76px",
    background: "rgba(255,255,255,0.65)",
  },
  wrapperHeader: {
    fontWeight: "500",
    padding: "7px 10px 2px 12px",
    fontSize: "16px",
    color: "#454545",
  },
  positionning: {
    marginLeft: "5.7px",
  },
  popBtn: {
    marginTop: "2px",
    marginLeft: "6.5px",
    backgroundColor: "rgba(0,0,0,0)",
    padding: "10px 8px",
    borderRadius: "3px",
    border: "solid 1px #6c6c6c",
    fontSize: "14px",
    textTransform: "capitalize",
    fontWeight: "500",
    lineHeight: "0.7",
    fontFamily: "Roboto",
    color: "#454545",
  },
  popBox: {
    width: "360px",
    padding: "12px",
    backgroundColor: "#454545",
    position: "relative",
  },
  popBoxHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "white",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
  },
  popBoxHeaderTitle: {
    marginBottom: "12px",
  },
  popBoxHeaderBtn: {
    position: "absolute",
    top: "8px",
    right: "10px",
    cursor: "pointer",
  },
  textBox: {
    backgroundColor: "#363636",
  },
  textStyle: {
    fontFamily: "Roboto",
    fontSize: "15px",
    color: "white",
    padding: "12px",
    fontWeight: "400",
    lineHeight: "1.2",
  },
  category: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#454545",
    display: "flex",
    position: "relative",
    margin: "10px 7px",
    padding: "4px 5px",
  },
};

export {
  suggested_style,
  none_style,
  missing_inputs_style,
  medication_list_style,
  consult,
};
