import React, { useState } from 'react';
import { Popover, Typography } from '@mui/material';
import { grid_style } from './style';

import { Buttons } from '../index';

export const Popovers = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    children, // the component form
    // BTN SHAPE
    onClick = null, // pass Fn as optional props
    onMouseEnter = null, // pass Fn as optional props
    onMouseLeave = null, // pass Fn as optional props
    hovered = false, // if you want a tooltip
    btnBackground,
    btnTextColor,
    text = '', // btn text
    borderRadius = borderRadius, // btn radius '3px' default
    margins = '0px', // btn margin
    paddings = '0px 10px', // btn paddings
    hasButton = true,
    ownStyle = null,
    // POINTER TRIANGLE
    pointer = true, // has a pointer
    backColor = '#ebebeb', // color for pointer ( triangle )
    pointerLeft = '-5px', // pointer ( triangle ) left position
    angle = '180deg', // pointer ( triangle ) rotation facing up is default
    pointerTop = '5px', // pointer ( triangle ) top position
    // POPOVER POSITIONNING
    elevation = 0, // dropShadow
    popLeftPos = '0px', // popover left position
    popTopPos = '-40px', // popover top position
    anchorVertical = 'bottom', // pointer point of start
    anchorHorizontal = 'center', // pointer point of start
    transformVertical = 'top', // pointer point of start
    transformHorizontal = 'center', // pointer point of start
    color,
  } = props;
  const open = Boolean(anchorEl);
  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const closePop = (value) => {
    // console.log('doSomething called by child with value:', value);
    closePopover();
  };

  return (
    <div style={hovered ? { position: 'absolute' } : null}>
      <Buttons
        text={text}
        background={btnBackground}
        textColor={btnTextColor}
        color={color}
        paddings={paddings}
        ownStyle={ownStyle}
        onClick={(e) => {
          openPopover(e);
          onClick && onClick();
        }}
        margins={margins}
        onMouseEnter={(e) => {
          hovered && openPopover(e);
          onMouseEnter && onMouseEnter();
          // could open also, todo add props.
        }}
        onMouseLeave={(e) => {
          hovered && closePopover();
          onMouseLeave && onMouseLeave();
        }}
        borderRadius={borderRadius}
        lowerCase
      />

      <Popover
        elevation={elevation}
        id="popper"
        open={open}
        disableRestoreFocus
        anchorOrigin={{
          vertical: anchorVertical,
          horizontal: anchorHorizontal,
        }}
        transformOrigin={{
          vertical: transformVertical,
          horizontal: transformHorizontal,
        }}
        anchorEl={anchorEl}
        onClose={closePopover}
        style={
          hovered
            ? {
              transform: `translateX(${popLeftPos}) translateY(${popTopPos})`,
              pointerEvents: 'none',
            }
            : { transform: `translateX(${popLeftPos}) translateY(${popTopPos})` }
        }
      >
        {children(closePop)}
      </Popover>

      {anchorEl && pointer && (
        <div
          style={{
            position: 'absolute',
            transform: `translateX(${pointerLeft}) translateY(${pointerTop}) rotate(${angle})`,
            borderStyle: 'solid',
            borderWidth: '15px 15px 0 15px',
            borderColor: `${backColor} transparent transparent transparent`,
            zIndex: 1000,
            pointerEvents: 'none',
          }}
        />
      )}
    </div>
  );
};
