export const inputStyle = {
  wrapper: {
    width: '145px',
    minHeight: '32px',
    textAlign: 'right',
  },
  endLarge: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '135px',
    marginLeft: '5px',
  },
  endLargeVital: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '70px',
    marginLeft: '5px',
    textAlign: 'right',
  },
  end: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '35px',
    marginLeft: '5px',
    textAlign: 'right',
  },
  smallEnd: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '35px',
    marginLeft: '5px',
    textAlign: 'right',
  },
};
