import React from 'react';
import { SvgIcon } from '@mui/material';

const Taken_no_halo = (props) => {
  const { fills = '#497632' } = props;
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      style={{ width: '16px', height: '16px' }}
      fills={fills}
    >
      <g id="ic_ActiveMx_16px" transform="translate(-69)">
        <path
          id="Path_1008"
          fill={fills}
          d="M80.5,4.6l-4.9,4.9c-0.1,0.1-0.3,0.1-0.4,0L73,7.4c-0.1-0.1-0.3-0.1-0.4,0l-0.8,0.8
  c-0.1,0.1-0.1,0.3,0,0.4l3.3,3.3c0.1,0.1,0.3,0.1,0.3,0l6-6c0.1-0.1,0.1-0.3,0-0.4l-0.8-0.8C80.8,4.5,80.6,4.5,80.5,4.6z M77,0
  c-4.4,0-8,3.6-8,8s3.6,8,8,8c4.4,0,8-3.6,8-8S81.4,0,77,0z M77,14.4c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4c3.5,0,6.4,2.9,6.4,6.4
  v0C83.4,11.5,80.5,14.4,77,14.4z"
        />
      </g>
    </SvgIcon>
  );
};

export { Taken_no_halo };
