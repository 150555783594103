import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  List, ListItemButton, ListItemText,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

type SubPathProps = {
  id: string;
  label: string;
  subPaths: { id: string; label: string; url: string }[];
};

const SubpathMenu = (props: SubPathProps) => {
  const [open, setOpen] = useState(false);
  const {
    id, label, subPaths,
  } = props;
  React.useEffect(() => {
    if (subPaths.some((value) => window.location.pathname.split('/').some((segment) => segment === value.id))) {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)} data-cy={id}>
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subPaths.map((value, i) => (
            <ListItemButton
              sx={{ pl: 4 }}
              data-cy={`${id}-${i}`}
              selected={window.location.pathname.split('/').some((segment) => segment === value.id)}
              component={Link}
              to={value.url}
              key={value.id}
            >
              <ListItemText primary={value.label} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default SubpathMenu;
