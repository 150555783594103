import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Paper, Box, Button, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormTextField from '../form-inputs/FormTextField';
import Row from '../Row';
import { greyboxApiActions } from '../../redux/api';
import { CompanyApiType } from '../../types';
import { RootState } from '../../redux/store';
import PhoneNumber from '../form-inputs/PhoneNumber';

const ClinicInformation = () => {
  const { t } = useTranslation();
  const { company } = greyboxApiActions;
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const { data, isLoading } = company.list({ id: clinic.id }) as {
    data: CompanyApiType; isLoading: boolean; };
  const [updateClinic, results] = company.update();

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      address: data?.address || '',
      phone_num: data?.phone_num || '',
      email: data?.email || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t('required')),
      address: Yup.string(),
      phone_num: Yup.string(),
      email: Yup.string().required(t('required')),
    }),
    onSubmit: (values) => {
      updateClinic({ id: clinic.id, body: values });
    },
  });

  return (
    <Paper sx={{ p: 1, mb: 1 }}>
      <Typography variant="h5" sx={{ m: 1 }}>{t('Edit Clinic Information')}</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <FormTextField formik={formik} name="name" label={t('Name')} required />
          <FormTextField formik={formik} name="address" label={t('Address')} />
        </Row>
        <Row>
          <PhoneNumber formik={formik} name="phone_num" label={t('Phone')} required />
          <FormTextField formik={formik} name="email" label={t('Email')} required />
        </Row>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="secondary"
            sx={{ mr: 1 }}
            onClick={() => formik.resetForm()}
            disabled={!formik.dirty}
          >
            {t('Reset')}
          </Button>
          <LoadingButton loading={results.isLoading} variant="contained" type="submit" disabled={!formik.dirty}>
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>
    </Paper>
  );
};

export default ClinicInformation;
