export const LANGUAGES = [{
  code: 'en',
  name: 'English',
}, {
  code: 'fr',
  name: 'French',
}, {
  code: 'es',
  name: 'Spanish',
}, {
  code: 'de',
  name: 'German',
}, {
  code: 'it',
  name: 'Italian',
}, {
  code: 'pt',
  name: 'Portuguese',
}];
