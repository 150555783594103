import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import {
  Box, Button, CircularProgress, IconButton, InputAdornment, Popover,
  TextField, ToggleButton, Tooltip, Typography, useMediaQuery,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useThreadWebSocket } from '../../hooks';
import { greyboxApiActions } from '../../redux/api';
import { getMediaTypeFromFileType } from '../../utils';
import MessageBox from './MessageBox';
import MessageTextField from './MessageTextField';

const PAGE_SIZE = 20;
/**
 * Used to display messages from clinic to patient. It can toggle if the patient can
 * answer or not. The previous message (if any) will load when the user scrolls to the top.
 */
const ChatBox = ({
  thread, handleClose = null, open = true, isDialog = false,
  setThread = null,
}) => {
  const { messageThread, user, media } = greyboxApiActions;
  const { access } = useSelector((state) => state.user);
  const { uuid } = useParams();
  const currentUser = user.get();
  const { t, i18n } = useTranslation();
  const [sendMessage] = messageThread.add();
  const [sendMedia] = media.add();
  const [currentMessage, setCurrentMessage] = useState('');
  const { clinic } = useSelector((state) => state.clinic);
  const [updateThread] = messageThread.update();
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [acceptPatientMessage, setAcceptPatientMessage] = useState(thread.accept_patient_message);
  const [attachedFile, setAttachedFile] = useState(null);
  const [anchorEmote, setAnchorEmote] = useState(null);
  const [sendLaterAnchorEl, setSendLaterAnchorEl] = useState(null);
  const [sendLaterDate, setSendLaterDate] = useState(moment().add(1, 'hours'));

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { lastJsonMessage, readyState } = useThreadWebSocket(thread.id);

  useEffect(() => {
    // A new thread is selected
    if (!isDialog) {
      setAcceptPatientMessage(thread.accept_patient_message);
      setMessages([]);
      setPage(1);
      setHasMore(false);
      setCurrentMessage('');
      setAttachedFile(null);
    }
  }, [thread]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'Message') {
        if (open) {
          if (page === 1) {
            refetch();
          } else {
            setPage(1);
          }
        }
      }
    }
  }, [lastJsonMessage]);

  const { data, isFetching, refetch } = messageThread.list(
    {
      id: `${thread.id}/message`,
      page_size: PAGE_SIZE,
      page: page,
    },
  );

  // reload on open
  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      if (data.count === 0) {
        setHasMore(false);
      } else {
        const hasPreviousMsg = data.count > PAGE_SIZE * page;
        setHasMore(hasPreviousMsg);
      }
    }
  }, [data, page]);

  const handleRemoveFile = () => {
    setAttachedFile(null);
  };

  const handleSendMessage = async () => {
    const body = { message: currentMessage };

    if (attachedFile) {
      const newMedia = new FormData();
      const mediaType = getMediaTypeFromFileType(attachedFile.type);
      newMedia.append('file', attachedFile);
      newMedia.append('clinic', clinic.id);
      newMedia.append('type', mediaType);
      newMedia.append('patient', uuid || thread.patient);
      const mediaData = await sendMedia({ body: newMedia });
      body.media = mediaData.data.id;
    }

    if (sendLaterAnchorEl) {
      body.scheduled_time = sendLaterDate.toISOString();
      setSendLaterAnchorEl(null);
    }

    sendMessage({ body, id: `${thread.id}/message` });
    setMessages([
      {
        message: currentMessage,
        created_by: {
          id: currentUser.data.id,
          first_name: currentUser.data.first_name,
          last_name: currentUser.data.last_name,
        },
        created: moment().toISOString(),
      },
      ...messages,
    ]);

    setCurrentMessage('');
    setAttachedFile(null);
  };

  const handleSwitch = () => {
    const accept = !acceptPatientMessage;
    setAcceptPatientMessage(accept);
    updateThread({
      id: thread.id,
      body: { accept_patient_message: accept },
    });
  };

  useEffect(() => {
    if (data && data.results) {
      if (page === 1) {
        setMessages(data.results);
      } else {
        setMessages([...messages, ...data.results]);
      }
    }
  }, [data]);

  const fetchMoreData = () => {
    const hasPreviousMsg = data.count > PAGE_SIZE * page;
    setHasMore(hasPreviousMsg);

    if (hasPreviousMsg) {
      setPage(page + 1);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '600px',
      }}
    >
      {isDialog && (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '45px',
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,
        }}
        >
          <IconButton
            size="small"
            style={{ marginRight: '8px' }}
            color="secondary"
            onClick={handleClose}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      )}
      {isMobile && (
        <Box>
          <Button
            sx={{ mb: 1 }}
            size="small"
            onClick={() => setThread(null)}
            startIcon={<ArrowBackIcon />}
          />
        </Box>
      )}
      <Box
        id="scrollableDiv"
        sx={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          borderRadius: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
          p: 1,
        }}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreData}
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }} // To put endMessage and loader to the top.
          inverse
          hasMore={hasMore}
          loader={<div><CircularProgress size={30} /></div>}
          scrollableTarget="scrollableDiv"
        >
          {messages.map((msg, index) => (
            <MessageBox
              key={msg.id}
              message={msg}
              previousMessage={messages[index + 1]}
              threadId={thread.id}
            />
          ))}
        </InfiniteScroll>
      </Box>
      {!(access === 'P' && !thread.accept_patient_message) && (
        <Box sx={{ p: 1 }}>
          {isMobile && (
            <Box display="flex">
              <MessageTextField
                isFetching={isFetching}
                attachedFile={attachedFile}
                setAttachedFile={setAttachedFile}
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
              />
              <IconButton
                size="small"
                color="primary"
                sx={{ ml: 1 }}
                disabled={!currentMessage && !attachedFile}
                onClick={handleSendMessage}
              >
                {isFetching ? <CircularProgress size={24} /> : <SendIcon />}
              </IconButton>
            </Box>
          )}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 1,
          }}
          >
            {access === 'PT' && (
              <Tooltip title={!acceptPatientMessage ? (
                t('Allow patient to answer')
              ) : (
                t('Do not allow patient to answer')
              )}
              >
                <ToggleButton
                  selected={acceptPatientMessage}
                  value={acceptPatientMessage}
                  color="success"
                  onChange={handleSwitch}
                >
                  {acceptPatientMessage ? <CommentIcon /> : <CommentsDisabledIcon />}
                </ToggleButton>
              </Tooltip>

            )}
            <input
              style={{ display: 'none' }}
              onChange={(e) => setAttachedFile(e.target.files[0])}
              type="file"
              id="raised-button-file"
            />
            <label htmlFor="raised-button-file">
              <IconButton
                size="small"
                component="span"
                color="primary"
                sx={{ ml: 1 }}
              >
                <AttachFileIcon />
              </IconButton>
            </label>
            {!isMobile && (
              <MessageTextField
                isFetching={isFetching}
                attachedFile={attachedFile}
                setAttachedFile={setAttachedFile}
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
              />
            )}
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              onClick={(e) => setAnchorEmote(e.currentTarget)}
            >
              <InsertEmoticonIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorEmote)}
              onClose={() => setAnchorEmote(null)}
              anchorEl={anchorEmote}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <EmojiPicker
                onEmojiClick={(emojiObject) => {
                  setCurrentMessage(currentMessage + emojiObject.emoji);
                }}
                lazyLoadEmojis
                searchDisabled
                autoFocusSearch={false}
                suggestedEmojisMode="recent"
                skinTonesDisabled
              />
            </Popover>
            {!isMobile && (
              <IconButton
                size="small"
                color="primary"
                disabled={!currentMessage && !attachedFile}
                onClick={handleSendMessage}
                onContextMenu={(e) => {
                  setSendLaterAnchorEl(e.currentTarget); e.preventDefault();
                }}
              >
                {isFetching ? <CircularProgress size={24} /> : <SendIcon />}
              </IconButton>
            )}
            <Popover
              open={Boolean(sendLaterAnchorEl)}
              onClose={() => setSendLaterAnchorEl(null)}
              anchorEl={sendLaterAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Box sx={{ p: 2 }}>
                <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.resolvedLanguage}>
                  <DateTimePicker
                    value={moment(sendLaterDate)}
                    label={t('Send message at')}
                    onChange={(newValue) => setSendLaterDate(newValue)}
                    disablePast
                  />
                </LocalizationProvider>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                  <Button color="secondary" size="small" onClick={() => setSendLaterAnchorEl(null)}>
                    {t('Close')}
                  </Button>
                  <Button size="small" onClick={handleSendMessage}>
                    {t('Send')}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatBox;
