import React from 'react';
import {
  MenuItem, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormSelect from './FormSelect';

type RolesProps = {
  formik: any;
  disabled?: boolean;
  name: string;
}

const RoleTypes = ({
  formik, name, disabled,
}: RolesProps) => {
  const { t } = useTranslation();

  return (
    <FormSelect
      formik={formik}
      multiple
      name={name}
      disabled={disabled}
      label={t('Role')}
    >
      <MenuItem value="P">{t('Patient')}</MenuItem>
      <MenuItem value="PT">{t('Practioner')}</MenuItem>
      <MenuItem value="CP">{t('Caregiver')}</MenuItem>
    </FormSelect>

  );
};

export default RoleTypes;
