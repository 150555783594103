import {
  Box, FormControl,
  FormLabel, Skeleton, TextField, Typography, useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { parseThresholdForPost, getThresholdCodes, thresholdInitValue } from './Threshold';

export const thresholdValidations = (enabledVitals) => {
  const validationSchema = yup.object();

  enabledVitals.forEach((vital) => {
    if (vital === 'bloodPressure') {
      validationSchema.concat(yup.object().shape({
        min_tensionLow: yup.number().min(0),
        min_tensionHigh: yup.number().min(0),
        max_tensionLow: yup.number().min(0),
        max_tensionHigh: yup.number().min(0),
      }));
    } else {
      validationSchema.concat(yup.object().shape({
        [`min_${vital}`]: yup.number().min(0), [`max_${vital}`]: yup.number().min(0),
      }));
    }
  });

  return validationSchema;
};

export const parseThresholdsForPost = (values, vitalsConfig) => {
  const thresholds = [];

  Object.keys(vitalsConfig).forEach((key) => {
    const thresholdCodes = getThresholdCodes(key, vitalsConfig);
    const parsed = parseThresholdForPost(values, key, thresholdCodes);
    thresholds.push(...parsed);
  });

  return thresholds;
};

export const thresholdsInitValue = (vitalsConfig, values) => {
  const thresholds = {};
  Object.keys(vitalsConfig).forEach((key) => {
    const thresholdCodes = getThresholdCodes(key, vitalsConfig);
    thresholdCodes.forEach((t) => {
      let value = {};
      if (values) {
        if (values[t.code]) {
          value = { [t.shortCode]: values[t.code].defaultValue };
        } else {
          value = values;
        }
      } else {
        return;
      }
      const newThreshold = thresholdInitValue(key, value, [t.shortCode]);
      Object.assign(thresholds, newThreshold);
    });
  });

  return thresholds;
};

export const Thresholds = ({ formik, isLoading }) => {
  const { t } = useTranslation();
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const matches = useMediaQuery('(min-width:600px)');

  return (
    Object.entries(vitalsConfig).map(([vital, config]) => {
      if (vital === 'BP') {
        return (
          <span key={vital}>
            <FormControl key="systolic" sx={{ m: 1, width: matches ? '48%' : '100%' }}>
              <FormLabel>{t('Systolic')}</FormLabel>
              <ThresholdTextField name="tensionHigh" formik={formik} isLoading={isLoading} />
            </FormControl>
            <FormControl key="diastolic" sx={{ m: 1, width: matches ? '48%' : '100%' }}>
              <FormLabel>{t('Diastolic')}</FormLabel>
              <ThresholdTextField name="tensionLow" formik={formik} isLoading={isLoading} />
            </FormControl>
          </span>
        );
      }
      if (config.threshold_code) {
        return (
          <FormControl key={vital} sx={{ m: 1, width: matches ? '48%' : '100%' }}>
            <FormLabel id={vital}>
              {config.name}
            </FormLabel>
            <ThresholdTextField name={vital} formik={formik} isLoading={isLoading} />
          </FormControl>
        );
      }
      return null;
    })
  );
};

const ThresholdTextField = ({ formik, name, isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Skeleton variant="rectangular" sx={{ m: 1, width: '48% ' }} height={56} />
        <Skeleton variant="rectangular" sx={{ m: 1, width: '48%' }} height={56} />
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <TextField
        id={`min_${name}`}
        sx={{ m: 1, width: '48%' }}
        name={`min_${name}`}
        label="Min"
        value={formik.values[`min_${name}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`min_${name}`] && formik.errors[`min_${name}`])}
        helperText={formik.touched[`min_${name}`] && formik.errors[`min_${name}`]}
        variant="outlined"
        type="number"
        onChange={formik.handleChange}
      />
      <TextField
        id={`max_${name}`}
        sx={{ m: 1, width: '48%' }}
        name={`max_${name}`}
        label="Max"
        value={formik.values[`max_${name}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`min_${name}`] && formik.errors[`min_${name}`])}
        helperText={formik.touched[`max_${name}`] && formik.errors[`max_${name}`]}
        variant="outlined"
        type="number"
        onChange={formik.handleChange}
      />
    </Box>
  );
};
