import { Skeleton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { usePatientWebSocket } from '../../hooks';
import { parseMax, parseMin, parseAverage } from './utils';

const SummaryChart = (props) => {
  const {
    vitalSelector,
    shortCode,
    unit,
    autoHeight = false,
  } = props;

  const { t } = useTranslation();
  const isMobile = useMediaQuery((s) => s.breakpoints.down('md'));
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const averageData = parseAverage(vitalSelector.data, unit);
  const minData = parseMin(vitalSelector.data, unit);
  const maxData = parseMax(vitalSelector.data, unit);

  const { lastJsonMessage, readyState } = usePatientWebSocket();

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'Observation' && lastJsonMessage.data.medicalMeasurementType === shortCode) {
        vitalSelector.refetch();
      }
    }
  }, [lastJsonMessage]);

  if (vitalSelector.isFetching) {
    return <Skeleton variant="rect" width="100%" height="30vh" />;
  }

  const getHeights = () => {
    if (isMobile) {
      return '280px';
    } if (autoHeight) {
      return '400px';
    }
    return '90%';
  };

  const series = [];
  const hasMultipleSeries = Object.keys(averageData).length > 1;
  Object.entries(averageData).forEach(([key, value]) => {
    series.push({
      name: hasMultipleSeries ? `${t('Average')} - ${vitalsConfig[key].name}` : t('Average'),
      type: 'line',
      data: value,
    });
  });

  if (series.length === 1) {
    series.unshift({
      name: t('Minimum'),
      type: 'line',
      data: minData,
    });
    series.push({
      name: t('Maximum'),
      type: 'line',
      data: maxData,
    });
  }

  const summaryOptions = {
    series: series,
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    markers: {
      size: [0],
    },
    legend: {
      show: true,
    },
    stroke: {
      width: [3, 3, 3],
      curve: 'straight',
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy HH:mm',
      },
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      id: 'summary',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      opacity: [1, 1, 1, 0.5],
    },
    yaxis: [
      {
        labels: {
          formatter: (val) => val.toFixed(0),
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={summaryOptions}
      type="line"
      series={summaryOptions.series}
      height={getHeights()}
    />
  );
};

export default SummaryChart;
