import {
  Box, useMediaQuery, Theme,
} from '@mui/material';
import React, { useState } from 'react';
import { ChatBox, ThreadSelector } from '../components/chat';

const MessagesDashboard = () => {
  const [thread, setThread] = useState(null);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isMobile) {
    if (!thread) {
      return (
        <Box sx={{ height: 'calc(100vh - 88px)' }}>
          <ThreadSelector onSelect={setThread} />
        </Box>
      );
    }

    return (
      <Box sx={{ height: 'calc(100vh - 88px)' }}>
        <ChatBox thread={thread} setThread={setThread} />
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      height: 'calc(100vh - 88px)',
    }}
    >
      <Box sx={{ flex: 1 }}>
        <ThreadSelector onSelect={setThread} />
      </Box>
      { thread && (
        <Box sx={{ width: '100%' }}>
          <ChatBox thread={thread} />
        </Box>
      )}
    </Box>
  );
};

export default MessagesDashboard;
