import React from 'react';
import Modal from '@mui/material/Modal';

const ImageModal = (props) => {
  const { open, image, handleClose } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={image} alt="" style={{ maxHeight: '90vh', maxWidth: '80vh', objectFit: 'contain' }} />
      </div>
    </Modal>
  );
};

export default ImageModal;
