import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  expirationDate: null,
  isUninitialized: true,
  isLoading: false,
  authState: 'default', // default, questionnaire
};

const authorization = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    logout: (state) => initialState,
    setTempAuthorization: (state, action) => {
      state.isLoading = false;
      state.isUninitialized = false;
      state.token = action.payload.token;
      state.authState = action.payload.authState || 'default';
      localStorage.setItem('authorization', action.payload.token);
    },
  },
});

export const {
  logout, setTempAuthorization,
} = authorization.actions;

export default authorization.reducer;
