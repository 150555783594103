import AddIcon from '@mui/icons-material/Add';
import {
  Box, Button, Paper, Skeleton, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import { RootState } from '../../redux/store';
import { InformationMessageApiType } from '../../types';
import TableSkeleton from '../skeletons/TableSkeleton';
import InformationMessageDialog from './InformationMessageDialog';

const InformationMessageTable = () => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const { informationMessage } = greyboxApiActions;
  const { data = [], isFetching } = informationMessage.list({ clinic: clinic.id }) as {
    data: InformationMessageApiType[]; isFetching: boolean;
  };
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const columns = [
    {
      id: 'key',
      label: t('Key'),
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'type',
      label: t('Type'),
      minWidth: 120,
      align: 'left',
    },
    {
      id: 'dtstart',
      label: t('Start Date'),
      minWidth: 120,
      align: 'left',
      format: (value) => moment(value).format('lll'),
    },
    {
      id: 'dtend',
      label: t('End Date'),
      minWidth: 120,
      align: 'left',
      format: (value) => moment(value).format('lll'),
    },
    {
      id: 'active',
      label: t('Active'),
      align: 'left',
      format: (value) => (value ? t('Yes') : t('No')),
    },
    {
      id: 'dismissible',
      label: t('Dismissible'),
      align: 'left',
      format: (value) => (value ? t('Yes') : t('No')),
    },
  ];

  useEffect(() => {
    if (selected) {
      setOpen(true);
    }
  }, [selected]);

  if (isFetching) {
    return (
      <TableContainer>
        <Skeleton variant="rectangular" width="90px" />
        <TableSkeleton columns={[{ id: 'name' }]} rowCount={4} />
      </TableContainer>
    );
  }

  return (
    <>
      <TableContainer sx={{ mb: 2 }} component={Paper}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ p: 2, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Typography variant="h5">{t('Notices')}</Typography>
          <Button
            data-cy="add-information-message"
            variant="outlined"
            sx={{ mt: 1, width: 'fit-content' }}
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
          >
            {t('Add')}
          </Button>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <TableRow
                  key={item.id}
                  data-cy={`information-message-${index}`}
                  onClick={() => setSelected(item.id)}
                  sx={{ '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}
                >
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {column.format ? column.format(item[column.id]) : item[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} height={100}>
                  <Typography align="center" color="textSecondary">
                    {t('No Messages')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <InformationMessageDialog
          open={open}
          handleClose={() => { setOpen(false); setSelected(null); }}
          messageID={selected}
        />
      )}
    </>
  );
};

export default InformationMessageTable;
