import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Dialog, DialogActions, DialogContent, MenuItem, TextField, Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import DialogTitle from '../../DialogTitle';
import MedicalAct from '../../form-inputs/MedicalAct';

const RegisterMedicalActDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const { medicalActType, medicalAct } = greyboxApiActions;
  const { uuid } = useParams();
  const { accountId } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const [addMedicalAct] = medicalAct.add();

  const formik = useFormik({
    initialValues: {
      medicalActType: '',
      note: '',
    },
    validationSchema: Yup.object({
      medicalActType: Yup.string().required(t('Required')),
      note: Yup.string(),
    }),
    onSubmit: (values) => {
      addMedicalAct({
        body: {
          patient: uuid,
          hcp: accountId,
          clinic: clinic.id,
          medical_act_type: values.medicalActType,
          note: values.note,
        },
        feedBack: {
          success: t('Follow-up registered'),
        },
      }).then(() => {
        handleClose();
      });
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Record a follow-up')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MedicalAct formik={formik} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
            {t('Register')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RegisterMedicalActDialog;
