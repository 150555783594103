import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button, Card, CardActions, CardContent, CardHeader,
  FormLabel,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import { getExtensionValueFromType } from '../../utils';
import Row from '../Row';
import {
  Clinic,
  EthnicGroup,
  FormTextField,
  Hin,
  Labels,
  Language, MultiSelect, Pharmacy, PhoneNumber, Sex,
  formatHinWithSpaces,
  hinNumberSchema,
  parseHinForPost,
} from '../form-inputs';
import BirthDate, { formatBirthDateForPost } from '../form-inputs/BirthDate';
import {
  Thresholds,
  parseThresholdsForPost,
  thresholdValidations,
  thresholdsInitValue,
} from '../form-inputs/Thresholds';
import { parseThreshold } from '../form-inputs/Threshold';
import { ZipCode, zipCodeSchema } from '../form-inputs/ZipCode';
import ArchiveModal from '../modals/ArchiveModal';

const PatientInformation = () => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const configs = clinic.config.features_enable;
  const { uuid } = useParams();
  const {
    account, threshold, diagnosis,
  } = greyboxApiActions;
  const { data = [] } = account.get(uuid);
  const [updateAccount] = account.update();
  const patientThreshold = threshold.list({ latest: true, localAccount: uuid });
  const [open, setOpen] = useState(false);
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const [createThreshold] = threshold.add();
  const { access } = useSelector((state) => state.user);
  const diagnosisSelector = diagnosis.list({ clinic: clinic.id });

  const informations = useFormik({
    initialValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      labels: data.labels || [],
      email: data.email || '',
      birthDate: data.birthDate ? moment(data.birthDate) : null,
      phoneNumber: data.phoneNumber || '',
      zipCode: data.zipCode || '',
      hin_number: formatHinWithSpaces(data.hin?.hin_number),
      hin_exp: data.hin ? moment(data.hin.hin_exp) : null,
      clinic: data.company?.[0] || clinic.id,
      gender: data.gender || 'U',
      language: data.language || 'fr',
      ethnic_group: data.ethnic_group || 'U',
      primaryDiagnosis: data?.diagnosisDetails
        ?.filter((d) => data.diagnosis.includes(d.diagnosis_key))
        .map((d) => ({ id: d.uuid, label: d.diagnosis_name, key: d.diagnosis_key })) || [],
      secondaryDiagnosis: diagnosisSelector.data
        ?.filter((d) => data.secondary_diagnosis.includes(d.uuid))
        .map((d) => ({ id: d.uuid, label: d.diagnosis_name, key: d.diagnosis_key })) || [],
      staffs: data.staffs ? data.staffs.map((staff) => ({ id: staff })) : [],
      pharmacy: getExtensionValueFromType(data.extension, 'patient-preferred-pharmacy'),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Required')),
      lastName: Yup.string().required(t('Required')),
      birthDate: Yup.date().nullable(),
      email: Yup.string().email(t('Invalid email address')),
      zipCode: zipCodeSchema,
      phone_number: Yup.string(),
      hin_number: hinNumberSchema,
      hin_exp: Yup.date().nullable(),
      clinic: Yup.string(),
      pharmacy: Yup.string(),
      primaryDiagnosis: Yup.array().of(Yup.object()),
      secondaryDiagnosis: Yup.array().of(Yup.object()),
      labels: Yup.array().of(Yup.object()),
    }),
    onSubmit: (values) => {
      const body = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== informations.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      if (body.staffs) {
        body.staffs = body.staffs.map((staff) => staff.id);
      }

      if (body.primaryDiagnosis) {
        body.diagnosis = body.primaryDiagnosis.map((d) => d.key);
        delete body.primaryDiagnosis;
      }

      if (body.secondaryDiagnosis) {
        body.secondary_diagnosis = body.secondaryDiagnosis.map((d) => d.id);
        delete body.secondaryDiagnosis;
      }

      if (body.clinic) {
        body.company = [body.clinic];
        delete body.clinic;
      }

      if (body.labels) {
        body.labels = body.labels.map((label) => label.id);
      }

      if (body.hin_number || body.hin_exp) {
        if (values.hin_number && values.hin_exp) {
          body.hin = parseHinForPost(
            values.hin_number.split(' ').join(''),
            values.hin_exp,
          );
        }
        delete body.hin_number;
        delete body.hin_exp;
      }

      if (body.birthDate) {
        body.birthDate = formatBirthDateForPost(body.birthDate);
      }

      if (body.pharmacy) {
        body.extension = [{
          url: 'https://ca.takecareapi.com/fhir/StructureDefinition/patient-preferred-pharmacy',
          valueId: values.pharmacy,
        }];
        delete body.pharmacy;
      }

      updateAccount({ id: uuid, body }).unwrap().then(() => {
        informations.setSubmitting(false);
      }).catch((error) => {
        informations.setSubmitting(false);
        // Assuming the server returns error details in a predictable format
        if (error.status === 400 && error.data) {
          const errors = error.data;
          informations.setErrors(errors);
        }
      });
    },
  });

  const thresholdForm = useFormik({
    initialValues: thresholdsInitValue(
      vitalsConfig, parseThreshold(patientThreshold.data, vitalsConfig),
    ),
    enableReinitialize: true,
    validationSchema: thresholdValidations(Object.keys(vitalsConfig)),
    onSubmit: (values) => {
      const body = parseThresholdsForPost(values, vitalsConfig);

      body.forEach((b) => {
        b.localAccount = uuid;
      });

      createThreshold({ body, feedback: { success: t('Thresholds updated') } }).unwrap()
        .then(thresholdForm.setSubmitting(false)).catch((error) => {
          thresholdForm.setSubmitting(false);
          // Assuming the server returns error details in a predictable format
          if (error.status === 400 && error.data) {
            const errors = error.data;
            thresholdForm.setErrors(errors);
          }
        });
    },
  });

  return (
    <Box sx={{ mb: 2 }}>
      <Card>
        <form onSubmit={informations.handleSubmit}>
          <CardHeader title={t('Patient Information')} />
          <CardContent>
            <Row>
              <FormTextField formik={informations} name="firstName" required label={t('First Name')} />
              <FormTextField formik={informations} name="lastName" required label={t('Last Name')} />
            </Row>
            <Row>
              <FormTextField formik={informations} name="email" label={t('Email')} />
              <BirthDate formik={informations} />
            </Row>
            <Row>
              <ZipCode formik={informations} />
              <PhoneNumber formik={informations} name="phoneNumber" />
            </Row>
            <Row>
              <Language formik={informations} />
              <Sex formik={informations} />
            </Row>
            <Row>
              <EthnicGroup formik={informations} />
              <Hin formik={informations} />
            </Row>
            {access === 'PT' && (
              <>
                <Row>
                  <Clinic formik={informations} />
                  <Labels formik={informations} />
                </Row>
                <MultiSelect
                  formik={informations}
                  type="diagnosis"
                  level="primary"
                  label={t('Primary Diagnosis')}
                  name="primaryDiagnosis"
                />
                {configs.secondary_diagnosis && (
                  <MultiSelect
                    formik={informations}
                    type="diagnosis"
                    level="secondary"
                    name="secondaryDiagnosis"
                    label={t('Secondary Diagnosis')}
                  />
                )}
                <MultiSelect formik={informations} type="staffs" label={t('Assigned HCP')} name="staffs" />
              </>
            )}
            <Row />
            <Row>
              {access === 'PT' && (
                <Box sx={{ mx: 1 }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    <FormLabel>
                      {t('Additional Information')}
                    </FormLabel>
                  </Box>
                  <Box sx={{
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    flexDirection: 'row',
                    mt: 1,
                    borderRadius: 3,
                    p: 1,
                  }}
                  >
                    <Box sx={{ pl: 1, width: '100%' }} display="flex" flexDirection="column">
                      <Typography>
                        <strong>
                          {data.name || ''}
                        </strong>
                      </Typography>
                      <Typography>
                        {`${t('Registered since')}: ${moment(data.createdDate).format('LLL')}`}
                      </Typography>
                      <Typography>
                        {`${t('Last active on')}: ${moment(data.lastActive).format('LLL')}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Pharmacy formik={informations} />
            </Row>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            {access === 'PT' && (
              <Button
                variant="contained"
                data-cy="archive-button"
                color="error"
                onClick={() => setOpen(true)}
                disabled={informations.isSubmitting}
              >
                {t('Archive')}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => informations.resetForm()}
              disabled={!informations.dirty || informations.isSubmitting}
            >
              {t('Reset')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={informations.isSubmitting}
              data-cy="informations-submit"
            >
              {t('Save')}
            </LoadingButton>
          </CardActions>
        </form>
        <ArchiveModal
          open={open}
          firstName={informations.values.firstName}
          handleClose={() => setOpen(false)}
          uuid={uuid}
        />
      </Card>
      {access === 'PT' && (
        <Card sx={{ mt: 1 }}>
          <CardHeader title={t('Thresholds Adjustment')} />
          <form onSubmit={thresholdForm.handleSubmit}>
            <CardContent>
              <Thresholds formik={thresholdForm} isLoading={patientThreshold.isFetching} />
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => thresholdForm.resetForm()}
                disabled={!thresholdForm.dirty}
              >
                {t('Reset')}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!thresholdForm.dirty}
                loading={thresholdForm.isSubmitting || patientThreshold.isFetching}
              >
                {t('Save')}
              </LoadingButton>
            </CardActions>
          </form>
        </Card>
      )}
    </Box>
  );
};

export default PatientInformation;
