import React from 'react';
import moment from 'moment';
import { Box, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../redux/api';
import Media from './Media';

const MessageBox = ({ message, previousMessage, threadId }) => {
  const { userId } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const { messageThread } = greyboxApiActions;
  const [deleteMessage] = messageThread.delete();
  const alignRight = userId === message.created_by.id;
  const time = moment(moment()).diff(message.created, 'days') > 0 ? moment(message.created).format('MMM DD, YYYY') : moment(message.created).format('h:mm A');
  const toolTipText = `${message.created_by.first_name} ${message.created_by.last_name} ${time}`;
  const isNewInstance = !previousMessage || moment(message.created).diff(previousMessage.created, 'minutes') > 5;
  const isNewPerson = !previousMessage || previousMessage.created_by.id !== message.created_by.id;
  const isScheduled = message.scheduled_time ? (
    moment(message.scheduled_time).isAfter(moment())
  ) : false;
  const [hover, setHover] = React.useState(false);
  const [translation, setTranslation] = React.useState('');

  const translate = async (text) => {
    const apiKey = '61568b414d3c4ecb9dbe4c4304753536';
    const baseUrl = 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0';
    const url = `${baseUrl}&to=${i18n.language}`;
    const headers = {
      'Ocp-Apim-Subscription-Key': apiKey,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const body = ([
      { text: text },
    ]);

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
    // print translation
    const result = await response.json();
    setTranslation(result[0].translations[0].text);
  };

  const scheduledToolTipText = isScheduled && userId === message.created_by.id
    ? t('Scheduled for', { scheduled_time: moment(message.scheduled_time).format('h:mm A') })
    : toolTipText;

  const getBackgroundColor = () => {
    if (alignRight) {
      if (isScheduled && userId === message.created_by.id) {
        return '#FFD700'; // gold for scheduled messages by current user
      }
      return '#e5f5ff'; // default color for user messages
    }
    return '#f5f5f5'; // default color for other messages
  };

  if (isScheduled && userId !== message.created_by.id) {
    return null;
  }

  return (
    <>
      <div>
        {(isNewPerson || isNewInstance) && (
          <Box display="flex" justifyContent={alignRight ? 'flex-end' : 'flex-start'}>
            <Typography variant="caption" sx={{ color: 'grey' }}>
              {`${message.created_by.first_name} ${message.created_by.last_name}`}
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent={alignRight ? 'flex-end' : 'flex-start'}>
          <Tooltip title={scheduledToolTipText} placement="left">
            <Box
              sx={{
                background: getBackgroundColor(),
                display: 'flex',
                borderRadius: '5px',
                m: 1,
                p: 1,
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                flexDirection: 'column',
                maxWidth: '85%',
                width: 'fit-content',
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {message.media ? (
                <Media media={message.media} />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: 'pre-line' }}
                >
                  {message.message}
                </Typography>
              )}
              {hover && (
                <Box display="flex">
                  {!translation && !message.media && (
                    <Typography
                      variant="body2"
                      sx={{
                        cursor: 'pointer',
                        color: 'blue',
                        alignSelf: 'flex-start',
                        textDecoration: 'underline',
                        mr: 1,
                      }}
                      onClick={() => translate(message.message)}
                    >
                      {t('Translate')}
                    </Typography>
                  )}
                  {alignRight && (
                    <Typography
                      variant="body2"
                      sx={{
                        cursor: 'pointer', color: 'blue', alignSelf: 'flex-start', textDecoration: 'underline',
                      }}
                      onClick={() => deleteMessage(`${threadId}/message/${message.id}`)}
                    >
                      {t('Delete')}
                    </Typography>
                  )}
                </Box>
              )}
              {translation && (
                <>
                  <Typography
                    variant="caption"
                  >
                    {`(${t('Auto-translated')})`}
                  </Typography>
                  <Typography variant="body2">
                    {translation}
                  </Typography>
                </>
              )}
            </Box>
          </Tooltip>
        </Box>
      </div>
      {isNewInstance && (
        <Typography variant="caption" sx={{ color: 'grey', alignSelf: 'center' }}>
          {time}
        </Typography>
      )}
    </>
  );
};

export default MessageBox;
