import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { setTempAuthorization } from '../redux/authorization';

const AuthenticateForQuestionnaire = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const id = params.get('id');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (token) {
      dispatch(setTempAuthorization({ token: `Token ${token}`, authState: 'questionnaire' }));
      history.push(`/complete-questionnaire/${id}`);
    }
  }, []);

  return null;
};

export default AuthenticateForQuestionnaire;
