import {
  Table,
  TableBody,
  TableCell, TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';
import React from 'react';

const TableSkeleton = (props) => {
  const { rowCount, colCount = 5, size = 'medium' } = props;

  return (
    <Table size={size}>
      <TableHead>
        <TableRow>
          {[...Array(colCount)].map((_, i) => (
            <TableCell key={i}>
              <Skeleton variant="text" width={75} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(rowCount)].map((_, j) => (
          <TableRow key={j}>
            {[...Array(colCount)].map((_, i) => (
              <TableCell key={i + (100 * j)}>
                <Skeleton variant="text" width={75} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableSkeleton;
