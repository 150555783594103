import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Typography } from '@mui/material';
import { AlertBlue, XIcon } from '../../../tk-ui';
import { contraStyle } from './contraindication_styling_web';

// this is the alignement portion for every click, the alignement is precisly tailored.
// we go by an index  [indexBox] to set this up nicelly+
const sharedAlignementIndex = [
  {
    anchorOriginVertical: 'top',
    anchorOriginHorizontal: 'left',
    transformOriginVertical: 'bottom',
    transformOriginHorizontal: 'left',
  },
  {
    anchorOriginVertical: 'top',
    anchorOriginHorizontal: 'left',
    transformOriginVertical: 'bottom',
    transformOriginHorizontal: 'left',
  },
  {
    anchorOriginVertical: 'top',
    anchorOriginHorizontal: 'left',
    transformOriginVertical: 'bottom',
    transformOriginHorizontal: 'left',
  },
  {
    anchorOriginVertical: 'top',
    anchorOriginHorizontal: 'left',
    transformOriginVertical: 'bottom',
    transformOriginHorizontal: 'left',
  },
  {
    anchorOriginVertical: 'center',
    anchorOriginHorizontal: 'center',
    transformOriginVertical: 'center',
    transformOriginHorizontal: 'center',
  },
  {
    anchorOriginVertical: 'top',
    anchorOriginHorizontal: 'left',
    transformOriginVertical: 'bottom',
    transformOriginHorizontal: 'left',
  },
  {
    anchorOriginVertical: 'top',
    anchorOriginHorizontal: 'left',
    transformOriginVertical: 'bottom',
    transformOriginHorizontal: 'left',
  },
];

// return a tree-way possible mapping of component.
const qualityCheck = (el, molecule, target, unit, operator, latestValue, i18n) => {
  // if no warning, and the patient has values
  if (!el.displayWarning && el.currentPatientValue) {
    return (
      <ContraClear
        molecule={molecule}
        target={target}
        unit={unit}
        operator={operator}
        latestValue={latestValue}
      />
    );
  }
  // if there is a warning
  if (el.displayWarning) {
    return (
      <ContraWarning
        molecule={molecule}
        target={target}
        unit={unit}
        operator={operator}
        latestValue={latestValue}
      />
    );
  }
  return null;
};

// RENDERS WHEN ALL THE PATIENTS HAS DATA OUTSIDE GUIDELINES
const ContraWarning = (props) => {
  const {
    molecule, target, unit, operator, latestValue,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <div style={contraStyle.sectionWrapper} key={molecule} cy-data="sectionWrapperHover">
      <div style={contraStyle.warningBar} cy-data="warningBar" />
      <div style={contraStyle.warningIcon} cy-data="warningIconWrapper">
        <Typography style={contraStyle.warningTitle} cy-data="warningTitle">
          {molecule}
          :
          {' '}
          <span style={contraStyle.bolderStyle} cy-data="latestValue">
            {' '}
            {latestValue === true ? t('Positive diagnosis') : `${latestValue} ${unit}`}
          </span>
        </Typography>
      </div>

      <Typography style={contraStyle.recommendations} cy-data="recommendations">
        {createSentence(operator, i18n.resolvedLanguage)}
        {' '}
        <span style={contraStyle.lighterStyle} cy-data="wraningTarget">
          {target}
          {' '}
          {unit}
        </span>
      </Typography>
    </div>
  );
};

// RENDERS WHEN ALL IS GOOD - GREEN
const ContraClear = (props) => {
  const {
    molecule, target, unit, operator, latestValue,
  } = props;
  const { i18n, t } = useTranslation();

  return (
    <div style={contraStyle.sectionWrapper} key={molecule}>
      <div style={contraStyle.clearBar} />
      <div style={contraStyle.textPosition}>
        <Typography style={contraStyle.clearTitle}>
          {molecule}
          :
          {' '}
          <span style={contraStyle.bolderStyle}>
            {' '}
            {latestValue === false ? t('Negative diagnosis') : `${latestValue} ${unit}`}
          </span>
        </Typography>
      </div>
      <Typography style={contraStyle.recommendations}>
        {createSentence(operator, i18n.resolvedLanguage)}
        {' '}
        <span style={contraStyle.lighterStyle}>
          {target}
          {' '}
          {unit}
        </span>
      </Typography>
    </div>
  );
};

// RENDERS WHEN ALL THE PATIENTS HAS NO DATA
const ContraNoData = (props) => {
  const {
    molecule, target, unit, operator, latestValue,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <div style={contraStyle.sectionWrapper} key={molecule} cy-data="sectionWrapperHover">
      <div style={contraStyle.noDataBar} cy-data="noDataBar" />

      <div style={contraStyle.textPosition} cy-data="noDataTextPosition">
        <Typography style={contraStyle.noDataTitle} cy-data="noDataTitle">
          {molecule}
          :
          {' '}
          <span style={contraStyle.bolderStyle} cy-data="noDataValue">
            {latestValue === false ? t('No diagnosis') : t('No data')}
          </span>
        </Typography>
      </div>

      <Typography style={contraStyle.recommendations} cy-data="recommendations">
        {createSentence(operator, i18n.resolvedLanguage)}
        {' '}
        <span style={contraStyle.lighterStyle} cy-data="noDataTarget">
          {target}
          {' '}
          {unit}
        </span>
      </Typography>
    </div>
  );
};

// creates the sentences
const createSentence = (operator, language) => {
  const { i18n, t } = useTranslation();
  let opSentence = null;
  if (operator === '<=') opSentence = t('Should not be over');
  if (operator === '=') opSentence = '';
  if (operator === '<') opSentence = t('Should be below');
  if (operator === '>=') opSentence = t('Should not be lower than');
  if (operator === '>') opSentence = t('Should be over');

  return opSentence;
};

// main logic function. the popover, maps the elements in the contraindication array.
const Contraindications = (props) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    medName = '',
    elevation = 0,
    onCards,
    indexBox,
    contraindications,
    warning,
    drugclass,
    btnStyle,
  } = props;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!contraindications) return null;
  // if we want to display plural or not.
  const warningCount = contraindications.filter((el) => el.displayWarning === true).length;

  return (
    <>
      <Popover
        id="contraindications-popper"
        cy-data="popover"
        open={open}
        elevation={elevation}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableRestoreFocus
        style={contraStyle.tooltipAdjustment}
      >
        <div
          style={
            onCards
              ? { ...contraStyle.tooltipWrapper }
              : { ...contraStyle.tooltipWrapper, cursor: 'default' }
          }
          role="button"
          tabIndex={0}
          onClick={() => {
            !onCards && handleClose();
          }}
        >
          <div
            style={contraStyle.closeIcon}
            onClick={handleClose}
            role="button"
            tabIndex={0}
          >
            <XIcon fills="#babdbf" />
          </div>

          <div className="headerText" style={contraStyle.classTitle} cy-data="headerText">
            {drugclass ? `${drugclass[i18n.resolvedLanguage]} -` : null}
            {' '}
            {t('Caution')}
            {medName ? (
              <span style={{ fontSize: '13px', textTransform: 'capitalize', opacity: 0.5 }}>
                {' '}
                -
                {' '}
                {medName.toLowerCase()}
                {' '}
              </span>
            ) : null}
          </div>
          <div>
            {contraindications
              && contraindications.map((el, index) => (
                <div key={index}>
                  {qualityCheck(
                    el, // THE ELEMENT
                    el.name[i18n.resolvedLanguage], // MOLECULE should be name
                    el.var2, // TARGET VAL
                    el.unit, // UNIT
                    el.op, // OPERATOR
                    el.currentPatientValue, // PATIENT LATEST INFO , val / null / bool
                    i18n,
                  )}
                </div>
              ))}
          </div>
        </div>
      </Popover>
      {/* this is the button to click to open contraindications.  */}
      {onCards ? (
        warning ? (
          <Button
            aria-owns={open ? 'contraindications-popper' : undefined}
            cy-data={`contraindications-btn${indexBox}`}
            aria-haspopup="true"
            onClick={handleClick}
            style={contraStyle.warningButton}
          >
            <span style={contraStyle.placement}>
              <AlertBlue fills="#FFF" viewBox="2 -15 14 50" />
              {' '}
            </span>
            {' '}
            {warningCount > 1 ? t('CAUTIONS') : t('CAUTION')}
          </Button>
        ) : (
          <Button
            aria-owns={open ? 'contraindications-popper' : undefined}
            cy-data={`contraindications-btn${indexBox}`}
            aria-haspopup="true"
            onClick={handleClick}
            style={open ? contraStyle.buttonSelected : contraStyle.button}
          >
            {/* {t("CAUTIONS")} */}
            {warningCount > 1 ? t('CAUTIONS') : t('CAUTION')}
          </Button>
        )
      ) : (
        <span
          aria-owns={open ? 'table-popper' : undefined}
          cy-data={`contraindications-btn${indexBox}`}
          aria-haspopup="true"
          role="button"
          tabIndex={0}
          onClick={handleClick}
          style={btnStyle}
        />
      )}
    </>
  );
};

export default Contraindications;
