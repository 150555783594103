import React from 'react';
import { SvgIcon } from "@mui/material";

const DownloadIcon = (props) => {
  const { viewBox = "0 0 30 30" } = props;
  return (
  <SvgIcon {...props} viewBox={viewBox} >
      <path d="M24,11.47H18.86V3H11.14v8.47H6l9,9.88ZM6,24.18V27H24V24.18Z" /> 
  </SvgIcon>
  )
}

export { DownloadIcon }
