const graph_style = {
  legend_wrapper: {
    position: 'relative',
    top: '0px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#454545',
    display: 'grid',
    justifyContent: 'center',
    gridAutoFlow: 'column',
    gridGap: '16px',
  },
  legend_text_label: {
    display: 'flex',
  },
  legend_gray_dot: {
    width: '12px',
    height: '12px',
    backgroundColor: '#919eab',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '4px',
  },
  legend_dotted_line: {
    border: 'none',
    borderTop: '2px dashed #09bbd0',
    color: '#fff',
    backgroundColor: '#fff',
    height: '1px',
    width: '12px',
    marginRight: '4px',
  },
  legend_dotted_line_gray: {
    border: 'none',
    borderTop: '2px dashed #747474',
    color: '#fff',
    backgroundColor: '#fff',
    height: '1px',
    width: '12px',
    marginRight: '4px',
  },
  x_axis: {
    axis: {
      stroke: '#09bbd0',
    },
    axisLabel: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#454545',
    },
    tickLabels: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      fill: '#95989a',
      padding: '2',
    },
  },
  y_axis: {
    axis: {
      stroke: 'none',
    },
    axisLabel: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
      color: '#454545',
      padding: '35',
    },
    tickLabels: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.17,
      letterSpacing: 'normal',
      textAlign: 'left',
      fill: '#95989a',
    },
    grid: {
      fill: 'none',
      stroke: '#e7eaed',
      pointerEvents: 'painted',
    },
  },
};

export default graph_style;
