import {
  Paper, Box, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import moment from 'moment';
import { greyboxApiActions } from '../../../redux/api';
import CurrentMedicationsWrapper from './medicationTable/medicationsTableWrapper';
import SuggestedMedications from './suggestedMedications/SuggestedMedications';
import TitrationSection from './titration/TitrationSections';
import { statusShapes } from './titrationStatusChoices';
import TimelineChart from '../../chart/TimelineChart';
import { parseMedForTimeLine } from '../../chart/utils';
import DateRangePicker from '../dateRangePicker/DateRangePicker';

const MedicationTab = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { startDate } = useSelector((state) => state.patientProfileDateRange);
  const { treatmentPlan, medication } = greyboxApiActions;
  const { config } = clinic;
  const user = useSelector((state) => state.user);
  const treatmentPlanSelector = treatmentPlan.list({
    patient: uuid, latest: true, clinic: clinic.id,
  });

  const [parsedData, setParsedData] = React.useState([]);
  const [colors, setColors] = React.useState([]);

  const { data, isFetching } = medication.list({
    account: uuid,
    start_date__gte: moment(startDate).format('YYYY-MM-DD'),
    status: 'active,completed,intended',
  });

  useEffect(() => {
    if (data) {
      const { medForTimeLine, colors: newColors } = parseMedForTimeLine(data);
      setParsedData(medForTimeLine);
      setColors(newColors);
    }
  }, [data]);

  // returns every status in an Obj - see titrationStatusChoices.js
  const titration_status_choices = statusShapes();
  const decisionStatus = treatmentPlanSelector.isFetching;

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {t('Treatment Plans')}
        </Typography>
        <DateRangePicker />
      </Box>
      <TimelineChart
        series={parsedData}
        isLoading={isFetching}
        name={t('Medications Timeline')}
        colors={colors}
      />
      <Paper sx={{ p: 2, mt: 1 }}>
        <CurrentMedicationsWrapper titration_status_choices={titration_status_choices} />
        {config.features_enable.decisionTree && (
          <>
            <SuggestedMedications
              uuid={uuid}
              acc_type={user.access}
              role_type_code={user.role}
            />
            <TitrationSection
              uuid={uuid}
              decisionStatus={decisionStatus}
              titration_status_choices={titration_status_choices}
            />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default MedicationTab;
