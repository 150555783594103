import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card, TableContainer, Paper, Stack, TablePagination, Checkbox, Dialog,
  Skeleton, Button, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell,
} from '@mui/material';
import { greyboxApiActions } from '../redux/api';
import TableSkeleton from '../components/skeletons/TableSkeleton';
import TablePaginationSkeleton from '../components/skeletons/TablePaginationSkeleton';
import { INVITATION_TYPE } from '../components/invitations/utils';
import InvitationCreation from '../components/invitations/InvitationsCreation';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const PendingInvitations = () => {
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { invitation } = greyboxApiActions;
  const { data, isLoading } = invitation.list({ status: 'pending', page: 1, clinic: clinic.id });
  const [updateInvitation] = invitation.update();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setDisabled(selected.length === 0);
  }, [selected]);

  const handleSelection = (e, id) => {
    e.stopPropagation();
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleClickChangeStatus = () => {
    Promise.all(selected.map((id) => {
      updateInvitation({
        id: id,
        body: {
          modifyDate: moment().toISOString(true),
          status: 'cancelled',
        },
      });
      return null;
    }))
      .then(setSelected([]));
  };

  const handleClickResend = () => {
    Promise.all(selected.map((id) => {
      updateInvitation({
        id: id,
        modifyDate: moment().toISOString(true),
      });
      return null;
    }))
      .then(setSelected([]));
  };

  const handleSelectAll = () => {
    if (selected.length === data.length) {
      setSelected([]);
    } else {
      setSelected(data.map((row) => row.uuid));
    }
  };

  const columns = [
    {
      id: 'firstName',
      label: t('First name'),
      minWidth: 100,
      value: (row) => row.invitationFormFields?.firstName || '',
    },
    {
      id: 'lastName',
      label: t('Last name'),
      minWidth: 100,
      value: (row) => row.invitationFormFields?.lastName || '',
    },
    {
      id: 'type',
      label: t('Type'),
      minWidth: 60,
      value: (row) => t(INVITATION_TYPE[row.type]),
    },
    {
      id: 'email',
      label: t('Email'),
      minWidth: 170,
    },
    {
      id: 'phoneNumber',
      label: t('Phone number'),
      minWidth: 100,
    },
    {
      id: 'modifyDate',
      label: t('Invitation date'),
      minWidth: 100,
      format: (value) => moment(value).format('YYYY-MM-DD'),
    },
    {
      id: 'invitedBy',
      label: t('Invited by'),
      minWidth: 100,
      value: (row) => row.hcpName,
    },
  ];

  const classes = useStyles();

  if (isLoading) {
    return (
      <Card className={classes.invitationsPanel}>
        <div className={classes.container}>
          <TableContainer component={Paper}>
            <div className={classes.toolbar}>
              <Skeleton variant="rect" width={84} height={35} />
              <span style={{ marginLeft: '8px' }} />
              <Skeleton variant="rect" width={84} height={35} />
              <div style={{ marginLeft: 'auto' }}>
                <TablePaginationSkeleton />
              </div>
            </div>
            <TableSkeleton columns={columns} rowCount={10} />
          </TableContainer>
        </div>
      </Card>
    );
  }

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5">
            {t('Pending Invitations')}
          </Typography>
          <Typography variant="subtitle1">
            {t('A reminder SMS is automatically sent 3 days after registration if not activated')}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Box
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              p: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setOpen(true)}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              {t('New Invitation')}
            </Button>
            <Button
              onClick={() => handleClickResend()}
              sx={{ mr: 1 }}
              startIcon={<SendIcon />}
              disabled={disabled}
            >
              {t('Resend')}
            </Button>
            <Button
              onClick={() => handleClickChangeStatus()}
              startIcon={<DeleteIcon />}
              sx={{ mr: 1 }}
              disabled={disabled}
              color="error"
            >
              {t('Delete')}
            </Button>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              sx={{ ml: 'auto' }}
              count={data.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t('Rows per page')}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
            />
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < data.length}
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.uuid} selected={selected.includes(row.uuid)}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.includes(row.uuid)}
                        onClick={(event) => handleSelection(event, row.uuid)}
                      />
                    </TableCell>
                    {columns.map((column) => {
                      const value = column.value ? column.value(row) : row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))) : (
                  <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center" sx={{ height: '300px' }}>
                      {t('No data')}
                    </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <InvitationCreation open={open} handleClose={() => setOpen(false)} />
    </>
  );
};
export default PendingInvitations;
