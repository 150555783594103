import React from 'react';
import { TextField } from '@mui/material';

interface PropsType {
  formik: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  type?: string;
  pattern?: string;
  min?: number;
  max?: number;
  sx?: any;
}

const FormTextField = (props: PropsType) => {
  const {
    formik, name, label, required, sx,
    type, pattern, disabled, min, max,
  } = props;

  return (
    <TextField
      id={name}
      data-cy={name}
      sx={sx || { mx: 1 }}
      required={required}
      name={name}
      label={label}
      placeholder={label}
      disabled={disabled}
      type={type}
      value={formik.values[name] || ''}
      onBlur={formik.handleBlur}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      inputProps={{ pattern, min, max }}
      onChange={formik.handleChange}
    />
  );
};

FormTextField.defaultProps = {
  required: false,
  sx: null,
  type: 'text',
  pattern: null,
  disabled: false,
};

export default FormTextField;
