import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

const keyConfig = {
  localhost: '54cd99c9-5e22-4741-b47d-04dc9ac958e2',
  'dev.greybox.ca': '54cd99c9-5e22-4741-b47d-04dc9ac958e2',
  'dev-ca.greybox.fr': '54cd99c9-5e22-4741-b47d-04dc9ac958e2',
  'dev.greybox.fr': '54cd99c9-5e22-4741-b47d-04dc9ac958e2',
  'staging.greybox.ca': '706e7f63-3aa3-42bb-ad81-4fca0d924b26',
  'staging.greybox.fr': '706e7f63-3aa3-42bb-ad81-4fca0d924b26',
  'takecare.greybox.ca': '908b13b8-2fee-4e9e-9769-13a658917c6a',
  'takecare.greybox.fr': '908b13b8-2fee-4e9e-9769-13a658917c6a',
};

const headerConfig = {
  localhost: 'dev-ca.takecareapi.com',
  'dev.greybox.ca': 'dev-ca.takecareapi.com',
  'dev-ca.greybox.fr': 'dev-ca.takecareapi.com',
  'dev.greybox.fr': 'dev-ca.takecareapi.com',
  'staging.greybox.ca': 'staging-ca.takecareapi.com',
  'staging.greybox.fr': 'staging-ca.takecareapi.com',
  'takecare.greybox.ca': 'ca.takecareapi.com',
  'takecare.greybox.fr': 'ca.takecareapi.com',
};

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: `instrumentationKey=${keyConfig[window.location.hostname]}`,
    extensions: [reactPlugin],
    correlationHeaderDomains: [headerConfig[window.location.hostname]],
    enableCorsCorrelation: true,
    disableFetchTracking: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    enableAutoRouteTracking: true,
    disableInstrumentationKeyValidation: window.location.hostname === 'localhost',
  },
});

ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);
export const { appInsights } = ai;
export { reactPlugin };
