import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ObservationChart from '../../chart/ObservationChart';
import { greyboxApiActions } from '../../../redux/api';
import LabResultDetailsTable from '../labResults/LabResultDetailsTable';
import { DateRangePicker } from '../dateRangePicker';

const ClinicalDataDetails = () => {
  const { t, i18n } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { clinicalDataConfig } = greyboxApiActions;
  const { clinicalDataCode } = useParams();
  const { data = [], isLoading } = clinicalDataConfig.list({ clinic: clinic.id });

  const config = data.find((item) => item.short_code === clinicalDataCode);

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {config[`name_${i18n.resolvedLanguage}`]}
        </Typography>
        <DateRangePicker />
      </Box>
      <ObservationChart
        measures={[{
          ...config,
          id: config.uuid,
          is_specific: false,
        }]}
        config={{
          label: config[`name_${i18n.resolvedLanguage}`],
          unit: config.unit,
          chartType: 'line',
          values: ['observation'],
          colors: ['spo2'],
        }}
        detailed
      />
      <Paper sx={{ width: '100%', mt: 1 }}>
        <LabResultDetailsTable config={{
          label: config[`name_${i18n.resolvedLanguage}`],
          unit: config.unit,
          chartType: 'line',
          values: ['observation'],
          colors: ['spo2'],
        }}
        />
      </Paper>
    </Box>
  );
};

export default ClinicalDataDetails;
