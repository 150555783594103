import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  enabled: {
    background: ({ color }) => theme.palette[color].main,
    color: ({ color }) => theme.palette[color].text,
    '&:hover': {
      background: ({ color }) => theme.palette[color].dark,
    },
  },
  disabled: {
    color: theme.palette.text.secondary,
    background: theme.palette.disabled.main,
  },
  buttonShape: {
    borderRadius: '3px',
    flexWrap: 'no-wrap',
    whiteSpace: 'nowrap',
    transition: 'background 1s ease',
  },
}));

export const Buttons = (props) => {
  const {
    children, // inside a 2 buttons tags
    type = null, // reset or submit
    disabled = false,
    startIcon = null, // icon before text
    startIconStyle = {}, // position and styling for icon
    endIcon = null, // icon end text
    endIconStyle = {}, // position and styling for icon
    paddings = '4px 8px',
    text = null,
    textSize = '14px',
    margins = '0 5px',
    background = null, // background color (not based on theme)
    textColor = '#fff', // textColor (not based on theme)
    borderRadius = '3px',
    lowerCase = false,
    onClick = null,
    onMouseEnter = null,
    onMouseLeave = null,
    ownStyle = null, // obj {} of css attributes
    borderColor = null,
    color = 'primary', // background color based on theme
    size,
  } = props;

  const classes = useStyles({ color });

  const handleClassName = () => `${classes.buttonShape} ${disabled ? classes.disabled : classes.enabled}`;

  return (
    <>
      <Button
        variant="contained"
        data-cy="btn"
        type={type}
        disableElevation
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={disabled}
        size={size || 'medium'}
        className={handleClassName()}
        style={ownStyle
          ? { ...ownStyle }
          : {
            padding: `${paddings}`,
            background: `${background}`,
            margin: `${margins}`,
            border: `1px solid ${borderColor}`,
            borderRadius: `${borderRadius}`,
            fontSize: `${textSize}`,
            textTransform: lowerCase ? 'none' : null,
          }}
      >
        <>
          <span style={startIconStyle}>{startIcon || null}</span>
          {text}
          {children}
          <span style={endIconStyle}>{endIcon || null}</span>
        </>
      </Button>
    </>
  );
};
