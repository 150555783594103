import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import Box from '@mui/material/Box';
import Progress_Bar from './progressBar';
import Header from './header';
import Graph from './graph';

/*
  DESCRIPTION:
    Function that find the difference between the time since the patient is
    on a dosage and the suggested duration of said dosage.
  INPUT:
    lastDoseData: Information on last dose.
    steps: A lists of all steps for this medication.
    weekSince: Number of weeks since the patient started taking the last dose
  OUTPUT:
    A number indicating the difference
*/
const diffStepDurationAndTimeOnDosage = (patientDoseData, steps) => {
  const lastDose = patientDoseData[patientDoseData.length - 1].total_dose;
  let duration = 0;
  let lower = 0;
  let upper = 0;

  // We want to find the stage for which lastDose is greater than stage dose
  // and lower than next stage dose
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].dosage > lastDose) {
      duration = steps[i].duration;
      lower = i > 0 ? steps[i - 1].dosage : 0;
      upper = steps[i].dosage;
      break;
    }
  }
  // Cumulate the number of weeks that are between those steps
  // starting from the second to last day
  let weeksSince = 0;
  if (patientDoseData.length > 1) {
    for (let i = patientDoseData.length - 2; i >= 0; i--) {
      const currentDose = patientDoseData[i].total_dose;

      if (currentDose >= lower && currentDose < upper) {
        weeksSince++;
      } else {
        break;
      }
    }
  }

  const delta = duration - weeksSince;
  // Return 0 if the number of week is greater than the duration
  return delta > 0 ? delta : 0;
};

/*
  DESCRIPTION:
    Function to generate data needed to plot updated doses when
    late on the original plan
  INPUT:
    lastDose: Last dose information
    steps: A lists of all steps for this medication
    delta: difference between the time since the patient is
           on a dosage and the suggested duration of said dosage.
  OUTPUT:
    Array of objects specifying x and y values of data points
    (this is the format expected by Victory charts)
*/

const computeUpdatedDosesData = (lastDoseData, steps, delta) => {
  const refDose = lastDoseData.total_dose;
  let currentWeek = lastDoseData.week;
  let currentDose = lastDoseData.total_dose;

  // Add current dose
  const updatedDosesData = [{
    x: currentWeek,
    y: refDose,
  }];

  // Consider for how long the patient is on this dose and
  // add the rest of the steps as is after that
  currentWeek += delta;
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].dosage > refDose) {
      if (steps[i - 1] && currentDose <= steps[i - 1].dosage) {
        updatedDosesData.push(
          { x: currentWeek, y: steps[i - 1].dosage },
          { x: currentWeek, y: steps[i].dosage },
        );
      } else {
        updatedDosesData.push(
          { x: currentWeek, y: currentDose },
          { x: currentWeek, y: steps[i].dosage },
        );
      }
      currentDose = steps[i].dosage;
      currentWeek += steps[i].duration;
    }
  }

  return updatedDosesData;
};

/*
  DESCRIPTION:
    Function to generate data needed to plot suggested dose
  INPUT:
    titration: titration for which we are generating graph
    numWeeks: the number of weeks that should be displayed in graph
              (This is the duration of titration in weeks, or the number
              of weeks for which the patient has been taking medications,
              whichever is greater)
  OUTPUT:
    array of objects specifying x and y values of data points
    (this is the format expected by Victory charts)
*/
const computeSuggestedDoseData = (titration, numWeeks) => {
  if (titration.dosage_steps.duration_unit !== 'w') {
    return []; // cannot compute dose data if titraiton unit is not 'w' (weeks)
  }

  const targetDose = titration.dosage_steps.steps[titration.dosage_steps.steps.length - 1].dosage;
  const suggestedDoseData = [];
  let x_index = 0;

  // Push data for each week of the titration plan
  titration.dosage_steps.steps.forEach((step, step_index) => {
    // At the start of every step except the first one, need to add an extra data point
    // with prev step's dosage at same x_index as next point, to prevent areachart from looking jagged
    if (step_index > 0) {
      const prevStep = titration.dosage_steps.steps[step_index - 1];
      suggestedDoseData.push({ x: x_index + 1, y: prevStep.dosage });
    }

    // Add a data point for every week in current dose step
    for (let i = 0; i < step.duration; i++) {
      x_index++;
      suggestedDoseData.push({ x: x_index, y: step.dosage });
    }
  });

  // If patient has been taking the medication longer than titration plan duration,
  // append data with target dosage to bring it to the number of weeks patient is taking meds
  while (x_index < numWeeks) {
    x_index++;
    suggestedDoseData.push({ x: x_index, y: targetDose });
  }

  // If x_index is less than 12, extend suggested data to 12 weeks (because graph must display 12 or 24 weeks)
  // If x_index is > 12 but < 24, extend suggested data to 24 weeks (because graph must display 12 or 24 weeks)
  if (x_index < 12) {
    // prolong to 12 weeks
    while (x_index < 12) {
      x_index++;
      suggestedDoseData.push({ x: x_index, y: targetDose });
    }
  } else if (x_index > 12 && x_index < 24) {
    // prolong to 24 weeks
    while (x_index < 24) {
      x_index++;
      suggestedDoseData.push({ x: x_index, y: targetDose });
    }
  }

  return suggestedDoseData;
};

/*
  DESCRIPTION:
    Function to calculate the number of weeks that have elapsed since a given start date
  INPUT:
    start_date (string of format 'YYYY-MM-DD')
    end_date (string of format 'YYYY-MM-DD'. Optional. Defaults to today's date)
  OUTPUT:
    int (number of weeks elapsed since input start date)
*/
const get_num_weeks_elapsed = (start_date, end_date = moment().format('YYYY-MM-DD'), include_current_week = true) => {
  const end_date_moment = moment(end_date); // start of day
  if (include_current_week) {
    end_date_moment.add(12, 'hours'); // calculate as if end_date day is underway
  }
  const start_date_moment = moment(start_date); // start of day

  // NOTE: do not use 'moment.duration()', it has approximation issues
  const duration_weeks = end_date_moment.diff(start_date_moment, 'weeks', true); // returns floating point number

  // round up, because we want to show current (incomplete) week
  return Math.ceil(duration_weeks);
};

const Graph_Wrapper = (props) => {
  const {
    uuid, // patient uuid
    titration_status_choices, // config object for all possible titration statuses, with display name and colors
    titrationStatusInfo, // titration status obj from API response
  } = props;

  const { titration_status } = titrationStatusInfo;

  const { titration } = titrationStatusInfo;

  const { titration_start_date } = titrationStatusInfo;
  const num_weeks_medication_being_taken = titrationStatusInfo.titration_history_doses_extended.length;

  // Calculate patient dose data(needed for titration graph)
  const patientDoseData = titrationStatusInfo.titration_history_doses_extended;

  // Calculate titration's projected reach and set status
  const { projected_reach_date } = titrationStatusInfo;

  // Calculate how many weeks away projected_reach_date is since starting meds and projected reach data point
  let projected_reach_duration_weeks = 0;
  let projectedReachDataPoint = null;
  let updatedDoseData = null;
  if (titration_status_choices[titration_status]?.display_projected_reach && projected_reach_date !== null) {
    const delta = diffStepDurationAndTimeOnDosage(
      patientDoseData,
      titration.dosage_steps.steps,
    );

    projected_reach_duration_weeks = get_num_weeks_elapsed(
      titration_start_date,
      projected_reach_date,
      false,
    );
    projectedReachDataPoint = {
      x: projected_reach_duration_weeks,
      y: titration.dosage_steps.steps[titration.dosage_steps.steps.length - 1].dosage, // target dose
    };
    updatedDoseData = useMemo(() => computeUpdatedDosesData(
      patientDoseData[patientDoseData.length - 1],
      titration.dosage_steps.steps,
      delta,
    ), [titration.uuid, patientDoseData]);
  }

  // Number of weeks to display in graph. This will be the duration of titration in weeks,
  // or the number of weeks for which the patient has been taking medications, whichever is greater.
  const num_weeks = Math.max(
    titration.dosage_steps.duration_total,
    num_weeks_medication_being_taken,
    projected_reach_duration_weeks,
  );

  // Calculate suggested dose data(needed for titration graph)
  const suggestedDoseData = useMemo(() => computeSuggestedDoseData(titration, num_weeks), [titration.uuid, num_weeks]);

  return (
    <Box sx={{
      width: '546px',
      height: '520px',
      borderRadius: 2,
      mt: 1,
      mr: 1,
      backgroundColor: '#f5faff',
      border: (theme) => `1px solid ${theme.palette.divider}`,
      padding: '20px',
      marginRight: '8px',
    }}
    >
      <Header
        med_name={titrationStatusInfo.medication_names.length > 0 ? titrationStatusInfo.medication_names[0] : ''}
        projected_reach_date={projected_reach_date}
        titration_status={titration_status}
        titration_status_choices={titration_status_choices}
        titration_start_date={titration_start_date}
        titration={titration}
      />
      <Progress_Bar
        titrationStatusInfo={titrationStatusInfo}
      />
      <Graph
        suggestedDoseData={suggestedDoseData}
        patientDoseData={patientDoseData}
        projectedReachDataPoint={projectedReachDataPoint}
        num_weeks={num_weeks}
        dose_unit={titrationStatusInfo.titration.unit.toLowerCase()}
        color_code={titration_status_choices[titration_status].color_code}
        updatedDoseData={updatedDoseData}
      />
    </Box>
  );
};

export {
  computeSuggestedDoseData,
  get_num_weeks_elapsed,
  computeUpdatedDosesData,
  diffStepDurationAndTimeOnDosage,
};

export default Graph_Wrapper;
