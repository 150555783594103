import {
  Box, Button,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
} from 'react-router-dom';

const ErrorFallback = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoBack = () => {
    history.push('/');
    window.location.reload();
  };
  return (
    <Box
      role="alert"
      sx={{
        display: 'flex',
        height: '100vh',
        backgroundColor: 'background.default',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5">{t('Something went wrong')}</Typography>
        <Box sx={{
          backgroundColor: 'background.default',
          p: 1,
          borderRadius: '5px',
        }}
        >
          <Typography>
            {t('An error has occurred Please try again later or contact support at')}
            {' : '}
            <br />
            <a href="mailto:support@greybox.biz">
              support@greybox.biz
            </a>
          </Typography>
        </Box>
        <Button
          onClick={handleGoBack}
        >
          {t('Go to home page')}
        </Button>
      </Paper>
    </Box>
  );
};

export default ErrorFallback;
