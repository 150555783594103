import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import FormSelect from './FormSelect';

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

const Language = (props: PropsType) => {
  const { t } = useTranslation();
  return (
    <FormSelect
      {...props}
      name="language"
      label={t('Language')}
    >
      <MenuItem value="en">{t('English')}</MenuItem>
      <MenuItem value="fr">{t('French')}</MenuItem>
      <MenuItem value="es">{t('Spanish')}</MenuItem>
      <MenuItem value="it">{t('Italian')}</MenuItem>
      <MenuItem value="pt">{t('Portuguese')}</MenuItem>
      <MenuItem value="de">{t('German')}</MenuItem>
    </FormSelect>
  );
};

Language.defaultProps = {
  required: false,
  sx: null,
};

export default Language;
