import React from 'react';
import RichTextEditor from '../RichTextEditor';

type PropsType = {
  formik: any;
  name: string;
  label: string;
  readOnly?: boolean;
};

const RichFormText = (props: PropsType) => {
  const {
    formik, name, label, readOnly = false,
  } = props;

  return (
    <RichTextEditor
      defaultValue={formik.values[name]}
      placeholder={label}
      readOnly={readOnly}
      onChange={(content) => formik.setFieldValue(name, content.markdown)}
    />
  );
};

export default RichFormText;
