import AddIcon from '@mui/icons-material/Add';
import TrashIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box, IconButton, Paper, Stack, Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../../redux/api';
import { documentReferenceType } from '../../../types';
import { getDateFnsLocale } from '../../../utils';
import DataGrid from '../../DataGrid';
import DocumentIconByType from '../../DocumentIconByType';

const Documents = () => {
  const { uuid } = useParams();
  const { t, i18n } = useTranslation();
  const { documentReference } = greyboxApiActions;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [addDocument] = documentReference.add();
  const [deleteDocument] = documentReference.delete();
  const [selected, setSelected] = React.useState([]);

  const { data, isLoading } = documentReference.list({
    subject: 'Patient/' + uuid,
    page: page + 1,
    _count: rowsPerPage,
  }) as { data: documentReferenceType; isLoading: boolean };

  const handleDelete = () => {
    Promise.all(selected.map((id) => deleteDocument(id))).then(() => setSelected([]));
  };

  const handleDownload = () => {
    selected.forEach((id) => {
      const url = data?.entry.find(
        (item) => item.resource.id === id,
      )?.resource.content[0].attachment.data;

      if (url) {
        window.open(url, '_blank');
      }
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Construct the FHIR DocumentReference resource
      const documentReferenceResource = {
        resourceType: 'DocumentReference',
        status: 'current',
        type: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '68603-1',
            },
          ],
        },
        subject: {
          reference: `Patient/${uuid}`,
        },
        content: [
          {
            attachment: {
              contentType: file.type,
              data: (reader.result as string).split(',')[1],
              title: file.name,
            },
          },
        ],
      };

      // Send the document
      addDocument({
        body: documentReferenceResource,
        feedback: {
          success: t('Document added successfully'),
          error: t('An error occurred while adding the document'),
        },
      });
    };
    reader.onerror = (error) => {
      console.error('Error reading file:', error);
    };
  };

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'type',
        headerName: '',
        width: 50,
        renderCell: (params) => (
          <Box display="flex" alignItems="center" justifyContent="center" height="90%">
            <DocumentIconByType type={params.value} />
          </Box>
        ),
      },
      { field: 'title', headerName: t('Title'), flex: 1 },
      { field: 'date', headerName: t('Created at'), flex: 1 },
    ],
    [t],
  );

  const rows = React.useMemo(
    () => data?.entry.map((item) => ({
      id: item.resource.id,
      date: format(item.resource.meta.lastUpdated, 'Pp', { locale: getDateFnsLocale(i18n.language) }),
      type: item.resource.content[0].attachment.contentType,
      title: item.resource.content[0].attachment.title,
      language: item.resource.content[0].attachment.language,
    })) || [],
    [data],
  );

  return (
    <Box width="100%">
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
        {t('Documents')}
      </Typography>
      <Paper sx={{ p: 1, my: 1 }}>
        <Stack direction="row" spacing={1}>
          <input style={{ display: 'none' }} onChange={handleFileChange} id="raised-button-file" type="file" />
          <label htmlFor="raised-button-file">
            <IconButton component="span" size="small">
              <AddIcon />
            </IconButton>
          </label>
          {selected.length > 0 && (
            <>
              <IconButton size="small" disabled={selected.length === 0} onClick={handleDownload}>
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={handleDelete} size="small" disabled={selected.length === 0}>
                <TrashIcon />
              </IconButton>
            </>
          )}
        </Stack>
      </Paper>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={isLoading}
        totalCount={data?.total || 0}
        page={page}
        setSelectedRows={setSelected}
        pageSize={rowsPerPage}
        onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
        onPageChange={(newPage) => setPage(newPage)}
      />
    </Box>
  );
};

export default Documents;
