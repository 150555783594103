import React from 'react';
import { MenuItem, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import i18n from '../../i18n';

export const getDurations = (startDate, end) => {
  const endDate = end || moment().valueOf();

  const durationOptions = [
    {
      label: `5 ${i18n.t('minutes')}`,
      value: moment.duration(5, 'minutes'),
      unit: 'minute',
    },
    {
      label: `15 ${i18n.t('minutes')}`,
      value: moment.duration(15, 'minutes'),
      unit: 'minute',
    },
    {
      label: `30 ${i18n.t('minutes')}`,
      value: moment.duration(30, 'minutes'),
      unit: 'minute',
    },
    {
      label: `1 ${i18n.t('hour')}`,
      value: moment.duration(1, 'hour'),
      unit: 'hour',
    },
    {
      label: `2 ${i18n.t('hours')}`,
      value: moment.duration(2, 'hours'),
      unit: 'hour',
    },
    {
      label: `4 ${i18n.t('hours')}`,
      value: moment.duration(4, 'hours'),
      unit: 'hour',
    },
    {
      label: `8 ${i18n.t('hours')}`,
      value: moment.duration(8, 'hours'),
      unit: 'hour',
    },
    {
      label: `12 ${i18n.t('hours')}`,
      value: moment.duration(12, 'hours'),
      unit: 'hour',
    },
    {
      label: `1 ${i18n.t('day')}`,
      value: moment.duration(1, 'day'),
      unit: 'day',
    },
    {
      label: `2 ${i18n.t('days')}`,
      value: moment.duration(2, 'days'),
      unit: 'day',
    },
    {
      label: `3 ${i18n.t('days')}`,
      value: moment.duration(3, 'days'),
      unit: 'day',
    },
    {
      label: `1 ${i18n.t('week')}`,
      value: moment.duration(1, 'week'),
      unit: 'week',
    },
    {
      label: `2 ${i18n.t('weeks')}`,
      value: moment.duration(2, 'weeks'),
      unit: 'week',
    },
    {
      label: `1 ${i18n.t('month')}`,
      value: moment.duration(1, 'month'),
      unit: 'month',
    },
    {
      label: `2 ${i18n.t('months')}`,
      value: moment.duration(2, 'months'),
      unit: 'month',
    },
    {
      label: `3 ${i18n.t('months')}`,
      value: moment.duration(3, 'months'),
      unit: 'month',
    },
    {
      label: `6 ${i18n.t('months')}`,
      value: moment.duration(6, 'months'),
      unit: 'month',
    },
    {
      label: `1 ${i18n.t('year')}`,
      value: moment.duration(1, 'year'),
      unit: 'year',
    },
  ];

  const filteredDurationOptions = durationOptions.filter(
    (option) => option.value.asMinutes() <= moment(endDate).diff(startDate, 'minutes'),
  );

  return filteredDurationOptions;
};

const DurationSelect = ({ duration, setDuration }) => {
  const { t } = useTranslation();
  const { startDate, endDate } = useSelector((state) => state.patientProfileDateRange);

  const filteredDurationOptions = getDurations(startDate, endDate);

  if (filteredDurationOptions.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mx: 1 }}>
      <TextField
        select
        fullWidth
        size="small"
        sx={{ mb: 1, pt: 1 }}
        label={t('Aggregation')}
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
      >
        {filteredDurationOptions.map((option) => (
          <MenuItem
            key={option.value.asMinutes()}
            value={option.value.asMinutes()}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default DurationSelect;
