import React from 'react';
import { SvgIcon } from '@mui/material';

const PopUp = (props) => {
  const { viewBox = '0 0 30 30', fills = '#09bbd0' } = props;
  return (
    <SvgIcon {...props} viewBox={viewBox} fills={fills}>
      <svg viewBox="0 0 30 30">
        <path id="Path_1011" d="M0 0h24v24H0z" fill="none" data-name="Path 1011" />
        <path
          id="Path_1012"
          d="M10 2a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm.8 12H9.2V9.2h1.6zm0-6.4H9.2V6h1.6z"
          fill={fills}
          data-name="Path 1012"
          transform="translate(2 2)"
        />
      </svg>
    </SvgIcon>
  );
};

export { PopUp };
