import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import englishTranslation from '../locales/en/translation.json';
import frenchTranslation from '../locales/fr/translation.json';
import germanTranslation from '../locales/de/translation.json';
import spanishTranslation from '../locales/es/translation.json';
import italianTranslation from '../locales/it/translation.json';
import portugueseTranslation from '../locales/pt/translation.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: englishTranslation,
      },
      fr: {
        translation: frenchTranslation,
      },
      de: {
        translation: germanTranslation,
      },
      es: {
        translation: spanishTranslation,
      },
      it: {
        translation: italianTranslation,
      },
      pt: {
        translation: portugueseTranslation,
      },
    },
  });

export default i18n;
