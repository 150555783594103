import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { generateFormRules } from '../forms/validations/index';
import BigFormButton from './BigFormButton';
import { setTempAuthorization } from '../../redux/authorization';
import { setIsInvitation } from '../../redux/invitation';
import { greyboxApiActions } from '../../redux/api';

const TokenValidation = (props) => {
  const { email } = props;
  const { t } = useTranslation();
  const { tokenValidation } = greyboxApiActions;
  const dispatch = useDispatch();
  const [sendToken, result] = tokenValidation.add();
  const formRules = generateFormRules(
    [
      { name: 'token', required: true },
    ], 'CA_QC', t, true,
  );

  const onSubmit = (value, formikBag) => {
    sendToken({
      body: {
        email: email.email,
        token: value.token,
      },
    }).then(() => {
      formikBag.setSubmitting(false);
    });
  };

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(setIsInvitation(true));
      dispatch(setTempAuthorization({ token: `Token ${result.data.token}` }));
    }
  }, [result]);

  return (
    <Formik
      initialValues={formRules.init}
      validationSchema={formRules.validation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <form onSubmit={formikBag.handleSubmit}>
          <Box display="flex" alignItems="center" flexDirection="column" margin="none">
            <TextField
              name="token"
              placeholder={t('Enter validation code')}
              value={formikBag.values.token}
              onChange={formikBag.handleChange}
              fullWidth
              required
            />
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column" sx={{ mt: 1 }}>
            <LoadingButton
              size="large"
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={formikBag.isSubmitting}
            >
              {t('Send')}
            </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default TokenValidation;
