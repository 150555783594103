import {
  Box, Collapse, Dialog, Button,
  DialogActions,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  DialogContent,
  FormControl,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSelector, useDispatch } from 'react-redux';
import DialogQuestionnaire from './filler/DialogQuestionnaire';
import DialogTitle from '../../DialogTitle';
import { greyboxApiActions } from '../../../redux/api';

/**
 *  Dialog that prompts the user to select a questionnaire to fill out.
 *  After selecting a questionnaire, the corresponding questions are displayed.
 */
const QuestionnaireSelector = (props) => {
  const { t } = useTranslation();
  const { questionnaires } = useSelector((state) => state.clinic);
  const [selected, setSelected] = useState(null);
  const [isStarted, setIsStarted] = useState(false);
  const { open, handleClose } = props;

  useEffect(() => {
    if (open) {
      setSelected(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={isStarted ? 'lg' : 'sm'}
    >
      <DialogTitle onClose={handleClose}>
        {isStarted ? '' : t('Questionnaire selection')}
      </DialogTitle>
      {!isStarted ? (
        <>
          <DialogContent dividers sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('Questionnaire')}</InputLabel>
              <Select onChange={(e) => setSelected(e.target.value)} label="Questionnaire" value={selected}>
                {questionnaires.map((q) => (
                  <MenuItem key={q.id} value={q.id}>
                    {q.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsStarted(true)}>
              {t('Start questionnaire')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <DialogQuestionnaire
          open={open}
          onComplete={() => handleClose}
          handleClose={() => handleClose}
          questionnaireId={selected}
        />
      )}
    </Dialog>
  );
};

QuestionnaireSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default QuestionnaireSelector;
