import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import {
  MenuItem,
  Select, Switch, TextField,
  Collapse,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { dosageFormater, dosageUnitFormater, ingredientNameFormater } from '../searchMedications/medicationUtils';
import { editMedication } from './currentMedications_styling';
import { greyboxApiActions } from '../../../../redux/api';
import { medication_status } from '../../../../helper-functions';

const StyledSelect = withStyles({
  root: {
    paddingTop: '6.5px',
    paddingBottom: '6.5px',
    width: '99px',
    fontSize: '14px',
    textAlign: 'left',
  },
})(Select);

const MedicationDetails = (props) => {
  const {
    medInfo, setMedication, currentMedication = null,
  } = props;
  const { t } = useTranslation();
  let dosageSum = 0.0;

  // count the units and sum by them
  const sumByUnits = medInfo.active_ingredient.reduce((sums, ingredient) => {
    const dosageUnit = dosageUnitFormater('en', ingredient);
    if (sums[dosageUnit]) {
      sums[dosageUnit] += parseFloat(ingredient.strength);
    } else {
      sums[dosageUnit] = parseFloat(ingredient.strength);
    }
    return sums;
  }, {});

  const units = Object.keys(sumByUnits);

  let unit = '';
  if (units.length === 1) {
    [unit] = units;
    dosageSum = sumByUnits[unit];
  }

  const [selectedPosology, setSelectedPosology] = useState('');
  const [dosageUnit, setDosageUnit] = useState(unit.toLowerCase());
  const [dosage, setDosage] = useState(dosageSum);
  const [morningDosage, setMorningDosage] = useState('');
  const [noonDosage, setNoonDosage] = useState('');
  const [afternoonDosage, setAfternoonDosage] = useState('');
  const [eveningDosage, setEveningDosage] = useState('');
  const [nightDosage, setNightDosage] = useState('');
  const [dailyDosage, setDailyDosage] = useState(0);
  const [maxDosage, setMaxDosage] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(null);
  const { posology } = greyboxApiActions;
  const { data } = posology.list({});

  const handleChange = (setter, fieldValue = 'value') => (event) => {
    if (event && event.target) {
      setter(event.target[fieldValue]);
    } else {
      setter(event);
    }
  };

  // todo -> use *string or *number
  const validateDose = (fn) => (value) => {
    if (!Number.isNaN(value) && value >= 0) {
      fn(value);
    }
  };

  useEffect(() => {
    if (selectedPosology
      && startDate
      && (!endDate || moment(startDate).isBefore(moment(endDate)))) {
      const med = {
        medication: medInfo.uuid,
        status: medication_status(moment(startDate), moment(endDate)),
        max_tolerated_dosage: maxDosage,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: endDate && moment(endDate).format('YYYY-MM-DD'),
      };

      if (selectedPosology === 'PART') {
        const ddVal = [
          morningDosage,
          noonDosage,
          afternoonDosage,
          eveningDosage,
          nightDosage,
        ].reduce((sum, elem) => sum + parseFloat(elem || 0), 0);

        setDailyDosage(ddVal);
        med.dosage = {
          daily_dosage: ddVal,
          dosage_mode: 'particular_posology',
          particular_posology: {
            short_code: 'particular',
            morning_dosage: parseFloat(morningDosage || 0),
            noon_dosage: parseFloat(noonDosage || 0),
            afternoon_dosage: parseFloat(afternoonDosage || 0),
            night_dosage: parseFloat(nightDosage || 0),
            evening_dosage: parseFloat(eveningDosage || 0),
          },
        };
      } else {
        let ddVal = null;
        if (dosage && !Number.isNaN(dosage)) {
          ddVal = parseFloat(dosage) * parseFloat(selectedPosology.frequency_value);
          setDailyDosage(ddVal);
        }

        const nDosage = parseFloat(dosage);

        med.dosage = {
          daily_dosage: ddVal,
          dosage_mode: 'frequency_posology',
          frequency_posology: { ...selectedPosology, dosage: nDosage > 0 ? nDosage : null },
        };
      }

      if ((!dosageUnit && med.dosage.frequency_posology) || med.dosage.daily_dosage > 0) {
        setMedication(med);
      } else {
        setMedication(null);
      }
    } else {
      setMedication(null);
    }
  }, [
    dosage,
    selectedPosology,
    morningDosage,
    noonDosage,
    afternoonDosage,
    eveningDosage,
    nightDosage,
    maxDosage,
    startDate,
    endDate,
  ]);

  return (
    <div style={editMedication.container}>
      <div style={editMedication.row}>
        <Typography>{t('Product Name')}</Typography>
        <Typography style={editMedication.inputColumn}>
          {medInfo.brand_name}
        </Typography>
      </div>
      <div style={editMedication.row}>
        <Typography>{t('Actives ingredients')}</Typography>
        <div style={editMedication.inputColumn}>
          {medInfo.active_ingredient.map((ingredient, index) => (
            <Typography key={`active_ingredient_${index}`} style={editMedication.activeIngredient}>
              {ingredientNameFormater('en', ingredient)}
              {' '}
              <span style={editMedication.activeIngredientUnit}>
                (
                {' '}
                {dosageFormater('en', ingredient)}
                {' '}
                )
              </span>
            </Typography>
          ))}
        </div>
      </div>
      {dosageUnit && (
        <div style={selectedPosology === 'PART' ? editMedication.disabledRow : editMedication.row}>
          <Typography>{t('Dose')}</Typography>
          <div style={editMedication.inputColumn}>
            <TextField
              value={dosage}
              disabled={selectedPosology === 'PART'}
              onChange={handleChange(validateDose(setDosage))}
            />
          </div>
        </div>
      )}
      <div style={editMedication.row}>
        <Typography>
          {t('Frequency')}
        </Typography>
        <div style={editMedication.inputColumn}>
          <StyledSelect
            data-cy="posologySelect"
            displayEmpty
            value={selectedPosology}
            onChange={handleChange(setSelectedPosology)}
            variant="outlined"
          >
            <MenuItem value="" style={editMedication.selectMenuPlaceHolder}>
              {t('Select')}
            </MenuItem>
            {data && data.map((item) => (
              <MenuItem
                data-cy={`posology_${item.frequency_value}`}
                key={item.uuid}
                value={item}
                style={editMedication.selectMenuItem}
              >
                {item.short_code}
              </MenuItem>
            ))}
            {
              // particular isn't enable for multiple unit
              dosageUnit && (
                <MenuItem
                  data-cy="posology_particular"
                  key="particular_pos"
                  value="PART"
                  style={editMedication.selectMenuItem}
                >
                  {t('Particular')}
                </MenuItem>
              )
            }
          </StyledSelect>
        </div>
      </div>
      {/* //only display this section when particular dosage is
      selected and the unit is the same */}
      {dosageUnit && (
        <>
          <Collapse in={selectedPosology === 'PART'}>
            <div style={editMedication.row}>
              <Typography>{t('Morning')}</Typography>
              <div style={editMedication.inputColumn}>
                <TextField
                  value={morningDosage}
                  name={`morning${dosageUnit}`}
                  onChange={handleChange(validateDose(setMorningDosage))}
                />
              </div>
            </div>
            <div style={editMedication.row}>
              <Typography>{t('Noon')}</Typography>
              <div style={editMedication.inputColumn}>
                <TextField
                  value={noonDosage}
                  name={`noon${dosageUnit}`}
                  onChange={handleChange(validateDose(setNoonDosage))}
                />
              </div>
            </div>
            <div style={editMedication.row}>
              <Typography>{t('Afternoon')}</Typography>
              <div style={editMedication.inputColumn}>
                <TextField
                  value={afternoonDosage}
                  name={`afternoon${dosageUnit}`}
                  onChange={handleChange(validateDose(setAfternoonDosage))}
                />
              </div>
            </div>
            <div style={editMedication.row}>
              <Typography>{t('Supper')}</Typography>
              <div>
                <TextField
                  value={eveningDosage}
                  name={`evening${dosageUnit}`}
                  onChange={handleChange(validateDose(setEveningDosage))}
                />
              </div>
            </div>
            <div style={editMedication.row}>
              <Typography>{t('Bedtime')}</Typography>
              <div style={editMedication.inputColumn}>
                <TextField
                  value={nightDosage}
                  name={`night${dosageUnit}`}
                  onChange={handleChange(validateDose(setNightDosage))}
                />
              </div>
            </div>
          </Collapse>
          <div style={editMedication.row}>
            <Typography>
              {t('Daily Dose')}
            </Typography>
            <div style={editMedication.inputColumn}>
              <div data-cy="dailyDosageValue" style={editMedication.lineBold}>
                <Typography style={editMedication.grey}>
                  {dosageUnit ? `${dailyDosage} ${dosageUnit}` : '---'}
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
      <div style={editMedication.row}>
        <Typography>{t('Maximum Tolerated Dose')}</Typography>
        <div style={editMedication.inputColumn}>
          <Switch
            name="maxDose"
            active={maxDosage}
            onClick={() => setMaxDosage(!maxDosage)}
          />
        </div>
      </div>
      <div style={editMedication.dateRow}>
        <div style={editMedication.labelDateRow}>{t('Start')}</div>
        <div style={editMedication.inputColumn}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Date"
              value={moment(startDate)}
              onChange={(d) => setStartDate(d)}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div style={editMedication.dateRow}>
        <Typography style={editMedication.labelDateRow}>
          {t('End')}
          {' '}
          <span style={editMedication.grey}>
            (
            {t('optional')}
            )
          </span>
        </Typography>
        <div style={editMedication.inputColumn}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Date"
              value={endDate ? moment(endDate) : null}
              onChange={(d) => setEndDate(d)}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default MedicationDetails;
