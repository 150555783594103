import { Skeleton } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { parseCount } from './utils';

const CountChart = (props) => {
  const {
    vitalSelector,
    unit,
    autoHeight = false,
  } = props;

  const { t } = useTranslation();

  if (!vitalSelector) {
    return null;
  }

  if (vitalSelector.isFetching) {
    return <Skeleton variant="rect" width="100%" height="30vh" />;
  }

  const summaryOptions = {
    series: [
      {
        name: t('Count'),
        type: 'column',
        data: parseCount(vitalSelector.data, unit),
      },
    ],
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    markers: {
      size: [0],
    },
    legend: {
      show: true,
    },
    stroke: {
      width: [3, 3, 3],
      curve: 'straight',
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy HH:mm',
      },
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      id: 'count',
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: t('Count'),
      align: 'left',
    },
    fill: {
      opacity: [1, 1, 1, 0.5],
    },
    yaxis: [
      {
        labels: {
          formatter: (val) => val.toFixed(0),
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={summaryOptions}
      type="bar"
      series={summaryOptions.series}
      height={autoHeight ? 400 : '100%'} // Default ratio or parent height
    />
  );
};

export default CountChart;
