import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestoreIcon from '@mui/icons-material/Restore';
import {
  Box, Button, IconButton, InputAdornment, LinearProgress, Menu, MenuItem, Paper, TableBody,
  TableCell, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../redux/api';
import { formatPhoneNumber } from '../../utils';
import ArchiveModal from '../modals/ArchiveModal';
import TableWrapper from '../TableWrapper';
import TableSortLabel from '../tk-ui/table/TableSortLabel';
import AccountCreationDialog from './AccountCreationDialog';
import EditAccountDialog from './EditAccountDialog';
import FilterButton from '../FilterButton';
import { PersonAddAlt1 } from '@mui/icons-material';

const DIRECTIONS = {
  asc: '',
  desc: '-',
};

const AccountRowButton = (props) => {
  const {
    row, idx, viewMode, setSelected,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { account } = greyboxApiActions;
  const [reactivateAccount] = account.put();
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [firstName, setFirstName] = useState('');

  const handleMenuActions = (action, name) => {
    switch (action) {
      case 'edit':
        setSelected(row.uuid);
        break;
      case 'delete':
        setFirstName(name);
        setArchiveOpen(true);
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };

  if (viewMode === 'archived') {
    return (
      <Tooltip title={t('Restore account')}>
        <IconButton
          data-cy={`row-${idx}-menu`}
          onClick={() => reactivateAccount({ id: `${row.uuid}/restore_account` })}
          size="small"
        >
          <RestoreIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <IconButton
        data-cy={`row-${idx}-menu`}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        data-cy={`row-${idx}-menu-main`}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleMenuActions('edit', '')}>
          {t('Edit')}
        </MenuItem>
        <MenuItem
          data-cy="archive-account"
          sx={{ color: (theme) => theme.palette.error.main }}
          onClick={() => handleMenuActions('delete', row.firstName)}
        >
          {t('Archive')}
        </MenuItem>
      </Menu>
      {archiveOpen && (
        <ArchiveModal
          open={archiveOpen}
          firstName={firstName}
          handleClose={() => setArchiveOpen(false)}
          uuid={row.uuid}
        />
      )}
    </>

  );
};

const AccountTable = () => {
  const [page, setPage] = useState(0);
  const { type } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ordering, setOrdering] = useState('last_active');
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState([]);
  const [direction, setDirection] = useState('asc');
  const [viewMode, setViewMode] = useState('active'); // 'active' or 'archived'
  const { clinic } = useSelector((state) => state.clinic);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { account } = greyboxApiActions;
  const [selected, setSelected] = useState(null);
  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
      setPage(0);
    }
  };

  const labels = useMemo(() => (
    filters.filter((item) => item.type === 'label').map((item) => item.id)
  ), [filters]);

  const { data, isLoading, isFetching } = account.list({
    acc_type: type,
    page: page + 1,
    page_size: rowsPerPage,
    invitations: true,
    clinic: clinic.id,
    search: query,
    deleted: viewMode === 'archived',
    ordering: `${DIRECTIONS[direction]}${ordering}`,
    labels,
  });

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
  }, [type]);

  const accountLabels = {
    PT: t('Practitioners'),
    P: t('Patients'),
    CP: t('Caregivers'),
  };

  const columns = [
    {
      id: 'firstName',
      label: t('Name'),
      format: (value, row) => (`${value} ${row.lastName}`),
    },
    {
      id: 'email',
      label: t('Email'),
    },
    {
      id: 'phoneNumber',
      label: t('Phone Number'),
      format: (value) => (formatPhoneNumber(value)),
    },
    {
      id: 'lastActive',
      label: t('Last active'),
      format: (value) => (moment(value).format('lll')),
    },
    {
      id: 'createdDate',
      label: t('Created at'),
      format: (value) => (moment(value).format('ll')),
    },
    {
      id: 'invitation_status',
      label: t('Invitation status'),
      format: (value) => (value ? t(value) : t('Not Connected')),
    },
  ];

  const handleSort = (id: string) => {
    if (ordering === id) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrdering(id);
      setDirection('desc');
    }
  };

  if (isLoading) {
    return (<LinearProgress />);
  }

  return (
    <Box width="100%">
      <Box mb={1}>
        <Typography gutterBottom variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {accountLabels[type]}
        </Typography>
        <Box display="flex" alignItems="center">
          <TextField
            placeholder={`${data.count} ${t('accounts')}...`}
            value={query}
            variant="outlined"
            sx={{
              minWidth: '150px',
              bgcolor: 'background.paper',
              borderRadius: 2,
            }}
            onChange={(e) => setQuery(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {query !== '' && (
                    <IconButton onClick={() => setQuery('')}>
                      <CancelIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <FilterButton filters={filters} setFilters={setFilters} type="account" />
          <ToggleButtonGroup
            value={viewMode}
            size="small"
            sx={{ bgcolor: 'background.paper', ml: 1 }}
            exclusive
            onChange={handleViewModeChange}
            aria-label="View Mode"
          >
            <ToggleButton value="active" aria-label="Active Accounts">
              {t('Actives')}
            </ToggleButton>
            <ToggleButton value="archived" aria-label="Archived Accounts">
              {t('Archived')}
            </ToggleButton>
          </ToggleButtonGroup>
          {type === 'P' && clinic && clinic.permissions.includes('register_account') && (<Button
            onClick={() => setOpen(true)}
            startIcon={<PersonAddAlt1 />}
            variant="contained"
            color="primary"
            sx={{ ml: 'auto' }}
          >
            {t('Add patient')}
          </Button>)}
        </Box>
      </Box>
      <Paper sx={{ width: '100%', mb: 3 }}>
        <TableWrapper
          maxHeight="calc(100vh - 230px)"
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          total={data.count}
          isFetching={isFetching}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={ordering === column.id}
                    direction={direction}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.count === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="200px"
                  >
                    <Typography variant="h5">
                      {t('No data')}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              data.results.map((row, idx) => (
                <Row
                  key={row.uuid}
                  viewMode={viewMode}
                  idx={idx}
                  row={row}
                  columns={columns}
                  setSelected={setSelected}
                />
              ))
            )}
          </TableBody>
        </TableWrapper>
        {open && (<AccountCreationDialog open={open} onClose={() => setOpen(false)} />)}
        {selected && (
          <EditAccountDialog
            open={Boolean(selected)}
            uuid={selected}
            handleClose={() => setSelected(null)}
          />
        )}
      </Paper>
    </Box>
  );
};

type RowProps = {
  row: any;
  columns: any;
  idx: number;
  viewMode: string;
  setSelected: (uuid: string) => void;
}

function Row(props: RowProps) {
  const {
    row,
    columns,
    idx,
    setSelected,
    viewMode,
  } = props;
  const [hover, setHover] = useState(false);

  return (
    <TableRow
      hover
      sx={{ height: 68 }}
      data-cy={`row-${idx}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {columns.map((column) => (
        <TableCell key={column.id}>
          {column.format ? (
            column.format(row[column.id], row)
          ) : (
            row[column.id]
          )}
        </TableCell>
      ))}
      <TableCell sx={{ minWidth: '35px' }}>
        {(hover) && (
          <AccountRowButton row={row} idx={idx} viewMode={viewMode} setSelected={setSelected} />
        )}
      </TableCell>
    </TableRow>
  );
}

export default AccountTable;
