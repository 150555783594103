import {
  Paper, Box,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import QuestionForm from './QuestionForm';
import BackButton from '../../../BackButton';
import { greyboxApiActions } from '../../../../redux/api';

const QuestionEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { questionnaireManagementClinic } = greyboxApiActions;
  const { data, isLoading } = questionnaireManagementClinic.get(id);

  if (isLoading) {
    return null;
  }

  return (
    <Box width="100%" sx={{ mb: 2 }}>
      <BackButton sx={{ mb: 1 }} />
      <Paper sx={{ p: 1 }}>
        <QuestionForm questionnaire={data.questionnaire} edit />
      </Paper>
    </Box>
  );
};

export default QuestionEdit;
