export const CARE_TEAM_CATEGORY = {
  'LA27975-4': 'Event',
  'LA27976-2': 'Encounter',
  'LA27977-0': 'Episode of care',
  'LA27978-8': 'Condition',
  'LA28865-6': 'Longitudinal care-coordination',
  'LA28866-4': 'Home and Community Based Services',
  'LA27980-4': 'Clinical research',
  'LA28867-2': 'Public health',
};

// Removes ids from the participant object to be used in a PATCH request
export const parseParticipants = (participants) => (
  participants.map((participant) => ({
    member: {
      reference: participant.member.reference,
    },
    role: participant.role,
  }))
);
