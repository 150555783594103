import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

const Row = (props) => {
  const { children } = props;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const colCount = Math.max(React.Children.count(children), 2);

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap">
      {React.Children.map(children, (child) => (
        <Box
          sx={{ mb: 1 }}
          display="flex"
          paddingTop={1}
          overflow="hidden"
          flexDirection="column"
          flex={isMobile ? '1 1 100%' : `0 0 ${100 / colCount}%`}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};

export default Row;
