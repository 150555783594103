const lab_wrapper_style = {
  container: {
    fontFamily: "Roboto",
    backgroundColor: "#FFF",
    paddingBottom: '40px'
  },
   wrapBtn: {
    display: "flex",
    height: "32px",
 
    padding: "20px 0 0 20px",
    justifyContent: "flex-start",
    flexWrap: 'nowrap',
    flexDirection: 'row'
   }
};

const lab_table_style = {
  multiGrid: {
    border: 'solid 1px rgba(112, 112, 112, 0.5)',
    margin: '20px',
  },
  topLeftGrid: {
    borderBottom: 'solid 0.5px #c4c4c4',
    borderRight: "solid 0.5px #babdbf",
    fontWeight: '500',
    backgroundColor: '#f6f6f7'
  },
  topRightGrid: {
    borderBottom: 'solid 0.5px #c4c4c4',
    fontWeight: '500',
    backgroundColor: '#f6f6f7',
  },
  bottomLeftGrid: {
    borderRight: "solid 0.5px #babdbf",
  },
  cell: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#454545',
    borderBottom: 'solid 0.5px #c4c4c4',
    borderRight: 'solid 0.5px #c4c4c4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labResultsNameHeaderCell: {
    justifyContent: 'space-between',
    cursor: 'pointer',
    //backgroundColor: 'rgb(9, 187, 208)', //greybox blue
  },
  labResultsNameHeaderCellText: {
    paddingLeft: '12.5px',
  },
  labResultsNameHeaderCellIcon: {
    paddingRight: '5px',
  },
  sortIconAsc: {
    transform: "rotate(180deg)",
  },
  sortIconDesc: {
    transformOrigin: "50% 40%",
    transition: "all 200ms ease",
  },
  columnHeaderCell: {
    fontSize: '12px',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  rowHeaderCell: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    //backgroundColor: 'rgb(9, 187, 208, 0.3)' // greybox blue at 30% opacity
  },
  rowHeaderCellName: {
    paddingLeft: '12.5px',
    fontSize: '14px',
    fontWeight: '500',
    borderRight: null
  },
  rowHeaderCellUnit: {
    paddingLeft: '12.5px',
    fontSize: '12px',
    color: '#95989a',
    borderRight: null
  },
  healthTargetHeaderCell: {
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "1fr 1fr",
    alignItems: 'stretch',
    justifyItems: 'stretch',
    //backgroundColor: 'rgb(9, 187, 208)', //greybox blue
    //backgroundColor: "rgba(210, 246, 250, 1)",
    //backgroundColor: "#09bbd0", //titration dash line (same as greybox blue)
    backgroundColor: "rgba(99, 115, 129)", //blueish grey from "No titration plan" status
    color: "#FFF"
  },
  healthTargetHeaderCellName: {
    display: "grid",
    borderBottom: "solid 0.5px #babdbf",
    textAlign: "center",
    lineHeight: "20px"
  },
  healthTargetHeaderCellNameSubgroups: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    borderBottom: "solid 0.5px #babdbf",
    textAlign: "center",
    lineHeight: "20px"
  },
  healthTargetHeaderCellNameSubgroupItem: {
    display: "grid",
    textAlign: "center",
    lineHeight: "20px"
  },
  healthTargetDataCell: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    borderRight: "solid 0.5px #babdbf",
    borderLeft: "solid 1px #babdbf",
    alignItems: 'stretch',
    justifyItems: 'stretch',
    textAlign: "center",
    lineHeight: "40px",
    //backgroundColor: "#ffe0bf"
    //backgroundColor: 'rgb(9, 187, 208, 0.3)' // greybox blue at 30% opacity
    backgroundColor: "rgba(210, 246, 250,0.5)"
  },
  targetRightBorder: {
    borderRight: "solid 0.5px #babdbf"
  },
  targetDateTime: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
    fontSize: '12px',
  }
  // blueHeaderBackground: {
  //   backgroundColor: 'rgb(9, 187, 208)', //greybox blue
  // },
  // blueDataBackground: {
  //   'rgb(9, 187, 208, 0.3)' // greybox blue at 30% opacity
  // }
}

export { lab_wrapper_style, lab_table_style };
