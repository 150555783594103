import {
  Box, Chip, IconButton, MenuItem, TextField, Tooltip, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddCommentIcon from '@mui/icons-material/AddComment';
import moment from 'moment';
import { greyboxApiActions } from '../../redux/api';
import NewMessageDialog from './NewMessageDialog';

const formatTime = (time) => {
  if (moment().diff(moment(time), 'days') > 0) {
    return moment(time).format('ll');
  }
  return moment(time).format('LT');
};

const ThreadSelector = ({ onSelect }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { messageThread } = greyboxApiActions;
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const [threads, setThreads] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [page, setPage] = useState(1);
  const scrollableDivRef = useRef(null);
  const { isLoading, data, isError } = messageThread.list({
    clinic: clinic.id,
    page: page,
    page_size: 30,
    has_messages: true,
    search,
  });
  const [selectedThread, setSelectedThread] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleSelect = (thread) => {
    setSelectedThread(thread);
    onSelect(thread);
  };

  const handleNewMessage = (thread) => {
    handleSelect(thread);
    setThreads([thread, ...threads]);
    setOpen(false);
  };

  const fetchMoreData = () => {
    const hasOtherThreads = data.count > 30 * page;

    if (hasOtherThreads) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;

      // Check if the scroll position is within the threshold of the bottom
      const isBottom = scrollHeight - scrollTop <= clientHeight + 100; // 100px threshold

      if (isBottom) {
        fetchMoreData(); // Your function to fetch more data
      }
    };

    // Attach the event listener if the component is mounted
    if (!scrollableDivRef.current) {
      return undefined;
    }

    const scrollableDiv = scrollableDivRef.current;
    scrollableDiv.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [threads, page]);

  useEffect(() => {
    if (search !== '') {
      setPage(1);
    }
  }, [search]);

  useEffect(
    () => {
      if (threads && threads.length > 0 && !isMobile) {
        handleSelect(data.results[0]);
      }
    },
    [threads],
  );

  useEffect(() => {
    if (data && data.results) {
      if (page === 1) {
        setThreads(data.results);
      } else {
        setThreads([...threads, ...data.results]);
      }
    }
  }, [data]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <>
      <Box sx={{
        height: '100%',
        display: 'flex',
        ml: isMobile ? 0 : -3,
        flexDirection: 'column',
        width: isMobile ? '100%' : '300px',
        mt: -3,
      }}
      >
        <Box sx={{
          p: 1, pl: 2, display: 'flex', flexDirection: 'column',
        }}
        >
          <Tooltip title={t('New Message')}>
            <IconButton sx={{ mb: 1, ml: 'auto' }} onClick={() => setOpen(true)}>
              <AddCommentIcon />
            </IconButton>
          </Tooltip>
          <TextField
            fullWidth
            sx={{ bgcolor: (theme) => theme.palette.background.paper }}
            label={t('Search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Box
          ref={scrollableDivRef}
          sx={{
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          {threads.map((thread) => (
            <MenuItem
              key={thread.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '65px',
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
              }}
              onClick={() => handleSelect(thread)}
              selected={selectedThread && selectedThread.id === thread.id}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                color="text.primary"
                width="100%"
              >
                <Typography fontWeight={thread.latest_message?.clinic_read ? 'normal' : 'bold'}>
                  {thread.title}
                </Typography>
                {thread.latest_message && (
                  <Typography variant="caption" fontWeight={thread.latest_message?.clinic_read ? 'normal' : 'bold'}>
                    {formatTime(thread.latest_message.created)}
                  </Typography>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                {thread.latest_message && (
                  <Typography
                    fontWeight={thread.latest_message?.clinic_read ? 'normal' : 'bold'}
                    variant="caption"
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}
                  >
                    {`${thread.latest_message?.created_by.first_name}: ${thread.latest_message.message}`}
                  </Typography>
                )}
                <Chip
                  label={thread.patient ? t('Patient') : t('Care Team')}
                  size="small"
                  sx={{ ml: 'auto' }}
                />
              </Box>
            </MenuItem>
          ))}
        </Box>
      </Box>
      {open && (
        <NewMessageDialog
          open={open}
          handleClose={() => setOpen(false)}
          onChange={handleNewMessage}
        />
      )}
    </>
  );
};

export default ThreadSelector;
