import {
  Button, CircularProgress, Dialog,
  DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import 'moment/locale/fr';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { greyboxApiActions } from '../../../../redux/api';
import MedicationSearch from '../searchMedications/SearchMedication';
import MedicationDetails from './MedicationDetails';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '10px',
    alignItems: 'center',
  },
}));

const AddMedicationModal = (props) => {
  const {
    open,
    handleClose,
    search = null,
  } = props;
  const { t, i18n } = useTranslation();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const [currentSelected, setSelect] = useState(null);
  const [newMed, setNewMed] = useState(null);
  const classes = useStyles();
  const { medication } = greyboxApiActions;
  const [postMedication, result] = medication.add();

  const handleQuit = () => {
    setSelect(null);
    setNewMed(null);
    handleClose();
  };

  const addMedication = async () => {
    await postMedication({ body: { ...newMed, account: uuid } });
    handleQuit();
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick={result.isLoading}
      onClose={handleQuit}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {t('Add Medication')}
      </DialogTitle>
      <DialogContent>
        <div className={classes.wrapper}>
          {!currentSelected ? (
            <MedicationSearch
              setSelect={setSelect}
              currentSelected={currentSelected}
              search={search}
            />
          ) : (
            <MedicationDetails
              medInfo={currentSelected}
              setMedication={setNewMed}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleQuit} disabled={result.isLoading}>
          {t('Cancel')}
        </Button>
        {currentSelected && (
          <Button disabled={!newMed || result.isLoading} onClick={() => addMedication()}>
            {result.isLoading ? <CircularProgress size={14} /> : t('Add')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddMedicationModal;
