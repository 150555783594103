import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { generateFormRules } from '../forms/validations/index';
import BigFormButton from './BigFormButton';
import { greyboxApiActions } from '../../redux/api';

const EmailConfirmation = (props) => {
  const { setEmail } = props;
  const { t } = useTranslation();
  const { emailValidation } = greyboxApiActions;
  const [sendEmail] = emailValidation.add();

  const formRules = generateFormRules(
    [
      { name: 'email', required: true },
    ], 'CA_QC', t, true,
  );

  const onSubmit = (value, formikBag) => {
    sendEmail({ body: value }).then(() => {
      setEmail(value);
    });
    formikBag.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formRules.init}
      validationSchema={formRules.validation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <form onSubmit={formikBag.handleSubmit}>
          <Box display="flex" alignItems="center" flexDirection="column" margin="none">
            <TextField
              name="email"
              type="email"
              fullWidth
              value={formikBag.values.email}
              onChange={formikBag.handleChange}
              label={t('Email')}
              placeholder={t('Email')}
              required
            />
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column" sx={{ mt: 1 }}>
            <LoadingButton
              size="large"
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={formikBag.isSubmitting}
            >
              {t('Send')}
            </LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EmailConfirmation;
