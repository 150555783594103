import {
  Box, FormControl, DialogActions, Button,
  FormLabel, Skeleton, TextField, Dialog, DialogContent,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { greyboxApiActions } from '../../redux/api';
import Row from '../Row';
import DialogTitle from '../DialogTitle';

export const parseThreshold = (values, vitalsConfig) => {
  const thresholds = {};

  if (!values) {
    return thresholds;
  }

  Object.values(vitalsConfig).forEach((v) => {
    const current = values.find((t) => v.threshold_code === t.function);
    if (current) {
      thresholds[v.short_code] = current;
    }
  });

  return thresholds;
};

export const parseThresholdForPost = (values, vital, thresholdsCode) => {
  const parsedThresholds = [];
  if (vital === 'BP') {
    const currentObj = {};
    if (values.min_tensionLow) {
      currentObj.lowerRange_5 = values.min_tensionHigh;
    }
    if (values.max_tensionLow) {
      currentObj.upperRange_5 = values.max_tensionHigh;
    }
    if (values.min_tensionHigh) {
      currentObj.lowerRange_1 = values.min_tensionLow;
    }
    if (values.max_tensionHigh) {
      currentObj.upperRange_1 = values.max_tensionLow;
    }
    parsedThresholds.push({
      function: 'V_BP',
      ...currentObj,
    });
  } else {
    thresholdsCode.forEach((t) => {
      parsedThresholds.push({ function: t.code, lowerRange_1: values[`min_${t.shortCode}`], upperRange_1: values[`max_${t.shortCode}`] });
    });
  }

  return parsedThresholds;
};

const thresholdValidations = (vital, thresholdCodes) => {
  const validationSchema = yup.object();

  if (vital === 'BP') {
    validationSchema.concat(yup.object().shape({
      min_tensionLow: yup.number().min(0),
      min_tensionHigh: yup.number().min(0),
      max_tensionLow: yup.number().min(0),
      max_tensionHigh: yup.number().min(0),
    }));
  } else {
    thresholdCodes.forEach((code) => {
      validationSchema.concat(yup.object().shape({
        [`min_${code}`]: yup.number().min(0), [`max_${code}`]: yup.number().min(0),
      }));
    });
  }
  return validationSchema;
};

export const getThresholdCodes = (shortCode, configs) => {
  const thresholdCodes = [];

  if (configs[shortCode].config && configs[shortCode].config.children) {
    configs[shortCode].config.children.forEach((child) => {
      if (!configs[child]) return;
      thresholdCodes.push({
        name: configs[child].name,
        code: configs[child].threshold_code,
        shortCode: child,
      });
    });
  } else if (configs[shortCode].threshold_code) {
    thresholdCodes.push({
      code: configs[shortCode].threshold_code,
      name: configs[shortCode].name,
      shortCode,
    });
  }

  return thresholdCodes;
};

export const thresholdInitValue = (vital, value, codes) => {
  const values = {};

  if (!value) return values;

  if (vital === 'BP') {
    const data = value.BP;
    if (!data) return values;
    if (data.lowerRange_5) values.min_tensionHigh = data.lowerRange_5;
    if (data.upperRange_5) values.max_tensionHigh = data.upperRange_5;
    if (data.lowerRange_1) values.min_tensionLow = data.lowerRange_1;
    if (data.upperRange_1) values.max_tensionLow = data.upperRange_1;
  } else {
    codes.forEach((code) => {
      if (value[code]?.lowerRange_1) values[`min_${code}`] = value[code].lowerRange_1;
      if (value[code]?.upperRange_1) values[`max_${code}`] = value[code].upperRange_1;
    });
  }

  return values;
};

const Threshold = ({ open, onClose, name }) => {
  const { t } = useTranslation();
  const { uuid, vital } = useParams();
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const thresholdCodes = getThresholdCodes(vital, vitalsConfig);
  const { threshold } = greyboxApiActions;
  const [addThreshold] = threshold.add();
  const [parsedThresholds, setParsedThresholds] = React.useState(null);

  const { data, isLoading } = threshold.list({
    latest: true,
    localAccount: uuid,
    function__in: thresholdCodes.map((c) => c.code).join(','),
  }, { skip: !thresholdCodes });

  const formik = useFormik({
    initialValues: thresholdInitValue(vital, parsedThresholds, thresholdCodes.map((c) => c.shortCode)),
    enableReinitialize: true,
    validationSchema: thresholdValidations(vital, thresholdCodes.map((c) => c.code)),
    onSubmit: (values) => {
      const p = parseThresholdForPost(values, vital, thresholdCodes);

      Promise.all(p.map((item) => addThreshold({
        body: {
          ...item,
          localAccount: uuid,
        },
      }))).then(() => {
        onClose();
      });
    },
  });

  React.useEffect(() => {
    if (data) {
      setParsedThresholds(parseThreshold(data, vitalsConfig));
    }
  }, [data, vitalsConfig]);

  if (isLoading) {
    return null;
  }

  if (vital === 'BP') {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle onClose={onClose}>{`${t('Adjust thresholds for')} ${name}`}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel>{t('Systolic')}</FormLabel>
              <ThresholdTextField config={{ name: 'tensionHigh', shortCode: 'tensionHigh' }} formik={formik} isLoading={isLoading} />
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel>{t('Diastolic')}</FormLabel>
              <ThresholdTextField config={{ name: 'tensionLow', shortCode: 'tensionLow' }} formik={formik} isLoading={isLoading} />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('Cancel')}</Button>
            <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained" autoFocus>
              {t('Save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>{`${t('Adjust threholds for')} ${name}`}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {thresholdCodes.map((item) => (
            <FormControl key={item.code} sx={{ width: '100%' }}>
              <ThresholdTextField config={item} formik={formik} isLoading={isLoading} />
            </FormControl>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('Cancel')}</Button>
          <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained" autoFocus>
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ThresholdTextField = ({ config, formik, isLoading }) => {
  const { name, shortCode } = config;

  if (isLoading) {
    return (
      <Row>
        <Skeleton variant="rectangular" sx={{ m: 1 }} height={56} />
        <Skeleton variant="rectangular" sx={{ m: 1 }} height={56} />
      </Row>
    );
  }

  return (
    <Row>
      <TextField
        id={`min_${shortCode}`}
        sx={{ mr: 1 }}
        name={`min_${shortCode}`}
        label={`Min - ${name}`}
        value={formik.values[`min_${shortCode}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`min_${shortCode}`] && formik.errors[`min_${shortCode}`])}
        helperText={formik.touched[`min_${shortCode}`] && formik.errors[`min_${shortCode}`]}
        variant="outlined"
        type="number"
        onChange={formik.handleChange}
      />
      <TextField
        id={`max_${shortCode}`}
        name={`max_${shortCode}`}
        label={`Max - ${name}`}
        value={formik.values[`max_${shortCode}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`max_${shortCode}`] && formik.errors[`max_${shortCode}`])}
        helperText={formik.touched[`max_${shortCode}`] && formik.errors[`max_${shortCode}`]}
        variant="outlined"
        type="number"
        onChange={formik.handleChange}
      />
    </Row>
  );
};

export default Threshold;
