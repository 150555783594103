import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@mui/material';
import { greyboxApiActions } from '../../redux/api';

const QuestionnaireLabel = ({ answerId }) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const [questionnaireName, setQuestionnaireName] = React.useState('questionnaire');
  const { questionnaire, questionnaireAnswers } = greyboxApiActions;
  const {
    data: questionnaireData,
    isLoading: questionnaireLoading,
  } = questionnaire.list({ clinic: clinic.id });

  const {
    data: answerData,
    isLoading: answerIsLoading,
  } = questionnaireAnswers.get(answerId);

  useEffect(() => {
    if (questionnaireData && answerData) {
      const { name } = questionnaireData.find(
        (q) => q.id === answerData.questionnaire,
      );
      setQuestionnaireName(name);
    }
  }, [questionnaireData, answerData]);

  if (questionnaireLoading || answerIsLoading) {
    return (
      <Skeleton width={100} />
    );
  }

  return (
    <Typography>
      {t(`${t('High Risk Level Based on')} ${questionnaireName}`)}
    </Typography>
  );
};

export default QuestionnaireLabel;
