import React from 'react';
import {
  Button, Avatar, Menu, MenuList, MenuItem, ListItemIcon, ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { greyboxApiActions } from '../redux/api';
import { logout } from '../redux/authorization';
import { toggleTheme } from '../redux/theme';

const ProfileButton = () => {
  const { user } = greyboxApiActions;
  const { data, isLoading } = user.get();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { style } = useSelector((state) => state.theme);

  const userName = isLoading ? '' : `${data?.first_name[0]}${data?.last_name[0]}`;

  const handleNavigation = (path) => {
    setAnchorEl(null);
    history.push(path);
  };

  const handleLogout = () => {
    dispatch(logout());
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  return (
    <>
      <Button
        endIcon={<SettingsIcon sx={{ mr: 1 }} />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="contained"
        sx={{
          borderRadius: '50px', p: 0.5,
        }}
      >
        <Avatar
          sx={{
            mr: 1,
            fontWeight: 'normal',
            fontSize: '0.9rem',
            bgcolor: (theme) => theme.palette.secondary.main,
            height: '30px',
            width: '30px',
          }}
        >
          {userName}
        </Avatar>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <MenuItem onClick={() => handleNavigation('/your-information')}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t('Your Information')}
            </ListItemText>
          </MenuItem>
        </MenuList>
        <MenuItem onClick={() => window.open('https://greybox.ca/terms', '_blank')}>
          <ListItemIcon>
            <AssignmentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t('Terms of Use')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          dispatch(toggleTheme());
          setAnchorEl(null);
        }}
        >
          <ListItemIcon>
            {style === 'light' ? <DarkModeIcon fontSize="small" /> : <LightModeIcon fontSize="small" />}
          </ListItemIcon>
          <ListItemText>
            {style === 'light' ? t('Dark theme') : t('Light theme')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t('Logout')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileButton;
