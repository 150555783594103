import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UnsavedWarning from '../../../modals/UnsavedWarning';
import ErrorHandlingDialog from '../../../utils/ErrorHandlingDialog';
import QuestionnaireContainer from './QuestionnaireContainer';

type DialogQuestionnaireProps = {
  open: boolean;
  handleClose: () => void;
  questionnaireId: number;
  onComplete: (answersId: string) => void;
  previousAnswers?: any;
};

const DialogQuestionnaire = (props: DialogQuestionnaireProps) => {
  const {
    open, handleClose, questionnaireId, onComplete, previousAnswers,
  } = props;
  const { clinic } = useSelector((state: any) => state.clinic);

  const [unsavedWarningOpen, setUnsavedWarningOpen] = useState(false);
  const [hasUnsaved, setHasUnsaved] = useState(false);

  const handleDialogClose = () => {
    if (hasUnsaved) {
      setUnsavedWarningOpen(true);
    } else {
      setHasUnsaved(false);
      handleClose();
    }
  };

  const handleWarningClose = (closeFiller: boolean) => {
    if (closeFiller) {
      setHasUnsaved(false);
      handleClose();
    }
    setUnsavedWarningOpen(false);
  };

  return (
    <>
      <ErrorHandlingDialog open={open} onClose={handleDialogClose} title="Questionnaire">
        <QuestionnaireContainer
          questionnaireId={questionnaireId}
          onComplete={onComplete}
          clinic={clinic.id}
          previousAnswers={previousAnswers}
          setHasUnsaved={setHasUnsaved}
        />
      </ErrorHandlingDialog>
      <UnsavedWarning
        open={unsavedWarningOpen}
        handleClose={handleWarningClose}
      />
    </>
  );
};

export default DialogQuestionnaire;
