import { createSlice } from '@reduxjs/toolkit';

const snackbarStatus = createSlice({
  name: 'snackbarStatus',
  initialState: {
    open: false,
    message: '',
    severity: 'info',
  },
  reducers: {
    setSnackbarStatus: (state, action) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
  },
});

export const { setSnackbarStatus } = snackbarStatus.actions;
export default snackbarStatus.reducer;
