import { Paper, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnswersTable from './AnswersTable';
import QuestionnaireInvitationTable from './QuestionnaireInvitationTable';
import LogBookTable from './LogBookTable';

type QuestionnaireDetailTableProps = {
  questionnaireId: number;
  setOpen: (open: boolean) => void;
  setPreviousAnswers?: (previousAnswers: any) => void;
  type: string;
};

const QuestionnaireDetailTable = (props: QuestionnaireDetailTableProps) => {
  const { t } = useTranslation();
  const {
    questionnaireId, setOpen, setPreviousAnswers, type,
  } = props;
  const [tab, setTab] = useState(0);

  return (
    <Paper sx={{ mt: 1 }}>
      <Tabs
        value={tab}
        onChange={(event, newTab) => setTab(newTab)}
      >
        <Tab label={t('Answers')} />
        <Tab label={t('Invitations')} />
      </Tabs>
      {tab === 0 && (
        type === 'logbook' ? (
          <LogBookTable
            questionnaireId={questionnaireId}
            setOpen={setOpen}
            setPreviousAnswers={setPreviousAnswers}
          />
        ) : (
          <AnswersTable
            questionnaireId={questionnaireId}
            setOpen={setOpen}
            setPreviousAnswers={setPreviousAnswers}
          />
        )
      )}
      {tab === 1 && <QuestionnaireInvitationTable questionnaireId={questionnaireId} />}
    </Paper>
  );
};

export default QuestionnaireDetailTable;
