import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import 'moment/locale/fr';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { greyboxApiActions } from '../../../../redux/api';
import DialogTitle from '../../../DialogTitle';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
  },
  categoriesName: {
    width: '50%',
  },
  categoriesUnit: {
    width: '33%',
  },
  categoriesDate: {
    textAlign: 'right',
    width: '17%',
  },
  groupName: {
    textTransform: 'capitalize',
    paddingRight: '12px',
    width: 'calc(50% - 12px)',
  },
  groupUnit: {
    width: '33%',
  },
  groupDate: {
    width: '17%',
    textAlign: 'right',
  },
}));

const DeleteMedicationModal = (props) => {
  const {
    open,
    handleClose,
    selectedMedications,
  } = props;
  const { t } = useTranslation();
  const { medication } = greyboxApiActions;
  const [deleteMedication, result] = medication.update();
  const classes = useStyles();

  const handleDelete = async () => {
    await Promise.all(selectedMedications.map((med) => (
      deleteMedication({ id: med.uuid, body: { status: 'entered-in-error' } })
    )));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Delete Medications')}
      </DialogTitle>
      <DialogContent>
        <div className={classes.wrapper}>
          <Typography color="textSecondary" className={classes.categoriesName}>
            {t('Medication')}
          </Typography>
          <Typography color="textSecondary" className={classes.categoriesUnit}>
            {t('Daily Dose')}
          </Typography>
          <Typography color="textSecondary" className={classes.categoriesDate}>
            {t('Start')}
          </Typography>
        </div>
        {selectedMedications.map((medInfo) => (
          <div key={medInfo.uuid}>
            <div className={classes.wrapper} key={medInfo.uuid}>
              <Typography variant="body2" className={classes.groupName}>
                <b>
                  {medInfo.name.toLowerCase()}
                </b>
              </Typography>
              <Typography variant="body2" className={classes.groupUnit}>
                {medInfo.daily}
                {' '}
                {medInfo.unit}
              </Typography>
              <Typography variant="body2" className={classes.groupDate}>
                {medInfo.startDate}
              </Typography>
            </div>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={result.isLoading}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleDelete} color="primary" disabled={result.isLoading}>
          {result.isLoading ? <CircularProgress size={14} /> : t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteMedicationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedMedications: PropTypes.arrayOf(PropTypes.object),
};

DeleteMedicationModal.defaultProps = {
  selectedMedications: [],
};

export default DeleteMedicationModal;
