import React, {useState} from 'react';
import DialogQuestionnaire from './DialogQuestionnaire';
import { Question } from '../../../../types';

type SubQuestionnaireProps = {
  questionnaireId: number;
  question: Question;
  setAnswers: (id: number, answer: string) => void;
};

const SubQuestionnaire = (props: SubQuestionnaireProps) => {
  const { questionnaireId, question, setAnswers } = props;
  const [open, setOpen] = useState<boolean>(true);

  const onComplete = (answersId: string) => {
    setAnswers(question.id, answersId);
    setOpen(false);
  };

  return (
    <DialogQuestionnaire
      open={open}
      handleClose={() => setOpen(false)}
      questionnaireId={questionnaireId}
      onComplete={onComplete}
    />
  );
};

export default SubQuestionnaire;
