import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QuestionnaireType } from '../../../../types';
import QuestionForm from './QuestionForm';
import DialogTitle from '../../../DialogTitle';

type AddQuestionDialogProps = {
  open: boolean;
  handleClose: () => void;
  questionnaire: QuestionnaireType;
};

const AddQuestionDialog = ({ open, handleClose, questionnaire }: AddQuestionDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle onClose={handleClose}>
        {t('Add Question')}
      </DialogTitle>
      <DialogContent>
        <QuestionForm questionnaire={questionnaire} handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AddQuestionDialog;
