const currentMed = {
  wrapper: {
    fontFamily: 'Roboto',
    marginBottom: '20px',
    fontSize: '14px',
    marginLeft: '0px',
    filter: 'saturate(1)',
    transition: 'all 400ms ease',
  },
  wrapperHisto: {
    fontFamily: 'Roboto',
    marginBottom: '20px',
    fontSize: '14px',
    marginLeft: '0px',
    filter: 'saturate(0.7)',
    transition: 'all 400ms ease',
  },
  editBtnSVGWrapper: {
    position: 'absolute',
    top: '8px',
    left: '8px',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'left',
    color: '#454545',
    // marginBottom: "7px",
  },
  subTitle: {
    width: '100%',
    color: '#707070',
    fontFamily: 'Roboto',
    fontSize: '14px',
    // lineHeight: "1.43",
  },
  tableTop: {
    borderTop: '1px solid #DEDEDE',
  },
  selectedBack: {
    // background: "#E8FDFF" before alerts with medical acts
    background: '#F3FFFF',
  },
  rowDefault: {
    transition: 'all 100ms ease',
  },
  lastDay: {
    // background: 'rgba(251, 241, 234, 0.35)',
    transition: 'all 300ms ease',
  },
  isIntended: {
    // background: "#F3FFFF",
    filter: 'saturate(0.2)',
    transition: 'all 300ms ease',
    opacity: 0.4,
  },
  subTitle2: {
    width: '100%',
    color: '#707070',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '1.43',
    marginBottom: '24px',
  },

  tabBar: {
    position: 'relative',
    height: '40px',
    background: '#09bbd0',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    filter: 'saturate(1)',
    transition: 'all 400ms ease',
  },
  tabBarHisto: {
    position: 'relative',
    height: '40px',
    background: '#7caeb6',
    transition: 'background 400ms ease',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    // filter: 'saturate(0.5)',
    // transition: 'all 400ms ease'
  },
  tabCurrentOn: {
    position: 'absolute',
    bottom: '0',
    left: '15px',
    width: '80px',
    height: '22px',
    background: '#ffffff',
    border: '1px solid white',
    lineHeight: '16.5px',
    borderBottom: 'none',
    color: '#747474',
    fontWeight: '500',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    textAlign: 'center',
    paddingTop: '8px',
    transition: 'background 200ms ease',
    cursor: 'pointer',
  },
  tabCurrentOff: {
    position: 'absolute',
    bottom: '0',
    left: '15px',
    width: '80px',
    height: '22px',
    background: '#09bbd0',
    lineHeight: '16.5px',
    border: '1px solid rgba(255,255,255,0.2)',
    borderBottom: 'none',
    color: '#ffffff',
    fontWeight: '500',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    textAlign: 'center',
    paddingTop: '8px',
    transition: 'background 200ms ease',
    cursor: 'pointer',
  },
  tabHistoryOff: {
    position: 'absolute',
    bottom: '0',
    left: '108px',
    width: '85px',
    height: '24px',
    background: 'rgba(0,0,0,0.1)',
    color: '#ffffff',
    fontWeight: '500',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    textAlign: 'center',
    paddingTop: '8px',
    transition: 'background 200ms ease',
    cursor: 'pointer',
  },
  tabHistoryOn: {
    position: 'absolute',
    bottom: '0',
    left: '108px',
    width: '85px',
    height: '24px',
    background: '#ffffff',
    color: '#747474',
    fontWeight: '500',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    textAlign: 'center',
    paddingTop: '8px',
    transition: 'background 200ms ease',
    cursor: 'pointer',
  },
  logoBtn: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '7px 7px 7px 28px',
    marginLeft: '12px',
    borderRadius: '3px',
    border: 'solid 1px #dddddd',
    backgroundColor: '#ffffff',
    color: '#747474',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 400ms ease',
  },
  logoBtnDisabled: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '7px 7px 7px 28px',
    borderRadius: '3px',
    marginLeft: '12px',
    opacity: 0.3,

    border: 'solid 1px #dddddd',
    backgroundColor: '#ffffff',
    fontFamily: 'Roboto',
    color: '#747474',
    fontWeight: '500',
    fontSize: '14px',
    transition: 'all 400ms ease',
    pointerEvents: 'none',
  },
  addSvg: {
    position: 'absolute',
    top: '4px',
    left: '2px',
  },
  editSvg: {
    position: 'absolute',
    top: '8px',
    left: '8px',
  },
  stopSvg: {
    position: 'absolute',
    top: '2px',
    transform: 'scale(0.7)',
    left: '2px',
  },
  toolBar: {
    padding: '0px 14px',
    position: 'relative',
  },
  toolBarSpan: {
    fontSize: '24px',
    fontWeight: '200',
    margin: 0,
    padding: 0,
    position: 'absolute',
    left: '23px',
    top: '14px',
  },
  barSvg: {
    position: 'absolute',
    top: '2px',
    transform: 'scale(0.7)',
    left: '3px',
  },
  barSvgStop: {
    position: 'absolute',
    top: '2.5px',
    transform: 'scale(0.75)',
    left: '3px',
  },
  paperStyle: {
    boxShadow: '0 0 1px 1px rgba(0,0,0,0.1)',
    minWidth: '940px',
  },
  cells: {
    borderRight: '1px solid #DEDEDE',
    padding: '0 0 0 9px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#454545',
    position: 'relative',
    margin: 0,
  },
  cellsFlex: {
    borderRight: '1px solid #DEDEDE',
    padding: '5px 0 3px 7px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#454545',
    position: 'relative',
    borderTop: '0px solid #DEDEDE',
  },
  tooltipWrapper: {
    position: 'absolute',
    fontFamily: 'Roboto',
    right: 0,
    transform: 'scale(0.33) translateY(-30px)  translateX( 10px)',
  },
  rowSpace: {
    width: '65.5%',
    minHeight: '40px',
    fontWeight: '500',
  },
  rowSpaceHisto: {
    width: '88%',
    minHeight: '40px',
    fontWeight: '500',
  },
  rowsIngredients: {
    fontSize: '12px',
    color: '#95989a',
    fontWeight: '500',
    textTransform: 'capitalize',
  },
  cellPadding: {
    borderRight: '1px solid #DEDEDE',
    padding: '0 9px 0 9px',
  },
  titrationBadge: {
    fontSize: '13px',
    fontWeight: '400',
    borderRadius: '10px',
    padding: '1.75px 11px',
    overflow: 'hidden',
    color: '#ffffff',
    whiteSpace: 'nowrap',
  },
  titrationNone: {
    background: '#637381',
    borderRadius: '10px',
    fontSize: '13px',
    fontWeight: '400',
    padding: '1.75px 11px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#ffffff',
  },
  cellStyle: {
    borderRight: '1px solid #DEDEDE',
    padding: '0 0 0 9px',
    color: '#454545',
    fontWeight: '400',
    position: 'relative',
  },
  dateCell: {
    padding: '0 0 0 9px',
    color: '#454545',
    fontWeight: '400',
  },
  tableIconPos: {
    position: 'absolute',
    right: '-2px',
    top: '-12px',
    transform: 'rotateX(180deg) scale(1.3)',

  },
  contraindications: {
    width: '88px',
    height: '30px',
    background: 'rgba(0,0,0,0)',
    position: 'absolute',
    zIndex: 100,
    right: '5px',
    top: '-5px',
    cursor: 'pointer',
  },
  graphBackground: {
    backgroundColor: '#f5faff',
    borderRadius: '7px',
    display: 'flex',
    cursor: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  closeModal: {
    position: 'absolute',
    top: '10px',
    right: '7px',
    cursor: 'pointer',
    padding: '10px',
  },
  ownStyle: {
    background: 'transparent',
    color: 'transparent',
    position: 'absolute',
    transform: 'translateY(-7px)',
    width: '160px',
    cursor: 'pointer',
  },
  isSuggested: {
    position: 'absolute',
    width: '15px',
    height: '12px',
    transform: 'translateX(6px) translateY(1px) scale(0.96)',
  },
  isLast: {
    position: 'absolute',
    width: '18px',
    height: '12px',
    transform: 'translateX(25px) translateY(-0.6px) scale(0.99)',
  },
  isNext: {
    position: 'absolute',
    width: '18px',
    height: '12px',
    transform: 'translateX(24px) translateY(-1px) scale(1.1)',
  },
  isNextAndLast: {
    position: 'absolute',
    width: '18px',
    height: '12px',
    transform: 'translateX(44.5px) translateY(-1px) scale(1.1)',
  },
};

const popUp = {
  pos: {
    position: 'relative',
  },
  iconBox: {
    position: 'absolute',
    width: '100%',
    height: '40px',
    cursor: 'pointer',
    top: 'calc(50% - 12px)',
  },
  iconPos: {
    position: 'absolute',
    right: '-1px',
    top: '3px',
    transform: 'scale(1.3)',
  },
  perticularBox: {
    minWidth: '90px',
    maxWidth: '300px',
    minHeight: '100px',
    backgroundColor: 'rgb(99, 115, 129)',
    padding: '8px 12px',
    color: '#ffffff',
    borderRadius: '3px',
    fontFamily: 'Roboto',
    boxShadow: 'none',
  },
  periodStyle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    margin: '8px 0',
    // lineHeight: 1.65, //line height is not a stable value, it will create "jerking" on screen .
    color: '#ffffff',
  },
  dosage: {
    fontWeight: '300',
  },
  limits: {
    transform: 'translateY(-3px) translateX(-5px)',
    pointerEvents: 'none',
  },
};

const stopModal = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '17px 0 0px 0',
    alignItems: 'center',
  },
  lineBold: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    fontSize: '14px',
  },
  endWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 0',
    alignItems: 'center',
    minHeight: '68px',
  },
  endWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 0 0px 0',
    height: '63px',
    alignItems: 'flex-start',
  },
  endTitle: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    marginTop: '20px',
    fontSize: '14px',
  },
  endInput: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    marginTop: '5px',
    fontSize: '14px',
  },

  info: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    fontSize: '14px',
  },

  categoriesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 0 10px 0',
    borderBottom: '1px solid rgb(241, 242, 243)',
    alignItems: 'center',
  },
  categoriesName: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#95989a',
    fontSize: '14px',
    width: '50%',
  },
  categoriesUnit: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#95989a',
    fontSize: '14px',
    width: '33%',
  },
  categoriesDate: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#95989a',
    fontSize: '14px',
    textAlign: 'right',
    width: '17%',
  },
  groupWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 0',
    borderBottom: '1px solid rgb(241, 242, 243)',
    alignItems: 'center',
  },
  groupName: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    textTransform: 'capitalize',
    color: '#454545',
    fontSize: '14px',
    paddingRight: '12px',
    width: 'calc(50% - 12px)',
  },
  groupUnit: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    fontSize: '14px',
    width: '33%',
  },
  groupDate: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    fontSize: '14px',
    width: '17%',
    textAlign: 'right',
  },
  endDateWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 0 0px 0',
    height: '63px',
    alignItems: 'flex-start',
  },
  endDateTitle: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    marginTop: '20px',
    fontSize: '14px',
  },
  endDateInput: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    marginTop: '5px',
    fontSize: '14px',
  },
};
const deleteModal = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 0',
    alignItems: 'center',
  },
  lineBold: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    fontSize: '14px',
  },
  endWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 0',
    alignItems: 'center',
    minHeight: '68px',
  },
  info: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    fontSize: '14px',
  },
  endWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 0 0px 0',
    height: '63px',
    alignItems: 'flex-start',
  },
  endTitle: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    marginTop: '20px',
    fontSize: '14px',
  },
  endInput: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    marginTop: '5px',
    fontSize: '14px',
  },

  categoriesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 0 10px 0',
    borderBottom: '1px solid rgb(241, 242, 243)',
    alignItems: 'center',
  },
  categoriesName: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#95989a',
    fontSize: '14px',
    width: '50%',
  },
  categoriesUnit: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#95989a',
    fontSize: '14px',
    width: '33%',
  },
  categoriesDate: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#95989a',
    fontSize: '14px',
    textAlign: 'right',
    width: '17%',
  },
  groupWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 0',
    borderBottom: '1px solid rgb(241, 242, 243)',
    alignItems: 'center',
  },
  groupWrapperLast: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 0 3px 0',
    alignItems: 'center',
  },
  groupName: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    textTransform: 'capitalize',
    color: '#454545',
    fontSize: '14px',
    paddingRight: '12px',
    width: 'calc(50% - 12px)',
  },
  groupUnit: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    fontSize: '14px',
    width: '33%',
  },
  groupDate: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    fontSize: '14px',
    width: '17%',
    textAlign: 'right',
  },
  endDateWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 0 0px 0',
    height: '63px',
    alignItems: 'flex-start',
  },
  endDateTitle: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    marginTop: '20px',
    fontSize: '14px',
  },
  endDateInput: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    marginTop: '5px',
    fontSize: '14px',
  },
};

const nextStepModal = {
  wrapper: {
    transition: 'all 600ms ease',
    fontFamily: 'Roboto',
    height: '100%',
    letterSpacing: 0,
  },
  lineWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px 0',
    alignItems: 'center',
    wrap: 'no-wrap',
  },
  lineWrapperFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 0  0px 0',
    alignItems: 'center',
    wrap: 'no-wrap',
  },
  new: {
    fontFamily: 'Roboto',
    fontSize: '14.5px',
    color: '#707070',
    fontWeight: '700',
    marginRight: '3px',
    display: 'flex',
    wrap: 'no-wrap',
  },
  current: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#707070',
    fontWeight: '700',
    marginRight: '10px',
    display: 'flex',
    wrap: 'no-wrap',
  },
  lines: {
    height: '2px',
    background: 'rgb(220, 222, 222)',
    width: 'calc(100% - 10px)',
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 0 2px 0',
    alignItems: 'center',
  },
  textRowDaily: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 0 8px 0',
    alignItems: 'center',
  },

  textRowTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 0 10px 0',
    alignItems: 'center',
  },
  textRowTopDose: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // padding: "0px 0 5px 0",
    alignItems: 'center',
  },
  textRowSwitch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 0 12px 0',
    alignItems: 'center',
  },
  textRowInput: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 0 10px 0',
    transition: 'all 600ms ease',
    alignItems: 'center',
  },
  textRowInputDose: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 0 6px 0',
    transition: 'all 600ms ease',
    alignItems: 'center',
  },
  textRowInputPos: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px 0',
    transition: 'all 600ms ease',
    alignItems: 'center',
  },
  rowName: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    // color:"#09bbd0",
    fontSize: '14px',
  },
  rowNameBold: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    fontSize: '14px',
  },
  rowData: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    fontSize: '14px',
    textAlign: 'right',
  },
  rowDataSwitch: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    fontSize: '14px',
    textAlign: 'right',
    // transform: 'translateY(-5px)'
  },
  rowDataBold: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    fontSize: '14px',
    letterSpacing: 0,
    textAlign: 'right',
  },
  endDateWrapperFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 0 16px 0',
    height: '53px',
    alignItems: 'flex-start',
  },
  endDateWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 0 12px 0',
    height: '53px',
    alignItems: 'flex-start',
  },
  endDateWrapperUp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 0 4px 0',
    height: '53px',
    alignItems: 'flex-start',
    transform: 'translateY(-5px)',
  },
  endDateWrapperLast: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '2px 0 15px 0',
    height: '53px',
    alignItems: 'flex-start',
  },
  endDateTitle: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    marginTop: '20px',
    fontSize: '14px',
  },
  endDateInput: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#454545',
    marginTop: '5px',
    fontSize: '14px',
  },
  inputWrapper: {
    // placement
    width: '145px',
    height: '32px',
    textAlign: 'right',
    transform: 'translateX(-5px) translateY(-10px)',
  },
  inputModal: {
    // inside input
    width: '145px',
    textAlign: 'right',
    fontSize: '14px',
    padding: '8px 0px 8px 5px',
  },
  inputModalBounds: {
    // inside input
    width: '145px',
  },
  selectWrapper: {
    // placement
    width: '140px',
    height: '32px',
    textAlign: 'left',
  },
  ingredientName: {
    textTransform: 'capitalize',
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '7px',
    lineHeight: '1.2',
  },
  unitsVal: {
    textTransform: 'lowercase',
    fontSize: '12px',
    fontWeight: '500',
    color: '#95989a',
  },
};

const editMedication = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '16px',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
    minHeight: '32px',
    color: '#454545',
  },
  disabledRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '16px',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
    minHeight: '32px',
    color: '#454545',
    opacity: 0.5,
  },
  inputColumn: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  activeIngredient: {
    fontSize: '13px',
    textTransform: 'capitalize',
  },
  activeIngredientUnit: {
    fontSize: '12px',
    color: '#95989a',
    textTransform: 'none',
  },
  grey: {
    color: '#95989a',
    fontWeight: '400',
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: '14px',
    height: '68px',
  },
  labelDateRow: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: '#454545',
    paddingTop: '14px',
  },
  selectMenuPlaceHolder: {
    color: '#95989a',
    fontSize: '14px',
  },
  selectMenuItem: {
    color: '#454545',
    fontSize: '14px',
  },
};

export {
  currentMed, popUp, stopModal, deleteModal, editMedication, nextStepModal,
};
