import React from "react";
import { SvgIcon } from "@mui/material";

const NoMedications = (props) => (
  <SvgIcon {...props}>
 <path id="Path_67" d="M0 0h24v24H0z" fill="none" data-name="Path 67"/>
    <g id="Ellipse_256"  data-name="Ellipse 256" fill="#d2f6fa" stroke="#74e6f4" strokeWidth='4px'>
        <circle cx="12" cy="12" r="12" stroke="none"/>
        <circle cx="12" cy="12" r="10" fill="none"/>
    </g>
    <path id="Path_68" d="M10.1 6.5h1.8v1.8h-1.8zm0 3.6h1.8v5.4h-1.8zM11 2a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 16.2a7.2 7.2 0 1 1 7.2-7.2 7.21 7.21 0 0 1-7.2 7.2z"  fill="#00848e" data-name="Path 68" transform="translate(1 1)"/>
  </SvgIcon>
);

export { NoMedications }