import {
  Box,
  Chip,
  Skeleton,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';

const ChipSection = ({ selection, type, ...rest }) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }} data-cy={`${type}-chip-section`}>
    {selection.map((item) => (
      <ChipSelector {...rest} key={item.id} type={type} {...item} />
    ))}
  </Box>
);

const ChipSelector = (props) => {
  const {
    id, label, type, handleRemove,
  } = props;
  let display = label;
  const { i18n } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { account, diagnosis } = greyboxApiActions;
  const { data, isLoading } = account.get(id, { skip: type !== 'staffs' || Boolean(label) });
  const diagnosisSelector = diagnosis.list({ clinic: clinic.id }, { skip: type !== 'diagnosis' || Boolean(label) });

  if (isLoading || diagnosisSelector.isLoading) {
    return <Skeleton variant="rectangular" sx={{ my: 0.5, mr: 0.5 }} width={50} height={30} />;
  }

  // Must get display from API
  if (!label) {
    if (type === 'staffs' && data) {
      display = `${data.firstName} ${data.lastName}`;
    } else if (type === 'diagnosis') {
      display = diagnosisSelector.data.find((d) => d.id === id).diagnosis_name || '';
    }
  }

  if (!display) {
    return null;
  }

  return (
    <Chip
      label={display}
      sx={{ my: 0.5, mr: 0.5 }}
      onDelete={() => handleRemove({ id, display, type })}
    />
  );
};

export default ChipSection;
