import { MoreVert } from '@mui/icons-material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LinkIcon from '@mui/icons-material/Link';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Box, IconButton, Paper, Skeleton, Table, TableBody, TableCell,
  TableHead, TableRow, Tooltip, Typography, Menu, MenuItem,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { greyboxApiActions } from '../../redux/api';
import TableSkeleton from '../skeletons/TableSkeleton';
import AddDocument from './AddDocument';

const TYPE_ICON = {
  pdf: <PictureAsPdfIcon />,
  image: <InsertPhotoIcon />,
  video: <OndemandVideoIcon />,
  link: <LinkIcon />,
  audio: <VolumeUpIcon />,
};

const EducationSection = ({ sectionId, readOnly }) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { educationArticle } = greyboxApiActions;
  const {
    data, isFetching, isError, refetch,
  } = educationArticle.list({
    clinic: clinic.id, section: sectionId,
  });
  const [rows, setRows] = React.useState([]);
  const [deleteArticle] = educationArticle.delete();
  const [selectedArticle, setSelectedArticle] = React.useState(null);
  const [editDocumentOpen, setEditDocumentOpen] = React.useState(false);

  const handleMenuClick = (action, article) => {
    switch (action) {
      case 'edit':
        setSelectedArticle(article.uuid);
        setEditDocumentOpen(true);
        break;
      case 'delete':
        deleteArticle(article.uuid).then(() => refetch());
        break;
      case 'see':
        window.open(article.url, '_blank');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRow = rows[dragIndex];
    setRows((prevSections) => {
      const newRows = [...prevSections];
      newRows.splice(dragIndex, 1);
      newRows.splice(hoverIndex, 0, dragRow);
      return newRows.filter((row) => row);
    });
  };

  if (isFetching) {
    return (
      readOnly ? (
        Array.from(new Array(3)).map((_, i) => (
          <Paper key={i} sx={{ p: 2, mb: 1 }}>
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <Skeleton variant="text" width={200} />
            </Box>
            <Skeleton variant="text" width={400} />
          </Paper>
        ))
      ) : (
        <TableSkeleton rowCount={4} />
      )
    );
  }

  if (isError) {
    return null;
  }

  return (
    readOnly ? (
      data.map((article) => (
        <Paper
          type="Button"
          onClick={() => window.open(article.url, '_blank')}
          key={article.uuid}
          sx={{
            p: 2,
            mb: 1,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.light,
            },
          }}
        >
          <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
            <Typography sx={{ mr: 1 }}>
              <b>
                {article.title}
              </b>
            </Typography>
            {TYPE_ICON[article.type]}
          </Box>
          <Typography variant="body2">{article.description}</Typography>
        </Paper>
      ))
    ) : (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t('Title')}</TableCell>
              <TableCell>{t('Type')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((article, index) => (
                <ArticleRow
                  article={article}
                  key={article.uuid}
                  moveRow={moveRow}
                  handleMenuClick={handleMenuClick}
                  index={index}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} height={100}>
                  <Typography align="center" color="textSecondary">
                    {t('No document')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {editDocumentOpen && (
          <AddDocument
            open={editDocumentOpen}
            handleClose={() => {
              setEditDocumentOpen(false);
              setSelectedArticle(null);
            }}
            section={sectionId}
            articleId={selectedArticle}
          />
        )}
      </>
    )
  );
};

const ArticleRow = ({
  article, moveRow, index, handleMenuClick,
}) => {
  const [hover, setHover] = React.useState(false);
  const { educationArticle } = greyboxApiActions;
  const [updateArticle] = educationArticle.update();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: article.section,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item) {
      const hoverIndex = index;
      const formData = new FormData();
      formData.append('order', hoverIndex + 1);
      updateArticle({ id: item.id, body: formData });
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: article.section,
    item: () => ({ id: article.uuid, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  const handleClick = (action) => {
    setAnchorEl(null);
    handleMenuClick(action, article);
  };

  return (
    <TableRow
      hover
      ref={ref}
      data-handler-id={handlerId}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{ height: 68, cursor: isDragging ? 'grabbing' : 'grab' }}
    >
      <TableCell>
        <DragHandleIcon />
      </TableCell>
      <TableCell>{article.title}</TableCell>
      <TableCell>{article.type}</TableCell>
      <TableCell align="right" sx={{ width: 50 }}>
        {hover && (
          <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => handleClick('edit')}>{t('Edit')}</MenuItem>
          <MenuItem onClick={() => handleClick('see')}>{t('See')}</MenuItem>
          <MenuItem onClick={() => handleClick('delete')}>{t('Delete')}</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default EducationSection;
