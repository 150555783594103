export enum EndingConditionType {
  None = 'none',
  EndDate = 'end_date',
  OccurrencesNumber = 'occurrences_number'
}

export enum FrequencyType {
  None = 'none',
  Hourly = 'hourly',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Annually = 'annually'
}

export interface RecurrenceType {
  frequency: FrequencyType;
  numberOfRepetitions?: number;
  weekDaysRepetition: Array<number>;
  endingCondition: EndingConditionType;
  endingDate?: Date;
  endingOccurrencesNumber?: number;
  isAllDay: boolean;
}

export interface Option {
  key: string;
  title: string;
}
