import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Badge, Box, Chip, IconButton, Menu,
  MenuItem, Skeleton, Tooltip, Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../redux/api';

const FilterButton = (props) => {
  const { filters, setFilters, showCareTeams = false } = props;
  const { careTeam, label } = greyboxApiActions;
  const { t } = useTranslation();
  const { accountId } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
  const isFilterMenuOpen = Boolean(filterMenuAnchorEl);
  const theme = useTheme();
  const careTeamSelector = careTeam.list({ participant: `Practitioner/${accountId}`, clinic: clinic.id }, { skip: !isFilterMenuOpen });
  const labelSelector = label.list({
    is_root_node: true, clinic: clinic.id, system: false,
  }, { skip: !isFilterMenuOpen });
  const hasFilter = filters.length > 0;

  return (
    <>
      <Box>
        <Badge badgeContent={filters.length} invisible={!hasFilter} color="primary">
          <Tooltip title={t('Filter')}>
            <IconButton
              sx={{ backgroundColor: theme.palette.background.paper, ml: 1 }}
              onClick={(e) => setFilterMenuAnchorEl(e.currentTarget)}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Badge>
      </Box>
      <Menu
        anchorEl={filterMenuAnchorEl}
        open={isFilterMenuOpen}
        onClose={() => setFilterMenuAnchorEl(null)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 1 }}
        >
          <Typography variant="h6">
            {t('Filter')}
          </Typography>
          <IconButton onClick={() => setFilterMenuAnchorEl(null)}>
            <ClearIcon />
          </IconButton>
        </Box>
        {hasFilter && (
          <>
            <MenuItem
              onClick={() => { setFilters([]); setFilterMenuAnchorEl(null); }}
              sx={{ textDecoration: 'underline' }}
            >
              {t('Clear all filters')}
            </MenuItem>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                flexWrap: 'wrap',
                minHeight: '70px',
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              {filters.map((f) => (
                <Chip
                  onDelete={() => setFilters(filters.filter((r) => r.id !== f.id))}
                  label={f.name}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </>
        )}
        {showCareTeams && (
          <Box minWidth="350px" sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
            <Typography sx={{ my: 1, ml: 1 }}>
              {t('Care Team')}
            </Typography>
            {careTeamSelector.isLoading ? (
              Array.from(new Array(3)).map((_, idx) => (
                <MenuItem key={idx}>
                  <Skeleton variant="text" width="100px" />
                </MenuItem>
              ))
            ) : (
                careTeamSelector.data?.entry?.map((r) => (
                  <MenuItem
                    key={r.resource.id}
                    value={r.resource.id}
                    selected={filters.some((f) => f.id === r.resource.id)}
                    onClick={() => {
                      if (filters.some((f) => f.id === r.resource.id)) {
                        setFilters(filters.filter((f) => f.id !== r.resource.id));
                      } else {
                        setFilters([...filters, { id: r.resource.id, type: 'careTeam', name: r.resource.name }]);
                      }
                    }}
                  >
                    {r.resource.name}
                  </MenuItem>
                ))
            )}
          </Box>
        )}
        <Box minWidth="350px" sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
          <Typography sx={{ my: 1, ml: 1 }}>
            {t('Labels')}
          </Typography>
          {labelSelector.isLoading ? (
            Array.from(new Array(3)).map((_, idx) => (
              <MenuItem key={idx}>
                <Skeleton variant="text" width="100px" />
              </MenuItem>
            ))
          ) : (
              labelSelector.data?.map((l) => (
                <MenuItem
                  key={l.id}
                  value={l.id}
                  selected={filters.some((f) => f.id === l.id)}
                  onClick={() => {
                    if (filters.some((f) => f.id === l.id)) {
                      setFilters(filters.filter((f) => f.id !== l.id));
                    } else {
                      setFilters([...filters, { id: l.id, type: 'label', name: l.name }]);
                    }
                  }}
                >
                  {l.name}
                </MenuItem>
              ))
          )}
        </Box>
      </Menu>
    </>
  );
};

export default FilterButton;
