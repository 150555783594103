import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogContent } from '@mui/material';
import { greyboxApiActions } from '../../../redux/api';
import { RootState } from '../../../redux/store';
import { QuestionnaireAnswers, Score } from '../../../types';
import ScoreDialog from './ScoreDialog';

type ScoreButtonProps = {
  score: Score;
}

const ScoreButton = ({ score }: ScoreButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        { t('Score') }
      </Button>
      {open && (
        <ScoreDialog score={score} open={open} setOpen={setOpen} />
      )}
    </>
  );
};

export default ScoreButton;
