import React from "react";
import { SvgIcon } from "@mui/material";

const Sorting = (props) => (
  <SvgIcon {...props}>
    
   
    <path id="Path_202" d="M7 10l5 5 5-5z" fill="#babdbf" data-name="Path 202" transform="translate(0 4)"/>
    <path id="Path_203" d="M0 0h24v24H0z" fill="none" data-name="Path 203"/>
    <path id="Path_204" d="M7 10l5 5 5-5z" fill="#babdbf"data-name="Path 204" transform="rotate(180 12 10)"/>
  </SvgIcon>
);

export { Sorting }