import { createSlice } from '@reduxjs/toolkit';

/**
 * State of the current user (patient or HCP). Contains most used data about the user.
 * The data here cannot be changed directly by the application so this should only be
 * initialized once at the start of the app.
 */
const user = createSlice({
  name: 'company',
  initialState: {
    accountId: null,
    isLoading: false,
    isUninitialized: true,
    access: null,
    role: null,
    clinics: [],
  },
  reducers: {
    userLoading: (state) => {
      state.isLoading = true;
    },
    userLoaded: (state, action) => {
      state.isLoading = false;
      state.isUninitialized = false;
      state.accountId = action.payload.accountId;
      state.userId = action.payload.userId;
      state.access = action.payload.access;
      state.role = action.payload.role;
      state.clinics = action.payload.clinics;
      state.invitations = action.payload.invitations
    },
  },
});

const { userLoading, userLoaded } = user.actions;

const initializeUser = (account) => async (dispatch) => {
  dispatch(userLoaded({
    accountId: account.uuid,
    userId: account.user.id,
    access: account.acc_type,
    role: account.role_type_code,
    clinics: account.company,
    invitations: account.invitations
  }));
};

export { initializeUser };
export default user.reducer;
