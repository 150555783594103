import { Box, Checkbox, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CheckboxSelector = ({
  selected, onChange, children, index,
}) => (
  <Box
    sx={{
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      width: '29%',
      borderRadius: '4px',
      m: 0.5,
      p: 1,
      border: (theme) => `1px solid ${theme.palette.divider}`,
      '&:hover': {
        backgroundColor: (theme) => theme.palette.background.default,
      },
    }}
    type="button"
    data-cy={index}
    onClick={() => onChange(children)}
  >
    <Typography variant="body2">{children.label}</Typography>
    <Checkbox
      checked={selected}
      onChange={() => onChange(children)}
      sx={{ ml: 'auto' }}
    />
  </Box>
);

export default CheckboxSelector;
