import {
  Button, Dialog, DialogActions, DialogContent,
  Typography, Box,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import CheckboxSelector from '../CheckboxSelector';
import DialogTitle from '../DialogTitle';

const filterDiagnosis = (diagnosis, level) => {
  const categories = {};

  diagnosis.filter((d) => d.diagnosis_category.level.includes(level)
  || d.diagnosis_category.level.length === 0)
    .forEach((item) => {
      const categoryId = item.diagnosis_category.uuid;
      if (!categories[categoryId]) {
        categories[categoryId] = {
          name: item.diagnosis_category.diagnosis_category_name,
          diagnosis: [{
            id: item.uuid,
            label: item.diagnosis_name,
            key: item.diagnosis_key,
          }],
        };
      } else {
        categories[categoryId].diagnosis.push({
          id: item.uuid,
          label: item.diagnosis_name,
          key: item.diagnosis_key,
        });
      }
    });

  return categories;
};

const DiagnosisSelectionDialog = (props) => {
  const { t, i18n } = useTranslation();
  const {
    open, selected = [], setSelected, onClose, level,
  } = props;
  const { diagnosis } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { data, isFetching } = diagnosis.list({ clinic: clinic.id });

  if (isFetching) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>{t('Diagnosis Selection')}</DialogTitle>
      <DialogContent dividers>
        {Object.entries(filterDiagnosis(data, level)).map(([key, value], index) => (
          <Box key={key} sx={{ mt: 1 }}>
            <Typography variant="h6">
              {value.name}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {value.diagnosis.map((item, idx) => (
                <CheckboxSelector
                  key={item.id}
                  index={index * 100 + idx}
                  id={item.id}
                  selected={Boolean(selected.find((s) => s.id === item.id))}
                  type={`${level}Diagnosis`}
                  onChange={setSelected}
                >
                  {item}
                </CheckboxSelector>
              ))}
            </Box>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button data-cy="close" onClick={onClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiagnosisSelectionDialog;
