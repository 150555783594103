import React from "react";
import { useTranslation } from "react-i18next";
import header_style from "./header_style";

// weeks from now till end of titration projected date
const countTimeTillEnd = (projected, todayArg = new Date()) => {
  let today, end, totalDays, totalWeeks;
  if (projected !== null) {
    today = todayArg;
    end = new Date(projected);
    let DifferenceInTime = end.getTime() - today.getTime();
    totalDays = Math.ceil(DifferenceInTime / (1000 * 3600 * 24));
    totalWeeks = Math.ceil(totalDays / 7);
  }
  return totalWeeks;
};

//weeks from titration start ( created date) to ending of titration according to plan projection
const countPeriod = (titrationStartDate, projectedReachDate) => {
  if (projectedReachDate !== null) {
    const start = new Date(titrationStartDate); //first day of titration
    const end = new Date(projectedReachDate); //projected date
    const  DifferenceInTime = end.getTime() - start.getTime();

    const totalDays = Math.ceil(DifferenceInTime / (1000 * 3600 * 24));
    return Math.ceil(totalDays / 7);
  }
  return undefined;
};

const Header = props => {
  const {
    med_name, //name of the medication
    titration, //titration obj
    titration_status, //pre determined status
    projected_reach_date, //projected end of titration
    titration_status_choices, //the different choices for the titration
    titration_start_date //beginning date
  } = props;
  const { t } = useTranslation();

  //returns weeks from today till projected end
  const timeTillEnd = countTimeTillEnd(projected_reach_date);
  //gives the proposed guideline time in weeks.
  const titrationNormalDuration = titration.dosage_steps.duration_total;
  //creates an array of the obj.
  const status = !!titration_status_choices && Object.values(titration_status_choices);
  //titration start date.
  const titrationStart = new Date(titration_start_date);
  //time it took / taking
  const titrationPeriod = countPeriod(
    titration_start_date,
    projected_reach_date,
  );

  //return week ahead of titration
  const isAhead =
    titrationNormalDuration > titrationPeriod ? titrationNormalDuration - titrationPeriod : null;
 
  //return week ahead of titration
  const isBehind =
    titrationNormalDuration < titrationPeriod ? titrationPeriod - titrationNormalDuration : null;

  //return is on time
  const isOnTime = titrationNormalDuration === titrationPeriod ? titrationPeriod : null;
  
  const status_title = titration_status_choices[titration_status].name;
 
  return (
    <>
      <div style={header_style.titleHeader} data-cy="titMedName">
        {med_name}
      </div>
      <div style={header_style.badgeWrapper} data-cy="badgeWrapper">
        {titration_status === "AHEAD" && (
          <div data-cy="badgeAhead">
            <span style={header_style.titrationClean}>{t(status_title)}</span>
            <div style={header_style.reach}>
              {t("At this pace, your patient will complete the titration plan in")}
              <span style={header_style.reachBold} >
                {" "}
                <>
                  {" "}
                  {timeTillEnd} {t(timeTillEnd === 1 ? "week" : "weeks")}
                </>
                .
              </span>
            </div>
            <div style={header_style.pace} data-cy="badgeAheadBy">
              {t("This is")}{" "}
              <span style={header_style.reachBold}>
                <>
                  {" "}
                  {isAhead} {t(isAhead === 1 ? "week ahead" : "weeks ahead")}
                </>
                {' '}
              </span>
              {t("of the initial plan")}.
            </div>
          </div>
        )}

        {titration_status === "BEHIND" && (
          <div data-cy="badgeBehind">
            <span style={header_style.titrationWarning}>{t(status_title)}</span>
            {projected_reach_date ? (
              <>
                <div style={header_style.reach}>
                  {t("At this pace, your patient will complete the titration plan in")}
                  <span style={header_style.reachBold}>
                    {" "}
                    <>
                      {" "}
                      {timeTillEnd} {t(timeTillEnd === 1 ? "week" : "weeks")}
                    </>
                    .
                  </span>
                </div>
                <div style={header_style.pace}>
                  {t("This is")}{" "}
                  <span style={header_style.reachBold}>
                    <>
                      {" "}
                      {isBehind} {t(isBehind === 1 ? "week behind" : "weeks behind")}
                    </>
                    {" "}
                  </span>
                  {t("of the initial plan")}.
                </div>
              </>
            ) : (
              <>
                <div style={header_style.reach}>
                  {t("Your patient is on a daily dose that is below the lowest suggested dose")}.
                </div>
                <div style={header_style.pace}>
                  {t(
                    "The titration plan’s projected completion cannot be calculated for this medication"
                  )}
                  .
                </div>
              </>
            )}
          </div>
        )}

        {titration_status === "ON_TIME" && (
          <div data-cy="badgeOnTime">
            <span style={header_style.titrationClean}>{t(status_title)}</span>
            <div style={header_style.reach}>
              {t("At this pace, your patient will complete the titration plan in")}
              <span style={header_style.reachBold}>
                {" "}
                <>
                  {" "}
                  {timeTillEnd} {t(timeTillEnd === 1 ? "week" : "weeks")}
                </>
                .
              </span>
            </div>
            <div style={header_style.pace}>
              {t("This is")} <span style={header_style.reachBold}>{t("on time")}</span>{" "}
              {t("according to the initial plan")}.
            </div>
          </div>
        )}

        {titration_status === "MAX_TOLERATED_DOSAGE" && (
          <div data-cy="badgeMax">
            <span style={header_style.titrationClean}>{t(status_title)}</span>
            <div style={header_style.reach}>
              {t("Your patient has reached the")}{" "}
              <span style={header_style.reachBold}> {t("maximum tolerated dose")}</span>{" "}
              {t("for this medication")}.
            </div>
            <div style={header_style.pace}>
              <span style={header_style.reachBold}>
                {t("Titration plan will not be completed")}.
              </span>
            </div>
          </div>
        )}

        {titration_status === "TARGET_REACHED" && (
          <div data-cy="badgeCompleted">
            <span style={header_style.titrationClean}>{t(status_title)}</span>
            <div style={header_style.reach}>
              {t("Your patient has completed the titration plan in")}{" "}
              <span style={header_style.reachBold}>
                {titrationPeriod} {t("weeks")}.
              </span>
            </div>

            {isAhead && (
              <div style={header_style.pace}>
                {t("This is")}{" "}
                <span style={header_style.reachBold}>
                  <>
                    {" "}
                    {isAhead} {t(isAhead === 1 ? "week ahead" : "weeks ahead")}
                  </>
                </span>{" "}
                {t("of the initial plan")}.
              </div>
            )}
            {isBehind && (
              <div style={header_style.pace}>
                {t("This is")}{" "}
                <span style={header_style.reachBold}>
                  <>
                    {" "}
                    {isBehind} {t(isBehind === 1 ? "week behind" : "weeks behind")}
                  </>
                </span>{" "}
                {t("of the initial plan")}.
              </div>
            )}
            {isOnTime && (
              <div style={header_style.pace}>
                {t("This is")} <span style={header_style.reachBold}> {t("on time")}</span>{" "}
                {t("with the initial plan")}.
              </div>
            )}
          </div>
        )}

        {titration_status === "CANNOT_CALC_PROJ_REACH" && (
          <div data-cy="badgeError">
            <span style={header_style.titrationException}>{t(status_title)}</span>
            <div style={header_style.reach}>
              {t("Unable to calculate the titration plan’s projected completion")}.
            </div>
            <div style={header_style.pace}>
              {t("This could be due to the dose being outside the titration of guidelines")}.
            </div>
          </div>
        )}
      </div>
    </>
  )
};

export { countPeriod, countTimeTillEnd };

export default Header;