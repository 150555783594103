import {
  Button,
  Checkbox,
  Radio,
  Typography,
} from '@mui/material';
import React from 'react';

  type AnswerSelectProps = {
    selected: boolean;
    setSelected: (value: string) => void;
    children: React.ReactNode;
    value: string;
    index: number;
    isCurrent: boolean;
    multiSelect?: boolean;
  };

const AnswerSelect = (props: AnswerSelectProps) => {
  const {
    selected, setSelected, children, value, index, isCurrent, multiSelect = false,
  } = props;

  return (
    <Button
      role="button"
      onClick={() => setSelected(value)}
      data-cy={isCurrent ? `answer-${index}` : ''}
      fullWidth
      variant={selected ? 'contained' : 'outlined'}
      sx={{
        minHeight: '70px',
        textTransform: 'none',
        justifyContent: 'flex-start',
        p: 2,
        mb: 1,
      }}
    >
      {multiSelect ? (
        <Checkbox checked={selected} value={value} />
      ) : (
        <Radio checked={selected} value={value} />
      )}
      <Typography textAlign="left">
        {children}
      </Typography>
    </Button>
  );
};

export default AnswerSelect;
