import React from 'react';
import { SvgIcon } from '@mui/material';

const OutdatedMeasurement = (props) => (
  <SvgIcon {...props}>
    <path
      fill="#454545"
      d="M7.333,4V9.333H12V8H8.667V4Zm7.236,5.122,1.332.106a7.96,7.96,0,0,1-.435,1.64l-1.2-.6a6.607,6.607,0,0,0,.3-1.143Zm-1.7,5.217-.942-.962A6.63,6.63,0,0,1,10.892,14l.446,1.266A7.954,7.954,0,0,0,12.871,14.339Zm.817-2.873a6.727,6.727,0,0,1-.766,1.019l.936.955a8.025,8.025,0,0,0,1.026-1.372ZM0,8a7.978,7.978,0,0,0,10.083,7.717l-.443-1.263A6.663,6.663,0,1,1,8,1.333a6.612,6.612,0,0,1,3.973,1.326L10.609,4.025l4.677.9-.9-4.675L12.926,1.708A7.989,7.989,0,0,0,0,8ZM15.849,6.427H14.465a6.775,6.775,0,0,1,.192,1.365L16,7.9A8.1,8.1,0,0,0,15.849,6.427Z"
    />

  </SvgIcon>
);

export { OutdatedMeasurement };
