import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import QuestionnaireContainer from './filler/QuestionnaireContainer';

const AskQuestionnaire = (props) => {
  const { questionnaireId } = props;
  const { clinic } = useSelector((state) => state.clinic);

  return (
    <Box height="90vh">
      <QuestionnaireContainer questionnaireId={questionnaireId} clinic={clinic.id} />
    </Box>
  );
};

export default AskQuestionnaire;
