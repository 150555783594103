import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  IconButton, Paper, Stack, Tooltip, Typography, Switch, Box, Select,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import Threshold from '../form-inputs/Threshold';
import DurationSelect from './DurationSelect';

const ChartContainer = ({
  children, name, url = '', setOpen = null, setIsSummary = null, isVital = false,
  isSummary = false, disabled = false, detailed = false, duration = null, setDuration = null,
  hasThresholds = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [thresholdOpen, setThresholdOpen] = React.useState(false);
  const { access } = useSelector((state) => state.user);

  if (detailed) {
    return (
      <Paper sx={{
        p: 1, height: '30vh', pb: 6,
      }}
      >
        {isVital && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={t('Show summary')}>
              <Switch checked={isSummary} onChange={() => setIsSummary(!isSummary)} size="small" disabled={disabled} />
            </Tooltip>
            {isSummary && (
              <Box>
                <DurationSelect duration={duration} setDuration={setDuration} />
              </Box>
            )}
            {hasThresholds && access === 'PT' && (
              <Tooltip title={t('Adjust Thresholds')}>
                <IconButton size="small" onClick={() => setThresholdOpen(true)}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            )}
            {thresholdOpen && (
              <Threshold name={name} open={thresholdOpen} onClose={() => setThresholdOpen(false)} />
            )}
          </Box>
        )}
        {children}
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 1 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>
          <b>
            {name}
          </b>
        </Typography>
        {setOpen && (
          <Tooltip title={t('Add')}>
            <IconButton size="small" color="primary" onClick={() => setOpen(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t('Detailed information')}>
          <IconButton size="small" color="primary" onClick={() => history.push(url)}>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
        {isVital && (
          <Tooltip title={t('Show summary')}>
            <Switch checked={isSummary} onChange={() => setIsSummary(!isSummary)} size="small" disabled={disabled} />
          </Tooltip>
        )}
      </Stack>
      {children}
    </Paper>
  );
};

export default ChartContainer;
