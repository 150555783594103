import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Autocomplete, CircularProgress, Button, Fab, IconButton, MenuItem, Paper, Select, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Link,
  Skeleton, Box, Dialog, DialogContent, DialogActions, Stack,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import { RootState } from '../../redux/store';
import DialogTitle from '../DialogTitle';
import FormSelect from '../form-inputs/FormSelect';
import { parseParticipants } from './utils';
import TableSkeleton from '../skeletons/TableSkeleton';
import CareTeamInvitation from '../invitations/CareTeamInvitation';
import Row from '../Row';

interface PropsType {
  participants: any[];
  refetch: () => void;
}

const ParticipantTable = (props: PropsType) => {
  const { t } = useTranslation();
  const { id, uuid } = useParams();
  const hasPatient = Boolean(uuid);
  const { participants, refetch } = props;
  const { roles, careTeam } = greyboxApiActions;
  const { data, isLoading } = roles.list({ acc_type__in: hasPatient ? 'PT,CP' : 'PT' });
  const [addParticipantOpen, setAddParticipantOpen] = React.useState(false);
  const [hovering, setIsHovering] = React.useState(null);
  const [updateCareTeam] = careTeam.update();
  const [invitationOpen, setInvitationOpen] = React.useState(false);

  const columns = [
    {
      id: 'name',
      label: t('Name'),
    },
    {
      id: 'type',
      label: t('Type'),
    },
    {
      id: 'role',
      label: t('Role'),
    },
  ];

  if (isLoading) {
    return (
      <TableContainer>
        <Skeleton variant="rectangular" width="90px" />
        <TableSkeleton columns={columns} rowCount={4} />
      </TableContainer>
    );
  }

  const handleDelete = (participantId) => {
    const newParticipants = participants.filter((participant) => participant.id !== participantId);
    updateCareTeam({ id, body: { participant: parseParticipants(newParticipants) } });
  };

  const handleInvitationClose = (isNewInvitation: boolean) => {
    setInvitationOpen(false);
    if (isNewInvitation) {
      refetch();
    }
  };

  return (
    <>
      <TableContainer sx={{ mb: 2 }} component={Paper}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ p: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Button startIcon={<AddIcon />} variant="outlined" onClick={() => setAddParticipantOpen(true)}>
            {t('Add')}
          </Button>
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.length > 0 ? (
              participants.map((participant) => (
                <TableRow
                  key={participant.id}
                  sx={{ height: 68 }}
                  onMouseEnter={() => setIsHovering(participant.id)}
                  onMouseLeave={() => setIsHovering(null)}
                >
                  <TableCell>{participant.member.display}</TableCell>
                  <TableCell>{participant.member.reference.split('/')[0]}</TableCell>
                  <TableCell>
                    {participant.role?.map((item) => data.find((role) => role.role_type === item.coding[0].code)?.role_name).join(', ')}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 50 }}>
                    {hovering === participant.id && (
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(participant.id)}
                        sx={{ transition: 'all 0.7s ease-in-out' }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} height={100}>
                  <Typography align="center" color="textSecondary">
                    {t('No participant')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {addParticipantOpen && (
        <ParticipantDialog
          open={addParticipantOpen}
          handleClose={() => setAddParticipantOpen(false)}
          handleInvitation={() => {
            setAddParticipantOpen(false);
            setInvitationOpen(true);
          }}
          participants={participants}
        />
      )}
      {invitationOpen && (
        <CareTeamInvitation
          open={invitationOpen}
          handleClose={handleInvitationClose}
          careTeamId={id}
        />
      )}
    </>
  );
};

const ParticipantDialog = (props) => {
  const { t, i18n } = useTranslation();
  const {
    open, handleClose, participants, handleInvitation,
  } = props;
  const { careTeam, account, roles } = greyboxApiActions;
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const { id, uuid } = useParams();
  const hasPatient = Boolean(uuid);
  const { access } = useSelector((state: RootState) => state.user);
  const [searchInput, setSearchInput] = React.useState('');
  const { data = [], isFetching } = account.list(
    { search: searchInput, clinic: clinic!.id, acc_type__in: hasPatient ? 'PT,P,CP,CPP' : 'PT,CPP' },
    { skip: searchInput.length < 3 },
  );
  const careTeamSelector = careTeam.list(
    {
      clinic: clinic!.id,
      'managing-organization:Organization.identifier': clinic!.id,
      'subject:missing': 'true',
    },
    { skip: access !== 'PT' },
  );
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const rolesSelector = roles.list({ acc_type__in: hasPatient ? 'PT,CP' : 'PT' });
  const [updateCareTeam] = careTeam.update();

  const formik = useFormik({
    initialValues: {
      member: '',
      role: '',
      type: hasPatient ? '' : 'Practitioner',
    },
    validationSchema: Yup.object({
      member: Yup.string().required(t('Required')),
      role: Yup.string().required(t('Required')),
      type: Yup.string().required(t('Required')),
    }),
    onSubmit: (values) => {
      updateCareTeam({
        id: id,
        body: {
          participant: [
            ...parseParticipants(participants),
            {
              member: {
                reference: `${values.type}/${values.member}`,
              },
              role: [{ coding: [{ code: values.role }] }],
            },
          ],
        },
      }).then(() => {
        handleClose();
      });
    },
  });

  useEffect(() => {
    if (formik.values.type === 'RelatedPerson') {
      formik.setFieldValue('role', 'CTP');
    }
  }, [formik.values.type]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Add participant')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Row>
            <Autocomplete
              noOptionsText={(
                <Link onClick={handleInvitation}>
                  {t('Invite a new participant')}
                </Link>
              )}
              sx={{ mx: 1 }}
              open={autocompleteOpen}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, value) => {
                formik.setFieldValue('member', value.id);
                if (value.type === 'Practitioner') {
                  formik.setFieldValue('type', 'Practitioner');
                } else {
                  formik.setFieldValue('type', 'CareTeam');
                }
              }}
              onOpen={() => {
                setAutocompleteOpen(true);
              }}
              onClose={() => {
                setAutocompleteOpen(false);
              }}
              options={
                [...data.map((item) => ({ id: item.uuid, label: `${item.firstName} ${item.lastName}`, type: 'Practitioner' })),
                  ...((hasPatient && careTeamSelector.data) ? (careTeamSelector.data.entry
                    .filter((item) => item.resource.id !== id)
                    .map((item) => ({ id: item.resource.id, label: item.resource.name, type: 'CareTeam' }))) : [])]
                  .filter((item) => !participants.some((p) => p.id === item.id))
              }
              renderOption={(p, option) => (
                <MenuItem {...p} key={option.id}>
                  {option.label}
                </MenuItem>
              )}
              loading={isFetching || careTeamSelector.isLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Search')}
                  onChange={(e) => setSearchInput(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <FormSelect
              formik={formik}
              required
              name="type"
              label={t('Type')}
              disabled={formik.values.type === 'CareTeam' || !hasPatient}
            >
              {formik.values.type === 'CareTeam' && <MenuItem value="CareTeam">{t('Care Team')}</MenuItem>}
              <MenuItem value="Practitioner">{t('Practitioner')}</MenuItem>
              {hasPatient && <MenuItem value="RelatedPerson">{t('Related Person')}</MenuItem>}
            </FormSelect>
          </Row>
          <Row>
            <FormSelect
              formik={formik}
              label={t('Role')}
              required
              name="role"
              disabled={formik.values.type === 'RelatedPerson'}
            >
              {rolesSelector.data.map((role: any) => (
                <MenuItem key={role.uuid} value={role.role_type}>
                  {role.role_name}
                </MenuItem>
              ))}
            </FormSelect>
          </Row>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ParticipantTable;
