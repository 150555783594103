import {
  Button,
  Checkbox,
  Radio,
  TextField,
} from '@mui/material';
import React from 'react';

  type AnswerSelectProps = {
    selected: boolean;
    setSelected: (value: string) => void;
    onChange: (value: string) => void;
    isCurrent: boolean;
    multiSelect?: boolean;
  };

const OtherAnswerSelect = (props: AnswerSelectProps) => {
  const {
    selected, setSelected, onChange, isCurrent, multiSelect = false,
  } = props;
  return (
    <Button
      role="button"
      data-cy={isCurrent ? 'answer-other' : ''}
      fullWidth
      onClick={() => setSelected('OTHER')}
      variant={selected ? 'contained' : 'outlined'}
      color={selected ? 'primary' : 'secondary'}
      sx={{
        minHeight: '70px',
        textTransform: 'none',
        justifyContent: 'flex-start',
        p: 2,
        mb: 1,
      }}
    >
      {multiSelect ? (
        <Checkbox checked={selected} color="secondary" />
      ) : (
        <Radio checked={selected} color="secondary" />
      )}
      <TextField
        fullWidth
        sx={{ ml: 2 }}
        placeholder="Other"
        onChange={(e) => onChange(e.target.value)}
      />
    </Button>
  );
};

export default OtherAnswerSelect;
