import moment from 'moment';
import 'moment/locale/fr';

/*
  Goal: Check empty-ness of JS Object and Array
  Input:
    - obj: object (json obj, array, or other)
    - pure: boolean, indicating wherther we allow empty arrays
  Output: boolean

  If pure = false (default), only allow: null, undefined, or {}
  If pure = true, also allow empty arrays: []
*/
export const emptyObject = (obj, pure = false) => {
  if (obj === null
    || obj === undefined
    || (obj.constructor === Object && Object.keys(obj).length === 0)) {
    return true;
  } if (!pure && obj.constructor === Array && obj.length === 0) {
    return true;
  }
  return false;
};

/*
  Goal: Check whether obj is array
  Input: object (json obj, array, or other)
  Output: boolean
*/
export const isArray = (obj) => obj !== undefined && obj != null && obj.constructor === Array;

/*
  Goal: Check whether obj is javascript obj
  Input: object (json obj, array, or other)
  Output: boolean
*/
export const isObject = (obj) => obj != null && obj !== undefined && obj.constructor === Object;

/*
  Goal: Return obj prop, or default (if prop is undefined, or obj is not an object)
  Input:
    obj - js obj, or other
    key - property we want to retrieve from obj
    default_result - result to return if obj is not an object or key does not exist on object
  Output: object property or default_result
*/
export const getObjectPropOrDefault = (obj, key, default_result = undefined) => {
  if (!isObject(obj) || obj[key] === undefined) {
    return default_result;
  }
  return obj[key];
};

/*
  Goal: Check whether obj is a non-empty array
  Input: object (json obj, array, or other)
  Output: boolean
*/
export const nonEmptyArray = (obj) => (
  isArray(obj) && obj.length > 0
);

/*
  Goal: Check whether obj is an empty array
  Input: object (json obj, array, or other)
  Output: boolean
*/
export const emptyArray = (obj) => (
  isArray(obj) && obj.length === 0
);

export const isValidEmail = (email) => (
  typeof email === 'string'
        && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
);

/*
  Goal: check whether i18n is non-empty object
  Input: i18n object
  Output: boolean
*/
export const translate_ready = (i18n) => !emptyObject(i18n);

/*
  Goal: append language abreviation to key string (i.e. "name" becomes "name_en")
  Input:
    - object: an object with language abbreviations as keys, and strings as values
              e.g. {en: "Name", fr: "Nom"}
    - lang: language str
              e.g. 'en', 'fr', etc.
  Output: boolean
*/
export const lang_base_obj = (object, lang) => {
  if (lang.includes('fr')) {
    return object.fr;
  }
  return object.en;
};

/*
  GOAL:
    Filter reducer data, to get just a patient's specific data, for reducers which
    have format described in 'data_mapping' below
  INPUT:
    data_mapping: object with patient data, organized w/ patient uuid as values
      e.g. {
        <patient1 uuid>: <patient1's data....>,
        <patient2 uuid>: <patient2's data....>,
        ...
      }
    uuid: uuid of patient we want to filter by
    default_result: what the function should return if patient's uuid does not exist in data_mapping
  OUTPUT:
    patient's data
    e.g. For the 'data_mapping' example above, if we passed in uuid=<patient1 uuid>,
         the output would be <patient1's data....>
*/
export const filterPatientSpecificData = (data_mapping, uuid, default_result = []) => {
  if (isObject(data_mapping) && uuid in data_mapping) {
    return data_mapping[uuid];
  }
  return default_result;
};

// Function that return the difference between 2 objects (deep check )
export const diff = function (obj1, obj2) {
  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1;
  }
  const diffs = {};
  let key;
  /**
     * Check if two arrays are equal
     * @param  {Array}   arr1 The first array
     * @param  {Array}   arr2 The second array
     * @return {Boolean}      If true, both arrays are equal
     */
  const arraysMatch = function (arr1, arr2) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i += 1) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };
    /**
     * Compare two items and push non-matches to object
     * @param  {*}      item1 The first item
     * @param  {*}      item2 The second item
     * @param  {String} key   The key in our object
     */
  const compare = function (item1, item2, key) {
    // Get the object type
    const type1 = Object.prototype.toString.call(item1);
    const type2 = Object.prototype.toString.call(item2);

    // If type2 is undefined it has been removed
    if (type2 === '[object Undefined]') {
      diffs[key] = null;
      return;
    }
    // If items are different types
    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }
    // If an object, compare recursively
    if (type1 === '[object Object]') {
      const objDiff = diff(item1, item2);
      if (Object.keys(objDiff).length >= 1) {
        diffs[key] = objDiff;
      }
      return;
    }
    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }
    // Else if i's a function, convert to a string and compare
    // Otherwise, just compare
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2;
      }
    } else if (item1 !== item2) {
      diffs[key] = item2;
    }
  };
    //  Compare our objects Loop through the first object
  Object.keys(obj1).forEach((k) => {
    if (k in obj2) {
      compare(obj1[k], obj2[k], k);
    }
  });
  // Loop through the second object and find missing items
  Object.keys(obj2).forEach((k) => {
    if (k in obj1) {
      if (!obj1[k] && obj1[k] !== obj2[k]) {
        diffs[k] = obj2[k];
      }
    }
  });
  return diffs;
};

/* /
 TABLE SORTING FOR M-UI
/ */
// takes a date obj and return a formated string // formats date to simple "YYYY-MM-DD"

export const formatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

/*
  Goal: this is used on tables,
  its sorting according to row vals and orderBy is the header value to sort by
  Input:
    - a: value one to compare - row value
    - b: value two to compare - row value
    - orderBy:  string name of header cell ex: "date"
  Output: return -1 or 0 like all sorting FNs
*/

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/*
  Goal: toggles against order value the sorting
  Input:
    - order: order 'desc' 'asc'
    - orderBy: string name of header cell ex: "date"
  Output: return -1 or 0 like all sorting FNs
*/
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/*
  Goal: combines both sorting FN returns a sorted array
    - array: initial data Arr
    - comparator: cb function to chain the 2 above methods
  Output: returns a sorted array
*/
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export { descendingComparator, getComparator, stableSort };
/* /
 TABLE SORTING FOR M-UI
/ */

// trimPhoneNumber('+(33) 12  34--56--78-90')  //returns 123456789
export const trimPhoneNumber = (nbStr, len = 10) => {
  let val = nbStr.replace(/[-+()\s]/g, '');
  return val = val.substr(val.length - len);
};

// Format phone number from API to prefill in form
// or for displaying throughout the app
// TODO -> ASK ANNA NOT TO HARDCODE STRINGS, the point is to reuse these FN
export const parsePhoneNumberOnPrefill = (phoneNumber, country) => {
  let phone;
  if (phoneNumber) {
    if (country === 'FR' && phoneNumber.startsWith('+33')) {
      // In forms, we don't display the +33
      phone = `0${phoneNumber.slice(3)}`;
    } else if (country === 'CA' && phoneNumber.startsWith('+1')) {
      // In forms, we don't display the +1
      phone = phoneNumber.slice(2);
    }
  }
  return phone;
};

// Format phone number from form to api format
export const formatPhoneNumberOnSubmit = (phoneNumber, country) => {
  if (phoneNumber && phoneNumber.trim().length > 0) {
    if (country === 'FR' && phoneNumber.startsWith('0')) {
      // Remove leading '0' and prepend '+33'
      return `+33${phoneNumber.slice(1)}`;
    } if (country === 'CA') {
      // Prepend '+1'
      return `+1${phoneNumber}`;
    }
  }
  return phoneNumber;
};

// Parse greybox support email from company support_config, if exists.
// If no support email exists in config, return default email 'support@greybox.biz'
export const greyboxSupportEmail = (company) => {
  let companyToCheck = company;
  if (Array.isArray(company) && company.length > 0) {
    [companyToCheck] = company;
  }

  if (
    !emptyObject(companyToCheck)
        && !emptyObject(companyToCheck.support_config)
        && !emptyObject(companyToCheck.support_config.GB_support)
  ) {
    return companyToCheck.support_config.GB_support.email;
  }

  return 'support@greybox.biz';
};

export const resolve = (path, obj, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

/*
  GOAL:
    Determine medication status based on end_date and start_date
  INPUT:
    start_date: medication start date (string of format 'YYYY-MM-DD')
    end_date: medication end date (null or string of format 'YYYY-MM-DD')
  OUTPUT:
    medication status (string)
*/
export const medication_status = (start_date, end_date, dateFormat = 'YYYY-MM-DD') => {
  if (
    end_date !== null && end_date !== undefined
        && moment(end_date, dateFormat).isBefore(moment().startOf('day'))
  ) {
    return 'completed';
  } if (moment(start_date, dateFormat).isAfter(moment().endOf('day'))) {
    return 'intended';
  }
  return 'active';
};

// date functions/////
/**
 *
 * @param {str} dateStr // the value in the input
 * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
 */

export const isAdult = (dateStr, expectedDateShape = 'YYYY-MM-DD') => {
  const dateValidation = moment(dateStr, expectedDateShape, true).isValid();
  const dateLength = dateStr.length >= expectedDateShape.length;
  const isMajor = moment().diff(moment(dateStr, expectedDateShape), 'years') >= 18;

  return dateValidation && dateLength && isMajor;
};

/**
   *
   * @param {str} dateStr // the value in the input
   * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
   */
export const isDateBefore = (dateStr, expectedDateShape = 'YYYY-MM-DD') => {
  const dateValidation = moment(dateStr, expectedDateShape, true).isValid();
  const beforeValidation = moment(dateStr, expectedDateShape, true).isBefore(moment());

  return dateValidation && beforeValidation;
};

/**
   *
   * @param {str} dateStr // the value in the input
   * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
   */
export const isValidDate = (dateStr, expectedDateShape = 'YYYY-MM-DD') => moment(dateStr, expectedDateShape, true).isValid();

/**
   *
   * @param {str} dateStr // the value in the input
   * @param {str} baseStr // the value in the other input
   * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
   */
export const isDateBeforeOrEqual = (dateStr, baseStr, expectedDateShape = 'YYYY-MM-DD') => {
  const dateValidation = moment(dateStr, expectedDateShape, true).isValid();
  // let beforeValidation = moment(baseStr).isAfter(moment(dateStr));
  // if we NEED the bad idea of next day uncomment line before and cancel next.
  const beforeValidation = moment(baseStr).isSameOrAfter(moment(dateStr));
  const isOk = baseStr === null ? true : !!beforeValidation;
  return dateValidation && isOk;
};

/**
   *
   * @param {str} dateStr // the value in the input
   * @param {str} baseStr // the value in the other input
   * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
   */
export const isDateBeforeOther = (dateStr, baseStr, expectedDateShape = 'YYYY-MM-DD') => {
  const dateValidation = moment(dateStr, expectedDateShape, true).isValid();
  const beforeValidation = moment(baseStr).isAfter(moment(dateStr));

  const isOk = baseStr === null ? true : !!beforeValidation;
  return dateValidation && isOk;
};

// retreive next Day
export const isTomorrow = (date, dateFormat = 'YYYY-MM-DD') => moment(date).add(1, 'days').format(dateFormat);

// isSame Date as todays
export const isToday = (date, dateFormat = 'YYYY-MM-DD') => moment(date).format(dateFormat) === moment(Date.now()).format(dateFormat);

// isInFuture from today
export const isInFuture = (date) => moment(date).isAfter(moment(Date.now()));

/**
    *
    * @param {*} arr1
    * @param {*} arr2
    */

export const arraysMatch = function (_arr1, _arr2) {
  if (!Array.isArray(_arr1)
  || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) { return false; }

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) { return false; }
  }
  return true;
};

/**
 *
 * @param {Object} questionnaire (provided by questionnaire API call)
 * @returns {Object} Same data but organized to be easier to use
 */
export const parseQuestionnaire = (questionnaire) => (
  questionnaire.questions.map((q) => {
    const q_type = (`${q.type_of_q}_question`).toLowerCase();
    const qa = { ...q[q_type] };
    const MCSelection = [];

    if (q.type_of_q === 'MC' || q.type_of_q === 'TF') {
      if (qa) {
        delete qa[`${q_type}_text`];
        delete qa.id;
        delete qa.multi_select;
        delete qa[`${q_type}_choice_other`];

        Object.keys(qa).forEach((k) => {
          if (k.slice(-2) !== 'fr'
            && k.slice(-2) !== 'en'
            && qa[k] !== null) {
            MCSelection.push(k.slice(-1).toUpperCase());
          }
        });
      }
    }

    return {
      choices: q[q_type],
      type: q.type_of_q,
      id: q.id,
      q_type: q_type,
      condition: q.condition,
      MCSelection: MCSelection,
    };
  })
);
