import * as React from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WEEK_DAYS } from './utils';

const WeekDaysSelector = ({ formik }) => {
  const { weekDaysRepetition } = formik.values;
  const { t } = useTranslation();

  const handleDayClicked = (index: number) => {
    let newDaysList: Array<number> = weekDaysRepetition;
    if (newDaysList.includes(index)) {
      newDaysList = newDaysList.filter((i) => i !== index);
    } else {
      newDaysList.push(index);
    }

    formik.setFieldValue('weekDaysRepetition', newDaysList);
  };

  return (
    <Box sx={{ display: 'flex', m: 2, justifyContent: 'space-around' }}>
      {WEEK_DAYS.map((day, index) => (
        <Button
          key={`${index}-btn`}
          sx={{ textTransform: 'none' }}
          variant={weekDaysRepetition.includes(index) ? 'contained' : 'outlined'}
          onClick={() => handleDayClicked(index)}
        >
          {t(day)}
        </Button>
      ))}
    </Box>
  );
};

export default WeekDaysSelector;
