import {
  Button, Dialog, DialogActions, DialogContent,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import CheckboxSelector from '../CheckboxSelector';
import DialogTitle from '../DialogTitle';

const StaffSelectionDialog = (props) => {
  const { t } = useTranslation();
  const {
    open, selected, setSelected, onClose,
  } = props;
  const { account } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { data, isFetching } = account.list({ acc_type__in: 'PT', clinic: clinic.id });

  if (isFetching) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>{t('Select HCP')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexWrap: 'wrap' }} dividers>
        {data && data.map((staff, index) => (
          <CheckboxSelector
            key={staff.uuid}
            index={index}
            id={staff.uuid}
            type="staffs"
            selected={Boolean(selected.find((item) => item.id === staff.uuid))}
            onChange={setSelected}
          >
            {{ id: staff.uuid, label: `${staff.firstName} ${staff.lastName}` }}
          </CheckboxSelector>
        ))}
      </DialogContent>
      <DialogActions>
        <Button data-cy="close" onClick={onClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StaffSelectionDialog;
