import {
  Box, Fade, LinearProgress, Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../../redux/api';
import QuestionnaireFiller from './QuestionnaireFiller';
import QuestionnaireIntro from './QuestionnaireIntro';

const parsePreviousAnswers = (previousAnswers) => {
  if (!previousAnswers) {
    return {};
  }

  const parsedAnswers = {};
  previousAnswers.forEach((ans) => {
    parsedAnswers[ans.base_question] = ans.answer;
  });
  return parsedAnswers;
};

const Container = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}
  >
    {children}
  </Box>
);

const QuestionnaireContainer = ({
  questionnaireId, setHasUnsaved, onComplete, clinic, previousAnswers = null,
}) => {
  const { questionnaire } = greyboxApiActions;
  const { t } = useTranslation();
  const [isStarted, setIsStarted] = useState(false);
  const prefilledAnswers = parsePreviousAnswers(previousAnswers);
  const { data, isLoading } = questionnaire.get(questionnaireId);

  if (isLoading) {
    return (
      <Container>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h4" align="center" gutterBottom>
            {t('Powered by Greybox')}
          </Typography>
          <Box width="30%">
            <LinearProgress height={10} color="primary" />
          </Box>
        </Box>
      </Container>
    );
  }

  if (isStarted) {
    return (
      <Container>
        <Fade in timeout={1000}>
          <Box width="100%" height="100%">
            <QuestionnaireFiller
              questions={data.questions}
              setHasUnsaved={setHasUnsaved}
              onComplete={onComplete}
              prefilledAnswers={prefilledAnswers}
            />
          </Box>
        </Fade>
      </Container>
    );
  }

  return (
    <Container>
      <QuestionnaireIntro
        questionnaire={data}
        setIsStarted={setIsStarted}
      />
    </Container>
  );
};

export default QuestionnaireContainer;
