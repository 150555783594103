import moment from 'moment';
import * as yup from 'yup';
import i18n from '../../i18n';

export const BLOOD_GLUCOSE_PERIODS = [
  {
    id: 'BM',
    label: 'Before Meal',
  },
  {
    id: 'AM',
    label: 'After Meal',
  },
  {
    id: 'BE',
    label: 'Before Exercise',
  },
  {
    id: 'BT',
    label: 'Bedtime',
  },
  {
    id: 'FT',
    label: 'Fasting',
  },
  {
    id: 'NA',
    label: 'Other',
  },
  {
    id: 'NE',
    label: 'No Entry',
  },
];

export const WEIGHT_UNITS = [{
  id: 'KG',
  label: 'Kilograms',
}, {
  id: 'LBS',
  label: 'Pounds',
}];

export const TEMPERATURE_UNITS = [{
  id: '°C',
  label: 'Celsius',
}, {
  id: '°F',
  label: 'Fahrenheit',
}];

/**
 * Contais all information about a vital.
 *
 * This include:
 * - Base information about the vital
 * - If the vital is managed by the observation api
 * - Apex chart configuration
 * - Field validations to add said vital in a form (including custom field)
 * - Custom columns configuration for the vital in a table
 *
 * IMPORTANT: If the vital IS NOT an observation, a redux slice should also
 *            be added to manage api.
 */
export const VITAL_CONFIG = {
  BG: {
    isSpecific: true,
    fields: [
      {
        id: 'glucoseLevel',
        label: 'Blood Glucose',
        validation: yup.number().min(0).max(1000),
      },
      {
        id: 'glucosePeriod',
        label: 'Period',
        defaultValue: 'BM',
        units: BLOOD_GLUCOSE_PERIODS,
        validation: yup.string().oneOf(BLOOD_GLUCOSE_PERIODS.map(({ id }) => id)),
      },
    ],
    thresholdCode: 'V_BG',
    apiCall: 'bloodGlucose',
    chartConfig: {
      label: 'Blood Glucose',
      title: 'Blood Glucose',
      values: ['glucoseLevel'],
      chartType: 'line',
      unit: 'mg/dL',
      colors: ['#b72b59'],
      fixedUnit: true,
    },
    tableConfig: [
      {
        field: 'glucoseLevel',
        headerName: 'Blood Glucose',
        format: (value, row) => (value ? `${value} ${row.unit}` : '---'),
      },
      {
        field: 'measurementPeriod',
        headerName: 'Period',
        format: (value) => BLOOD_GLUCOSE_PERIODS.find((b) => b.id === value).label || '---',
      },
    ],
  },
  BG3: {
    chartConfig: {
      label: 'Blood Glucose',
      title: 'Blood Glucose',
      values: ['glucoseLevel'],
      chartType: 'line',
      unit: 'mg/dL',
      colors: ['#b72b59'],
      fixedUnit: true,
    },
    tableConfig: [
      {
        field: 'measurementPeriod',
        headerName: 'Period',
        format: (value, row) => row.component
          && (BLOOD_GLUCOSE_PERIODS.find((p) => p.id === row.component[0].valueString).label || '---'),
      },
    ],
  },
  BP2: {
    isSpecific: true,
    thresholdCode: 'V_BP',
    apiCall: 'bloodPressure',
    chartConfig: {
      label: 'Blood Pressure',
      title: 'Blood Pressure',
      chartType: 'line',
      markerSize: [3, 3, 0, 0, 0, 0],
      strokeSize: [3, 3, 2, 2, 2, 2],
      dashArray: [0, 0, 5, 5, 5, 5],
      curve: ['straight', 'straight', 'stepline', 'stepline', 'stepline', 'stepline'],
      unit: 'mmHg',
      tooltipEnabled: [0, 1],
      values: ['tensionLow', 'tensionHigh'],
      colors: ['#ff8225', '#804213'],
    },
  },
  BP: {
    isSpecific: true,
    thresholdCode: 'V_BP',
    fields: [
      {
        id: 'tensionHigh',
        label: 'Systolic',
        validation: yup.number().min(0).max(200),
      },
      {
        id: 'tensionLow',
        label: 'Diastolic',
        validation: yup.number().min(0).max(200),
      },
    ],
    apiCall: 'bloodPressure',
    chartConfig: {
      label: 'Blood Pressure',
      title: 'Blood Pressure',
      chartType: 'line',
      markerSize: [3, 3, 0, 0, 0, 0],
      strokeSize: [3, 3, 2, 2, 2, 2],
      dashArray: [0, 0, 5, 5, 5, 5],
      curve: ['straight', 'straight', 'stepline', 'stepline', 'stepline', 'stepline'],
      unit: 'mmHg',
      tooltipEnabled: [0, 1],
      values: ['tensionLow', 'tensionHigh'],
      colors: ['#ff8225', '#804213'],
    },
    tableConfig: [
      {
        field: 'tensionHigh',
        headerName: 'Systolic',
        format: (value, row) => (value ? `${value} ${row.unit}` : '---'),
        flex: 1,
      },
      {
        field: 'tensionLow',
        headerName: 'Diastolic',
        format: (value, row) => (value ? `${value} ${row.unit}` : '---'),
        flex: 1,
      },
    ],
  },
  heartRate: {
    isSpecific: true,
    thresholdCode: 'V_HR',
    apiCall: 'heartRate',
    chartConfig: {
      label: 'Resting Heart Rate',
      title: 'Resting Heart Rate',
      chartType: 'line',
      unit: 'bpm',
      values: ['heartRate'],
      colors: ['#6cf'],
    },
    tableConfig: [
      {
        field: 'heartRate',
        headerName: 'Resting Heart Rate',
        format: (value, row) => (value ? `${value} ${row.unit}` : '---'),
        flex: 1,
      },
    ],
  },
  hr: {
    thresholdCode: 'M_HR',
    apiCall: 'hr',
    chartConfig: {
      label: 'Heart Rate',
      title: 'Heart Rate',
      chartType: 'line',
      unit: 'bpm',
      values: ['observation'],
      colors: ['#6cf'],
      summaryColors: {
        upper: '#6cf',
        lower: '#3d7a99',
      },
    },
    tableConfig: [],
  },
  HR3: {
    chartConfig: {
      label: 'Heart Rate',
      title: 'Heart Rate',
      chartType: 'line',
      unit: 'bpm',
      values: ['observation'],
      colors: ['#6cf'],
      summaryColors: {
        upper: '#6cf',
        lower: '#3d7a99',
      },
    },
    tableConfig: [],
  },
  rr: {
    thresholdCode: 'M_RR',
    apiCall: 'rr',
    chartConfig: {
      label: 'Respiratory Rate',
      title: 'Respiratory Rate',
      chartType: 'line',
      unit: 'rpm',
      values: ['observation'],
      colors: ['#6cf'],
      summaryColors: {
        upper: '#6cf',
        lower: '#3d7a99',
      },
    },
    tableConfig: [],
  },
  ppi: {
    thresholdCode: 'M_PPI',
    apiCall: 'ppi',
    chartConfig: {
      label: 'Peripheral Perfusion Index',
      chartType: 'line',
      unit: '%',
      values: ['observation'],
      colors: ['#6cf'],
      summaryColors: {
        upper: '#6cf',
        lower: '#3d7a99',
      },
    },
    tableConfig: [],
  },
  temperature: {
    fields: [{
      id: 'temperature',
      label: 'Temperature',
      validation: yup.number().min(0).max(100),
    },
    {
      id: 'temperatureUnit',
      label: 'Unit',
      units: TEMPERATURE_UNITS,
      defaultValue: '°C',
      validation: yup.string().oneOf(TEMPERATURE_UNITS.map(({ id }) => id)),
    }],
    tableConfig: [],
    thresholdCode: 'M_temperature',
    apiCall: 'temperature',
    chartConfig: {
      label: 'Temperature',
      values: ['observation'],
      title: 'Temperature',
      unit: '°C',
      chartType: 'line',
      colors: ['#ff5858'],
      summaryColors: {
        upper: '#ff5858',
        lower: '#B23D3D',
      },
    },
  },
  spo2: {
    thresholdCode: 'M_spo2',
    apiCall: 'spo2',
    chartConfig: {
      label: 'Oxygen Saturation',
      title: 'Oxygen Saturation',
      chartType: 'line',
      unit: '%',
      values: ['observation'],
      colors: ['#cacb3c'],
      summaryColors: {
        upper: '#cacb3c',
        lower: '#a9aa2f',
      },
    },
    tableConfig: [],
  },
  CD: {
    isSpecific: true,
    thresholdCode: 'P_CD',
    apiCall: 'steps',
    chartConfig: {
      label: 'Steps',
      chartType: 'bar',
      title: 'Steps',
      unit: 'steps',
      values: ['steps'],
      colors: ['#9816a0'],
    },
    tableConfig: [
      {
        field: 'endTime',
        headerName: 'End date',
        format: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm') : '---'),
        flex: 1,
      },
      {
        field: 'steps',
        headerName: 'Steps',
        format: (value) => (value || '---'),
        flex: 1,
      }
    ],
  },
  WT: {
    isSpecific: true,
    thresholdCode: 'V_WT',
    fields: [
      {
        id: 'weight',
        label: 'Weight',
        validation: yup.number().min(0).max(1000),
      },
      {
        id: 'weightUnit',
        label: 'Unit',
        units: WEIGHT_UNITS,
        defaultValue: 'KG',
        validation: yup.string().oneOf(WEIGHT_UNITS.map((unit) => unit.id)),
      },
    ],
    apiCall: 'weight',
    chartConfig: {
      label: 'Weight',
      title: 'Weight',
      values: ['weight'],
      chartType: 'line',
      unit: 'kg',
      colors: ['#12d0d0'],
      fixedUnit: true,
    },
    tableConfig: [
      {
        field: 'weight',
        headerName: 'weight',
        format: (value, row) => (value ? `${value} ${row.unit}` : '---'),
        flex: 1,
      },
      {
        field: 'bodyFatR',
        headerName: 'Body fat ratio',
        flex: 1,
      },
      {
        field: 'flutuation',
        headerName: 'Fluctuation',
        format: (value) => (value ? 'Yes' : 'No'),
        flex: 1,
      },
      {
        field: 'preWeight',
        headerName: 'Previous weight',
        format: (value) => (value ? value.weight : '---'),
        flex: 1,
      },
    ],
  },
  WT3: {
    chartConfig: {
      label: 'Weight',
      title: 'Weight',
      values: ['weight'],
      chartType: 'line',
      unit: 'kg',
      colors: ['#12d0d0'],
      fixedUnit: true,
    },
    tableConfig: [
      {
        field: 'fluctuation',
        headerName: 'Fluctuation',
        format: (value, row) => {
          if (row.component && row.component[0].valueBoolean) {
            return i18n.t('Yes');
          }
          return i18n.t('No');
        },
      },
    ],
  },
};
