import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as Yup from 'yup';
import moment from 'moment';
import FormTextField from './FormTextField';
import i18n from '../../i18n';

export const hinNumberSchema = Yup
  .string()
  .nullable()
  .matches(/^[A-Za-z]{4}(\s?\d{4}){2}$/,
    `${i18n.t('Please make sure to follow the format of 4 letters followed by 8 numbers')}. ${i18n.t('For example')}, ABCD 1234 5678`);

export const parseHinForPost = (number, expiration) => {
  if (number && expiration) {
    return {
      hin_number: number,
      hin_exp: `${moment(expiration).format('YYYY-MM')}-01`,
    };
  }

  return null;
};

export const formatHinWithSpaces = (hin) => {
  if (hin) {
    return hin.replace(/\s+/g, '').replace(/(\w{4})/g, '$1 ').trim();
  }
  return hin;
};

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

export const Hin = (props: PropsType) => {
  const { t } = useTranslation();
  const { sx, formik } = props;

  const handleHinNumberChange = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\s+/g, '').replace(/(\w{4})/g, '$1 ').trim();
    formik.setFieldValue('hin_number', formattedValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length === 7 && moment(inputValue, 'YYYY-MM', true).isValid()) {
      formik.setFieldValue('hin_exp', moment(inputValue, 'YYYY-MM'));
    } else {
      formik.setFieldValue('hin_exp', null);
    }
  };

  return (
    <Box sx={{ ...sx, display: 'inline-flex' }}>
      <FormTextField
        {...props}
        name="hin_number"
        sx={{ width: '50%', mr: 1 }}
        label={t('Health Insurance Number')}
        inputProps={{ maxLength: 14 }}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          onChange={(date) => formik.setFieldValue('hin_exp', date)}
          openTo="year"
          minDate={moment()}
          views={['year', 'month']}
          sx={{ width: '50%' }}
          disablePast
          label={t('Expiration Date')}
          value={formik.values.hin_exp}
          slotProps={{
            textField: {
              id: 'hin_exp',
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

Hin.defaultProps = {
  required: false,
  sx: { mx: 1 },
};
