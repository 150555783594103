import { createSlice } from '@reduxjs/toolkit';

/**
 * Used when user open a chat box to be able to keep this chat box open anywhere in the app.
 */
const dockedChatBox = createSlice({
  name: 'dockedChatBox',
  initialState: {
    docked: false,
    threadId: null,
    websocket: null,
  },
  reducers: {
    setDockedChatBox: (state, action) => {
      state.docked = true;
      state.threadId = action.payload.threadId;
      state.websocket = action.payload.websocket;
    },
    closeDockedChatBox: (state) => {
      state.docked = false;
      state.threadId = null;
      state.websocket = null;
    },
  },
});

export const { setDockedChatBox, closeDockedChatBox } = dockedChatBox.actions;
export default dockedChatBox.reducer;
