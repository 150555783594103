import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Autocomplete, TextField, Skeleton, Box,
} from '@mui/material';
import { DiagnosisType } from '../../types';
import { greyboxApiActions } from '../../redux/api';

type DiagnosisProps = {
  formik: any;
  disabled?: boolean;
  name: string;
  label: string;
  filter?: string;
  sx?: any;
}

const Diagnosis = ({
  formik, name, label, disabled, sx, filter,
}: DiagnosisProps) => {
  const { diagnosis } = greyboxApiActions;
  const [open, setOpen] = React.useState(false);
  const { clinic } = useSelector((state: any) => state.clinic);
  const { data = [], isLoading, isError } = diagnosis.list({ clinic: clinic.id, acc_type__in: filter || 'P,PT' }) as {
    data: DiagnosisType[],
    isLoading: boolean,
    isError: boolean,
  };

  const handleClose = (event: React.ChangeEvent<{}>, reason: string) => {
    if (reason === 'selectOption') {
      return;
    }
    setOpen(false);
  };

  const sortedData = useMemo(() => data.slice().sort((a, b) => {
    const categoryA = a.diagnosis_category.diagnosis_category_name.toLowerCase();
    const categoryB = b.diagnosis_category.diagnosis_category_name.toLowerCase();
    if (categoryA < categoryB) return -1;
    if (categoryA > categoryB) return 1;
    return 0;
  }), [data]);

  if (isError) {
    return null;
  }

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={40} />;
  }

  return (
    <Box sx={{ mx: 1 }}>
      <Autocomplete
        multiple
        sx={sx}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={handleClose}
        disabled={disabled}
        groupBy={(option) => option.diagnosis_category.diagnosis_category_name}
        options={sortedData}
        getOptionLabel={(d: DiagnosisType) => d.diagnosis_name}
        fullWidth
        value={data.filter((d) => formik.values[name].includes(d.uuid))}
        onChange={(e, values: DiagnosisType[]) => {
          formik.setFieldValue(name, values.map((v) => v.uuid));
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Box>
  );
};

export default Diagnosis;
