import React, { useEffect } from 'react';
import { MenuItem, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type DurationProps = {
  formik: any;
  name: string;
  label: string;
};

const Duration = (props: DurationProps) => {
  const { formik, name, label } = props;
  const { t } = useTranslation();
  const [index, setIndex] = React.useState(1);

  const DURATION_OPTIONS = [{
    label: t('None'),
    value: null,
  },
  {
    label: `15 ${t('minutes')}`,
    value: moment.duration(15, 'minutes'),
  },
  {
    label: `30 ${t('minutes')}`,
    value: moment.duration(30, 'minutes'),
  },
  {
    label: `45 ${t('minutes')}`,
    value: moment.duration(45, 'minutes'),
  },
  {
    label: `1 ${t('hour')}`,
    value: moment.duration(1, 'hours'),
  },
  {
    label: `4 ${t('hours')}`,
    value: moment.duration(4, 'hours'),
  },
  {
    label: `1 ${t('day')}`,
    value: moment.duration(1, 'days'),
  },
  {
    label: `1 ${t('week')}`,
    value: moment.duration(1, 'weeks'),
  },
  ];

  useEffect(() => {
    if (index === 0) {
      formik.setFieldValue(name, null);
    } else {
      formik.setFieldValue(name, DURATION_OPTIONS[index].value);
    }
  }, [index]);

  useEffect(() => {
    if (formik.values[name] === null || formik.values[name] === '') {
      setIndex(0);
    } else {
      const i = DURATION_OPTIONS.findIndex((option) => (
        moment.duration(option.value).asMilliseconds()
        === moment.duration(formik.values[name]).asMilliseconds()));
      setIndex(i);
    }
  }, [formik.initialValues[name]]);

  return (
    <Box sx={{ mx: 1 }}>
      <TextField
        select
        fullWidth
        sx={{ mb: 1, pt: 1 }}
        label={label}
        value={index}
        onChange={(e) => setIndex(parseInt(e.target.value, 10))}
      >
        {DURATION_OPTIONS.map((option, i) => (
          <MenuItem key={i} value={i}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default Duration;
