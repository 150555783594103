import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Skeleton } from '@mui/material';
import FormSelect from './FormSelect';
import { greyboxApiActions } from '../../redux/api';
import { ClinicType } from '../../redux/clinic';

interface PropsType {
  formik: any;
  name?: string;
  sx?: any;
  required?: boolean;
}

const Clinic = (props: PropsType) => {
  const { t } = useTranslation();
  const { company } = greyboxApiActions;
  const { data = [], isLoading } = company.list({});
  const { sx, name = 'clinic' } = props;

  if (isLoading) {
    <Skeleton sx={sx} />;
  }

  return (
    <FormSelect
      {...props}
      sx={sx}
      name={name}
      label={t('Clinic')}
    >
      {data.map((clinic: ClinicType) => (
        <MenuItem key={clinic.id} value={clinic.id}>
          {clinic.name}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

Clinic.defaultProps = {
  required: false,
  sx: null,
};

export default Clinic;
