import React, { useEffect, useState } from 'react';
import {
  Tab, Typography, Box, Tabs, Paper,
} from '@mui/material';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import ObservationChart from '../chart/ObservationChart';
import DateRangePicker from '../patient-profile/dateRangePicker/DateRangePicker';
import VitalTable from './VitalTable';

const VitalDetails = () => {
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const { vital } = useParams();
  const [measures, setMeasures] = useState([]);
  const [value, setValue] = React.useState(0);
  const hasTabs = measures.length > 1;

  const tableSx = hasTabs ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 } : {};

  useEffect(() => {
    const m = [];
    if (vitalsConfig[vital].config?.children) {
      vitalsConfig[vital].config.children.forEach((child) => {
        m.push(vitalsConfig[child]);
      });
    } else {
      m.push(vitalsConfig[vital]);
    }
    setMeasures(m);
  }, [vital]);

  if (measures.length === 0) {
    return null;
  }

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {vitalsConfig[vital].name}
        </Typography>
        <DateRangePicker />
      </Box>
      <ObservationChart
        measures={measures}
        config={vitalsConfig[vital]}
        detailed
      />
      <Paper sx={{ mt: 1, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
        {hasTabs && (
          <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
            {
              measures.map((measure) => (
                <Tab label={measure.name} key={measure.id} />
              ))
            }
          </Tabs>
        )}
      </Paper>
      {
        measures.map((measure, index) => (
          <div
            key={measure.short_code}
            role="tabpanel"
            hidden={measures.length > 1 && value !== index}
          >
            {value === index && (
              <VitalTable vital={measure.short_code} sx={tableSx} />
            )}
          </div>
        ))
      }
    </Box>
  );
};

export default VitalDetails;
