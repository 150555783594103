import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ImageIcon from '@mui/icons-material/Image';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import DescriptionIcon from '@mui/icons-material/Description';
import React from 'react';

type Props = {
  type: string;
}

const DocumentIconByType = (props: Props) => {
  const { type } = props;
  const [prefix, sufix] = type.split('/');

  switch (prefix) {
    case 'application':
      switch (sufix) {
        case 'pdf':
          return <PictureAsPdfIcon />;
        default:
          return <DescriptionIcon />;
      }
    case 'text':
      return <DescriptionIcon />;
    case 'video':
      return <VideoLibraryIcon />;
    case 'image':
      return <ImageIcon />;
    case 'audio':
      return <AudioFileIcon />;
    default:
      return <DescriptionIcon />;
  }
};

export default DocumentIconByType;
