import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@mui/material';

import { PopUp } from '../../../tk-ui';
import { popUp } from './currentMedications_styling';

// A TOOLTIP IS BUILD TO DISPLAY PARTICULAR POSOLOGY...
const PosologyModes = (props) => {
  const {
    posology: { dosage_mode },
    unitLang,
    posology,
  } = props;

  const { t } = useTranslation();

  const [anchorPop, setAnchorPop] = useState(null);

  const handlePopOpen = (event) => {
    setAnchorPop(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorPop(null);
  };

  const open = Boolean(anchorPop);

  return (
    <>
      {dosage_mode === 'frequency_posology' ? (
        <span>
          {posology.frequency_posology.dosage}
          {' '}
          {unitLang === '---' ? null : unitLang + ' '}
          {' '}
          {posology.frequency_posology.short_code}
        </span>
      ) : (
        <>
          <div style={popUp.pos}>
            <span>{t('Particular')}</span>
            <div
              style={popUp.iconBox}
              data-cy="target"
              aria-haspopup="true"
              aria-owns={open ? 'medication-popper' : undefined}
              onMouseEnter={handlePopOpen}
              onMouseLeave={handlePopClose}
              onTouchStart={handlePopOpen}
            >
              <div style={popUp.iconPos}>
                <PopUp fills="#09bbd0" />
              </div>
            </div>
            <Popover
              id="medication-popper"
              data-cy="tooltip"
              elevation={0}
              open={open}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorEl={anchorPop}
              style={popUp.limits}
              disableRestoreFocus
            >
              {/* tooltip */}
              <div style={popUp.perticularBox} data-cy="box">
                <div>
                  <div style={popUp.periodStyle}>
                    {t('Morning')}
                    :
                    {' '}
                    <span style={popUp.dosage}>
                      {posology.particular_posology.morning_dosage}
                      {' '}
                      {unitLang}
                    </span>
                  </div>
                  <div style={popUp.periodStyle}>
                    {t('Noon')}
                    :
                    {' '}
                    <span style={popUp.dosage}>
                      {posology.particular_posology.noon_dosage}
                      {' '}
                      {unitLang}
                    </span>
                  </div>
                  <div style={popUp.periodStyle}>
                    {t('Afternoon')}
                    :
                    {' '}
                    <span style={popUp.dosage}>
                      {posology.particular_posology.afternoon_dosage}
                      {' '}
                      {unitLang}
                    </span>
                  </div>
                  <div style={popUp.periodStyle}>
                    {t('Supper')}
                    :
                    {' '}
                    <span style={popUp.dosage}>
                      {posology.particular_posology.evening_dosage}
                      {' '}
                      {unitLang}
                    </span>
                  </div>
                  <div style={popUp.periodStyle}>
                    {t('Bedtime ')}
                    :
                    {' '}
                    <span style={popUp.dosage}>
                      {posology.particular_posology.night_dosage}
                      {' '}
                      {unitLang}
                    </span>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </>
      )}
    </>
  );
};

export default PosologyModes;
