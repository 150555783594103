import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Fade, Button } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import ScoreDialog from '../ScoreDialog';

const FeedbackMessage = (props) => {
  const {
    handleClose,
    data,
  } = props;
  const { t } = useTranslation();

  const { score, feedback } = data;
  const [open, setOpen] = React.useState(true);
  const { clinic } = useSelector((state) => state.clinic);

  const handleCloseDialog = () => {
    setOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  if ((clinic && clinic.name === 'APP4WE') || score.global_weight) {
    return (
      <ScoreDialog open={open} setOpen={handleCloseDialog} score={score} />
    );
  }

  return (
    <Fade in timeout={1000}>
      <Box
        display="flex"
        flexDirection="column"
        fontFamily={(theme) => theme.typography.fontFamily}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box width="90%">
          <ReactMarkdown skipHtml linkTarget="_blank">
            {feedback}
          </ReactMarkdown>
        </Box>
        <Button onClick={handleClose}>
          {t('Close')}
        </Button>
      </Box>

    </Fade>
  );
};

export default FeedbackMessage;
