import React from "react";
import { SvgIcon } from "@mui/material";

const WarningInfoIcon = (props) => (
  <SvgIcon {...props}>
 <g id="ic_error_outlined_24px" transform="translate(-2)">
        <g id="Ellipse_27" fill="#faf5e7" stroke="#ffc58b" strokeWidth="4px" data-name="Ellipse 27" transform="translate(2)">
            <circle cx="12" cy="12" r="12"  stroke="none" />
            <circle cx="12" cy="12" r="10"  fill="none"/>
        </g>
        <path id="Path_994" d="M0 0h24v24H0z" fill="none" data-name="Path 994" transform="translate(2)"/>
        <path id="Path_995" d="M10.1 13.7h1.8v1.8h-1.8zm0-7.2h1.8v5.4h-1.8zm.891-4.5A9 9 0 1 0 20 11a9 9 0 0 0-9.009-9zM11 18.2a7.2 7.2 0 1 1 7.2-7.2 7.2 7.2 0 0 1-7.2 7.2z"  fill="#ff8225" data-name="Path 995" transform="translate(3 1)"/>
    </g>
  </SvgIcon>
);

export { WarningInfoIcon }