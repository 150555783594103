import moment from 'moment';
import i18n from '../../i18n';

export const ALERT_TRIGGER = {
  V_BP: 'Blood Pressure',
  V_WT: 'Weight',
  V_BG: 'Blood Glucose',
  V_HR: 'Heart Rate',
  V_WT_F: 'Weight Fluctuation',
  DQ: 'Questionnaire',
  D: 'Diagnosis',
  M_LDL: 'LDL',
  M_potassium: 'Potassium',
  M_sodium: 'Sodium',
  M_ALT: 'ALT',
  M_temperature: 'Temperature',
  M_spo2: 'Oxygen Saturation',
  M_HbA1c: 'HbA1c',
  M_AST: 'AST',
  M_LVEF: 'LVEF',
  M_tobaccoQA: 'Tobacco smoking status',
  M_creatinine: 'Creatinine',
  M_INR: 'INR',
  M_CK: 'CK',
  M_BNP: 'BNP',
  M_microalbuminuria: 'Microalbuminuria',
  M_NYHA: 'NYHA',
  M_NTproBNP: 'NTproBNP',
  M_GFReMDRD: 'GFRe (MDRD)',
  M_referenceLVEF: 'Reference LVEF',
  M_referenceNYHA: 'Reference NYHA',
  M_heigth: 'Height',
  M_dryWeight: 'Dry Weight',
  M_GFReCockroft: 'GFRe (Cockroft-Gault)',
  RULE: 'Rule',
};

export const ALERT_TRIGGER_URL = {
  V_BP: 'vitals/bloodPressure',
  V_WT: 'vitals/weight',
  V_BG: 'vitals/bloodGlucose',
  V_HR: 'vitals/heartRate',
  M_LDL: 'lab-results/LDL',
  M_potassium: 'lab-results/potassium',
  M_sodium: 'lab-results/sodium',
  M_ALT: 'lab-results/ALT',
  M_temperature: 'vitals/temperature',
  M_spo2: 'vitals/spo2',
  M_HbA1c: 'lab-results/HbA1c',
  M_AST: 'lab-results/AST',
  M_LVEF: 'clinical-data/LVEF',
  M_tobaccoQA: 'clinical-data/tobaccoQA',
  M_creatinine: 'lab-results/creatinine',
  M_INR: 'lab-results/INR',
  M_CK: 'lab-results/CK',
  M_BNP: 'lab-results/BNP',
  M_microalbuminuria: 'lab-results/microalbuminuria',
  M_NYHA: 'clinical-data/NYHA',
  M_NTproBNP: 'lab-results/NTproBNP',
  M_GFReMDRD: 'lab-results/GFReMDRD',
  M_referenceLVEF: 'clinical-data/referenceLVEF',
  M_referenceNYHA: 'clinical-data/referenceNYHA',
  M_heigth: 'clinical-data/heigth',
  M_dryWeight: 'clinical-data/dryWeight',
  M_GFReCockroft: 'lab-results/GFReCockroft',
};

export const ALERT_CONFIGS = {
  alert: {
    label: 'Alerts',
    pieLabel: i18n.t('Active alerts'),
    types: {
      OT: {
        label: i18n.t('Outside of Thresholds data'),
        color: '#052F5F',
      },
      HR: {
        label: i18n.t('High Risk Level Based on Questionnaire Alert'),
        color: '#005377',
      },
      VF: {
        label: i18n.t('Vital Fluctuation'),
        color: '#06A77D',
      },
      MC: {
        label: i18n.t('Medication Contraindication'),
        color: '#D5C67A',
      },
      RL: {
        label: i18n.t('Rule Based Alert'),
        color: '#F1A208',
      },
    },
    columns: [
      {
        id: 'alert_trigger',
        label: i18n.t('Alert Trigger'),
        format: (value) => i18n.t(ALERT_TRIGGER[value]) || value,
      },
      {
        id: 'hcp',
        label: i18n.t('Cleared by'),
      },
      {
        id: 'medicalAct',
        label: i18n.t('Follow-up Action'),
      },
    ],
  },
  reminder: {
    label: i18n.t('Reminders'),
    pieLabel: i18n.t('Active Reminders'),
    types: {
      NS: {
        label: i18n.t('Should start next titration step'),
        color: '#f48c06',
      },
      MT: {
        label: i18n.t('Suggested medication not taken'),
        color: '#e85d04',
      },
      MI: {
        label: i18n.t('Missing information - cannot generate treatment plan'),
        color: '#ffba08',
      },
    },
  },
};

export const mergeActiveAndClearedAlerts = (actives, cleared) => (
  [...actives, ...cleared].sort((a, b) => (
    moment(a.last_modify).diff(moment(b.last_modify))
  ))
);

export const countActiveAlerts = (alerts, startDate, endDate) => {
  let activeAlerts = 0;
  let parsedAlerts = [];

  alerts.forEach((alert) => {
    if (alert.status === 'active') {
      activeAlerts += 1;
      if (moment(alert.last_modify).isAfter(moment(startDate))) {
        parsedAlerts.push({
          x: moment(alert.create_date),
          y: activeAlerts,
        });
      }
    } else {
      if (parsedAlerts.length === 0) {
        parsedAlerts.push({
          x: moment(startDate),
          y: activeAlerts + 1,
          status: 'cleared',
        });
      } else {
        parsedAlerts = parsedAlerts.map((item) => ({
          ...item,
          y: item.y + 1,
        }));
      }
      parsedAlerts.push({
        x: moment(alert.last_modify),
        y: activeAlerts,
        status: 'cleared',
      });
    }
  });

  if (parsedAlerts.length === 1) {
    parsedAlerts.push({
      x: moment(endDate),
      y: activeAlerts,
    });
  }

  return parsedAlerts;
};

export const parseAlertByType = (alerts, type) => {
  const alertsByType = {};
  alerts.forEach((alert) => {
    if (alert.alert_type in ALERT_CONFIGS[type].types) {
      if (!alertsByType[alert.alert_type]) {
        alertsByType[alert.alert_type] = [alert];
      } else {
        alertsByType[alert.alert_type].push(alert);
      }
    }
  });
  return alertsByType;
};
