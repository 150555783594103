import {
  Box, Paper, Table, TableContainer, TablePagination,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TablePaginationSkeleton from './skeletons/TablePaginationSkeleton';
import TableSkeleton from './skeletons/TableSkeleton';

const TableWrapper = (props) => {
  const { t } = useTranslation();
  const {
    total, page, setPage, rowsPerPage, setRowsPerPage, maxHeight,
    children, isFetching = false, colCount,
  } = props;

  if (isFetching) {
    return (
      <TableContainer component={Paper}>
        <TableSkeleton colCount={colCount} rowCount={rowsPerPage} />
        <Box display="flex" justifyContent="flex-end" alignItems="center" padding={1}>
          <TablePaginationSkeleton />
        </Box>
      </TableContainer>
    );
  }

  return (
    <Box width="100%">
      <Paper sx={{ width: '100%', mb: 3 }}>
        <TableContainer sx={{ maxHeight: maxHeight }}>
          <Table stickyHeader>
            {children}
          </Table>
        </TableContainer>
        <Box marginLeft="auto">
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={t('Rows per page')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          />
        </Box>
      </Paper>
    </Box>
  );
};
export default TableWrapper;
