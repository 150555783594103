import {
  Autocomplete, Button, Dialog, DialogActions, DialogContent,
  TextField, CircularProgress, MenuItem, Chip,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';

const NewMessageDialog = (props) => {
  const { open, handleClose, onChange } = props;
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const [search, setSearch] = React.useState('');
  const [autoOpen, setAutoOpen] = React.useState(false);
  const { messageThread } = greyboxApiActions;
  const { isFetching, data = {}, isError } = messageThread.list({
    clinic: clinic.id,
    page_size: 15,
    has_messages: false,
    search,
  }, { skip: search === '' });
  const { results = [] } = data;
  const [selected, setSelected] = React.useState(null);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle onClose={handleClose}>
        {t('New message')}
      </DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          open={autoOpen}
          onOpen={() => {
            setAutoOpen(true);
          }}
          onClose={() => {
            setAutoOpen(false);
          }}
          options={results}
          onChange={(_, value) => setSelected(value)}
          value={selected}
          getOptionLabel={(option) => option.title}
          openText={t('Open')}
          clearText={t('Clear')}
          noOptionsText=""
          loading={isFetching}
          renderOption={(props, option) => (
            <MenuItem {...props}>
              <span style={{ display: 'block' }}>
                {option.title}
              </span>
              <Chip
                label={option.patient ? t('Patient') : t('Care Team')}
                size="small"
                sx={{ ml: 'auto' }}
              />
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('Search')}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={() => { onChange(selected); }}
          disabled={selected === null}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewMessageDialog;
