import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormTextField from './FormTextField';
import i18n from '../../i18n';

export const zipCodeSchema = Yup.string()
  .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, i18n.t('Invalid postal code'));

export const formatZipCode = (value: string) => {
  if (value) {
    return value.replace(/[ -]/g, '');
  }

  return value;
};

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

export const ZipCode = (props: PropsType) => {
  const { t } = useTranslation();
  return (
    <FormTextField
      {...props}
      name="zipCode"
      label={t('Postal Code')}
    />
  );
};

ZipCode.defaultProps = {
  required: false,
  sx: null,
};
