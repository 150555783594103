import React from 'react';
import { Box, Skeleton } from '@mui/material';

const TablePaginationSkeleton = () => (
  <Box marginLeft="auto" display="flex" justifyContent="center">
    <Skeleton variant="text" width={100} />
    <span style={{ width: '8px' }} />
    <Skeleton variant="text" width={48} />
    <span style={{ width: '8px' }} />
    <Skeleton variant="text" width={48} />
    <span style={{ width: '8px' }} />
    <Skeleton variant="rect" width={25} height={25} />
    <span style={{ width: '16px' }} />
    <Skeleton variant="rect" width={25} height={25} />
  </Box>
);

export default TablePaginationSkeleton;
