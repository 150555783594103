import React from 'react';
import { SvgIcon } from '@mui/material';

const TemperatureIcon = ({ style }) => (
  <SvgIcon style={style} viewBox="0 0 37 37">
    <g id="prefix__Groupe_12085" data-name="Groupe 12085" transform="translate(-250.072 -495.639)">
      <path id="prefix__Path_7157-2" fill="#ff5858" d="M267.572 495.639a17.5 17.5 0 1 1-17.5 17.5 17.5 17.5 0 0 1 17.5-17.5z" data-name="Path 7157-2" />
      <g id="prefix__Groupe_12084" data-name="Groupe 12084" transform="translate(262.219 500.651)">
        <path id="prefix__Shape" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="4px" d="M266.98 514.429a1.358 1.358 0 0 1-.551-1.079v-11.5a2.736 2.736 0 0 0-2.1-2.71 2.681 2.681 0 0 0-3.252 2.616v11.6a1.347 1.347 0 0 1-.545 1.075 5.352 5.352 0 1 0 6.449 0z" transform="translate(-258.401 -499.076)" />
        <path id="prefix__Shape-2" fill="#ff5858" stroke="#ff5858" strokeMiterlimit="10" d="M264.085 512.383v-7.473a.925.925 0 0 0-.742-.927.891.891 0 0 0-1.041.879v7.519a.905.905 0 0 1-.557.821 3.568 3.568 0 1 0 2.9 0 .9.9 0 0 1-.56-.819z" data-name="Shape" transform="translate(-257.841 -496.833)" />
        <path id="prefix__Shape-3" fill="#fff" d="M260.988 513.681a.446.446 0 0 1-.446-.446 2.233 2.233 0 0 1 2.23-2.23.446.446 0 1 1 0 .891 1.338 1.338 0 0 0-1.337 1.339.446.446 0 0 1-.447.446z" data-name="Shape" transform="translate(-257.42 -493.608)" />
      </g>
    </g>
  </SvgIcon>
);

export { TemperatureIcon };
