import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
} from '@mui/material';
import React from 'react';

const DashboardSkeleton = (props) => {
  const {
    columns,
  } = props;

  return (
    <TableBody>
      {[...Array(25)].map((_, idx) => (
        <TableRow key={idx}>
          <TableCell align="left">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </TableCell>
          {columns.map((column) => (
            <TableCell
              key={column.id} >
              <Box width="100%" display="flex" justifyContent="center">
                <Skeleton
                  variant={column.skeleton.variant} 
                  height={column.skeleton.height}
                  width={column.skeleton.width || 25}
                />
              </Box>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default DashboardSkeleton;
