import { useTranslation } from 'react-i18next';

export const statusShapes = () => {
  const { i18n, t } = useTranslation();
  return {
    AHEAD: {
      status_code: 'AHEAD',
      order: 1,
      display_projected_reach: true,
      name: t('Ahead of plan'),
      color_code: { bright: '#50b83c', pale: '#b5e2a1' },
    },
    BEHIND: {
      status_code: 'BEHIND',
      order: 2,
      display_projected_reach: true,
      name: t('Behind plan'),
      color_code: { bright: '#f49342', pale: '#f8e0cd' },
    },
    ON_TIME: {
      status_code: 'ON_TIME',
      order: 3,
      display_projected_reach: true,
      name: t('On time'),
      color_code: { bright: '#50b83c', pale: '#b5e2a1' },
    },
    NO_TITRATION_PLAN: {
      status_code: 'NO_TITRATION_PLAN',
      order: 4,
      display_projected_reach: false,
      name: t('No available plan'),
      color_code: { bright: '#637381', pale: '#637381' },
    },
    STOPPED: {
      status_code: 'STOPPED',
      order: 4,
      display_projected_reach: false,
      name: t('No available plan'),
      color_code: { bright: '#637381', pale: '#637381' },
    },
    MAX_TOLERATED_DOSAGE: {
      status_code: 'MAX_TOLERATED_DOSAGE',
      order: 5,
      display_projected_reach: false,
      name: t('Max. tolerated dose'),
      color_code: { bright: '#50b83c', pale: '#b5e2a1' },
    },
    TARGET_REACHED: {
      status_code: 'TARGET_REACHED',
      order: 6,
      display_projected_reach: false,
      name: t('Plan completed'),
      color_code: { bright: '#50b83c', pale: '#b5e2a1' },
    },
    CANNOT_CALC_PROJ_REACH: {
      status_code: 'CANNOT_CALC_PROJ_REACH',
      order: 7,
      display_projected_reach: false,
      name: t('Status not available'),
      color_code: { bright: '#dea533', pale: '#e9cf98' },
    },
  };
};
