import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent } from '@mui/material';
import DeviceDetails from './DeviceDetails';
import DialogTitle from '../DialogTitle';

type PropsType = {
  open: boolean;
  onClose: () => void;
}

const AddDeviceDialog = ({ open, onClose }: PropsType) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle onClose={onClose}>{t('Add Device')}</DialogTitle>
      <DialogContent>
        <DeviceDetails onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AddDeviceDialog;
