import { createSlice } from '@reduxjs/toolkit';

const theme = createSlice({
  name: 'theme',
  initialState: {
    style: localStorage.getItem('theme') || 'light',
  },
  reducers: {
    toggleTheme: (state) => {
      state.style = state.style === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', state.style);
    },
  },
});

export const { toggleTheme } = theme.actions;
export default theme.reducer;
