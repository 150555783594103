const header_style = {
 titrationWrapper: {
	fontFamily: 'Roboto'
 },
 titleHeader: {
	fontFamily: 'Roboto',
	fontSize: '18px',
	fontWeight: 500,
	color: "#454545",
	 marginBottom: '3px'
 },
 badgeWrapper: {
	marginBottom: '5px'
 },
 titrationWarning: {
	fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: '400',
	background: "#f49342",
	borderRadius: "10px",
	padding: "1.75px 11px",
	color: "#ffffff"
},
titrationClean: {
	fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: '400',
	background: "#50b83c",
	borderRadius: "10px",
	padding: "1.75px 11px",
	color: "#ffffff"
},
titrationNone: {
	fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: '400',
	background: "#637381",
	borderRadius: "10px",
	padding: "1.75px 11px",
	color: "#ffffff"
},
titrationException: {
	fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: '400',
	background: "#DEA533",
	borderRadius: "10px",
	padding: "1.75px 11px",
	color: "#ffffff"
},

reach: {
	fontFamily: 'Roboto',
	fontSize: '14.5px',
	fontWeight: 300,
	color: "#707070",
	marginTop: '10px',
	marginBottom: '2px'
},
pace:{
	fontFamily: 'Roboto',
	fontSize: '14.5px',
	fontWeight: 300,
	color: "#707070",
	
},
reachBold: {
	fontFamily: 'Roboto',
	fontWeight: 500,
	color: "#454545",
}
}

export default header_style;