import * as React from 'react';
import clsx from 'clsx';
import ButtonBase from '@mui/material/ButtonBase';
import capitalize from '@mui/material/utils/capitalize';
import { withStyles } from '@mui/styles';
import { Sorted, Sorting } from '../img';

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexDirection: 'inherit',
    alignItems: 'center',
    width: '100%',
    '&:focus': {
      color: theme.palette.text.secondary,
    },
    '&:hover': {
      color: theme.palette.text.secondary,
      '& $icon': {
        opacity: 0.5,
      },
    },
    '&$active': {
      color: theme.palette.text.primary,
      // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
      '&& $icon': {
        opacity: 1,
        color: theme.palette.text.secondary,
      },
    },
  },
  /* Pseudo-class applied to the root element if `active={true}`. */
  active: {},
  /* Styles applied to the icon component. */
  icon: {
    fontSize: 18,
    height: 24,
    width: 24,
    opacity: 0,

    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    userSelect: 'none',
  },
  iconAlignLeft: {
    marginLeft: 'auto', height: 24, width: 18,
  },
  iconAlignRight: {
    marginRight: 'auto', height: 24, width: 18,
  },
  /* Styles applied to the icon component if `direction="desc"`. */
  iconDirectionDesc: {
    transform: 'rotate(0deg)',
  },
  /* Styles applied to the icon component if `direction="asc"`. */
  iconDirectionAsc: {
    transform: 'rotate(180deg)',
  },
});

/**
 * A button based label for placing inside `TableCell` for column sorting.
 */
const TableSortLabel = React.forwardRef((props, ref) => {
  const {
    active = false,
    children,
    classes,
    className,
    direction = 'asc',
    align = 'left',
    ...other
  } = props;

  return (
    <ButtonBase
      className={clsx(classes.root, { [classes.active]: active }, className)}
      component="span"
      disableRipple
      ref={ref}
      {...other}
    >
      {children}
      <div className={classes[`iconAlign${capitalize(align)}`]}>
        {!active ? (<Sorting />) : (
          <Sorted
            className={clsx(classes.icon, classes[`iconDirection${capitalize(direction)}`])}
          />
        )}
      </div>
    </ButtonBase>
  );
});

export default withStyles(styles)(TableSortLabel);
