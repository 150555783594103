const progress = {
	header: {
		fontFamily: 'Roboto',
		fontSize: '18px',
		fontWeight: 500,
		color: "#454545",
	},
	position: {
		marginTop: '15px',
		marginBottom: '0px'
	},
	
	wrapper: {
    fontFamily: "Roboto",
		width: "548px",
		height: "10px",
		background: "#d2f6fa",
		borderRadius: "20px",
		color: "#454545",
		position: "relative",
		overflow: 'hidden',
 
	},
	bar: {
		background: "#09bbd0",
		borderRadius: "20px",
		position: "absolute",
		top:0,
		left: 0,
		padding: 0,
		margin: 0,
		height: "10px",
		transition: "all 400ms ease",
	},
	presentValue: {
		fontFamily: 'Roboto',
		fontSize: '23px',
		fontWeight: '300',
		marginBottom: '5px',
		color: "#454545",
	},
	period: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 400,
		color: "#454545",
	},
	stepWrapper: {
		width: "548px",
		fontFamily: 'Roboto',
		fontSize: '14px',
		// position: "relative"
		marginTop: '5px',
		display: "flex",
		justifyContent:"space-between"
	},

	steps: {
		fontFamily: 'Roboto',
		fontSize: "12px",
		padding: 0,
		margin: 0,
		textAlign: "left",
		color: "#747474"
	} 
}

export {  progress };
