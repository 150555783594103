import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import {
  Button, Dialog, DialogActions, DialogContent,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../redux/api';
import { QuestionnaireManagementClinicType } from '../../../types/API/questionnaireManagementClinic';
import { LocaleWrapper, FormTextField, RichFormText } from '../../form-inputs';
import DialogTitle from '../../DialogTitle';
import { LANGUAGES } from '../../../../locales/languages';

const localize = (name: string) => {
  const formikFields = {};
  LANGUAGES.forEach((lang) => {
    formikFields[`${name}_${lang.code}`] = '';
  });

  return formikFields;
};

type AddQuestionnaireProps = {
  open: boolean;
  handleClose: () => void;
  questionnaireId?: number;
};

const AddQuestionnaire = (props: AddQuestionnaireProps) => {
  const {
    open, handleClose, questionnaireId,
  } = props;
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { questionnaireManagementClinic } = greyboxApiActions;
  const [addQuestionnaire] = questionnaireManagementClinic.add();

  const formik = useFormik({
    initialValues: {
      ...localize('name'),
      ...localize('description'),
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const body: Partial<QuestionnaireManagementClinicType> = {
        questionnaire: {
          clinic_owner: clinic.id,
          ...values,
        },
      };
      body.clinic = clinic?.id;
      addQuestionnaire({ body: body }).then(() => handleClose());
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={handleClose}>
        {questionnaireId ? t('Edit Questionnaire') : t('Add Questionnaire')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <LocaleWrapper name="name">
            <FormTextField name="name" formik={formik} label={t('Name')} sx={{ width: '100%' }} />
          </LocaleWrapper>
          <LocaleWrapper name="description">
            <RichFormText formik={formik} name="description" label={t('Description')} />
          </LocaleWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('Cancel')}
          </Button>
          {
            questionnaireId && (
              <LoadingButton
                onClick={() => 'delete'}
                data-cy="info-message-delete"
                variant="contained"
                color="error"
              >
                {t('Delete')}
              </LoadingButton>
            )
          }
          <LoadingButton
            type="submit"
            variant="contained"
            data-cy="info-message-submit"
            loading={formik.isSubmitting}
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddQuestionnaire;
