import AddIcon from '@mui/icons-material/Add';
import {
  Box, Button, Card, CardContent, CardHeader, Skeleton,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import QuestionnaireDetails from './QuestionnaireDetails';
import AddQuestionnaire from './AddQuestionnaire';
import DialogQuestionnaire from '../../patient-profile/questionnaire/filler/DialogQuestionnaire';

const Questionnaires = () => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { questionnaireManagementClinic } = greyboxApiActions;
  const {
    data, isFetching, isError, refetch,
  } = questionnaireManagementClinic.list({ clinic: clinic.id, ordering: 'order' });
  const [sections, setSections] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [testId, setTestId] = React.useState(null);

  useEffect(() => {
    if (data) {
      setSections(data);
      setOrders(data.map((s) => s.order));
    }
  }, [data]);

  const moveSection = (dragIndex, hoverIndex) => {
    const dragSection = sections[dragIndex];
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections.splice(dragIndex, 1);
      newSections.splice(hoverIndex, 0, dragSection);
      return newSections;
    });
  };

  if (isFetching) {
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={t('Questionnaires')}
          subheader={`${t('Add')}, ${t('edit')} ${t('and')} ${t('delete')} ${t('questionnaires')}.`}
        />
        <CardContent>
          <Box sx={{
            p: 2,
            mb: 1,
            borderRadius: 2,
            border: (theme) => (`1px solid ${theme.palette.divider}`),
          }}
          >
            <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" height="160px" />
            <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" height="160px" />
            <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" height="160px" />
            <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" height="160px" />
            <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" height="160px" />
            <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" height="160px" />
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={t('Questionnaires')}
          subheader={`${t('Add')}, ${t('edit')} ${t('and')} ${t('delete')} ${t('questionnaires')} ${t('and')} ${t('their questions')}`}
        />
        <CardContent>
          error
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Card>
        <CardHeader
          title={t('Questionnaires')}
          subheader={`${t('Add')}, ${t('edit')} ${t('and')} ${t('delete')} ${t('questionnaires')} ${t('and')} ${t('their questions')}`}
        />
        {clinic.permissions.includes('add_questionnaire') && (
          <Button
            onClick={() => setOpen(true)}
            variant="outlined"
            sx={{ ml: 2 }}
            startIcon={<AddIcon />}
          >
            {t('Add')}
          </Button>
        )}
        <CardContent>
          {sections.map((q, index) => (
            <QuestionnaireDetails
              key={q.id}
              index={index}
              questionnaireClinic={q}
              moveSection={moveSection}
              setTestId={setTestId}
              orders={orders}
              refetch={refetch}
            />
          ))}
        </CardContent>
      </Card>
      {open && (
        <AddQuestionnaire open={open} handleClose={() => setOpen(false)} />
      )}
      {
        !!testId && (
          <DialogQuestionnaire
            open={!!testId}
            onComplete={() => setTestId(null)}
            handleClose={() => setTestId(null)}
            questionnaireId={testId}
          />
        )
      }
    </Box>
  );
};
export default Questionnaires;
