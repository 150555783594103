import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, MenuItem,
  DialogTitle, Button, Box, Typography, Tooltip, Divider, IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import FormTextField from '../form-inputs/FormTextField';
import FormSelect from '../form-inputs/FormSelect';
import { greyboxApiActions } from '../../redux/api';
import Row from '../Row';

const LANGUAGES = [{
  code: 'en',
  name: 'English',
}, {
  code: 'fr',
  name: 'French',
}, {
  code: 'es',
  name: 'Spanish',
}, {
  code: 'de',
  name: 'German',
}, {
  code: 'it',
  name: 'Italian',
}, {
  code: 'pt',
  name: 'Portuguese',
}];

const NewDocumentSection = ({ formik, index }) => {
  const { t } = useTranslation();

  const others = formik.values.documents.filter((document, i) => i !== index);
  const availableLanguages = LANGUAGES.filter(
    (language) => !others.find((doc) => doc.language === language.code),
  );

  const newDoc = useFormik({
    initialValues: {
      title: formik.values.documents[index].title,
      description: formik.values.documents[index].description,
      url: formik.values.documents[index].url,
      source: formik.values.documents[index].source,
      language: formik.values.documents[index].language || availableLanguages[0].code,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required(t('Required')),
      description: Yup.string(),
      url: Yup.string(),
      source: Yup.mixed(),
      language: Yup.string().required(t('Required')),
    }),
  });

  useEffect(() => {
    formik.setFieldValue(`documents[${index}]`, newDoc.values);
  }, [newDoc.values]);

  return (
    <Box>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        {index > 0 && (
          <Tooltip title={t('Remove section')}>
            <IconButton
              sx={{ ml: 'auto' }}
              onClick={() => formik.setFieldValue('documents', others)}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Row>
        <FormTextField data-cy={`name-${index}`} required name="title" formik={newDoc} label={t('Name')} />
        <FormSelect
          name="language"
          data-cy={`language-${index}`}
          formik={newDoc}
          label={t('Language')}
          disabled={formik.values.documents[index].language === 'en'}
        >
          {availableLanguages.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              {t(language.name)}
            </MenuItem>
          ))}
        </FormSelect>
      </Row>
      <FormTextField
        sx={{ m: 1, display: 'flex' }}
        name="description"
        data-cy={`description-${index}`}
        formik={newDoc}
        label={t('Description')}
      />
      {formik.values.type === 'url' ? (
        <FormTextField
          sx={{ m: 1, display: 'flex' }}
          name="url"
          formik={newDoc}
          label={t('URL')}
        />
      ) : (
        <Dropzone formik={newDoc} />
      )}
    </Box>
  );
};

const Dropzone = ({ formik }) => {
  const [isDragging, setIsDragging] = React.useState(false);
  const { t } = useTranslation();

  const handleDrop = (event) => {
    event.preventDefault();

    // Only handle one file
    if (event.dataTransfer.files.length > 1) {
      return;
    }

    formik.setFieldValue('source', event.dataTransfer.files[0]);
  };

  return (
    formik.values.source ? (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        m: 1,
        p: 1,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
      >
        <Typography variant="body1" sx={{ mr: 1 }}>
          {formik.values.source.name}
        </Typography>
        <Tooltip title={t('Remove file')}>
          <IconButton
            onClick={() => formik.setFieldValue('source', null)}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ) : (
      <Box
        onDrop={(e) => handleDrop(e)}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        sx={{
          border: (theme) => `2px ${isDragging ? 'solid' : 'dashed'} ${theme.palette.divider}`,
          borderRadius: 2,
          display: 'flex',
          cursor: isDragging ? 'copy' : 'pointer',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          m: 1,
          p: 4,
        }}
      >
        <Typography variant="h6" sx={{ mt: 2 }}>
          {t('Drag and drop your file here')}
        </Typography>
        <input
          style={{ display: 'none' }}
          onChange={(e) => formik.setFieldValue('source', e.target.files[0])}
          id="raised-button-file"
          type="file"
        />
        <label htmlFor="raised-button-file">
          <Button variant="raised" component="span">
            {t('Browse files')}
          </Button>
        </label>
      </Box>
    ));
};

const documentsParser = (data) => {
  const documents = [];
  LANGUAGES.forEach((language) => {
    if (data[`title_${language.code}`]) {
      const newDocument = {
        title: data[`title_${language.code}`],
        description: data[`description_${language.code}`],
        language: language.code,
      };

      if (data.type === 'url') {
        newDocument.url = data[`url_${language.code}`];
      } else if (data[`url_${language.code}`]){
        newDocument.source = {
          name: data[`url_${language.code}`],
        };
      }

      documents.push(newDocument);
    }
  });

  return documents;
};

const AddDocument = (props) => {
  const {
    open, handleClose, section, articleId,
  } = props;
  const { t, i18n } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    type: 'pdf',
    documents: [{
      title: '',
      description: '',
      url: '',
      source: null,
      language: 'en',
    }],
  });
  const { educationArticle } = greyboxApiActions;
  const [addArticle] = educationArticle.add();
  const [updateArticle] = educationArticle.update();
  const { data, isLoading } = educationArticle.get(articleId, { skip: !articleId });

  useEffect(() => {
    if (data) {
      setInitialValues({
        type: data.type,
        documents: documentsParser(data),
      });
    }
  }, [data]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      type: Yup.string().required(t('Required')),
      documents: Yup.array().of(Yup.object()).min(1).required(t('Required')),
    }),
    onSubmit: async (values) => {
      if (articleId) {
        const formData = new FormData();
        // Append only changed fields
        Object.entries((values)).forEach(([key, value]) => {
          if (key === 'documents') {
            value.forEach((document, index) => {
              Object.entries(document).forEach(([documentKey, documentValue]) => {
                if (index >= formik.initialValues.documents.length
                  || documentValue !== formik.initialValues.documents[index][documentKey]) {
                  formData.append(`${documentKey}_${document.language}`, documentValue);
                }
              });
            });
          } else if (value !== data[key]) {
            formData.append(key, value);
          }
        });

        await updateArticle({ id: articleId, body: formData, feedback: { success: t('Document updated') } });
      } else {
        const formData = new FormData();
        formData.append('type', values.type);
        formData.append('section', section.uuid);
        formData.append('order', section.articles.length + 1);

        values.documents.forEach((document) => {
          formData.append(`title_${document.language}`, document.title);
          formData.append(`description_${document.language}`, document.description);

          if (document.url) {
            formData.append(`url_${document.language}`, document.url);
          } else if (document.source) {
            formData.append(`source_${document.language}`, document.source);
          }
        });

        await addArticle({ body: formData, feedback: { success: t('Education document added') } });
      }
      handleClose();
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{articleId ? t('Edit document') : t('Add document')}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Row>
            <FormSelect name="type" formik={formik} label={t('Type')} required>
              <MenuItem value="image">{t('Image')}</MenuItem>
              <MenuItem value="video">{t('Video')}</MenuItem>
              <MenuItem value="audio">{t('Audio')}</MenuItem>
              <MenuItem value="pdf">{t('PDF')}</MenuItem>
              <MenuItem value="url">{t('URL')}</MenuItem>
            </FormSelect>
          </Row>
          {formik.values.documents.map((document, index) => (
            <NewDocumentSection key={index} index={index} formik={formik} />
          ))}
          {formik.values.documents.length < LANGUAGES.length && (
            <Tooltip title={t('Add another language')}>
              <Button
                variant="outlined"
                data-cy="add-language"
                sx={{ m: 1, width: '98%' }}
                onClick={() => {
                  formik.setFieldValue('documents', [...formik.values.documents, {
                    title: '',
                    description: '',
                    url: '',
                    source: '',
                  }]);
                }}
              >
                <AddIcon />
              </Button>
            </Tooltip>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            data-cy="save"
            loading={formik.isSubmitting}
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddDocument;
