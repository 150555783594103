import moment from 'moment';

export interface InformationMessageApiType {
  id: string;
  clinic: number;
  deleted_at: string;
  key: string;
  text: string;
  text_en: string;
  text_fr: string;
  text_es: string;
  text_de: string;
  text_it: string;
  text_pt: string;
  type: string;
  dtstart: moment.Moment;
  dtend: moment.Moment;
  recurrences: string;
  active: boolean;
  dismissible: boolean;
  created: string;
  updated: string;
  recurrence: string;
}

export interface InformationMessageFormType {
  key: string;
  dtstart: string;
  dtend: string;
  recurrences: string;
  active: boolean;
  endingCondition: string;
  dismissible: boolean;
  texts: [{
    language: string;
    text: string;
  }];
  type: string;
};

export const parseFormToApi = (form: InformationMessageFormType, hasRecurrence: boolean) : Partial<InformationMessageApiType> => {
  const {texts, recurrences, dtstart, dtend, ...rest} = form
  const res: Partial<InformationMessageApiType> = {...rest}

  if (hasRecurrence) {
    res.recurrences = recurrences
  }

  res.dtstart = moment(dtstart).format('YYYY-MM-DD HH:mm:ss')
  res.dtend = moment(dtend).format('YYYY-MM-DD HH:mm:ss')

  texts.forEach((text) => {
    if (text.language === 'en') {
      res.text = text.text
    }
    res[`text_${text.language}`] = text.text
  })

  return res;
}