import { Reference, Coding } from './fhir';
import i18n from '../../i18n';

export const DEVICE_STATUS = {
  active: i18n.t('Active'),
  inactive: i18n.t('Inactive'),
  'entered-in-error': i18n.t('Entered in Error'),
};

export const DEVICE_AVAILABILITY_STATUS = {
  lost: i18n.t('lost'),
  damaged: i18n.t('damaged'),
  destroyed: i18n.t('destroyed'),
  available: i18n.t('available'),
};

export const DEVICE_CATEGORY = {
  active: i18n.t('Active'),
  communicating: i18n.t('Communicating'),
  dme: i18n.t('dme'),
  'home-use': i18n.t('Home-use'),
  implantable: i18n.t('Implantable'),
  'in-vitro': i18n.t('In-vitro'),
  'point-of-care': i18n.t('Point-of-care'),
  'single-use': i18n.t('Single-use'),
  reusable: i18n.t('Reusable'),
  software: i18n.t('Software'),
};

export const DEVICE_MODE = {
  normal: i18n.t('Normal'),
  demo: i18n.t('Demo'),
  service: i18n.t('Service'),
  maintenance: i18n.t('Maintenance'),
  test: i18n.t('Test'),
};

export const DEVICE_SAFETY = {
  C106046: i18n.t('Magnetic Resonance Conditional'),
  C106045: i18n.t('Magnetic Resonance Safe'),
  C106047: i18n.t('Magnetic Resonance Unsafe'),
  C113844: i18n.t('Labeling does not contain MRI Safety Information'),
  C101673: i18n.t('Labeled as containing natural rubber latex'),
  C106038: i18n.t('Not made with natural rubber latex'),
};

export type DeviceType = {
  id: string;
  status: string | null;
  availabilityStatus: {coding: Coding[]} | null;
  category: {coding: Coding[]} | null;
  mode: {coding: Coding[]} | null;
  safety: {coding: Coding[]} | null;
  displayName: string;
  definition: any | null;
  biologicalSourceEvent: string | null;
  manufacturer: string | null;
  manufactureDate: Date | null;
  expirationDate: Date | null;
  lotNumber: string | null;
  serialNumber: string | null;
  modelNumber: string | null;
  partNumber: string | null;
  type: string[] | null;
  cycle: any | null;
  duration: any | null;
  url: string | null;
  location: any | null;
  owner: Reference | null;
  created_by: any;
};
