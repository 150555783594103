import React from 'react';
import { Box, Link } from '@mui/material';
import ImageModal from './ImageModal';

const Media = ({ media }) => {
  const [selectedImg, setSelectedImg] = React.useState(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSelectedImg(media.file);
    }
  };

  if (media.type === 'image') {
    return (
      <>
        <div
          tabIndex="0"
          role="button"
          onClick={() => setSelectedImg(media.file)}
          onKeyDown={handleKeyDown}
        >
          <img
            src={media.file}
            alt="message"
            style={{
              maxHeight: '300px',
              width: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
        <ImageModal
          image={selectedImg}
          open={selectedImg !== null}
          handleClose={() => setSelectedImg(null)}
        />
      </>
    );
  }
  if (media.type === 'video') {
    return (
      <video
        src={media.file}
        controls
        style={{
          maxHeight: '300px',
          width: '100%',
          objectFit: 'contain',
        }}
      />
    );
  }
  return (
    <Box>
      <Link
        href={media.file}
        target="_blank"
        rel="noopener noreferrer"
      >
        {media.name.split('/').pop()}
      </Link>
    </Box>
  );
};

export default Media;
