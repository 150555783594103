import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackCard from './ErrorFallbackCard';
import DialogTitle from '../DialogTitle';

const ErrorHandlingDialog = ({ open, onClose, title, children }) => {
    

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle onClose={onClose} >{title}</DialogTitle>
      <DialogContent>
        <ErrorBoundary
          FallbackComponent={ErrorFallbackCard}
          onReset={onClose}
        >
          {children}
        </ErrorBoundary>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorHandlingDialog;
