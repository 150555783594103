import { Skeleton, Paper, Typography } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import ChartContainer from './ChartContainer';

interface DataType {
  x: string;
  y: number[];
}

interface SeriesType {
  data: DataType[];
  name: string;
}

interface TimelineChartProps {
  series: SeriesType[];
  name: string;
  isLoading: boolean;
  colors: string[];
}

const TimelineChart = ({
  series, name, isLoading, colors,
}: TimelineChartProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (isLoading || !series) {
    return (
      <Paper sx={{
        p: 1, height: '35vh',
      }}
      >
        <Skeleton width="100%" height="100%" />
      </Paper>
    );
  }

  if (series.length === 0) {
    return (
      <Paper sx={{
        height: '35vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Typography variant="body2">
          {t('No data')}
        </Typography>
      </Paper>
    );
  }

  const options: ApexOptions = {
    series,
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    legend: {
      position: 'bottom',
    },
    colors,
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const a = moment(val[0]);
        const b = moment(val[1]);
        const diff = b.diff(a, 'days');
        return `${diff} ${diff > 1 ? t('days') : t('day')}`;
      },
    },
    xaxis: {
      type: 'datetime',
    },
  };

  return (
    <ChartContainer
      detailed
      name={name}
    >
      <ReactApexChart
        type="rangeBar"
        options={options}
        series={options.series}
        height="100%"
        width="100%"
      />
    </ChartContainer>
  );
};

export default TimelineChart;
