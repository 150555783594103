import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  VictoryZoomContainer,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryClipContainer,
} from 'victory';
import { emptyObject } from '../../../../helper-functions';
import graph_style from './graph_style';

const Legend = (props) => {
  const {
    currentDoseColor,
  } = props;

  const { t } = useTranslation();

  return (
    <div style={graph_style.legend_wrapper}>
      <div style={graph_style.legend_text_label}>
        <span style={({ ...graph_style.legend_gray_dot, backgroundColor: currentDoseColor })} />
        <div>{t('Current dose')}</div>
      </div>
      <div style={graph_style.legend_text_label}>
        <hr style={graph_style.legend_dotted_line_gray} />
        <div>{t('Original titration plan')}</div>
      </div>
      <div style={graph_style.legend_text_label}>
        <hr style={graph_style.legend_dotted_line} />
        <div>{t('Updated titration plan')}</div>
      </div>
    </div>
  );
};

/*
Generate x axis tick base on require spacing.
Base on Zeplin, the spacing should be 1 when the array is 12 or less,
and spacing will become 2 after that.

Input
@parm: array - array to base of on to generate spacing
@parm: spacing - spacing requirement. This can be any int to provide us flexibility in the future

Output:
@parm: tick_array - array of x axis tick with proper index
*/
const generate_tick = (array, spacing) => {
  const tick_array = [];
  array.map((ele, index) => {
    if (index % spacing === 0) {
      tick_array.push(index);
    }
    return null;
  });
  return tick_array;
};

const Graph = (props) => {
  const {
    suggestedDoseData,
    patientDoseData,
    projectedReachDataPoint,
    num_weeks, // duration of titration in weeks, or the number of weeks for which
    // the patient has been taking medications, or the number of weeks
    // to projected reach, whichever is greater.
    dose_unit,
    color_code,
    updatedDoseData,
  } = props;

  const { t } = useTranslation();

  // Height and width of the graph
  const graph_height = 310;
  const graph_width = 570;

  // Set projected reach color
  const projectedReachColor = '#747474';

  // Determine if the x axis is 12 or 24
  const domain_X_limit = num_weeks > 12 ? 24 : 12;

  // Find out the longer array between patient med history and plan. Use the
  // longer one as a base for any x-axis calculation
  const maxLengthArray = suggestedDoseData.length >= patientDoseData.length
    ? suggestedDoseData
    : patientDoseData;

  // Generate x-axis tick value base on max length of array and spacing needed
  const x_axis_tickValues = num_weeks > 12
    ? generate_tick(maxLengthArray, 2)
    : generate_tick(maxLengthArray, 1);

  // Determine if panning is enable
  const pan_enable = !(domain_X_limit >= num_weeks);

  // Predefine the zoom area to the latest read within the graph
  // (only needed when pan_enable is true)
  const lastPatientDataPoint_X = patientDoseData[patientDoseData.length - 1].week;
  const zoomDomain = { x: [lastPatientDataPoint_X - 24, lastPatientDataPoint_X + 1] };

  // Determine whether or not to display projected reach
  const projectedReachExists = !emptyObject(projectedReachDataPoint);

  return (
    <div>
      <VictoryChart
        domain={{ x: [0, num_weeks] }}
        height={graph_height}
        width={graph_width}
        padding={{
          top: 10, bottom: 50, left: 50, right: 10,
        }}
        domainPadding={{ x: 5, y: 5 }}
        containerComponent={(
          <VictoryZoomContainer
            zoomDimension="x"
            allowPan={pan_enable}
            allowZoom={false}
            zoomDomain={pan_enable ? zoomDomain : { x: [0, domain_X_limit] }}
            clipContainerComponent={<VictoryClipContainer clipPadding={{ top: 10, right: 10 }} />}
          />
        )}
        style={pan_enable ? { parent: { cursor: 'ew-resize' } } : null}
      >
        {/* x-Axis */}
        <VictoryAxis
          label={t('Weeks')}
          tickValues={x_axis_tickValues}
          tickLabelComponent={<VictoryLabel dy={-10} />}
          style={graph_style.x_axis}
        />
        {/* y-Axis */}
        <VictoryAxis
          dependentAxis
          label={t('Daily Dose') + ' (' + dose_unit + ')'}
          tickLabelComponent={<VictoryLabel dx={5} />}
          style={graph_style.y_axis}
        />
        <VictoryLine
          style={{
            data: {
              stroke: projectedReachExists ? 'gray' : '#09bbd0',
              strokeWidth: 1.5,
              strokeDasharray: '3,3',
            },
          }}
          data={suggestedDoseData}
        />
        <VictoryBar
          style={{
            parent: {
              width: graph_width,
            },
            data: {
              fill: ({ index }) => (patientDoseData.length - 1 === index
                ? color_code.bright
                : color_code.pale),
              fillOpacity: 1,
            },
          }}
          data={patientDoseData}
          barWidth={() => (domain_X_limit === 12 ? 24 : 12)}
          alignment="middle"
          x="week"
          y="total_dose"
        />
        {projectedReachExists ? (
          <VictoryLine
            style={{
              data: {
                stroke: '#09bbd0',
                strokeWidth: 1.5,
                strokeDasharray: '3,3',
              },
            }}
            data={updatedDoseData}
          />
        ) : null}
      </VictoryChart>
      <Legend
        currentDoseColor={color_code.bright}
      />
    </div>
  );
};

export default Graph;
export {
  Legend,
  generate_tick,
};
