import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    minHeight: '95vh',
    paddingBottom: theme.spacing(2),
  },
}));

const Layout = (props) => {
  const { width = 'md', children } = props;
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Container maxWidth={width} className={classes.container}>
        {children}
      </Container>
    </div>
  );
};

export default Layout;
