import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import { DownloadIcon } from '../../tk-ui';

const PdfButton = (props) => {
  const {
    patientUuid,
  } = props;
  const { t, i18n } = useTranslation();
  const { access } = useSelector((state) => state.user);
  const report_domain_config = {
    localhost: {
      domain: 'http://localhost:3000',
    },
    'dev.greybox.ca': {
      domain: 'https://dev-report.greybox.ca',
    },
    'dev.greybox.fr': {
      domain: 'https://dev-report.greybox.fr',
    },

    'staging.greybox.ca': {
      domain: 'https://staging-report.greybox.ca',
    },
    'staging.greybox.fr': {
      domain: 'https://staging-report.greybox.fr',
    },

    'takecare.greybox.ca': {
      domain: 'https://report.greybox.ca',
    },
    'takecare.greybox.fr': {
      domain: 'https://report.greybox.fr',
    },
  };

  const {
    domain,
  } = report_domain_config[window.location.hostname];

  const { token } = useSelector((state) => state.authorization);
  const { instance, accounts } = useMsal();
  const { range } = useSelector((state) => state.patientProfileDateRange);

  const getToken = async () => {
    if (token && token.startsWith('Token ')) {
      return token;
    }

    const tokenResponse = await instance.acquireTokenSilent({
      scopes: ['openid',
        'https://takecareca.onmicrosoft.com/api/takecare.read',
        'https://takecareca.onmicrosoft.com/api/takecare.write',
      ],
      account: accounts[0],
    });
    // Use msal to retrieve the authentication token
    return 'Bearer ' + tokenResponse.accessToken;
  };

  const showRapport = async () => {
    let authToken = await getToken();

    const tokenSplit = authToken.split(' ');
    let prefix = '';

    if (tokenSplit.length > 1) {
      let unused = '';
      prefix = `prefix=${tokenSplit[0]}`;
      [unused, authToken] = tokenSplit;
    }

    const url = `${domain}/pdf/${i18n.resolvedLanguage}/${access === 'P' ? 'patient-summary' : 'continuum'}/${patientUuid}/?time__range=${range}&${prefix}&token=${authToken}`;
    window.open(url, '_blank');
  };

  return (
    <IconButton onClick={showRapport}>
      <DownloadIcon />
    </IconButton>
  );
};

export default PdfButton;
