const contraStyle = {
  tooltipWrapper: {
    minWidth: "330px",
    minHeight: "80px",
    padding: "12px",
    backgroundColor: "#454545",
  },
  tooltipAdjustment: {
    transform: "translateY(-8px) translateX(0px)",
    boxSizing: "border-box",
  },
  closeIcon: {
    position: "absolute",
    top: "8px",
    right: "7px",
    cursor: "pointer",
  },
  classTitle: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.19",
    textAlign: "left",
    color: "#fafafa",
  },
  placement: { position: "absolute", left: "0px", top: "-0.5px" },
  button: {
    marginTop: "2px",
    marginLeft: "7px",
    padding: "10px 8px",
    borderRadius: "3px",
    border: "solid 1px #6c6c6c",
    fontSize: "14px",
    textTransform: "capitalize",
    fontWeight: "500",
    lineHeight: "0.7",
    fontFamily: "Roboto",
    textAlign: "left",
    color: "#454545",
  },
  warningButton: {
    marginTop: "2px",
    marginLeft: "7px",
    padding: "10px 8px 10px 29px",
    borderRadius: "3px",
    border: "solid 1px #ff8225",
    backgroundColor: "#ff8225",
    fontSize: "14px",
    textTransform: "capitalize",
    fontWeight: "500",
    lineHeight: "0.7",
    fontFamily: "Roboto",
    textAlign: "left",
    color: "white",
  },
  buttonSelected: {
    marginTop: "2px",
    marginLeft: "7px",
    padding: "10px 8px",
    borderRadius: "3px",
    // backgroundColor: "#454545",
    border: "solid 1px rgb(221, 221, 221)",
    fontSize: "14px",
    textTransform: "capitalize",
    fontWeight: "500",
    lineHeight: "0.7",
    fontFamily: "Roboto",
    textAlign: "left",
    // color: "#f7fdfd"
    color: "#454545",
  },

  sectionWrapper: {
    position: "relative",
    marginTop: "12px",
    width: "calc(100% - 15px)",
    height: "52px",
    backgroundColor: "#363636",
    paddingLeft: "15px",
    borderRadius: "2px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  sectionWrapperHover: {
    position: "relative",
    marginTop: "12px",
    width: "100%",
    height: "52px",
    transition: "all 230ms",
    transform: "translateX(3px)",
    backgroundColor: "#454f6f",
  },
  warningBar: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "4px",
    height: "52px",
    background: "#f49342",
    borderTopLeftRadius: "2px",
    borderBottomLeftRadius: "2px",
  },
  noDataBar: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "4px",
    height: "52px",
    background: "#fcf1cd",
    borderTopLeftRadius: "2px",
    borderBottomLeftRadius: "2px",
  },
  clearBar: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "4px",
    height: "52px",
    background: "#b5e2a1",
    borderTopLeftRadius: "2px",
    borderBottomLeftRadius: "2px",
  },
  warningIcon: {},
  warningTitle: {
    fontSize: "15px",
    color: "#f49342",
    minWidth: "345px",
    fontWeight: "400",
  },
  recommendations: {
    fontSize: "13px",

    color: "#e7eaed",
    width: "300px",
    fontWeight: "400",
  },
  textPosition: {
    justifyContent: "center",
  },
  noDataTitle: {
    fontSize: "15px",
    color: "#fcf1cd",
    minWidth: "345px",
    fontWeight: "400",
  },

  clearTitle: {
    fontSize: "15px",
    color: "#b5e2a1",
    minWidth: "345px",
    fontWeight: "400",
  },
  bolderStyle: {
    fontWeight: "500",
  },
  lighterStyle: {
    fontWeight: "500",
    color: "white",
  },
};

export { contraStyle };
