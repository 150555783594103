import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import moment from 'moment';
import { RecurrenceType, FrequencyType, EndingConditionType } from '../../../types';
import FrequencySelector from './FrequencySelector';
import EndingConditionSelector from './EndingConditionSelector';
import { WEEK_DAYS_CODE } from './utils';

export interface RecurrenceProps {
  onChange: (rule: string) => void;
  rule?: string;
}

const parseRecurrenceToRule = (data: RecurrenceType): string => {
  if (!data) {
    return '';
  }

  let rule = 'RRULE:';

  const {
    frequency, endingCondition, weekDaysRepetition, endingOccurrencesNumber,
    endingDate, numberOfRepetitions,
  } = data;

  if (frequency === FrequencyType.None) {
    return '';
  }

  rule += `FREQ=${frequency.toUpperCase()}`;
  if (weekDaysRepetition.length > 0) {
    rule += `;BYDAY=${weekDaysRepetition.map((day) => WEEK_DAYS_CODE[day]).join(',')}`;
  }

  rule += `;INTERVAL=${numberOfRepetitions || 1}`;

  switch (endingCondition) {
    case EndingConditionType.None:
      break;
    case EndingConditionType.EndDate:
      rule += `;UNTIL=${endingDate?.toISOString().replace(/[-:]/g, '')}`;
      break;
    case EndingConditionType.OccurrencesNumber:
      rule += `;COUNT=${endingOccurrencesNumber}`;
      break;
    default:
      break;
  }

  return rule;
};

const parseRuleToRecurrence = (rule: string): RecurrenceType => {
  const recurrence: RecurrenceType = {
    frequency: FrequencyType.None,
    endingCondition: EndingConditionType.None,
    weekDaysRepetition: [],
    endingDate: null,
    endingOccurrencesNumber: undefined,
    numberOfRepetitions: undefined,
    isAllDay: false,
  };

  if (!rule) {
    return recurrence;
  }

  const ruleParts = rule.replace('RRULE:', '').split(';');

  ruleParts.forEach((part) => {
    const [key, value] = part.split('=');
    switch (key) {
      case 'FREQ':
        recurrence.frequency = value.toLowerCase() as FrequencyType;
        break;
      case 'BYDAY':
        recurrence.weekDaysRepetition = value.split(',').map((day) => WEEK_DAYS_CODE.indexOf(day));
        break;
      case 'INTERVAL':
        recurrence.numberOfRepetitions = parseInt(value, 10);
        break;
      case 'UNTIL':
        recurrence.endingCondition = EndingConditionType.EndDate;
        recurrence.endingDate = moment(value, 'YYYYMMDDTHHmmssZ').toDate();
        break;
      case 'COUNT':
        recurrence.endingCondition = EndingConditionType.OccurrencesNumber;
        recurrence.endingOccurrencesNumber = parseInt(value, 10);
        break;
      default:
        break;
    }
  });

  return recurrence;
};

const Recurrence = (props: RecurrenceProps) => {
  const { onChange, rule } = props;
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      ...parseRuleToRecurrence(rule),
    },
    validationSchema: Yup.object({
      endingOccurrencesNumber: Yup.number().min(1, t('Must be greater than 0')),
      numberOfRepetitions: Yup.number().min(0, t('Must be positive')),
      endingDate: Yup.date().min(moment().toDate(), t('Must be in the future')),
    }),
    onSubmit: () => {
      // Do nothing
    },
  });

  useEffect(() => {
    onChange(parseRecurrenceToRule(formik.values));
  }, [formik.values, formik.values.weekDaysRepetition.length]);

  return (
    <>
      <FrequencySelector formik={formik} />
      {formik.values.frequency !== FrequencyType.None && (
        <EndingConditionSelector formik={formik} />
      )}
    </>
  );
};

export default Recurrence;
