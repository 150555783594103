import {
  Box, MenuItem, TextField, Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../redux/api';

const MedicalAct = ({ formik }) => {
  const { i18n, t } = useTranslation();
  const { medicalActType, medicalAct } = greyboxApiActions;
  const { data = [], isFetching } = medicalActType.list({});
  const [menuOpen, setMenuOpen] = React.useState(false);

  if (isFetching) {
    return null;
  }

  return (
    <>
      <TextField
        fullWidth
        select
        defaultValue=""
        label={t('Follow-up type')}
        name="medicalActType"
        value={formik.values.medicalActType}
        onChange={formik.handleChange}
        SelectProps={{
          renderValue: (value) => data.find((item) => item.uuid === value)?.name,
          MenuProps: {
            sx: {
              width: '100%',
              maxWidth: 500,
            },
          },
        }}
      >
        {data.map((item) => (
          <MenuItem
            key={item.uuid}
            value={item.uuid}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', whiteSpace: 'normal' }}>
              {item.name}
              <Typography variant="caption" color="text.secondary" flexWrap>
                {item.description}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        label={t('Note')}
        name="note"
        value={formik.values.note}
        onChange={formik.handleChange}
        multiline
        rows={4}
        variant="outlined"
        sx={{ mt: 2 }}
      />
    </>
  );
};

export default MedicalAct;
