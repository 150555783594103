import React from 'react';
import { SvgIcon } from '@mui/material';

const Sorted = (props) => (
  <SvgIcon {...props}>
    <path id="Path_202" d="M7 10l5 5 5-5z" fill="#09bbd0" data-name="Path 202" />
    <path id="Path_203" d="M0 0h24v24H0z" fill="none" data-name="Path 203" />
  </SvgIcon>
);

export { Sorted };
