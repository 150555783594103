import React from 'react';
import { SvgIcon } from '@mui/material';

const WeightIcon = ({ style }) => (

  <SvgIcon style={style} viewBox="0 0 37 37">
    <g>
      <circle
        fill="#12d0d0"
        data-name="Ellipse 6"
        cx="17.5"
        cy="17.5"
        r="17.5"
      />
      <path
        fill="#fff"
        d="M25.91,7.67H9.09A1.59,1.59,0,0,0,7.5,9.26V26.08a1.59,1.59,0,0,0,1.59,1.59H25.91a1.59,1.59,0,0,0,1.59-1.59V9.26A1.59,1.59,0,0,0,25.91,7.67Zm-3.38,7.5a.63.63,0,0,1-.63.63H20.76L19.4,13.38,18,15.8H13.61a.64.64,0,0,1-.64-.63V12.76a.64.64,0,0,1,.64-.63H21.9a.63.63,0,0,1,.63.63Z"
      />
    </g>
  </SvgIcon>
);

export { WeightIcon };
