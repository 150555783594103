import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Box, Fade, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FrequencyType, Option } from '../../../types';
import FormSelect from '../FormSelect';
import WeekDaysSelector from './WeekDaysSelector';
import FormTextField from '../FormTextField';
import Row from '../../Row';
import i18n from '../../../i18n';

const FREQUENCY_OPTIONS: Option[] = [
  {
    key: FrequencyType.None,
    title: i18n.t('Does not repeat'),
  },
  {
    key: FrequencyType.Daily,
    title: i18n.t('Daily'),
  },
  {
    key: FrequencyType.Weekly,
    title: i18n.t('Weekly'),
  },
  {
    key: FrequencyType.Monthly,
    title: i18n.t('Monthly'),
  },
];

const FrequencySelector = ({ formik }) => {
  const { frequency } = formik.values;
  const { t } = useTranslation();

  const getFrequencyLabel = () => {
    const numberOfRep = formik.values.numberOfRepetitions || 1;

    switch (frequency) {
      case FrequencyType.Daily:
        return `${numberOfRep} ${t('day')}`;
      case FrequencyType.Weekly:
        return `${numberOfRep} ${t('week')}`;
      case FrequencyType.Monthly:
        return `${numberOfRep} ${t('month')}`;
      default:
        return '';
    }
  };

  const getRepetitionsLabelByFrequency = () => {
    const frequencyLabel = getFrequencyLabel();

    if (frequencyLabel === '') {
      return '';
    }
    return `${frequencyLabel}(s)`;
  };

  useEffect(() => {
    if (formik.dirty) {
      if (frequency !== FrequencyType.Weekly && formik.values.weekDaysRepetition.length > 0) {
        formik.setFieldValue('weekDaysRepetition', []);
      }
      if (frequency === FrequencyType.None) {
        formik.setFieldValue('numberOfRepetitions', 0);
      }
    }
  }, [frequency]);

  return (
    <>
      <Row>
        <FormSelect
          name="frequency"
          formik={formik}
          label={t('Frequency')}
        >
          {FREQUENCY_OPTIONS.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.title}
            </MenuItem>
          ))}
        </FormSelect>
        {frequency !== FrequencyType.None && (
          <FormTextField
            name="numberOfRepetitions"
            label={`${t('Repeat every')} ${getRepetitionsLabelByFrequency(formik.values.numberOfRepetitions)}`}
            formik={formik}
            type="number"
          />
        )}
      </Row>
      {frequency === FrequencyType.Weekly && (
        <Fade in={frequency === FrequencyType.Weekly}>
          <div>
            <WeekDaysSelector formik={formik} />
          </div>
        </Fade>
      )}
    </>
  );
};

export default FrequencySelector;
