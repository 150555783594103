import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import moment from 'moment';
import 'moment/locale/fr';

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const letterRegex = /(?=.*[A-Za-z])/;

// adding function to Yup validation librairy
// ref is the watched input obj
function greaterOrEqualTo(ref, msg) {
  return this.test({
    name: 'greaterOrEqualTo',
    message: msg || `must be the same as ${ref}`,
    test: function (value) {
      return this.resolve(ref) && value ? value >= this.resolve(ref) : true;
    },
  });
}
// then add method to Yup obj and type
Yup.addMethod(Yup.number, 'greaterOrEqualTo', greaterOrEqualTo);

// adding function to Yup validation librairy
function lessOrEqualTo(ref, msg) {
  return this.test({
    name: 'lessOrEqualTo',
    // we can add message , but theese vital fields have weird names...
    // message: msg || '${path} must be the same as ${reference}',
    message: msg,
    test: function (value) {
      // dont test if other value is empty
      return this.resolve(ref) && value ? value <= this.resolve(ref) : true;
    },
  });
}
// then add method to Yup obj and type
Yup.addMethod(Yup.number, 'lessOrEqualTo', lessOrEqualTo);

/**
 *
 * @param {str} dateStr // the value in the input
 * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
 */

export const isAdult = (dateStr, expectedDateShape) => {
  const dateValidation = moment(dateStr, expectedDateShape, true).isValid();
  const dateLength = dateStr.length >= expectedDateShape.length;
  const isMajor = moment().diff(moment(dateStr, expectedDateShape), 'years') >= 18;

  return dateValidation && dateLength && isMajor;
};

/**
 *
 * @param {str} dateStr // the value in the input
 * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
 */
export const isDateBefore = (dateStr, expectedDateShape) => {
  const dateValidation = moment(dateStr, expectedDateShape, true).isValid();
  const beforeValidation = moment(dateStr, expectedDateShape, true).isBefore(moment());

  return dateValidation && beforeValidation;
};

/**
 *
 * @param {str} dateStr // the value in the input
 * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
 */
export const isValidDate = (dateStr, expectedDateShape) => (
  moment(dateStr, expectedDateShape, true).isValid()
);

/**
 *
 * @param {str} dateStr // the value in the input
 * @param {str} baseStr // the value in the other input
 * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
 */
export const isDateBeforeOrEqual = (dateStr, baseStr, expectedDateShape = 'YYYY-MM-DD') => {
  const dateValidation = moment(dateStr, expectedDateShape, true).isValid();
  // let beforeValidation = moment(baseStr).isAfter(moment(dateStr));
  // if we NEED the bad idea of next day uncomment line before and cancel next.
  const beforeValidation = moment(baseStr).isSameOrAfter(moment(dateStr));
  const isOk = baseStr === null ? true : !!beforeValidation;
  return dateValidation && isOk;
};

/**
 *
 * @param {str} dateStr // the value in the input
 * @param {str} baseStr // the value in the other input
 * @param {str} expectedDateShape //the placeholder usually 'yyyy-mm-dd'
 */
export const isDateBeforeOther = (dateStr, baseStr, expectedDateShape = 'YYYY-MM-DD') => {
  const dateValidation = moment(dateStr, expectedDateShape, true).isValid();
  const beforeValidation = moment(baseStr).isAfter(moment(dateStr));

  const isOk = baseStr === null ? true : !!beforeValidation;
  return dateValidation && isOk;
};

// retreive next Day
export const isTomorrow = (date) => moment(date).add(1, 'days').format('YYYY-MM-DD');

// isSame Date and todays
export const isSameAsToday = (date) => moment(date).format('YYYY-MM-DD') === moment(Date.now()).format('YYYY-MM-DD');
/*
  GOAL:
    Return default validations for each common value.
    This will allow us to re-use validations for fields,
    using the `generateValidation` func defined below.

    NOTE: 'required' should not be included in default_validations output.
          Required check will be added in `generateValidation` func defined below
          for appropraite fields

  INPUT:
    name: name of value for which we want to generate validation schema
    t: translation function
    country_region: user's country region string (e.g. "FR" or "CA_QC")
  OUTPUT:
    yup validation schema
*/
export const default_validations = (name, t, country_region) => {
  switch (name) {
    case 'firstName':
      return Yup.string().min(2, t('Too Short')).max(30, t('Must less than 30 characters'));

    case 'lastName':
      return Yup.string().min(2, t('Too Short')).max(30, t('Must less than 30 characters'));

    case 'phoneNumber':
      // all 3 countries we support have 10 digits phones
      return Yup.string();

    case 'email':
      return Yup.string()
        .lowercase()
        .email(t('Must be a valid email'))
        .max(254, t('Must less than 254 characters'));

    case 'language':
      return Yup.string().min(1);

    case 'diagnosis':
      return Yup.array().of(Yup.string());

    case 'staffs':
      return Yup.array().of(Yup.string());

    case 'birthDate':
      return Yup.string()
        .min(10, t('Must be a valid date'));

    case 'gender':
      return Yup.string();

    case 'hin_number':
      return Yup.string().min(12, t('Too Short'));

    case 'hin_exp':
      return country_region === 'CA_QC'
        ? Yup.string()
          .min(7, t('Must be a valid date'))
          .test(' ', t('Invalid Date'), (val) => (
            val === undefined
                || val === null
                || val === ''
                || isValidDate(val, 'YYYY-MM')
                || isValidDate(val, 'YYYY-MM-DD')
          ))
        : Yup.string()
          .min(10, t('Must be a valid date'))
          .test(' ', t('Invalid Date'), (val) => (
            val === undefined || val === null || val === '' || isValidDate(val, 'YYYY-MM-DD')
          ));

    case 'zipCode':
      return country_region === 'CA_QC' || 'CA_ON'
        ? Yup.string().min(6, t('Min 6 characters'))
        : Yup.string().min(5, t('Min 5 characters'));

    case 'acceptedTerms':
      return Yup.boolean().oneOf([true]);

    case 'mailOptIn':
      return Yup.boolean();

    case 'password':
      return Yup.string()
        .matches(letterRegex, t('one letter required'))
        .matches(numericRegex, t('one number required'))
        .min(9, t('Minimum 9 characters'));

    case 'passwordConfirm':
      return Yup.string().oneOf([Yup.ref('password')], t('must be the same'));

    case 'role_type':
      return Yup.string().min(1, t('Most Choose'));

    case 'groupNumber':
      return Yup.string().min(1, t('Too Short')).nullable();
    case 'licenceID':
      return Yup.string().min(1, t('Too Short')).nullable();
    case 'sectorNumber':
      return Yup.string().min(1, t('Too Short')).nullable();

    // todo, fix form modification
    case 'V_BG$lowerRange_1':
      return Yup.number().lessOrEqualTo(Yup.ref('V_BG$upperRange_1'), ' ');
    case 'V_BG$upperRange_1':
      return Yup.number().greaterOrEqualTo(
        Yup.ref('V_BG$lowerRange_1'),
        t('Must be equal or greater than Min'),
      );
    case 'V_BP$lowerRange_5':
      return Yup.number().lessOrEqualTo(Yup.ref('V_BP$upperRange_5'), ' ');
    case 'V_BP$upperRange_5':
      return Yup.number().greaterOrEqualTo(
        Yup.ref('V_BP$lowerRange_5'),
        t('Must be equal or greater than Min'),
      );
    case 'V_BP$lowerRange_1':
      return Yup.number().lessOrEqualTo(Yup.ref('V_BP$upperRange_1'), ' ');
    case 'V_BP$upperRange_1':
      return Yup.number().greaterOrEqualTo(
        Yup.ref('V_BP$lowerRange_1'),
        t('Must be equal or greater than Min'),
      );
    case 'V_HR$lowerRange_1':
      return Yup.number().lessOrEqualTo(Yup.ref('V_HR$upperRange_1'), ' ');
    case 'V_HR$upperRange_1':
      return Yup.number().greaterOrEqualTo(
        Yup.ref('V_HR$lowerRange_1'),
        t('Must be equal or greater than Min'),
      );
    case 'V_WT$lowerRange_1':
      return Yup.number().lessOrEqualTo(Yup.ref('V_WT$upperRange_1'), ' ');
    case 'V_WT$upperRange_1':
      return Yup.number().greaterOrEqualTo(
        Yup.ref('V_WT$lowerRange_1'),
        t('Must be equal or greater than Min'),
      );
    case 'M_temperature$lowerRange_1':
      return Yup.number().lessOrEqualTo(Yup.ref('M_temperature$upperRange_1'), ' ');
    case 'M_temperature$upperRange_1':
      return Yup.number().greaterOrEqualTo(
        Yup.ref('M_temperature$lowerRange_1'),
        t('Must be equal or greater than Min'),
      );
    case 'M_spo2$lowerRange_1':
      return Yup.number().lessOrEqualTo(Yup.ref('M_spo2$upperRange_1'), ' ');
    case 'M_spo2$upperRange_1':
      return Yup.number().greaterOrEqualTo(
        Yup.ref('M_spo2$lowerRange_1'),
        t('Must be equal or greater than Min'),
      );
    case 'P_CD$lowerRange_1':
      return Yup.number();

    default:
      return Yup.mixed(); // matches all types
  }
};

export const generateValidation = (validateFieldsArray, country_region) => {
  const { i18n, t } = useTranslation();

  const yupSchema = Object.assign(
    {},
    ...validateFieldsArray.map((validationConfig) => {
      let schema = default_validations(validationConfig.name, t, country_region);
      if (validationConfig.required === true) {
        schema = schema.required(' ');
      }
      return { [validationConfig.name]: schema };
    }),
  );

  return Yup.object().shape(yupSchema);
};

/*
  GOAL:
    Generate validation for commonly used values based on a config array
    Generate empty initialvalues
  INPUT:
    validateFieldsConfig - config from which to generate validation, format:
      [
        {
          name: firstName,
          required: true
        },
        {
          name: lastName,
          required: true
        },
        ...
      ]
    country_region: user's country region string (e.g. "FR" or "CA_QC")
  OUTPUT:
    {init: Initiavalues, validation: Yup validation obj}
    if initialize === false it returns Yup validation obj
*/
export const generateFormRules = (validateFieldsArray, country_region, t, initialize = false) => {
  const emptyInitials = {};

  if (initialize) {
    validateFieldsArray.forEach((element) => {
      if (element.initVal === undefined) {
        emptyInitials[element.name] = '';
      } else {
        emptyInitials[element.name] = element.initVal;
      }
    });
  }

  const yupSchema = Object.assign(
    {},
    ...validateFieldsArray.map((validationConfig) => {
      let schema = default_validations(validationConfig.name, t, country_region);
      if (validationConfig.required === true) {
        schema = schema.required(' ');
      } else {
        schema = schema.nullable();
      }
      return { [validationConfig.name]: schema };
    }),
  );

  return initialize
    ? { init: emptyInitials, validation: Yup.object().shape(yupSchema) }
    : Yup.object().shape(yupSchema);
};
