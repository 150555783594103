import React, { useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import { Box, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isFunction } from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import TableSortLabel from './TableSortLabel';

export function descendingComparator(a, b, orderBy) {
  if (orderBy(b) < orderBy(a)) {
    return -1;
  }
  if (orderBy(b) > orderBy(a)) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes, onSelectAllClick, numSelected, headCells, rowCount, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {onSelectAllClick && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
              color="primary"
            />
          </StyledTableCell>
        )}
        {headCells.map((headCell, index) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            style={{
              minWidth: headCell.minWidth,
              width: headCell.width,
              borderRight: '1px solid #DEDEDE',
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              align={headCell.align}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {index === 0 && rowCount ? `${headCell.label} (${rowCount})` : headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTableRow = withStyles(() => ({
  root: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'theme.palette.primary.light',
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    padding: '6px 9px 6px 9px',
    height: '26px',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
  },
  container: {
    maxHeight: (props) => props.maxHeight,
    boxShadow: 'inset 0px -8px 28px -20px rgba(0,0,0,0.30)',
    borderLeft: `1px solid ${theme.palette.border.main}`,
    borderTop: `1px solid ${theme.palette.border.main}`,
  },
  tableHead: {
    fontWeight: '500',
    padding: '9px',
    color: theme.palette.text.primary,
    border: 'none',
    background: theme.palette.secondary.dark,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function asOrderByLookup(columns, orderBy) {
  const column = columns.find((c) => c.id === orderBy);
  if (column) {
    return (isFunction(column.value) && column.value) || ((rows) => rows[orderBy]);
  }
  return () => null;
}

export const Table = (props) => {
  const {
    columns,
    rows,
    rowId,
    selected,
    setSelected,
    loading = false,
    href = null, // Always use rowId to complete href
  } = props;

  const classes = useStyles(props);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[rowId]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected && selected.indexOf(id) !== -1;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container} sx={{ maxHeight: '450px', overflow: 'auto' }}>
        <MuiTable size="small" stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            headCells={columns}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
            numSelected={selected && selected.length}
            onSelectAllClick={selected && handleSelectAllClick}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, asOrderByLookup(columns, orderBy)))
              .map((row, index) => {
                const isItemSelected = isSelected(row[rowId]);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    component={href !== null ? Link : 'tr'}
                    href={href !== null ? `${href}/${row[rowId]}` : null}
                    key={row[rowId]}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    {setSelected && (
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          id={labelId}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row[rowId])}
                          color="primary"
                        />
                      </StyledTableCell>
                    )}
                    {columns.map((column) => {
                      const value = (isFunction(column.value) && column.value(row))
                      || row[column.id];
                      return (
                        <StyledTableCell
                          data-cy={`cell-${index}-${column.id}`}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            borderRight: '1px solid #DEDEDE',
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {(column.format && column.format(value, row)) || value}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="30vh">
          <CircularProgress size={70} />
        </Box>
      )}
    </Paper>
  );
};
