const healthTarget_styles = {
  addedButton: {
    padding: "8px 8px 8px 30px",
    borderRadius: "3px",
    background: "#09bbd0",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    cursor: "pointer",
    marginRight: '20px',
    minWidth: '104px',
   maxWidth: '200px',
  },
  addedButtonEn: {
    padding: "8px 8px 8px 30px",
    borderRadius: "3px",
    background: "#09bbd0",
    color: "#FFFFFF",
    marginRight: '20px',
    fontFamily: "Roboto",
    fontSize: "14px",
    cursor: "pointer",
    minWidth: '104px',
    maxWidth: '200px',
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1080px",
    overflowX: "hidden",
    minWidth: "650px"
  },
  headerWrapper: {
    flexGrow: "0.2",
    padding: 0
  },
  cardWrapper: {
    display: "flex",
    height: "70px",
    borderTop: "1px solid  rgba(0, 0, 0, 0.03)",
    backgroundColor: "#f1f1f1"
  },
  container: {
    maxWidth: "1080px",
    overflowX: "hidden",
    minWidth: "560px",
    maxHeight: "90vh",
    minHeight: "500px",
    borderRadius: "10px",
    backgroundColor: "#fafafa"
  },
  cancelBtn: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "6px",
    paddingBottom: "6px"
  },
  widthSet: {
    width: "100%",
    maxWidth:"100%",
    minWidth: "560px"
  },
  editIcon: {
    position: "absolute",
    top: "8px"
  },
  header: {
    position: "relative",
    height: "140px",
    width: "1040px",
    padding: "2px 20px 0 20px",
    boxShadow: "0px 2px 2px 0 rgba(0, 0, 0, 0.06)",
    zIndex: "12"
  },
  closeModalBtn: {
    minWidth: "24px !important",
    height: "24px",
    backgroundColor: "transparent",
    position: "absolute",
    right: "-24px",
    top: "-4px"
  },

  xIconBtn: {
    position: "absolute",
    top: "15px",
    right: "13px",
    cursor: "pointer",
    zIndex: "13"
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    fontSize: "20px",
    lineHeight: "1.3",
    textAlign: "left",
    marginTop: "-58px",
    color: "#454545"
  },
  titleText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "1.14",
    textAlign: "left",
    color: "#454545",
    marginTop: "2px",
    maxWidth: "85vw",
  },
  formStyling: {
    marginTop: "24px"
  },
  keyboardDateTimePicker: {
    marginTop: "8px",
    borderRadius: "3px"
  },
  keyboardButtonProps: {
    paddingLeft: "0px",
    paddingRight: "0px",
    backgroundColor: "transparent"
  },
  pickerWrapper: { marginRight: "98px" },
  sampleDateTimeWrapper: {
    position: "absolute",
    top: "75px",
    marginBottom: "14px",
    display: "flex"
  },
  sampleDateTitle: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "14px",
    color: "#454545",
    textAlign: "left",
    lineHeight: "0.6"
  },
  timePickerInputProps: {
    backgroundColor: "transparent",
    paddingTop: "7.7px",
    paddingBottom: "7.7px",
    maxWidth: "107px",
    color: "#454545",
    fontSize: "14px",
    fontFamily: "Roboto"
  },
  toolBarSpan: {
    fontSize: "24px",
    fontWeight: "200",
    margin: 0,
    padding: 0,
    position: "absolute",
    marginLeft: "-22px",
    marginTop: "-5px"
  }
};

const btn_style = {
  messageTextBtn: {
    position: "relative",
    height: "35px",
    padding: "0",
    borderRadius: "2px",
    border: "solid 1px #09bbd0",
    backgroundColor: "#f8f8f8",
    color: "#09bbd0",
    marginTop: "7.5px"
  },
  messageTextWrapper: {
    display: "flex",
    height: "35px",
    width: "100%",
    flexDirection: "row",
    alignItems: "center"
  },
  textBtnPlus: {
    position: "absolute",
    top: "15.5px",
    left: "2px",
    fontFamily: "sans-serif",
    fontWeight: "300",
    lineHeight: "0",
    fontSize: "29px",
    margin: 0,
    padding: "0 5px",
    color: "#09bbd0"
  },
  textBtn: {
    fontSize: "14px",
    padding: "0px 10px 0 32px" //best match between firefox and chrome.
  },
  msgCancelDialogBtn: {
    height: "32px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.17",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#454545",
    padding: "0 10px",
    marginBottom: "10px",
    marginRight: "10px",
    alignSelf: "flex-end"
  },
  msgSendDialogBtnDisabled: {
    height: "32px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    padding: "0 10px",
    lineHeight: "1.17",
    letterSpacing: "normal",
    borderRadius: "2px",
    color: "rgb(149, 152, 154)",
    margin: "0 12px 10px 0",
    alignSelf: "flex-end",
    backgroundColor: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.06) 1px 2px 2px 0px"
  },
  msgSendDialogBtn: {
    height: "32px",
    fontFamily: "Roboto",
    fontSize: "14px",
    padding: "0 10px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.17",
    letterSpacing: "normal",
    borderRadius: "2px",
    backgroundColor: "#09bbd0",
    color: "#ffffff",
    boxShadow: "none",
    margin: "0 12px 10px 0",
    alignSelf: "flex-end"
  },
  btnWrapper: {
    position: "relative",
    width: "1080px",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    background: "#fafafa",
    textRendering: "optimizeLegibility",
    paddingTop: "5px",
    zIndex: 12
  }
};

const clinic_style = {
  contentWrapper: {
    flexGrow: "3",
    overflowX: "hidden",
    width: "calc(100% - 20px)",
    height: "100%",
    padding: "10px",
    background: "#fafafa"
  },
  textWrapper: {
    display: "flex",
    overflowY: "scroll",
    flexDirection: "row",
    flexWrap: "wrap",
    fontFamily: "Roboto",
    overflow: 'hidden'
  },
  //change 1080px elements to 980px
  // contentBlock: {
  //   margin: "10px ",
  //   width: "220px",
  //   height: "110px"
  // },
  //more space
  contentBlock: {
    margin: "10px ",
    width: "245px",
    height: "100px"
  },

  flexWrap: {
    display: "flex",
    flexDirection: "row",
    height: "50px"
  },
  titleLabel: {
    fontFamily: "Roboto",
    display: "block",
    fontSize: "14px",
    fontWeight: "500",

    color: "#95989a",
    padding: 0,
    marginBottom: "3px"
  },
  titleError: {
    fontFamily: "Roboto",
    display: "block",
    fontSize: "14px",
    fontWeight: "500",
    color: "#ff2b57",
    marginBottom: "3px"
  },
  fieldText: {
    height: "22px",
    borderRadius: "3px",
    padding: "0",
    width: "50px",
    border: "solid 1px #DEDEDE",
    fontFamily: "Roboto",
    fontSize: "14px",
    textAlign: "center",
    outline: "none"
  },
  textInputField: {
    padding: 0,
    margin: 0,
    minWidth: "65px",
    width: "65px",
    height: "32px",
    borderRadius: "3px",
    fontWeight: "normal",
    fontSize: "14px",
    marginTop: "0px",
    marginRight: "8px",
    color: "#454545"
  },

  textInputFieldError: {
    padding: 0,
    margin: 0,
    width: "65px",
    height: "32px",
    borderRadius: "3px",
    fontWeight: "normal",
    fontSize: "14px",
    marginTop: "0px",
    marginRight: "8px",
    backgroundColor: "#fde5ea",
    color: "#454545"
  },

  latest: {
    fontWeight: "normal",
    fontSize: "12px",
    color: "#95989a",
    margin: "5px 0 10px 0"
  },
  unitTitle: {
    fontWeight: "500",
    fontSize: "11px",
    color: "#95989a",
    margin: "0px 0 0px 0"
  },
  latestMin: {
    fontWeight: "normal",
    fontSize: "12px",
    color: "#95989a",
    margin: "5px 0 10px 0"
  },
  flexWrap: {
    display: "flex",
    flexDirection: "row",
    height: "41px"
  },
  titleLabel: {
    display: "block",
    fontSize: "14px",
    fontWeight: "500",
    color: "#09bbd0",
    padding: 0,
    marginBottom: "8px"
  },
  titleError: {
    display: "block",
    fontSize: "14px",
    fontWeight: "500",
    color: "#ff2b57",
    marginBottom: "8px"
  },
  unit: {
    display: "flex",
    color: "#95989a",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "13px",
    justifyContent: "center",
    marginTop: "15px",
    marginLeft: "0px",
    marginRight: "8px"
  },
  errorText: {
    position: "absolute",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "400",
    color: "#ff2b57",
    fontStyle: "italic",
    marginTop: "6px",
    marginLeft: "0"
  }
};

export { healthTarget_styles, btn_style, clinic_style };


 