import LoadingButton from '@mui/lab/LoadingButton';
import {
  Card, CardActions, CardContent, CardHeader
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Layout from '../components/Layout';
import Row from '../components/Row';
import FormTextField from '../components/form-inputs/FormTextField';
import Language from '../components/form-inputs/Language';
import PhoneNumber from '../components/form-inputs/PhoneNumber';
import { greyboxApiActions } from '../redux/api';

const UserInformation = () => {
  const { t, i18n } = useTranslation();
  const { user } = greyboxApiActions;
  const { data = [], isLoading, refetch } = user.get();
  const [updateUser, results] = user.update();

  useEffect(() => {
    if (results.isSuccess) {
      refetch();

      if (results.data.language !== i18n.resolvedLanguage) {
        i18n.changeLanguage(results.data.language);
        moment.locale(results.data.language);
        window.location.reload();
      }
    }
  }, [results]);

  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      email: data.email || '',
      mobile: data.phone || '',
      language: data.language || 'en',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      first_name: yup.string().required(t('required')),
      last_name: yup.string().required(t('required')),
      email: yup.string().email(),
      mobile: yup.string(),
      language: yup.string().required(t('required')),
    }),
    onSubmit: (values) => {
      // Prevent form sending (+1) and no actual number
      if (values.mobile.length < 7) {
        values.mobile = '';
      }

      updateUser({body: values }).then(() => {
        formik.setSubmitting(false);
      });
    },
  });

  if (isLoading) return null;

  return (
    <>
      <Layout width="md">
        <Card sx={{ m: 1 }}>
          <CardHeader
            title={t('Your Information')}
          />
          <form onSubmit={formik.handleSubmit}>
            <CardContent>
              <Row>
                <FormTextField formik={formik} name="first_name" label={t('First name')} required />
                <FormTextField formik={formik} name="last_name" label={t('Last name')} required />
              </Row>
              <Row>
                <FormTextField formik={formik} name="email" label={t('Email')} />
                <PhoneNumber formik={formik} name="mobile" label={t('Mobile')} />
              </Row>
              <Row>
                <Language formik={formik} name="language" />
              </Row>
            </CardContent>
            <CardActions>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                sx={{ ml: 'auto' }}
                disabled={formik.isSubmitting || !formik.dirty}
                loading={formik.isSubmitting}
              >
                {t('Save')}
              </LoadingButton>
            </CardActions>
          </form>
        </Card>
      </Layout>
    </>
  );
};

export default UserInformation;
