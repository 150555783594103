import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, MenuItem, FormControlLabel, Checkbox,
  Button, Box, Typography, Tooltip, TextField, IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import DialogTitle from '../DialogTitle';
import DatePicker from '../form-inputs/DatePicker';
import FormTextField from '../form-inputs/FormTextField';
import FormSelect from '../form-inputs/FormSelect';
import { greyboxApiActions } from '../../redux/api';
import Row from '../Row';
import { LANGUAGES } from '../../../locales/languages';
import { InformationMessageApiType, InformationMessageFormType, parseFormToApi } from '../../types';
import RichTextEditor from '../RichTextEditor';
import Recurrence from '../form-inputs/recurrence/Recurrence';

const parseTextLang = (data) => {
  if (!data) {
    return [];
  }

  const texts = [];
  LANGUAGES.forEach((language) => {
    if (data[`text_${language.code}`]) {
      texts.push({
        text: data[`text_${language.code}`],
        language: language.code,
      });
    }
  });

  return texts;
};

const NewTextSection = ({ formik, index }) => {
  const { t } = useTranslation();

  const others = formik.values.texts.filter((_, i) => i !== index);
  const availableLanguages = LANGUAGES.filter(
    (language) => !others.find((text) => text.language === language.code),
  );

  const newTextForm = useFormik({
    initialValues: {
      text: formik.values.texts[index]?.text || '',
      language: formik.values.texts[index]?.language || availableLanguages[0].code,
    },
    validationSchema: Yup.object({
      text: Yup.string().required(t('Required')),
    }),
    onSubmit: () => {
      // do nothing
    },
  });

  useEffect(() => {
    formik.setFieldValue(`texts[${index}]`, newTextForm.values);
  }, [newTextForm.values]);

  return (
    <Box sx={{ mt: 1 }}>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        {index > 0 && (
          <Tooltip title={t('Remove section')}>
            <IconButton
              sx={{ ml: 'auto' }}
              onClick={() => formik.setFieldValue('texts', others)}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Row>
        <FormSelect
          name="language"
          formik={newTextForm}
          label={t('Language')}
          disabled={formik.values.texts[index]?.language === 'en'}
        >
          {availableLanguages.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              {t(language.name)}
            </MenuItem>
          ))}
        </FormSelect>
      </Row>
      <Box sx={{ mx: 1 }}>
        <RichTextEditor
          defaultValue={newTextForm.initialValues.text}
          onChange={(content) => newTextForm.setFieldValue('text', content.markdown)}
          placeholder={t('Write your notice here')}
        />
      </Box>
    </Box>
  );
};

type InformationMessageDialogProps = {
  open: boolean;
  handleClose: () => void;
  messageID?: number;
};

const InformationMessageDialog = (props: InformationMessageDialogProps) => {
  const {
    open, handleClose, messageID,
  } = props;
  const { clinic } = useSelector((state) => state.clinic);
  const { t } = useTranslation();
  const { informationMessage } = greyboxApiActions;
  const [addMessage] = informationMessage.add();
  const [deleteMessage, deleteRes] = informationMessage.delete();
  const [showRecurrence, setShowRecurrence] = React.useState(false);
  const [updateMessage] = informationMessage.update();
  const { data, isLoading } = informationMessage.get(messageID, { skip: !messageID }) as {
    data: InformationMessageApiType; isLoading: boolean;
  };
  const [dtstart, setDtstart] = useState(moment());
  const [dtend, setDtend] = useState(moment().add(1, 'hour'));

  const defaultText = {
    language: 'en',
    text: '',
  };

  const formik = useFormik({
    initialValues: {
      key: data?.key || '',
      type: data?.type || 'information',
      texts: data ? parseTextLang(data) : [defaultText],
      dtstart,
      dtend,
      recurrences: data?.recurrences || '',
      dismissible: data?.dismissible || false,
      active: data?.active || true,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      type: Yup.string().required(t('Required')),
      texts: Yup.array().of(Yup.object()),
      dtstart: Yup.string().nullable(),
      dtend: Yup.string().nullable(),
      recurrences: Yup.string(),
      dismissible: Yup.boolean(),
      active: Yup.boolean(),
    }),
    onSubmit: async (values: InformationMessageFormType) => {
      const body = {
        ...parseFormToApi(values, showRecurrence),
        clinic: clinic.id,
      };

      if (messageID) {
        await updateMessage({ id: messageID, body, feedback: { success: t('Notice updated') } });
      } else {
        await addMessage({ body });
      }
      handleClose();
    },
  });

  useEffect(() => {
    if (data) {
      setDtstart(moment(data.dtstart));
      setDtend(moment(data.dtend));
    }
  }, [data]);

  useEffect(() => {
    formik.setFieldValue('dtstart', dtstart);
    formik.setFieldValue('dtend', dtend);
  }, [dtstart, dtend]);

  useEffect(() => {
    if (formik.initialValues.recurrences !== '') {
      setShowRecurrence(true);
    }
  }, [formik.initialValues.recurrences]);

  if (isLoading) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={handleClose}>
        {messageID ? t('Edit Notice') : t('Add Notice')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Row>
            <FormSelect name="type" formik={formik} label={t('Type')} required>
              <MenuItem value="information">{t('Information')}</MenuItem>
              <MenuItem value="alert">{t('Alert')}</MenuItem>
            </FormSelect>
            <FormTextField name="key" formik={formik} label={t('Identifier')} required />
          </Row>
          <Row>
            <DatePicker
              time
              formik={formik}
              name="dtstart"
              label={t('start')}
            />
            <DatePicker
              time
              minDate={moment(formik.values.dtstart)}
              name="dtend"
              formik={formik}
              label={t('End')}
            />
          </Row>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              sx={{ m: 0 }}
              checked={formik.values.dismissible}
              onChange={() => formik.setFieldValue('dismissible', !formik.values.dismissible)}
              control={<Checkbox name="dismissible" />}
              label={t('Dismissible')}
            />
            <FormControlLabel
              sx={{ m: 0 }}
              checked={formik.values.active}
              onChange={() => formik.setFieldValue('active', !formik.values.active)}
              control={<Checkbox name="active" />}
              label={t('Active')}
            />
          </Box>
          <Button
            sx={{ ml: 1, mb: 1 }}
            onClick={() => setShowRecurrence(!showRecurrence)}
            variant="outlined"
          >
            {showRecurrence ? t('Remove recurrence') : t('Add recurrence')}
          </Button>
          {showRecurrence && (
            <Box border={(theme) => `1px solid ${theme.palette.grey[300]}`} borderRadius={2} p={1}>
              <Recurrence
                onChange={(recurrence) => formik.setFieldValue('recurrences', recurrence)}
                rule={formik.values.recurrences}
              />
            </Box>
          )}
          {formik.values.texts.map((text, index) => (
            <NewTextSection key={index} index={index} formik={formik} />
          ))}
          {formik.values.texts.length < LANGUAGES.length && (
            <Tooltip title={t('Add another language')}>
              <Button
                variant="outlined"
                sx={{ m: 1, width: '98%' }}
                onClick={() => {
                  formik.setFieldValue('texts', [...formik.values.texts, {
                    text: '',
                  }]);
                }}
              >
                <AddIcon />
              </Button>
            </Tooltip>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('Cancel')}
          </Button>
          {
            messageID && (
              <LoadingButton
                onClick={() => deleteMessage(messageID).then(() => handleClose())}
                loading={deleteRes.isLoading}
                data-cy="info-message-delete"
                variant="contained"
                color="error"
              >
                {t('Delete')}
              </LoadingButton>
            )
          }
          <LoadingButton
            type="submit"
            variant="contained"
            data-cy="info-message-submit"
            loading={formik.isSubmitting}
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InformationMessageDialog;
