import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  Button,
  Dialog,
  CardHeader,
  Typography,
  DialogActions,
  FormHelperText,
  TextField,
} from '@mui/material';
import { healthTarget_styles, btn_style, clinic_style } from './addLabRes_style';
import DialogTitle from '../../DialogTitle';

// this return the Cancel and Save btns at the bottom of modal.
function DisplayActionBtns(props) {
  const {
    toggleModal, handleSubmit, handleReset, err, validDate,
  } = props;
  const { i18n, t } = useTranslation();
  return (
    <DialogActions>
      <Button
        onClick={() => {
          handleReset();
          toggleModal(true);
        }}
        color="primary"
        style={btn_style.msgCancelDialogBtn}
        data-cy="cancelBtn"
      >
        {t('Cancel')}
      </Button>
      <Button
        color="primary"
        autoFocus
        onClick={() => {
          handleSubmit();
          toggleModal(false);
        }}
        disabled={err || !validDate}
      >
        {t('Save')}
      </Button>
    </DialogActions>
  );
}

// this return the form inputs AND validates
const InputGroup = (props) => {
  const {
    configArr, form, setVal, latest,
  } = props;
  const isValid = true;
  const { i18n, t } = useTranslation();

  // validation for min and max
  // needs to be a int or float, does not allow 10.0.something
  //
  const validateType = (event, name) => {
    // trim string and replace commas
    const trimmed = event.target.value.replace(',', '.').trim();

    // unari validation +StrVal
    const unariVal = +trimmed;

    if (unariVal !== '') {
      if (Number.isNaN(unariVal)) {
        form.values[name].isValValid = false;
      }

      if (unariVal) {
        form.values[name].isValValid = true;
      }
    }

    if (unariVal === 0) {
      form.values[name].isValValid = true;
    }
  };

  // handle values for both min max fields
  const handleChange = (event, name) => {
    if (form.values[name].value.length < 8) {
      form.values[name].value = event.target.value.replace(',', '.').trim();

      validateType(event, name);
      setVal({ ...form.values });
    }
  };

  return (
    <div style={clinic_style.textWrapper}>
      {configArr.map((el, idx) => (
        <div style={clinic_style.contentBlock} key={idx}>
          <label
            htmlFor={el.short_code}
            style={isValid ? clinic_style.titleLabel : clinic_style.titleError}
          >
            {i18n.resolvedLanguage === 'en' ? el.name_en : el.name_fr}

            <div style={clinic_style.flexWrap}>
              <TextField
                data-cy={`${el.short_code}_val`}
                placeholder="----"
                value={form.values[el.short_code].value}
                name={el.short_code}
                onChange={(e, name) => handleChange(e, `${el.short_code}`)}
                error={!form.values[`${el.short_code}`].isValValid}
                variant="outlined"
                margin="dense"
                InputProps={{
                  style: !isValid ? clinic_style.textInputFieldError : clinic_style.textInputField,
                }}
              />
              <span style={clinic_style.unit}>{el.unit ? el.unit : '----'}</span>

            </div>
            {!form.values[`${el.short_code}`].isValValid ? (
              <FormHelperText
                id={`M_${el.short_code}_helper`}
                data-cy={`M_${el.short_code}_helper`}
                style={clinic_style.errorDateSelect}
              >
                {t('Invalid format')}
              </FormHelperText>
            ) : (
              <div style={clinic_style.latest} data-cy={`M_${el.short_code}_init`}>
                {' '}
                {t('Latest Targets')}
                :
                {'  '}
                <span style={clinic_style.latestMin} data-cy={`M_${el.short_code}_initMin`}>
                  {latest[`M_${el.short_code}`] ? (
                    <span style={{}}>
                      {' '}
                      {latest[`M_${el.short_code}`].target_min}
                      {' '}
                    </span>
                  ) : (
                    '----'
                  )}
                  {' '}
                  Min
                  {' '}
                  {latest[`M_${el.short_code}`] ? (
                    <span style={{}}>
                      {' '}
                      {latest[`M_${el.short_code}`].target_max}
                    </span>
                  ) : (
                    '----'
                  )}
                  {' '}
                  Max
                  {' '}
                </span>
                {' '}
              </div>
            )}
          </label>
        </div>
      ))}
    </div>
  );
};

const AddLabThreshWrapper = (props) => {
  const {
    configArr,
    form,
    setVal,
    handleSubmit,
    handleReset,
    handleDateChange,
    selectedDate,
    validDate,
    err,
    latest,
  } = props;

  const [open, setOpen] = useState(false);
  const { edit } = useSelector((state) => state.permissions);
  const { i18n, t } = useTranslation();

  // resets is a boolean if you want to close and keep value or close and reset values.
  const handleOpen = (resets) => {
    setOpen(!open);
    if (resets) {
      handleReset();
      handleDateChange(moment());
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpen()}
        disabled={!edit}
        sx={{ mr: 1 }}
      >
        {t('Add Lab Results')}
      </Button>
      {open && (
        <Dialog
          open={open}
          onBackdropClick={handleOpen}
          disableEnforceFocus
          maxWidth={false}
          cy-test="Dialog"
        >
          <DialogTitle onClose={handleOpen}>
            {t('Add Lab Results')}
          </DialogTitle>
          <div style={clinic_style.contentWrapper}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Date"
                name="time"
                disableFuture
                value={moment(selectedDate)}
                onChange={(date) => handleDateChange(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <InputGroup configArr={configArr} form={form} setVal={setVal} latest={latest} />
          </div>
          <DisplayActionBtns
            toggleModal={handleOpen}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            validDate={validDate}
            dirty={form.dirty}
            err={err}
          />
        </Dialog>
      )}
    </>
  );
};

export default AddLabThreshWrapper;
