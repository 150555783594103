import {
  Button, Dialog, DialogContent, TextField, Typography,
  CircularProgress,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import DialogTitle from '../DialogTitle';
import { greyboxApiActions } from '../../redux/api';

const ArchiveModal = (props) => {
  const {
    handleClose, open, uuid, firstName,
  } = props;
  const location = useLocation();
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  const { account } = greyboxApiActions;
  const [archive, results] = account.delete();

  const handleArchive = () => {
    // Redirect to dashboard if we are on the patient profile page
    if (location.pathname.includes('patient-profile')) {
      archive(uuid).then(() => {
        history.push('/dashboard');
      });
    } else {
      archive(uuid);
    }
  };

  const handleChange = (event) => {
    if (!disabled) {
      if (firstName !== event.target.value) {
        setDisabled(true);
      }
    } else if (firstName === event.target.value) {
      setDisabled(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle onClose={handleClose}>
        {t('Archive Patient')}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t('Are you sure you want to archive this account')}
          {' '}
          {`${t('To confirm')},`}
          {' '}
          {t('please type the patient first name below')}

        </Typography>
        <TextField
          data-cy="archive-first-name"
          placeholder={t('First Name')}
          sx={{ mt: 1 }}
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={handleArchive}
          loading={results.isLoading}
          data-cy="archive-submit"
          disabled={disabled}
        >
          {t('Archive')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveModal;
