import {
  Box,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AboveThreshold,
  BelowThreshold,
  OutdatedMeasurement,
  OutdatedMeasurementRed,
} from '../tk-ui';

const useStyles = makeStyles((theme) => ({
  outsideThresholds: {
    color: theme.palette.error,
  },
}));

const InfoIcon = (isOutdated, isOutsideThresholds) => {
  if (isOutdated) {
    if (isOutsideThresholds) {
      return <OutdatedMeasurementRed style={{ transform: 'translateY(4px) translateX(3px)' }} />;
    }
    return <OutdatedMeasurement style={{ transform: 'translateY(4px) translateX(3px)' }} />;
  }
  if (isOutsideThresholds) {
    return isOutsideThresholds.bellow ? <BelowThreshold /> : <AboveThreshold />;
  }
  return null;
};

const VitalCell = (props) => {
  const {
    timestamp, outsideThresholds, children, customTooltip = '',
  } = props;
  const isOutdated = moment().diff(timestamp, 'hour') > 24;
  const isOutsideThresholds = outsideThresholds.bellow || outsideThresholds.above;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const handleClassName = () => (
    isOutsideThresholds ? classes.outsideThresholds : null
  );

  let text = '';

  if (isOutdated) {
    text += t('Outdated data');
    if (isOutsideThresholds) {
      text += ` ${t('and')} `;
    }
  }

  if (outsideThresholds.bellow) {
    text += isOutdated ? t('below the threshold') : t('Below the threshold');
  } else if (outsideThresholds.above) {
    text += isOutdated ? t('above the threshold') : t('Above the threshold');
  }

  return (
    <Tooltip
      title={(
        <span>
          {timestamp ? (
            moment(timestamp).locale(i18n.resolvedLanguage).format('LLL')
          ) : (
            customTooltip
          )}
          <br />
          {text}
        </span>
      )}
      disableHoverListener={children === '---'}
      arrow
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={handleClassName()}
      >
        {children}
        {InfoIcon(isOutdated, isOutsideThresholds)}
      </Box>
    </Tooltip>
  );
};

export default VitalCell;
