import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import {
  Select, InputBase, MenuItem, FormControl, InputLabel,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import OutlinedInput from '@mui/material/OutlinedInput';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 3,
    position: 'relative',
    backgroundColor: '#FAFAFA',
    border: '1px solid rgba(0,0,0,0.2)',
    fontSize: 14,
    padding: '7px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      border: '2px solid #09bbd0',
    },
  },
}))(InputBase);

export function Selects(props) {
  const {
    disabled,
    selectionArr = [],
    unit = '',
    name,
    onChange,
    label = null,
    values = '',
    styleBase = {
      width: '145px', height: '32px', fontSize: '14px', textAlign: 'left',
    },
    styleItem = {
      width: '145px',
      height: '32px',
      background: '#FAFAFA',
      fontSize: '14px',
      textAlign: 'left',
    },
  } = props;
  const [val, setVal] = useState(values);
  const { t } = useTranslation();

  const handleChanges = (event) => {
    setVal(event.target.value);
  };

  useEffect(() => {
    onChange && onChange(val);
  }, [val]);

  return (
    <div style={{ width: '145px', height: '32px' }}>
      <Select
        data-cy="selectWrapper"
        displayEmpty
        attrib="true"
        value={val}
        label={label}
        variant="outlined"
        onChange={handleChanges}
        name={name}
        inputProps={{ 'data-cy': 'posologySelect' }}
        style={styleBase}
        input={<BootstrapInput />}
      >
        <MenuItem value="" attrib="true" data-cy="itemSelect">
          <div style={{ color: 'grey', fontSize: '14px', textAlign: 'left' }}>{t('Select')}</div>
        </MenuItem>
        {selectionArr.map((choice, idx) => (
          <MenuItem
            value={choice}
            key={idx}
            attrib="true"
            data-cy={'item' + idx}
            style={{
              width: '145px',
              height: '32px',
              background: '#FAFAFA',
              fontSize: '14px',
              textAlign: 'left',
            }}
          >
            <span style={styleItem}>{choice}</span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: '40',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const SelectWithLabel = (props) => {
  const classes = useStyles();

  const {
    selectionArr = [],
    disabled,
    unit = '',
    values = '',
    name,
    onChange,
    heightVal = '52px',
    label = null,
    width = '300px',
    styleBase = {},
    styleItem = {
      fontSize: '14px',
    },
  } = props;
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [val, setVal] = useState(values);
  const { t } = useTranslation();

  const handleChanges = (event) => {
    setVal(event.target.value);
  };

  useEffect(() => {
    onChange && onChange(val);
  }, [val]);

  return (
    <form className={classes.root} autoComplete="off">
      <FormControl variant="outlined" className={classes.formControl} style={{ width: width, height: heightVal }}>
        <InputLabel ref={inputLabel} htmlFor="outlined-Select-simple">
          {label}
        </InputLabel>
        <Select
          value={val}
          style={{ width: width, height: heightVal }}
          onChange={handleChanges}
          input={<OutlinedInput labelWidth={labelWidth} name="Select" id="outlined-Select-simple" />}
        >
          <MenuItem value="" style={styleItem}>
            {t('Select')}
          </MenuItem>
          {selectionArr.map((choice, idx) => (
            <MenuItem
              value={choice}
              key={idx}
              attrib="true"
              data-cy={'item' + idx}
              style={styleItem}
            >
              <span style={styleItem}>{choice}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
};
