import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem, Switch,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import { QuestionnaireManagementClinicType } from '../../../types';
import Row from '../../Row';
import {
  FormSelect, FormTextField, LocaleWrapper, Diagnosis,
  Recurrence, RichFormText, RoleTypes,
} from '../../form-inputs';
import { parseQuestionnaireValuesForPost } from './utils';
import { RootState } from '../../../redux/store';
import DeleteDialog from '../../DeleteDialog';

const QuestionnaireEdit = ({ data }: {data: QuestionnaireManagementClinicType}) => {
  const { t } = useTranslation();
  const { questionnaireManagementClinic } = greyboxApiActions;
  const { id } = useParams<{ id: string }>();
  const [updateQuestionnaire] = questionnaireManagementClinic.update();
  const [deleteQuestionnaire] = questionnaireManagementClinic.delete();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [showRecurrence, setShowRecurrence] = React.useState(false);
  const readOnly = !(data?.permissions.includes('change_questionnaireclinic_management'));
  const history = useHistory();

  const handleDelete = async () => {
    await deleteQuestionnaire(id);
    history.goBack()
  };

  const formik = useFormik({
    initialValues: {
      name_en: data?.questionnaire?.name_en,
      name_fr: data?.questionnaire?.name_fr,
      name_de: data?.questionnaire?.name_de,
      name_es: data?.questionnaire?.name_es,
      name_it: data?.questionnaire?.name_it,
      name_pt: data?.questionnaire?.name_pt,
      description_en: data?.questionnaire?.description_en,
      description_fr: data?.questionnaire?.description_fr,
      description_de: data?.questionnaire?.description_de,
      description_es: data?.questionnaire?.description_es,
      description_it: data?.questionnaire?.description_it,
      description_pt: data?.questionnaire?.description_pt,
      has_score: data?.questionnaire?.has_score || false,
      max_score: data?.questionnaire?.max_score || false,
      score_type: data?.questionnaire?.score_type,
      on_demand: data?.on_demand || false,
      active: data?.active || false,
      is_dashboard: data?.is_dashboard || false,
      type: data?.questionnaire?.type,
      diagnoses: data?.diagnoses || [],
      recurrences: data?.recurrences,
      role_acc_type: data?.role_acc_type || [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      texts: Yup.array().of(
        Yup.object({
          name: Yup.string().required(t('Required')),
          description: Yup.string(),
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      // keep only modified values
      const modifiedValues = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== formik.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      const body = parseQuestionnaireValuesForPost(modifiedValues, data.questionnaire.id);

      updateQuestionnaire({ id, body })
        .then(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.recurrences !== '') {
      setShowRecurrence(true);
    }
  }, [data]);

  return (
    <>
      <Box width="100%">
        <Box>
          <FormControlLabel
            control={<Switch disabled={readOnly} checked={formik.values.active} onChange={formik.handleChange} name="active" />}
            label={formik.values.active ? t('Active') : t('Inactive')}
          />
        </Box>
        <LocaleWrapper name="name">
          <FormTextField disabled={readOnly} name="name" formik={formik} label={t('Name')} sx={{ width: '100%' }} />
        </LocaleWrapper>
        <LocaleWrapper name="description">
          <RichFormText formik={formik} name="description" label={t('Description')} readOnly={readOnly} />
        </LocaleWrapper>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <FormControlLabel
              sx={{ mx: 1 }}
              control={<Checkbox disabled={readOnly} checked={formik.values.on_demand} onChange={formik.handleChange} name="on_demand" />}
              label={t('On Demand')}
            />
            <FormControlLabel
              sx={{ mx: 1 }}
              control={<Checkbox disabled={readOnly} checked={formik.values.is_dashboard} onChange={formik.handleChange} name="is_dashboard" />}
              label={t('Show on Dashboard')}
            />
            <FormControlLabel
              sx={{ mx: 1 }}
              control={<Checkbox disabled={readOnly} checked={formik.values.has_score} onChange={formik.handleChange} name="has_score" />}
              label={t('Has Score')}
            />
          </Row>

          {formik.values.has_score && (
            <Row>
              <FormTextField disabled={readOnly} formik={formik} name="max_score" label={t('Max Score')} />
              <FormSelect disabled={readOnly} formik={formik} name="score_type" label={t('Score Type')}>
                <MenuItem value="none">{t('None')}</MenuItem>
                <MenuItem value="sum">{t('Sum')}</MenuItem>
                <MenuItem value="ahp">{t('Analytic Hierarchy Process')}</MenuItem>
              </FormSelect>
            </Row>
          )}
          <Row>
            <FormSelect disabled={readOnly} formik={formik} name="type" label={t('Type')}>
              <MenuItem value="standard">{t('Standard')}</MenuItem>
              <MenuItem value="logbook">{t('Logbook')}</MenuItem>
            </FormSelect>
          </Row>
          <Row>
            <RoleTypes
              formik={formik}
              disabled={readOnly}
              name="role_acc_type"
            />
            <Diagnosis
              formik={formik}
              label={t('Diagnosis')}
              name="diagnoses"
            />
          </Row>
          <Button
            sx={{ ml: 1, mb: 1 }}
            disabled={readOnly}
            onClick={() => setShowRecurrence(!showRecurrence)}
            variant="outlined"
          >
            {showRecurrence ? t('Remove recurrence') : t('Add recurrence')}
          </Button>
          {showRecurrence && (
            <Box border={(theme) => `1px solid ${theme.palette.grey[300]}`} borderRadius={2} p={1}>
              <Recurrence
                onChange={(recurrence) => formik.setFieldValue('recurrences', recurrence)}
                rule={formik.values.recurrences}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Button
              color="error"
              variant="contained"
              disabled={readOnly}
              onClick={() => setDeleteOpen(true)}
            >
              {t('Delete')}
            </Button>
            <LoadingButton
              variant="contained"
              loading={formik.isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              disabled={!formik.dirty}
            >
              {t('Save')}
            </LoadingButton>
          </Box>
        </form>
      </Box>
      <DeleteDialog open={deleteOpen} handleClose={() => setDeleteOpen(false)} deleteItem={handleDelete} />
    </>
  );
};

export default QuestionnaireEdit;
