import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import QuestionnaireEdit from './QuestionnaireEdit';
import BackButton from '../../BackButton';
import QuestionsTable from './questions/QuestionsTable';
import { greyboxApiActions } from '../../../redux/api';
import { QuestionnaireManagementClinicType } from '../../../types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {
    children, value, index,
  } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ p: 1 }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && (
        <Box sx={{ pt: 1, width: '100%' }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

const QuestionnaireEditTabs = () => {
  const [value, setValue] = React.useState(0);
  const { questionnaireManagementClinic } = greyboxApiActions;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  const { data, isLoading } = questionnaireManagementClinic.get(id) as {
    data: QuestionnaireManagementClinicType;
    isLoading: boolean;
  };

  React.useEffect(() => {
    const path = location.pathname;
    const tab = path.includes('/questions') ? 1 : 0;
    setValue(tab);
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // Update the URL based on the tab selected
    if (newValue === 1) {
      history.push(`${id}${'/questions'}`);
    } else {
      const path = location.pathname.split('/');
      path.pop();
      history.push(path.join('/'));
    }
  };

  const getPreviousPath = () => {
    const path = location.pathname.split('/');
    path.pop();
    if (value === 1) {
      path.pop();
    }

    return path.join('/');
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Box>
        <BackButton sx={{ mb: 1 }} to={getPreviousPath()} />
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {`${data.questionnaire.name}`}
        </Typography>
      </Box>
      <Paper sx={{ p: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="details" key="details" />
            <Tab label="questions" key="questions" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <QuestionnaireEdit data={data} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <QuestionsTable questionnaire={data.questionnaire} />
        </CustomTabPanel>
      </Paper>
    </Box>
  );
};

export default QuestionnaireEditTabs;
