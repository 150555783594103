import ClearIcon from '@mui/icons-material/Clear';
import {
  Box, IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MessageTextField = ({
  isFetching, attachedFile, setAttachedFile, currentMessage, setCurrentMessage,
}) => {
  const { t } = useTranslation();

  const handleRemoveFile = () => {
    setAttachedFile(null);
  };

  return (
    <TextField
      fullWidth
      multiline
      size="small"
      sx={{ bgcolor: (theme) => theme.palette.background.paper }}
      disabled={isFetching}
      autoFocus
      placeholder={!attachedFile && t('Enter your message')}
      value={currentMessage}
      onChange={(e) => setCurrentMessage(e.target.value)}
      InputProps={{
        style: { height: attachedFile ? '56px' : 'auto' },
        startAdornment: attachedFile && (
          <InputAdornment position="start">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.grey[200],
                borderRadius: 1,
                m: 1,
                padding: 0.5,
              }}
            >
              <Typography variant="body2" color="textSecondary" noWrap>
                {`${t('Attached file')}: ${attachedFile.name}`}
              </Typography>
              <IconButton
                size="small"
                color="secondary"
                onClick={handleRemoveFile}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MessageTextField;
