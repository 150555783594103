import React, { useEffect, useState, useRef } from 'react';
import {
  Button, Box, Dialog, DialogActions, DialogContent, FormControlLabel,
  Checkbox, LinearProgress, Typography,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker, TimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import FullCalendar from '@fullcalendar/react';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import iCalendarPlugin from '@fullcalendar/icalendar';
import { useParams } from 'react-router-dom';
import { greyboxApiActions, domainConfigs } from '../../../redux/api';
import Recurrence from '../../form-inputs/recurrence/Recurrence';
import Row from '../../Row';
import {
  FormTextField, DatePicker, Duration, QuestionnaireSelect,
} from '../../form-inputs';
import DialogTitle from '../../DialogTitle';

const renderEventContent = (eventInfo) => (
  <Box sx={{
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    height: '100%',
  }}
  >
    <Typography
      variant="caption"
      sx={{ mx: 0.5 }}
      noWrap
    >
      {eventInfo.timeText}
      {' '}
      {eventInfo.event.title}
    </Typography>
  </Box>
);

type EventType = {
  id: string;
  label: string;
};

type AddEventModalProps = {
  open: boolean;
  handleClose: () => void;
  refetchEvents: () => void;
  eventId?: number;
  date: Moment;
  selectedType?: EventType;
  isAllDay: boolean;
};

const AddEventModal = (props: AddEventModalProps) => {
  const {
    open,
    handleClose,
    refetchEvents,
    selectedType,
    date,
    eventId,
    isAllDay,
  } = props;
  const { t, i18n } = useTranslation();
  const { calendar, calendarEvent } = greyboxApiActions;
  const [addEvent] = calendar.add();
  const { uuid } = useParams();
  const { clinic } = useSelector((state) => state.clinic);
  const [deleteEvent] = calendarEvent.delete();
  const [updateEvent] = calendarEvent.update();
  const [showRecurrence, setShowRecurrence] = useState(false);
  const calendarSelector = calendar.list({ owner: uuid, clinic: clinic.id });
  let { data, isLoading } = calendarEvent.get(eventId, { skip: !eventId });
  const calendarRef = useRef(null);

  const EVENT_TYPES_LABELS = {
    Event: t('Event'),
    QuestionnaireEvent: t('Questionnaire'),
    OnlineMeetingEvent: t('Online Meeting'),
  };


  useEffect(() => {
    if (data && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(data['start_datetime']);
    }
  }, [data, calendarRef.current]);


  const handleChangeDate = (date: Date | null) => {
    // only change date keeping time
    if (date) {
      const newDate = moment(date)
        .hour(formik.values.start_datetime.hour()).minute(formik.values.start_datetime.minute());
      formik.setFieldValue('start_datetime', newDate);

      if (!formik.values.all_day) {
        formik.setFieldValue('end_datetime', moment(newDate).add(30, 'minutes'));
      }
      else {
        formik.setFieldValue('end_datetime', moment(newDate).endOf('day'));
      }

      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(newDate.toDate());
    }
  };

  const handleChangeTime = (time: Date | null) => {
    // change time keeping date
    if (time) {
      const newDate = moment(formik.values.start_datetime).hour(moment(time).hour()).minute(moment(time).minute());
      formik.setFieldValue('start_datetime', newDate);

      if (!formik.values.all_day) {
        formik.setFieldValue('end_datetime', moment(newDate).add(30, 'minutes'));
      }
      else {
        formik.setFieldValue('end_datetime', moment(newDate).endOf('day'));
      }
    }
  };

  const icsUrl = `https://${domainConfigs[window.location.hostname]}/calendar/${calendarSelector.data[0].secret_key}/takecare.ics`;

  let start_datetime = moment(date).minute(Math.round(moment().minute() / 30) * 30);
  let end_datetime = moment(start_datetime).add(30, 'minutes');

  if (data && data.start_datetime) {
    start_datetime = moment(data.start_datetime);
    end_datetime = moment(data.end_datetime);
  }



  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      description: data?.description || '',
      all_day: data?.all_day === undefined ? isAllDay : data?.all_day,
      calendar: data?.calendar || '',
      start_datetime: start_datetime,
      end_datetime: end_datetime,
      join_url: data?.join_url || '',
      resourcetype: data?.resourcetype || selectedType.id,
      questionnaire: '',
      recurrences: data?.recurrences || '',
      reminder: data?.reminder ? moment.duration(data.reminder) : moment.duration('PT15M'),
      location: data?.location || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t('Required')),
      start_datetime: Yup.date().required(t('Required')),
      end_datetime: Yup.date().required(t('Required')).when('start_datetime', (start, schema) => (
        start && schema.min(start, t('End date must be after start date'))
      )),
    }),
    onSubmit: async (values) => {
      const calendarKey = calendarSelector.data[0].id;
      const body = { ...values };

      body.calendar = calendarKey;
      if (body.questionnaire) {
        body.questionnaire = {
          id: values.questionnaire,
          clinic: clinic.id,
        };
      }

      if (body.resourcetype === 'Event') {
        delete body.calendar;
        delete body.resourcetype;
      }

      if (body.all_day) {
        body.start_datetime = moment(body.start_datetime).startOf('day');
        body.end_datetime = moment(body.end_datetime).endOf('day');
      }

      if (eventId) {
        await updateEvent({ id: eventId, body });
      } else {
        await addEvent({ id: `${calendarKey}/event`, body });
      }
      refetchEvents();
      handleClose();
    },
  });


  if (isLoading || calendarSelector.isLoading) {
    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <LinearProgress />
        <DialogContent sx={{ height: '300px' }} />
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle onClose={handleClose}>
        {`${eventId ? t('Edit event') : t('Add event')} ${selectedType.id !== 'Event' ? `(${t(EVENT_TYPES_LABELS[formik.values.resourcetype])})` : ''}`}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ p: 0 }}>
          <Box display="flex">
            <Box flexGrow={1} m={1}>
              <Row>
                <FormTextField name="name" formik={formik} label={t('Name')} required />
                <FormTextField name="description" formik={formik} label={t('Description')} />
              </Row>
              <Duration formik={formik} name="reminder" label={t('Reminder')} />
              <Box width="100%" sx={{ p: 1 }}>
                {formik.values.resourcetype === 'OnlineMeetingEvent' ? (
                  <FormTextField
                    name="join_url"
                    required
                    type="url"
                    formik={formik}
                    label={t('Online meeting link')}
                    sx={{ width: '97.5%' }}
                  />
                ) : (
                  <FormTextField
                    name="location"
                    formik={formik}
                    label={t('Location')}
                    sx={{ width: '97.5%' }}
                  />
                )}
              </Box>
              {formik.values.resourcetype === 'QuestionnaireEvent' && (
                <Box width="100%" sx={{ p: 1 }}>
                  <QuestionnaireSelect
                    name="questionnaire"
                    formik={formik}
                    sx={{ width: '97.5%' }}
                    questionnaireId={data?.questionnaire?.id}
                  />
                </Box>
              )}
              {formik.values.all_day ? (
                <Row>
                  <DatePicker
                    formik={formik}
                    label={t('Start')}
                    name="start_datetime"
                  />
                  <DatePicker
                    formik={formik}
                    name="end_datetime"
                    label={t('End')}
                  />
                </Row>
              ) : (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Row>
                    <Box sx={{ mx: 1 }}>
                      <MuiDatePicker
                        label={t('Date')}
                        sx={{ width: '100%' }}
                        value={formik.values.start_datetime}
                        onChange={(d) => handleChangeDate(d)}
                      />
                    </Box>
                    <Box sx={{ mx: 1 }}>
                      <TimePicker
                        label={t('Start Time')}
                        sx={{ width: '100%' }}
                        value={formik.values.start_datetime}
                        onChange={(time) => handleChangeTime(time)}
                        ampm={false}
                      />
                    </Box>
                    <Box sx={{ mx: 1 }}>
                      <TimePicker
                        label={t('End Time')}
                        sx={{ width: '100%' }}
                        value={formik.values.end_datetime}
                        minTime={formik.values.start_datetime}
                        onChange={(time) => formik.setFieldValue('end_datetime', time)}
                        ampm={false}
                      />
                    </Box>
                  </Row>
                </LocalizationProvider>
              )}
              <Row>
                <FormControlLabel
                  sx={{ ml: 1 }}
                  control={(
                    <Checkbox
                      checked={formik.values.all_day}
                      onChange={() => formik.setFieldValue('all_day', !formik.values.all_day)}
                    />
                  )}
                  label={t('All Day')}
                />
              </Row>
              <Button
                sx={{ ml: 1, mb: 1 }}
                onClick={() => {
                  if (showRecurrence) {
                    formik.setFieldValue('recurrences', '');
                  }
                  setShowRecurrence(!showRecurrence);
                }}
                variant="outlined"
              >
                {showRecurrence ? t('Remove recurrence') : t('Add recurrence')}
              </Button>

              {(showRecurrence || formik.values.recurrences !== '') && (
                <Box border={(theme) => `1px solid ${theme.palette.grey[300]}`} borderRadius={2} p={1}>
                  <Recurrence
                    onChange={(recurrence) => formik.setFieldValue('recurrences', recurrence)}
                    rule={formik.values.recurrences}
                  />
                </Box>
              )}
            </Box>
            <Box width="30%" sx={{ fontFamily: (theme) => theme.typography.fontFamily, pr: 1 }}>
              <FullCalendar
                ref={calendarRef}
                initialView="dayGridDay"
                plugins={[dayGridPlugin, iCalendarPlugin]}
                initialDate={date.toDate()}
                events={{
                  url: icsUrl,
                  format: 'ics',
                }}
                height="100%"
                eventContent={renderEventContent}
                eventClick={(info) => {
                  info.jsEvent.preventDefault();
                }}
                locale={i18n.language}
                locales={allLocales}
                headerToolbar={{
                  left: '',
                  center: '',
                  right: '',
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {eventId && (
            <Button
              id="delete"
              onClick={() => {
                deleteEvent(eventId).then(() => {
                  refetchEvents();
                });
                handleClose();
              }}
              color="error"
            >
              {t('Delete')}
            </Button>
          )}
          <Button onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <LoadingButton id="submit" type="submit" loading={formik.isSubmitting}>
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEventModal;
