import {
  Box, Paper, LinearProgress,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid as MuiDataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

type Props = {
  columns: GridColDef[];
  rows: Record<string, any>[];
  loading: boolean;
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange?: (page: number) => void;
  totalCount: number;
  page: number;
  pageSize: number;
  setSelectedRows?: (rows: GridRowSelectionModel) => void;

}

const DataGrid = (props: Props) => {
  const {
    columns,
    rows,
    loading,
    onPageSizeChange,
    onPageChange,
    setSelectedRows,
    totalCount,
    page,
    pageSize,
  } = props;
  const { t } = useTranslation();

  const localeText = React.useMemo(() => ({
    noRowsLabel: t('No data'),
    footerRowSelected: (count: number) => `${count} ${t('rows selected')}`,
    columnMenuLabel: t('Menu'),
    columnMenuShowColumns: t('Show columns'),
    columnMenuManageColumns: t('Manage columns'),
    columnMenuFilter: t('Filter'),
    columnMenuHideColumn: t('Hide column'),
    columnMenuUnsort: t('Unsort'),
    columnMenuSortAsc: t('Sort by ASC'),
    columnMenuSortDesc: t('Sort by DESC'),
  }), [t]);

  return (
    <Paper sx={{ width: '100%', mb: 3 }}>
      {loading && <LinearProgress />}
      <MuiDataGrid
        rows={rows}
        sx={{ height: rows.length > 0 ? 'auto' : '300px' }}
        loading={loading}
        columns={columns}
        checkboxSelection
        paginationMode="server"
        localeText={localeText}
        onPaginationModelChange={(paginationModel) => {
          if (onPageSizeChange) {
            onPageSizeChange(paginationModel.pageSize);
          }
          if (onPageChange) {
            onPageChange(paginationModel.page);
          }
        }}
        onRowSelectionModelChange={(selection: GridRowSelectionModel) => {
          if (setSelectedRows) {
            setSelectedRows(selection);
          }
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        paginationModel={{
          page,
          pageSize,
        }}
        pageSizeOptions={[10, 20, 50]}
        rowCount={totalCount}
      />
    </Paper>
  );
};
export default DataGrid;
