import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import {
  CircularProgress,
  Button, Dialog, DialogActions, TextField,
  DialogContent, DialogTitle, Typography,
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { greyboxApiActions } from '../../../../redux/api';
import { dosageResponse } from '../searchMedications/medicationUtils';
import { nextStepModal } from './currentMedications_styling';
import { isSameAsToday, isTomorrow, isValidDate } from '../../../forms/index';
import { arraysMatch, medication_status } from '../../../../helper-functions';
import {
  Selects, Switchs, TextInput,
} from '../../../tk-ui';

const EditMedicationModal = (props) => {
  const {
    medInfo,
    hovered = [],
    handleClose,
    edit,
    setModalOpen,
    setHovered,
    open,
  } = props;

  const { t, i18n } = useTranslation();
  const { medication, titrationStatus, posology } = greyboxApiActions;
  const { uuid } = useParams();
  const [editMedication, updateResult] = medication.update();
  const [addMedication, addResult] = medication.add();
  const { data = [], isFetching } = titrationStatus.list({ patient: uuid });
  const posologySelector = posology.list({});
  const isLoading = updateResult.isLoading || addResult.isLoading;
  const [selectVal, setSelectVal] = useState(
    edit
      ? medInfo.posology[medInfo.posology.dosage_mode].short_code === 'particular'
        ? t('Particular')
        : medInfo.posology[medInfo.posology.dosage_mode].short_code
      : '',
  );

  const [dosage, setDosage] = useState(
    edit
      ? medInfo.posology.frequency_posology
        ? medInfo.posology.frequency_posology.dosage
        : medInfo.daily
      : '',
  );
  const [dailyDose, setDailyDose] = useState('0');
  const [particularDose, setParticularDose] = useState(
    edit && medInfo.posology[medInfo.posology.dosage_mode].short_code === 'particular'
      ? {
        Morning: medInfo.posology.particular_posology.morning_dosage,
        Noon: medInfo.posology.particular_posology.noon_dosage,
        Afternoon: medInfo.posology.particular_posology.afternoon_dosage,
        Supper: medInfo.posology.particular_posology.evening_dosage,
        Bedtime: medInfo.posology.particular_posology.night_dosage,
      }
      : {
        Morning: '', Noon: '', Afternoon: '', Supper: '', Bedtime: '',
      },
  );

  const [maxDosage, setMaxDosage] = useState(medInfo.max_tolerated_dosage);
  // sets a lang variable to avoid repeating
  const lang = i18n.resolvedLanguage;

  const nextStephHeaderBtn = {
    width: edit ? (lang === 'en' ? '60px' : '72px') : lang === 'en' ? '100px' : '130px',
    height: '30px',
    cursor: 'pointer',
    left: !edit ? (lang === 'en' ? '88px' : '110px') : lang === 'en' ? '278px' : '343px',
    top: 'calc(50% - 15px)',
    position: 'absolute',
  };
  const nextStepBtn = {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    left: !edit ? '4px' : '79px',
    top: 'calc(50% - 15px)',
    position: 'absolute',
  };

  // date state
  const [endCurrent, setEndCurrent] = useState(
    isSameAsToday(medInfo.startDate)
      ? isTomorrow(medInfo.startDate)
      : moment(Date.now()),
  );
  const [startNew, setStartNew] = useState(
    edit
      ? moment(medInfo.startDate)
      : isSameAsToday(medInfo.startDate)
        ? isTomorrow(medInfo.startDate)
        : moment(Date.now()),
  );
  const [endNew, setEndNew] = useState(
    edit
      ? medInfo.endDate === '---'
        ? null
        : moment(medInfo.endDate)
      : null,
  );

  // return med for dosages steps
  const [medTitration] = data.filter(
    (el, idx, self) => arraysMatch(el.titration.active_ingredient_codes, medInfo.aiCode),
  );
  // find next step
  const setNextStep = medTitration && medTitration.titration.dosage_steps.steps.find(
    (el) => el.dosage > medInfo.daily,
  );

  // removed undefined cases
  const nextStep = setNextStep && setNextStep;

  // returns frequency i.e. "B.I.D" from previous medication
  const codeStrParser = () => (
    !!medInfo.posology.frequency_posology && medInfo.posology.frequency_posology.short_code
  );

  const codeStr = codeStrParser();

  // set the select value
  const handleSelect = (str) => setSelectVal(str);

  const dividedDosage = (shortCode, nextDosage) => {
    if (shortCode && nextDosage) {
      if (shortCode === 'D.I.E') {
        handleSelect(codeStr);
        setDosage(+nextDosage);
      } else if (shortCode === 'B.I.D') {
        handleSelect(codeStr);
        setDosage(+nextDosage / 2);
      } else if (shortCode === 'T.I.D') {
        handleSelect(codeStr);
        setDosage(+nextDosage / 3);
      } else if (shortCode === 'Q.I.D') {
        handleSelect(codeStr);
        setDosage(+nextDosage / 4);
      }
    }
  };

  const handleSubmit = async () => {
    const getDosageResponse = dosageResponse(
      posologySelector.data,
      dailyDose,
      dosage,
      particularDose,
      selectVal,
    );

    const newMedication = {
      end_date: endNew ? moment(endNew).format('YYYY-MM-DD') : null,
      max_tolerated_dosage: maxDosage,
      start_date: moment(startNew).format('YYYY-MM-DD'),
      dosage: getDosageResponse,
    };

    await editMedication({
      id: medInfo.uuid,
      body: {
        account: uuid,
        status: edit ? 'entered-in-error' : medication_status(medInfo.startDate, endCurrent),
        end_date: edit ? null : moment(endCurrent).format('YYYY-MM-DD'),
      },
    });
    await addMedication({
      body: {
        ...newMedication,
        account: uuid,
        medication: medInfo.medUuid,
        status: medication_status(startNew, endNew),
      },
    });
    handleClose();
  };

  // adds all particular doses
  const totalDose = Object.values(particularDose).reduce((acc, item) => acc + +item, 0);

  // validates dates only if not null
  const valideDates = (date, shape = 'YYYY-MM-DD') => date !== null && isValidDate(date, shape);

  // checks if a medication has a value ( no value if different medication ingredients )
  const hasInitialDose = medInfo.daily !== null;

  useEffect(() => {
    !edit && codeStr && nextStep && nextStep.dosage && dividedDosage(codeStr, nextStep.dosage);
  }, [codeStr, nextStep]);

  // sets startdate to the nexday the end is
  useEffect(() => {
    !edit && setStartNew(isTomorrow(endCurrent));
  }, [endCurrent]);
  // assign dailyDose
  useEffect(() => {
    selectVal === t('Particular') && totalDose && setDailyDose(totalDose);
  }, [totalDose]);

  useEffect(() => {
    if (selectVal === t('Particular')) totalDose === 0 ? setDailyDose('0') : setDailyDose(totalDose);
    else if (selectVal === '') setDailyDose('0');
    else if (selectVal === 'D.I.E') setDailyDose(+dosage);
    else if (selectVal === 'B.I.D') setDailyDose(+dosage * 2);
    else if (selectVal === 'T.I.D') setDailyDose(+dosage * 3);
    else if (selectVal === 'Q.I.D') setDailyDose(+dosage * 4);
  }, [selectVal, dosage]);

  // resets form on closing modal - prefill again..
  const reset = () => {
    // edit with frequency pos
    setDosage(
      edit
        ? medInfo.posology.frequency_posology
          ? medInfo.posology.frequency_posology.dosage
          : medInfo.daily
        : '',
    );
    // next step with particular pos
    !edit && codeStr && nextStep && nextStep.dosage && dividedDosage(codeStr, nextStep.dosage);
    // atribute selectVal if titration step dosage

    setSelectVal(
      edit
        ? medInfo.posology[medInfo.posology.dosage_mode].short_code === 'particular'
          ? t('Particular')
          : medInfo.posology[medInfo.posology.dosage_mode].short_code
        : medInfo.posology[medInfo.posology.dosage_mode].short_code,
    );

    setParticularDose(
      edit && medInfo.posology && medInfo.posology.dosage_mode === 'particular_posology'
        ? {
          Morning: medInfo.posology.particular_posology.morning_dosage,
          Noon: medInfo.posology.particular_posology.noon_dosage,
          Afternoon: medInfo.posology.particular_posology.afternoon_dosage,
          Supper: medInfo.posology.particular_posology.noon_dosage,
          Bedtime: medInfo.posology.particular_posology.night_dosage,
        }
        : {
          Morning: '', Noon: '', Afternoon: '', Supper: '', Bedtime: '',
        },
    );

    setEndCurrent(
      isSameAsToday(medInfo.startDate)
        ? isTomorrow(medInfo.startDate)
        : moment(Date.now()),
    );
    setStartNew(
      edit
        ? moment(medInfo.startDate)
        : isSameAsToday(medInfo.startDate)
          ? isTomorrow(medInfo.startDate)
          : moment(Date.now()),
    );
    setModalOpen((prev) => !prev);
    setHovered && setHovered([]);
  };

  return (
    (hovered)
    && !!medInfo && !isFetching && (
      <Dialog
        open={open}
        disableBackdropClick={isLoading}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {edit ? t('Edit Medication') : t('Next Step')}
        </DialogTitle>
        <DialogContent dividers>
          <div style={nextStepModal.wrapper}>
            {!edit && (
              <div style={nextStepModal.lineWrapperFirst}>
                <span style={nextStepModal.current}>{t('CURRENT')}</span>
                <span style={nextStepModal.lines} />
              </div>
            )}

            <div style={nextStepModal.textRow}>
              <div style={nextStepModal.rowName}>{t('Product Name')}</div>
              <div style={nextStepModal.rowDataBold} data-cy="productName">
                {medInfo.name}
              </div>
            </div>

            <div style={nextStepModal.textRowTop}>
              <Typography style={nextStepModal.rowName}>{t('Active Ingredients')}</Typography>
              <div style={nextStepModal.rowData}>
                {medInfo.ingredient.map((molecule) => (
                  <div
                    style={nextStepModal.ingredientName}
                    key={molecule.drugUuid}
                    data-cy="molecule"
                  >
                    {lang === 'en' ? molecule.molecule : molecule.moleculeFr}
                    {' '}
                    {molecule.dosageUnit === 'nan' ? (
                      <span style={nextStepModal.unitsVal}>
                        (
                        {lang === 'en' ? molecule.strength : molecule.strengthFr}
                        )
                      </span>
                    ) : (
                      <span style={nextStepModal.unitsVal}>
                        (
                        {lang === 'en' ? molecule.strength : molecule.strengthFr}
                        {' '}
                        /
                        {' '}
                        {lang === 'en' ? molecule.dosageUnit : molecule.dosageUnitFr}
                        )
                      </span>
                    )}
                    {' '}
                  </div>
                ))}
              </div>
            </div>
            {!edit && (
              <>
                {hasInitialDose && (
                  <div style={nextStepModal.textRowDaily}>
                    <Typography style={nextStepModal.rowName}>{t('Daily Dose')}</Typography>
                    <div style={nextStepModal.rowData} data-cy="daily">
                      {medInfo.daily}
                      {' '}
                      <span style={{ color: '#95989a' }}>
                        {lang === 'en' ? medInfo.unit : medInfo.unitFr}
                      </span>
                    </div>
                  </div>
                )}
                <div style={nextStepModal.endDateWrapperFirst} data-cy="endWrapper">
                  <Typography style={nextStepModal.endDateTitle}>{t('End')}</Typography>
                  <div style={nextStepModal.endDateInput} data-cy="startDate">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Date"
                        value={endCurrent ? moment(endCurrent) : null}
                        onChange={(d) => setEndCurrent(d)}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                {/* //------------NEW ------------- // */}
                <div style={nextStepModal.lineWrapperFirst}>
                  <span style={nextStepModal.new}>{t('NEW')}</span>
                  <span style={nextStepModal.lines} />
                </div>
              </>
            )}
            {hasInitialDose && (
              <div
                style={
                  selectVal === t('Particular')
                    ? { ...nextStepModal.textRowInputDose, opacity: 0.5 }
                    : nextStepModal.textRowInputDose
                }
              >
                <Typography
                  style={
                    selectVal === t('Particular')
                      ? { ...nextStepModal.rowName, opacity: 0.5 }
                      : nextStepModal.rowName
                  }
                >
                  {' '}
                  {t('Dose')}
                  {' '}
                </Typography>
                <div style={nextStepModal.rowData}>
                  <TextInput
                    values={dosage}
                    unit={lang === 'en' ? medInfo.unit : medInfo.unitFr}
                    name="dosage"
                    disabled={selectVal === t('Particular')}
                    onChange={(e) => {
                      e.persist();
                      setDosage(e.target.value);
                    }}
                    data-cy="dosage"
                  />
                </div>
              </div>
            )}
            <div style={nextStepModal.textRowInput}>
              <Typography style={nextStepModal.rowName}>{t('Frequency')}</Typography>
              <div style={nextStepModal.rowData}>
                <Selects
                  name="frequency"
                  data-cy="frequency"
                  values={selectVal}
                  selectionArr={
                    hasInitialDose
                      ? ['D.I.E', 'B.I.D', 'T.I.D', 'Q.I.D', t('Particular')]
                      : ['D.I.E', 'B.I.D', 'T.I.D', 'Q.I.D']
                  }
                  onChange={handleSelect}
                />
              </div>
            </div>
            <div
              style={{
                height: selectVal !== t('Particular') ? '0px' : '285px',
                transition: 'height 300ms ease',
              }}
            >
              {selectVal === t('Particular')
                && Object.keys(particularDose).map((time, idx, self) => (
                  <div style={nextStepModal.textRowInputPos} key={time + idx}>
                    <div style={nextStepModal.rowName}>{t(time)}</div>
                    <div style={nextStepModal.rowData} data-cy={time}>
                      <TextInput
                        unit={lang === 'en' ? medInfo.unit : medInfo.unitFr}
                        data-cy={`input${idx}`}
                        values={particularDose[`${time}`]}
                        name={time}
                        onChange={(e) => {
                          e.persist();
                          setParticularDose((prevState) => ({
                            ...prevState,
                            [`${time}`]: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
            {hasInitialDose && (
              <div style={nextStepModal.textRowSwitch}>
                <Typography style={nextStepModal.rowName}>{t('Daily Dose')}</Typography>
                <div style={nextStepModal.rowData}>
                  <span style={{ fontWeight: '500' }} data-cy="dailyDose">
                    {dailyDose}
                  </span>
                  {' '}
                  <span style={{ color: '#95989a' }} data-cy="unit">
                    {lang === 'en' ? medInfo.unit : medInfo.unitFr}
                  </span>
                </div>
              </div>
            )}
            <div style={nextStepModal.textRowTopDose}>
              <Typography style={nextStepModal.rowName}>{t('Maximum Tolerated Dose')}</Typography>
              <div style={nextStepModal.rowDataSwitch}>
                <Switchs
                  data-cy="maxTol"
                  name="exemple"
                  onClick={() => setMaxDosage(!maxDosage)}
                  active={maxDosage}
                />
              </div>
            </div>

            <div style={nextStepModal.endDateWrapperUp} data-cy="startWrapper">
              <Typography style={nextStepModal.endDateTitle}>{t('Start Date')}</Typography>
              <div style={nextStepModal.endDateInput}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Date"
                    value={moment(startNew)}
                    onChange={(d) => setStartNew(d)}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div style={nextStepModal.endDateWrapperLast} data-cy="endWrapper">
              <Typography style={nextStepModal.endDateTitle}>
                {t('End')}
                {' '}
                <span style={{ fontWeight: '400' }}>
                  (
                  {t('optionnal')}
                  )
                </span>
              </Typography>
              <div style={nextStepModal.endDateInput}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Date"
                    value={endNew ? moment(endNew) : null}
                    onChange={(d) => setEndNew(d)}
                    minDate={moment().add(1, 'days')}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleSubmit} disabled={isLoading} color="primary">
            {isLoading ? <CircularProgress size={14} /> : t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default EditMedicationModal;
