import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import {
  CircularProgress, Dialog, DialogTitle,
  DialogContent, DialogActions, Button, TextField,
} from '@mui/material';
import moment from 'moment';
import { stopModal } from './currentMedications_styling';
import { greyboxApiActions } from '../../../../redux/api';
import { medication_status } from '../../../../helper-functions';

import 'moment/locale/fr';

const StopMedicationModal = (props) => {
  const {
    selectedMeds = [],
    open,
    handleClose,
  } = props;
  const { t, i18n } = useTranslation();
  const { medication } = greyboxApiActions;
  const [updateMedication, result] = medication.update();
  const minDate = moment.max(selectedMeds.map((med) => moment(med.startDate)));
  const [date, setDate] = useState(moment.max(moment(minDate).add(1, 'days'), moment()).format('YYYY-MM-DD'));

  const handleStopMedication = async () => {
    await Promise.all(selectedMeds.map((med) => (
      updateMedication({
        id: med.uuid,
        body: {
          status: medication_status(med.start_date, date),
          end_date: moment(date).format('YYYY-MM-DD'),
        },
      })
    )));
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      disableBackdropClick={result.isLoading}
      maxWidth="sm"
    >
      <DialogTitle>
        {t('Stop Medication')}
      </DialogTitle>
      <DialogContent>
        <div style={stopModal.categoriesWrapper} data-cy="multiCatWrapper">
          <div style={stopModal.categoriesName}>{t('Medication')}</div>
          <div style={stopModal.categoriesUnit}>{t('Daily Dose')}</div>
          <div style={stopModal.categoriesDate}>{t('Start')}</div>
        </div>
        {selectedMeds.map((med, idx) => (
          <div
            style={stopModal.groupWrapper}
            key={med.uuid + idx}
            data-cy="multiGroupWrapper"
          >
            <div style={stopModal.groupName}>{med.name.toLowerCase()}</div>
            <div style={stopModal.groupUnit}>
              {med.daily}
              {' '}
              {med.unit}
            </div>
            <div style={stopModal.groupDate}>{med.startDate}</div>
          </div>
        ))}
        <div style={stopModal.endDateWrapper} data-cy="multiEndWrapper">
          <div style={stopModal.endDateTitle}>{t('End')}</div>
          <div style={stopModal.endDateInput}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date"
                value={moment(date)}
                onChange={(d) => setDate(d)}
              />
            </LocalizationProvider>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={result.isLoading}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={() => handleStopMedication()}
          variant="contained"
          disabled={result.isLoading}
          color="primary"
        >
          {result.isLoading ? <CircularProgress size={14} /> : t('Stop')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopMedicationModal;
