import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box, Divider, IconButton, Skeleton, Typography, Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { greyboxApiActions } from '../../../redux/api';
import AnswerFormatter from './AnswerFormatter';
import PrintableAnswers from './PrintableAnswers';

const Header = ({ children }) => (
  <Box
    sx={{
      backgroundColor: (theme) => theme.palette.primary.main,
      color: (theme) => theme.palette.primary.contrastText,
      display: 'flex',
      px: 1,
      alignItems: 'center',
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    }}
  >
    {children}
  </Box>
);

const useStyles = makeStyles((theme) => ({
  question: {
    minHeight: '45px',
    padding: '0 10px 0 10px',
  },
  borderLine: {
    width: '95%',
    height: '1px',
    transform: 'translateX(15px)',
    background: theme.palette.border.main,
  },
  content: {
    width: '360px',
    maxHeight: '50vh',
    overflowY: 'scroll',
    borderRadius: '0 0 5px 5px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
  },
}));

const QuestionnaireAnswersSkeleton = ({ handleClose }) => {
  const classes = useStyles();
  return (
    <Paper>
      <Header>
        <Skeleton variant="text" width="200px" />
        <IconButton
          sx={{ ml: 'auto' }}
          color="inherit"
          size="small"
          onClick={(event) => handleClose(event)}
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <div className={classes.content}>
        {Array.from(Array(5).keys()).map((i) => (
          <div key={i}>
            <Box sx={{ p: 1 }}>
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="60%" />
            </Box>
            <Divider />
          </div>
        ))}
      </div>
    </Paper>
  );
};

const QuestionnaireAnswersError = ({ handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper>
      <Header>
        <Typography>{t('Error')}</Typography>
        <IconButton
          sx={{ ml: 'auto' }}
          color="inherit"
          size="small"
          onClick={(event) => handleClose(event)}
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <div className={classes.content}>
        <Box sx={{ p: 1 }}>
          <Typography variant="body2">
            {`${t('An error has occurred while fetching the data')}.`}
          </Typography>
        </Box>
      </div>
    </Paper>
  );
};

const QuestionnaireAnswers = (props) => {
  const { handleClose, recordId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { questionnaireAnswers, questionnaire, account } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const printRef = useRef();
  const { data, isLoading, isError } = questionnaireAnswers.get(recordId);
  const accountSelector = account.get(data?.patient, { skip: !data });
  const questionnaireSelector = questionnaire.list(
    {
      id: data?.questionnaire,
      clinic: clinic.id,
    },
    {
      skip: isLoading || !data,
    },
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: questionnaireSelector.data?.name,
  });

  if (isLoading || questionnaireSelector.isLoading || accountSelector?.isLoading) {
    return <QuestionnaireAnswersSkeleton handleClose={handleClose} />;
  }

  if (isError || questionnaireSelector.isError || accountSelector?.isError) {
    return <QuestionnaireAnswersError handleClose={handleClose} />;
  }

  return (
    <Paper>
      <Header>
        <div>
          <Typography>{questionnaireSelector.data?.name}</Typography>
          <Typography variant="caption">
            {`${t('Answered on')} ${moment(data.answer_timestamp).format('lll')}`}
          </Typography>
        </div>
        <IconButton
          color="inherit"
          sx={{ ml: 'auto' }}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handlePrint();
          }}
        >
          <PrintIcon />
        </IconButton>
        <IconButton
          color="inherit"
          size="small"
          onClick={(event) => handleClose(event)}
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <div className={classes.content}>
        {questionnaireSelector.data.questions.map((question, index) => (
          <Box sx={{ px: 2 }} key={question.id}>
            <AnswerFormatter key={question.id} question={question} data={data} />
            {index < questionnaireSelector.data.questions.length - 1 && <Divider />}
          </Box>
        ))}
      </div>
      <Box sx={{ display: 'none' }}>
        <PrintableAnswers
          ref={printRef}
          answers={data}
          questionnaire={questionnaireSelector.data}
          patient={accountSelector?.data}
        />
      </Box>
    </Paper>
  );
};

export default QuestionnaireAnswers;
