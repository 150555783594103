import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  editor: {
    '& .ql-editor': {
      minHeight: '100px',
      fontFamily: theme.typography.fontFamily,
    },
  },
}));

const markdownToHtml = (markdown) => {
  const file = remark().use(remarkHtml).processSync(markdown);
  return file.toString();
};

const htmlToMarkdown = (html) => {
  const file = remark()
    .use(rehypeParse)
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(html);

  return file.toString();
};

/**
 * Basic text editor using Quill.
 * Uses only feature that are markdown compatible.
 * Output markdown as well as html.
 */
const RichTextEditor = (props) => {
  const {
    defaultValue, placeholder, readOnly, onChange,
  } = props;
  const [value, setValue] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const ReactQuillRef = React.createRef(null);
  const classes = useStyles();

  const handleChange = (content) => {
    setValue(content);
    onChange({
      html: content,
      markdown: htmlToMarkdown(content),
    });
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ header: [1, 2, 3, 4] }],
    ],
  };

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'header',
  ];

  // Update the state when defaultValue changes
  React.useEffect(() => {
    if (defaultValue) {
      const html = markdownToHtml(defaultValue);
      setValue(html);
    }
    setIsInitialized(true);
  }, [defaultValue]);

  return (
    <div>
      <ReactQuill
        ref={ReactQuillRef}
        readOnly={readOnly}
        className={classes.editor}
        theme="snow"
        modules={modules}
        formats={formats}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export default RichTextEditor;
