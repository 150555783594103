import React from 'react';
import { SvgIcon } from '@mui/material';

const StepCountIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 30 30 ">
    <g id="Step_count_icon_30px" transform="translate(21)">
      <circle id="Ellipse_6" cx="15" cy="15" r="15" fill="#5c6ac4" data-name="Ellipse 6" transform="translate(-21)" />
      <path id="Path_133" d="M22.232 12.715a34.946 34.946 0 0 0-2.825-1.721 2.845 2.845 0 0 1-1.7-1.73l.011-.007-.018-.036a.021.021 0 0 1 0-.007.072.072 0 0 0-.022-.028l-.3-.442A13.378 13.378 0 0 1 14.49 10.3c-.7.087 1.572-1.747 1.572-1.747l.71-.686-.5-.728a6.5 6.5 0 0 1-2.48 1.326c-1.135.175 1.135-1.834 1.135-1.834l.658-.5-.5-.727A7.44 7.44 0 0 1 12.57 6.9c-1.135.262 1.223-1.747 1.223-1.747l.631-.706-.024-.032c-.535-.688-1.048-1.37-1.592-2.031a.751.751 0 0 0-.45-.24 3.647 3.647 0 0 0-2.339.611l1.058.456c-.692-.024-1.457-.062-2.222-.073a1.9 1.9 0 0 1-1.7-.961 10.309 10.309 0 0 0-.793-1.2 3.482 3.482 0 0 0-.878-.755 1.345 1.345 0 0 0-1.751.235A9.076 9.076 0 0 0 1.42 4.1 8.184 8.184 0 0 1 .372 6.239C.293 6.36.209 6.48.136 6.6c-.251.426-.238.7.175.978A25.156 25.156 0 0 0 3.5 9.623c2.943 1.416 5.949 2.7 8.912 4.075a18.536 18.536 0 0 0 8.835 1.978c.892-.045 1.37-.327 1.607-1.059a1.479 1.479 0 0 0-.622-1.902zm-.842 2.567c-.7-.165-3.7-.321-4.4-.509a17.3 17.3 0 0 1-4.6-2.245c-.983-.611-2.078-1.414-3.144-1.961-.961-.611-1.106-.611-1.834-1.135a27.7 27.7 0 0 0-2.52-1.556 30.953 30.953 0 0 0-3.849-1.763l.8-.668a19.131 19.131 0 0 1 4.439 1.891C8.6 8.941 10.97 10.3 13.291 11.893a14.871 14.871 0 0 0 5.953 2.543c.072.012 1.624.388 1.693.411.037.012.769.12.8.136-.004.037-.337.262-.347.299z" fill="#ffffff" data-name="Path 133" transform="translate(-17.712 7.378)" />
    </g>
  </SvgIcon>
);

export { StepCountIcon };
