import React from 'react';
import { TextField } from '@mui/material';

interface PropsType {
  children: React.ReactNode;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  sx?: any;
  formik: any;
}

const FormSelect = (props: PropsType) => {
  const {
    formik, name, label, required, sx, children,
    disabled, multiple = false, ...other
  } = props;

  return (
    <TextField
      id={name}
      sx={sx || { mx: 1 }}
      required={required}
      select
      SelectProps={{
        multiple: multiple,
      }}
      name={name}
      data-cy={name}
      label={label}
      value={formik.values[name] || ''}
      onBlur={formik.handleBlur}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      variant="outlined"
      onChange={formik.handleChange}
      disabled={disabled}
      {...other}
    >
      {children}
    </TextField>
  );
};

FormSelect.defaultProps = {
  required: false,
  sx: null,
  disabled: false,
};

export default FormSelect;
