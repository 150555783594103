import React from 'react';
import { SvgIcon } from '@mui/material';

export const Add = ({ style }) => (
  <SvgIcon style={style}>
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </SvgIcon>

);
