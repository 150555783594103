import {
  Box, Paper, Grid,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import ObservationChart from '../chart/ObservationChart';
import QuestionnaireResultChart from '../chart/QuestionnaireResultChart';
import AskQuestionnaire from './questionnaire/AskQuestionnaire';
import { VITAL_CONFIG } from '../vitals/utils';
import ErrorFallbackCard from '../utils/ErrorFallbackCard';

const initDefaultConfig = (features, vitalsConfig, questionnaires) => {
  const defaultConfig = [];

  Object.values(vitalsConfig)
    .filter((v) => v.enabled)
    .forEach((vital) => {
      defaultConfig.push({
        id: vital.short_code,
        type: 'vital',
      });
    });

  Object.entries(features).forEach(([key, value]) => {
    if (key === 'questionnaire') {
      questionnaires.forEach((questionnaire) => {
        if (questionnaire.has_score) {
          defaultConfig.push({
            id: questionnaire.id,
            type: 'questionnaire',
          });
        }
      });
    }
  });

  return defaultConfig;
};

/**
 * Default display of the patient profile page. Shows and array of vitals and questionnaire results
 * charts. Also shows a counter of reminders and alerts. What is shown and in what order is
 * determined by the clinic configurations.
 */
const Overview = () => {
  const { vitalsConfig, clinic } = useSelector((state) => state.clinic);
  const { uuid } = useParams();
  const { questionnaires } = useSelector((state) => state.clinic);

  const configurations = useMemo(() => {
    if (clinic.config.overview) {
      return (clinic.config.overview?.map((item) => {
        const config = { ...item };
        if (item.type === 'vital' && item.id in VITAL_CONFIG) {
          config.config = VITAL_CONFIG[item.id].chartConfig;
        }
        return config;
      }));
    }
    return initDefaultConfig(clinic.config.features_enable, vitalsConfig, questionnaires);
  }, []);

  if (!configurations || configurations.length === 0) {
    return null;
  }

  if (configurations[0].type === 'ask_questionnaire') {
    return (
      <Paper
        sx={{
          width: '100%',
          p: 0,
        }}
      >
        <AskQuestionnaire
          patientUuid={uuid}
          questionnaireId={configurations[0].id}
        />
      </Paper>
    );
  }

  return (
    <Grid container spacing={1} sx={{ mt: 1, mb: 3 }}>
      {configurations.map((config) => {
        if (config.type === 'vital') {
          const vital = config.id;
          const measures = [];
          if (vital in vitalsConfig) {
            if (vitalsConfig[vital].config?.children) {
              vitalsConfig[vital].config.children.forEach((child) => {
                measures.push(vitalsConfig[child]);
              });
            } else {
              measures.push(vitalsConfig[vital]);
            }
          }

          if (measures.length === 0) {
            return null;
          }
          return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={config.id}>
              <ErrorBoundary
                FallbackComponent={ErrorFallbackCard}
                onReset={() => {
                  // Reset the state of your application so the error doesn't happen again
                }}
              >
                <ObservationChart
                  key={config.id}
                  measures={measures}
                  config={vitalsConfig[vital]}
                  autoHeight
                />
              </ErrorBoundary>
            </Grid>
          );
        }

        if (config.type === 'questionnaire') {
          return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={config.id}>
              <ErrorBoundary
                FallbackComponent={ErrorFallbackCard}
                onReset={() => {
                  // Reset the state of your application so the error doesn't happen again
                }}
              >
                <QuestionnaireResultChart
                  key={config.id}
                  questionnaireId={config.id}
                  autoHeight
                />
              </ErrorBoundary>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};

export default Overview;
