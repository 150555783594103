import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from './DatePicker';

interface PropsType {
  formik: any;
  sx?: any;
}

export const formatBirthDateForPost = (date: Date) => (
  date ? moment(date).format('YYYY-MM-DD') : null
);

const BirthDate = (props: PropsType) => {
  const { t } = useTranslation();
  const { sx, formik } = props;
  return (
    <DatePicker
      name="birthDate"
      data-cy="birth-date"
      formik={formik}
      openTo="year"
      disableFuture
      label={t('Birth Date')}
    />
  );
};

BirthDate.defaultProps = {
  sx: { mx: 1 },
};

export default BirthDate;
