import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Skeleton } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../redux/api';
import {
  DeviceType, DEVICE_AVAILABILITY_STATUS, DEVICE_CATEGORY,
  DEVICE_MODE, DEVICE_SAFETY, DEVICE_STATUS,
} from '../../types';
import {
  Clinic, DatePicker, FormSelect, FormTextField,
} from '../form-inputs';
import Row from '../Row';

const parseValues = (values: any) => {
  const body = {};

  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case 'owner':
          body[key] = { reference: `Organization/${value}` };
          break;
        case 'availabilityStatus':
          body[key] = { coding: [{ code: value }] };
          break;
        case 'category':
          body[key] = { coding: [{ code: value }] };
          break;
        case 'safety':
          body[key] = { coding: [{ code: value }] };
          break;
        case 'mode':
          body[key] = { coding: [{ code: value }] };
          break;
        default:
          body[key] = value;
      }
    }
  });

  return body;
};

type PropsType = {
  onClose?: () => void;
};

const DeviceDetails = ({ onClose = null }: PropsType) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { device } = greyboxApiActions;
  const { data, isLoading, isError } = device.get(id, { skip: !id }) as {
    data: DeviceType;
    isLoading: boolean;
    isError: boolean;
  };
  const [updateDevice] = device.update();
  const [addDevice] = device.add();

  const formik = useFormik({
    initialValues: {
      displayName: data?.displayName || '',
      status: data?.status || '',
      availabilityStatus: data?.availabilityStatus.coding[0].code || '',
      category: data?.category[0]?.coding[0].code || '',
      expirationDate: data?.expirationDate || null,
      owner: data?.owner?.reference.split('/')[1] || '',
      safety: data?.safety ? data?.safety[0].coding[0].code : '',
      mode: data?.mode?.coding[0].code || '',
      serialNumber: data?.serialNumber || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = parseValues(values);

      if (id) {
        await updateDevice({ id, body, feedback: { success: t('Device Updated') } });
      } else {
        await addDevice({ body, feedback: { success: t('Device Created') } });
        onClose();
      }
    },
  });

  if (isLoading) {
    return (
      <>
        <Row>
          <Skeleton variant="rectangular" width="98%" sx={{ height: '40px' }} />
          <Skeleton variant="rectangular" width="98%" sx={{ height: '40px' }} />
        </Row>
        <Row>
          <Skeleton variant="rectangular" width="98%" sx={{ height: '40px' }} />
          <Skeleton variant="rectangular" width="98%" sx={{ height: '40px' }} />
        </Row>
        <Row>
          <Skeleton variant="rectangular" width="98%" sx={{ height: '40px' }} />
          <Skeleton variant="rectangular" width="98%" sx={{ height: '40px' }} />
        </Row>
        <Row>
          <Skeleton variant="rectangular" width="98%" sx={{ height: '40px' }} />
          <Skeleton variant="rectangular" width="98%" sx={{ height: '40px' }} />
        </Row>
      </>
    );
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row>
        <FormTextField name="displayName" label={t('Name')} formik={formik} />
        <FormSelect name="status" label={t('Status')} formik={formik}>
          {Object.entries(DEVICE_STATUS).map(([key, value]) => (
            <MenuItem key={key} value={key}>{value}</MenuItem>
          ))}
        </FormSelect>
      </Row>
      <Row>
        <FormSelect name="availabilityStatus" label={t('Availability Status')} formik={formik}>
          {Object.entries(DEVICE_AVAILABILITY_STATUS).map(([key, value]) => (
            <MenuItem key={key} value={key}>{value}</MenuItem>
          ))}
        </FormSelect>
        <Clinic name="owner" formik={formik} />
      </Row>
      <Row>
        <FormSelect name="category" label={t('Category')} formik={formik}>
          {Object.entries(DEVICE_CATEGORY).map(([key, value]) => (
            <MenuItem key={key} value={key}>{value}</MenuItem>
          ))}
        </FormSelect>
        <DatePicker name="expirationDate" label={t('Expiration Date')} formik={formik} />
      </Row>
      <Row>
        <FormSelect name="safety" label={t('Safety')} formik={formik}>
          {Object.entries(DEVICE_SAFETY).map(([key, value]) => (
            <MenuItem key={key} value={key}>{value}</MenuItem>
          ))}
        </FormSelect>
        <FormSelect name="mode" label={t('Mode')} formik={formik}>
          {Object.entries(DEVICE_MODE).map(([key, value]) => (
            <MenuItem key={key} value={key}>{value}</MenuItem>
          ))}
        </FormSelect>
      </Row>
      <Row>
        <FormTextField name="serialNumber" label={t('Serial Number')} formik={formik} />
      </Row>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained">
          {t('Save')}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default DeviceDetails;
