import React from 'react';
import { SvgIcon } from '@mui/material';

const HeartRateIcon = ({ style }) => (
  <SvgIcon style={style} viewBox="0 0 37 37">
    <g>
      <circle
        fill="#6cf"
        data-name="Ellipse 6"
        cx="17.5"
        cy="17.5"
        r="17.5"
      />
      <path
        fill="#fff"
        d="M23.37,18.54l-2.49-3.47L18,19.82l-1.21-1.09-1.45,1.91-3.09-4.22a1.52,1.52,0,0,1-.52,0,1.3,1.3,0,1,1,1.47-1.11,1.34,1.34,0,0,1-.23.58l2.39,3.28,1.39-1.9,1,1.05,3-4.94,3,4.23,5.31.06a8.67,8.67,0,0,0,.36-1.23.64.64,0,0,0,0-.07h0a7.14,7.14,0,0,0-.07-3.22,6.79,6.79,0,0,0-5.12-5,6.91,6.91,0,0,0-6.85,2.28A6.91,6.91,0,0,0,5.31,14.91c0,.19,0,.38,0,.58a4.63,4.63,0,0,0,.12.91c1.48,6.94,12,14.6,12,14.6s8.44-6.13,11.29-12.38Z"
      />
    </g>
  </SvgIcon>
);

export { HeartRateIcon };
