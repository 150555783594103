import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Button,
  Checkbox,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControlLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import { RootState } from '../../redux/store';
import { AccountCreationToAccount, AccountCreationType, AccountType } from '../../types';
import Layout from '../Layout';
import Row from '../Row';
import { Labels } from '../form-inputs';
import BirthDate from '../form-inputs/BirthDate';
import EthnicGroup from '../form-inputs/EthnicGroup';
import FormTextField from '../form-inputs/FormTextField';
import { Hin, hinNumberSchema } from '../form-inputs/Hin';
import Language from '../form-inputs/Language';
import MultiSelect from '../form-inputs/MultiSelect';
import Pharmacy from '../form-inputs/Pharmacy';
import PhoneNumber from '../form-inputs/PhoneNumber';
import Sex from '../form-inputs/Sex';
import { ZipCode, zipCodeSchema } from '../form-inputs/ZipCode';

type PropsType = {
  open: boolean;
  onClose: () => void;
}

const AccountCreationDialog = ({ open, onClose }: PropsType) => {
  const { t, i18n } = useTranslation();
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const { account, invitation } = greyboxApiActions;
  const [createAccount] = account.add();
  const [sendInvitation, setSendInvitation] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const configs = clinic.config.features_enable;
  const [createInvitation] = invitation.add();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: null,
      birthDate: null,
      phoneNumber: null,
      zipCode: '',
      hin_number: null,
      hin_exp: null,
      gender: 'U',
      language: i18n.language,
      ethnic_group: 'U',
      labels: [],
      primaryDiagnosis: [],
      secondaryDiagnosis: [],
      staffs: [],
      pharmacy: '',
    },
    validate: (values) => {
      if (sendInvitation && !values.email && !values.phoneNumber) {
        return {
          email: t('Must provide either email or phone number'),
          phoneNumber: t('Must provide either email or phone number'),
        };
      }
      return null;
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('Required')),
      lastName: Yup.string().required(t('Required')),
      birthDate: Yup.string().nullable(),
      email: Yup.string().nullable().email(t('Invalid email address')),
      zipCode: zipCodeSchema,
      phoneNumber: Yup.string().nullable(),
      hin_number: hinNumberSchema,
      hin_exp: Yup.string().nullable(),
      pharmacy: Yup.string(),
      labels: Yup.array().of(Yup.object()),
      primaryDiagnosis: Yup.array().of(Yup.object()),
      secondaryDiagnosis: Yup.array().of(Yup.object()),
    }),
    onSubmit: (values: AccountCreationType) => {
      setErrorMessage('');
      values['clinic'] = clinic.id;
      const body: Partial<AccountType> = AccountCreationToAccount(values);

      createAccount({ body, feedback: { success: t('Account created') } })
        .unwrap()
        .then(async (res) => {
          if (sendInvitation) {
            await createInvitation({
              body: {
                account: res.uuid,
                email: values.email,
                clinic: clinic.id,
                phoneNumber: values.phoneNumber,
              },
            });
          }
          formik.setSubmitting(false);
          formik.resetForm();
          onClose();
        }).catch((error) => {
          formik.setSubmitting(false);
          const errors = error.data;
          if (errors.error) {
            setErrorMessage(errors.error);
          }
          formik.setErrors(errors);
        });
    },
  });

  return (
    <Layout width="lg">
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          {t('Create Patient Account')}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} noValidate>
          <DialogContent>
            {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
            <Row>
              <FormTextField formik={formik} name="firstName" required label={t('First Name')} />
              <FormTextField formik={formik} name="lastName" required label={t('Last Name')} />
            </Row>
            <Row>
              <FormTextField formik={formik} name="email" label={t('Email')} />
              <PhoneNumber formik={formik} name="phoneNumber" />
            </Row>
            <Row>
              <Language formik={formik} />
              <ZipCode formik={formik} />
            </Row>
            <Row>
              <BirthDate formik={formik} />
              <Sex formik={formik} />
            </Row>
            <Row>
              <EthnicGroup formik={formik} />
              <Hin formik={formik} />
            </Row>
            <Row>
              <Labels formik={formik} />
            </Row>
            <MultiSelect
              formik={formik}
              type="diagnosis"
              level="primary"
              label={t('Primary Diagnosis')}
              name="primaryDiagnosis"
            />
            {configs.secondary_diagnosis && (
              <MultiSelect
                formik={formik}
                type="diagnosis"
                level="secondary"
                name="secondaryDiagnosis"
                label={t('Secondary Diagnosis')}
              />
            )}
            <MultiSelect formik={formik} type="staffs" label={t('Assigned HCP')} name="staffs" />
            <Pharmacy formik={formik} />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <FormControlLabel
              sx={{ ml: 1 }}
              control={<Checkbox />}
              checked={sendInvitation}
              label={t('Send invitation')}
              onChange={() => setSendInvitation(!sendInvitation)}
            />
            <Button onClick={onClose} color="secondary">
              {t('Cancel')}
            </Button>
            <LoadingButton
              data-cy="account-creation-submit"
              type="submit"
              variant="contained"
              loading={formik.isSubmitting}
            >
              {t('Submit')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Layout>
  );
};

export default AccountCreationDialog;
