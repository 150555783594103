import React, { useState } from 'react';
import { Typography, TextField } from '@mui/material';
import { inputStyle } from './style';

function trimmed(valStr) {
  return valStr.replace(/[-+,()\s]/g, '');
}

export function TextInput(props) {
  const {
    disabled,
    unit = '',
    name,
    type = '',
    values,
    onChange,
    onKeyPress,
    spellCheck = false,
    autoComplete = 'off',
    placeholder,
    autoFocus = false,
    asEnd = true,
    elementWidth = { width: '145px' },
    elementHeight = { height: '32px' },
    padding = '8px 8px 8px 12px',
    maxEndLength = 3,
    trim = true,
    size = 'small', // medium
    fontStyle = { fontSize: '14px', color: '#95989a' },
    inputStyling = asEnd ? {
      // inside input
      width: elementWidth.width,
      textAlign: 'right',
      fontSize: '14px',
      padding: '8px 0px 8px 5px',
    } : {
      // inside input
      width: elementWidth.width,
      textAlign: 'left',
      fontSize: '14px',
      padding: padding,
    },
  } = props;
  const [val, setVal] = useState(values);

  const handleChanges = (event) => {
    trim ? setVal(trimmed(event.target.value)) : setVal(event.target.value);
    onChange && onChange(event);
  };

  return (
    <div
      style={{ ...inputStyle.wrapper, ...elementHeight, ...elementWidth }}
    >

      <TextField
        type={type}
        name={name}
        disabled={disabled}
        value={val}
        size={size}
        autoFocus={autoFocus}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        variant="outlined"
        autoComplete={autoComplete}
        inputProps={{
          style: inputStyling,
        }}
        onChange={handleChanges}
        style={{ ...elementWidth, ...elementHeight }}
      />
    </div>
  );
}

export function TextWithLabel(props) {
  const {
    disabled,
    unit = '',
    name,
    type = '',
    values,
    onChange,
    onKeyPress,
    spellCheck = false,
    autoComplete = 'off',
    placeholder,
    autoFocus = false,
    asEnd = true,
    label,
    width,
    padding,
    onBlur,
    hasError,
    errorMessage,
    isInValid, // can display message without turning red in error.
    maxEndLength = 3,
    trim = true,
    size = 'medium', // medium
    fontStyle = { fontSize: '14px', color: '#95989a' },

  } = props;
  const [val, setVal] = useState(values);

  const handleChanges = (event) => {
    trim ? setVal(trimmed(event.target.value)) : setVal(event.target.value);
    onChange && onChange(event);
  };

  return (
    <TextField
      type={type}
      name={name}
      disabled={disabled}
      value={val}
      label={label}
      data-cy="input"
      size={size}
      onBlur={() => onBlur && onBlur()}
      autoFocus={autoFocus}
      onKeyPress={handleChanges}
      placeholder={placeholder}
      helperText={hasError ? errorMessage : isInValid ? errorMessage : null}
      error={hasError}
      variant="outlined"
      autoComplete={autoComplete}
      inputProps={{
        style: { fontSize: '14px', height: '15px' }, // height + pading makes height (52 or so)
      }}
      onChange={handleChanges}
      style={{ fontSize: '14px', width: width }}
    />
  );
}
