const searchMedication_style = {
  container: {
    width: "100%",
  },
  wrapper: {
    marginBottom: "24px",
  },
  header: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#454545"
  },
  header_text_select: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    // lineHeight: "1.14",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#454545",
  },
  header_text: {
    fontFamily: "Roboto",
    fontSize: "14px",
    color: "#95989a",
  },
  tableCell_brand_text: {
    fontFamily: "Roboto",
    fontSize: "13.5px",
    fontWeight: "500",
    color: "#454545",
    lineHeight: "1.2",
    textTransform: "capitalize",
    marginRight: "8px",
  },
  tableCell_text: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    color: "#454545",
    lineHeight: "1.2",
    textTransform: "capitalize",
    marginRight: "8px",
  },
  tableCell_dosage: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    color: "#454545",
    lineHeight: "1.2",

    marginRight: "14px",
  },

  subHeader: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#707070",
  },
  loader: {
    margin: "50px 0",
  },
  table_wrapper: {
    maxHeight: "45vh",
    // overflowX: "hidden",
    boxSizing: "border-box",
    overflowY: "overlay",
  },
  header_row: {},
  noResult_text: {
    margin: "50px 0",
  },
  radioWrapper: {},
  radioBtn: {
    color: "#09bbd0",
    padding: "0 10px",
    margin: "5px",
  },
  radioBtnOff: {
    color: "#454545",
    padding: "0 10px",
    margin: "5px",
  },
  flexWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    // paddingBottom: "5px",
    fontWeight: "400",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    color: "#95989a",
    fontSize: "14px",
  },
};

export default searchMedication_style;
