import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiPhoneNumber from 'material-ui-phone-number-2';

const PhoneNumber = (props) => {
  const { t } = useTranslation();
  const {
    formik, sx, label, name, disabled,
  } = props;

  return (
    <MuiPhoneNumber
      defaultCountry="ca"
      disabled={disabled}
      sx={sx || { mx: 1 }}
      onlyCountries={['ca', 'fr', 'de']}
      localization={{
        France: t('France'),
        Germany: t('Germany'),
        Canada: t('Canada'),
        'United States': t('United States'),
      }}
      disableAreaCodes
      name={name}
      label={label || t('Phone Number')}
      value={formik.values[name]}
      onChange={(value) => formik.setFieldValue(name, value)}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      variant="outlined"
    />
  );
};

export default PhoneNumber;
