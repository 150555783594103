import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box, Dialog, DialogContent, DialogActions,
  Button, MenuItem, Typography,
} from '@mui/material';
import { greyboxApiActions } from '../../redux/api';
import DialogTile from '../DialogTitle';
import CheckboxSelector from '../CheckboxSelector';
import { CARE_TEAM_CATEGORY } from './utils';
import { RootState } from '../../redux/store';
import { FormTextField, FormSelect } from '../form-inputs';
import Row from '../Row';
import {
  CareTeamType,
} from './interfaces';

interface PropsType {
  open: boolean;
  handleClose: () => void;
  selectedCareTeam?: string;
  hasPatient: boolean;
  setInviteOpen: (id: string) => void;
}

const CareTeamDialog = (props: PropsType) => {
  const { t } = useTranslation();
  const {
    open, handleClose, selectedCareTeam, setInviteOpen, hasPatient,
  } = props;
  const { careTeam } = greyboxApiActions;
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const { uuid } = useParams();
  const [createCareTeam, result] = careTeam.add();
  const [updateCareTeam] = careTeam.update();
  const { data, isLoading } = careTeam.list(
    { _id: selectedCareTeam }, { skip: !selectedCareTeam },
  );

  const edit = Boolean(selectedCareTeam);
  const [value, setValue] = React.useState(0);

  interface CategoryType {
    id: string;
    label: string;
  }

  interface Values {
    name: string;
    category: string[];
    status: string;
  }

  const formik = useFormik({
    initialValues: {
      name: data?.entry[0].resource.name || '',
      category: data?.entry[0].resource.category?.map((item) => item.coding[0].code) || [],
      status: data?.entry[0].resource.status || 'proposed',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t('Required')),
      category: Yup.array().of(Yup.string()),
      status: Yup.string().required(t('Required')),
    }),
    onSubmit: (values: Values) => {
      const body: CareTeamType = {
        name: values.name,
        clinic: clinic!.id,
        managing_organization: [{
          reference: `Organization/${clinic!.id}`,
        }],
        status: values.status,
        category: values.category.map((item) => ({ coding: [{ code: item }] })),
      };

      if (uuid) {
        body.subject = {
          reference: `Patient/${uuid}`,
        };
      }

      createCareTeam({ body }).then(() => {
        handleClose();
      });
    },
  });

  if (isLoading) {
    return null;
  }

  const handleSelect = ({ id }: CategoryType) => {
    const { category } = formik.values;
    if (category.includes(id)) {
      formik.setFieldValue('category', formik.values.category.filter((item) => item !== id));
    } else {
      formik.setFieldValue('category', [...formik.values.category, id]);
    }
  };

  const handleDelete = () => {
    updateCareTeam({ id: selectedCareTeam, body: { status: 'entered-in-error' } });
    handleClose();
  };

  const disabled = formik.isSubmitting || result.isLoading;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTile onClose={handleClose}>
        {edit ? t('Edit Care Team') : t('Create Care Team')}
      </DialogTile>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Row>
            <FormTextField
              label={t('Name')}
              name="name"
              formik={formik}
              required
            />
            <FormSelect
              formik={formik}
              required
              name="status"
              label={t('Status')}
            >
              <MenuItem value="active">{t('Active')}</MenuItem>
              <MenuItem value="proposed">{t('Draft')}</MenuItem>
              <MenuItem value="inactive">{t('Disabled')}</MenuItem>
            </FormSelect>
          </Row>
          <Box sx={{ mt: 2, p: 1 }}>
            <Typography variant="h6">
              {t('Category')}
            </Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {Object.entries(CARE_TEAM_CATEGORY).map(([key, value]) => (
                <CheckboxSelector
                  key={key}
                  id={key}
                  selected={formik.values.category.includes(key)}
                  onChange={handleSelect}
                >
                  {{ id: key, label: t(value) }}
                </CheckboxSelector>
              ))}
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          {edit && (
            <Button
              onClick={() => handleDelete()}
              color="error"
            >
              {t('Delete')}
            </Button>
          )}
          <Button onClick={handleClose} disabled={disabled}>
            {t('Cancel')}
          </Button>
          <LoadingButton
            data-cy="care-team-submit"
            type="submit"
            disabled={disabled}
            loading={formik.isSubmitting}
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CareTeamDialog.defaultProps = {
  selectedCareTeam: null,
};

export default CareTeamDialog;
