import React from 'react';

import { Typography, Tooltip, Fade } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
  tooltip: {
    backgroundColor: 'rgb(99, 115, 129)',
  },
  arrow: { color: 'rgb(99, 115, 129)' },
});

const CustomTooltip = withStyles(styles)(Tooltip);

export const Tooltips = React.forwardRef((props, ref) => {
  const {
    children, // the component form
    text = 'tooltipText',
    delayTime = 900,
    delayBeforeOut = 10,
    placement = 'bottom',
    background = 'rgb(99, 115, 129)',
    textColor = '#FFFFFF',
    elevation = 0, // dropShadow
    arrow = true, // if you need a pointer triangle
    customPos = {},
  } = props;

  return (
    <>
      <CustomTooltip
        background={background}
        enterDelay={delayTime}
        leaveDelay={delayBeforeOut}
        style={{ ...customPos }}
        ref={ref}
        // enterNextDelay={600}
        placement={placement}
        title={(
          <>
            <div
              style={{
                fontSize: '13px',
                fontFamily: 'Roboto',
                color: textColor,
                padding: '2px',
                // transform: "translateX(15px)",
              }}
            >
              {text}
            </div>
          </>
        )}
        arrow={arrow}
      >
        {children}
      </CustomTooltip>
    </>
  );
});
