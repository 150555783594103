import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import AnswerFormatter from './AnswerFormatter';
import { formatPhoneNumber } from '../../../utils';

const PrintableAnswers = forwardRef(({ answers, questionnaire, patient }, ref) => {
  const { t, i18n } = useTranslation();

  if (!answers || !questionnaire || !patient) return null;

  return (
    <Box sx={{ p: 4 }} ref={ref}>
      <Box display="flex">
        <div>
          <Typography variant="h5">{questionnaire.name}</Typography>
          {answers.score && (
            <Typography variant="h6">
              {`${t('Score')}: `}
              {answers.score.value}
              {questionnaire.max_score && `/${questionnaire.max_score}`}
              {' '}
              {`(${answers.score.display ? answers.score.display[0].value[i18n.resolvedLanguage] : t(answers.score.value_text)})`}
            </Typography>
          )}
        </div>
        <Box sx={{ ml: 'auto' }}>
          <Typography variant="caption">
            {`${t('Answered on')} ${moment(answers.answer_timestamp).format('lll')}`}
            {(answers.created_by && patient.user.id !== answers.created_by?.id) && (
              <>
                <br />
                {`${t('By')} ${answers.created_by.first_name} ${answers.created_by.last_name}`}
              </>
            )}
          </Typography>
          <br />
          <Typography variant="caption">
            {`${t('For')} ${patient.firstName} ${patient.lastName}`}
            <br />
            {patient.birthDate && (
              <>
                {`${t('Birth Date')}: ${moment(patient.birthDate).format('ll')}`}
                <br />
              </>
            )}
            {patient.phoneNumber && (
              `${t('Phone')}: ${formatPhoneNumber(patient.phoneNumber)}`
            )}
          </Typography>
          <br />
          <Typography variant="caption">
            {patient.hin && (
              `${patient.hin.hin_number}`
            )}
          </Typography>
        </Box>
      </Box>
      <Box mt={-1}>
        {questionnaire.questions.map((question) => (
          <div key={question.id}>
            <AnswerFormatter key={question.id} question={question} data={answers} />
            <hr />
          </div>
        ))}
      </Box>
    </Box>
  );
});

export default PrintableAnswers;
