const pharma_style = {
    pharmaPlanWrapper:{
        backgroundColor: "white", 
        marginTop: "0px", 
        width: "100%"
    },
    pharmaPlanTitle:{
        fontSize: "20px",
        color: "#454545",
        fontFamily: "Roboto",
        fontWeight: "500",
        // marginBottom: "8px",
        marginTop: '5px'
    },
    pharmaPlanSubTitle: {
        fontSize: "14px",
        fontFamily: "Roboto",
        fontWeight: 400,
        color: "#707070",
        // lineHeight: 1.43,
        marginBottom: "0px",
    },
    separator: {
        height: "2px",
        margin: "10px 20px 18px 1px",
        opacity: 0.4,
        background: "#707070",
      },
      header: {
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: 500,
        color: "#454545",
        position: "relative",
        width: '100%',
        marginBottom: "0px",
        // display: 'block'
      },
      
      subTitle: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        color: "#707070",
        marginBottom: "11px",
        position: "relative",
        transform: "translateY(-5px)",
        width: '100%'
        // display: 'block'
      },
    pharmaPlanSubTitleLast: { //should be there
        marginBottom: "20px",
        fontSize: "14px",
        fontWeight: 400,
        color: "#707070",
        lineHeight: 1.43,
        marginBottom: "20px",
    },
    DialogTitle: {
        marginTop: '20px', 
        marginBottom: '20px',
        fontSize: "20px", 
        fontFamily: "Roboto", 
        textAlign: 'center', 
        fontWeight: '300'
    },
    missingDialogText:{
        fontSize: '16px'
    },
    noInputDialogText: {
        fontSize: '16px',
        textAlign: 'center'
    },
    graphCard: {
        backgroundColor: "#fafafa",
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16)",
        borderRadius: "5px",
        height: "450px"
    },
    graphTitle:{
        fontSize: '16px', 
        color: '#454545', 
        fontFamily: 'Roboto', 
        marginLeft: '20px',
        marginTop: "20px", 
        width:'200px'
    },
    newPlanBtn:{
        width: "178px",
        height: "35px",
        borderRadius: "2px",
        boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#09bbd0",
        fontSize: "14px",
        fontWeight: "500",
        color: "#ffffff",
        fontFamily: "Roboto",
        marginLeft: "1044px",
        top: "-20px"
    },
    noPlanDivOne: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "left",
        color: "#747474",
        marginBottom: "10px"
    },
    noPlanDivTwo: {
        fontFamily: "Roboto",
        fontSize: "14px",
        textAlign: "left",
        fontWeight: "normal",
        color: "#95989a",
        marginBottom: "15px"
    },
    noPlanBtn: {
        width: "138px",
        height: "35px",
        color: "white",
        fontSize: "14px",
        backgroundColor: "#09bbd0",
        borderRadius: "2px",
        boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.16)"
    },
    errorsDialogDissmissBtn: {
        display: "block",
        margin: "0 auto",
        marginTop: "20px",
        width: "138px",
        height: "35px",
        color: "white",
        fontSize: "14px",
        backgroundColor: "#09bbd0",
        borderRadius: "2px",
        boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.16)"
    },

    graphLegend: {
        fontSize: "12px", 
        fontFamily: "Roboto", 
        color: "#454545", 
        textTransform : "capitalize",
        top: "-35px",
        marginLeft: "500px"
    },
    table: {
      maxHeight: "188px",
      overflow: "auto" 
    },
    tableHeaderRow: {
        backgroundColor: "#e7eaed", 
        height: "40px",
        borderRight: "3px solid #dedede",
        borderLeft: "3px solid #dedede",
        borderTop: "3px solid #dedede"
    },
    tableHeaderCell: {
        color: "#454545", 
        fontWeight: "500", 
        fontSize: "13px", 
        align: "right",
        paddingLeft: "15px",
        paddingRight: "45px"
    },
    tableHeaderCellDC: {
        color: "#454545", 
        fontWeight: "500", 
        fontSize: "13px", 
        align: "right",
        borderRight: "3px solid #dedede",
        paddingRight: "60px"
    },
    tableDataComment: {
        color: "#454545", 
        fontWeight: "500", 
        fontSize: "13px", 
        align: "right",
        paddingLeft: "15px",
        paddingRight: "45px",
        width: "330px"
    },
    tableBody: {
        border: "3px solid #dedede",
        overflow: "auto"
    },
    tableRow: {
        height: "40px"
    }
}

export default pharma_style