import React from 'react';
import { SvgIcon } from '@mui/material';

const BloodPressureIcon = ({ style }) => (
  <SvgIcon style={style} viewBox="0 0 37 37">
    <g>
      <circle
        fill="#ff8225"
        data-name="Ellipse 6"
        cx="17.5"
        cy="17.5"
        r="17.5"
      />
      <path
        fill="#fff"
        data-name="bp icon"
        d="M26.39,23.3c-1.5-2.32-6.78-1.38-6.78-1.38l-5.38.67s-1.38.18-1.6-.65.85-1.26.85-1.26,8-3.21,8-10a4.25,4.25,0,0,0-4.3-4.19A4.3,4.3,0,0,0,13.79,8.1a4.36,4.36,0,0,0-3.41-1.6,4.25,4.25,0,0,0-4.31,4.19h0v.26c0,4.5,3.89,7.36,6.22,8.68a2.27,2.27,0,0,0-.89,2.87c.64,1.67,2.9,1.32,2.9,1.32l7.14-.89s3.14-.27,3.81.93-.65,2.28-1,2.45a5.17,5.17,0,0,0-3.69.27c-2.08.87-3.36,2.56-2.85,3.78s2.61,1.51,4.69.64c1.87-.78,3.08-2.22,2.95-3.39,2.41-2.45,1.07-4.31,1.07-4.31ZM10.08,9.76a.214.214,0,0,1,.29-.3,24,24,0,0,1,3.77,2.88c1,1,.37,1.43.37,1.43s-.46.62-1.44-.34a24.58,24.58,0,0,1-3-3.67Z"
      />
    </g>
  </SvgIcon>
);

export { BloodPressureIcon };
