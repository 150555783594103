import { createSlice } from '@reduxjs/toolkit';
import api from './api';

export interface ClinicType {
  id: number;
  name: string;
  email: string;
  phone_num: string;
  address: string;
  lat: string;
  lng: string;
  config: any;
  backend_config: any;
  support_config: any;
  region: string;
}

interface ClinicState {
  clinic: ClinicType | null;
  vitalsConfig: string[];
  questionnaires: any[];
  customsVitals: any[];
  thresholds: any;
  isUninitialized: boolean;
  isLoading: boolean;
}

const initialState: ClinicState = {
  isUninitialized: true,
  thresholds: {},
  clinic: null,
  isLoading: false,
  vitalsConfig: [],
  questionnaires: [],
  customsVitals: [],
};

/**
 * Slice indicating which clinic the user is currently seeing.
 * Responsible of dispatching all configuration specific to the clinic.
 */
const clinic = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    clinicLoading: (state) => {
      state.isLoading = true;
    },
    clinicLoaded: (state, action) => {
      state.isUninitialized = false;
      state.isLoading = false;
      state.questionnaires = action.payload.questionnaires;
      state.clinic = action.payload.clinic;
      state.vitalsConfig = action.payload.vitalsConfig;
      state.thresholds = action.payload.thresholds;
    },
  },
});

const { clinicLoading, clinicLoaded } = clinic.actions;

const setCurrentClinic = (company) => async (dispatch) => {
  dispatch(clinicLoading());
  const { backend_config } = company;

  // Clinic will stay the same on refresh
  localStorage.setItem('clinic', (company.id).toString());
  const questionnaires = await dispatch(
    api.endpoints.listQuestionnaire.initiate({ clinic: company.id }),
  );

  const measurementConfig = await dispatch(
    api.endpoints.listMedicalMeasurementConfig.initiate({ clinic: company.id }),
  );

  const config = measurementConfig.data.filter((m) => m.category.includes('vital-signs'));
  const vitalsConfig = {};
  config.forEach((vital) => {
    vitalsConfig[vital.short_code] = vital;
  });
  dispatch(clinicLoaded({
    clinic: company,
    vitalsConfig: vitalsConfig,
    questionnaires: questionnaires.data || [],
    thresholds: backend_config.thresholds,
  }));
};

export { setCurrentClinic };
export default clinic.reducer;
