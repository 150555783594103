import React from 'react';
import {
  Box, Dialog, DialogContent, Typography, Table, TableHead, TableRow,
  TableCell, TableBody, FormGroup, FormControlLabel, Checkbox, FormControl,
  FormLabel, RadioGroup, Radio, DialogActions, Button, Divider
} from '@mui/material';
import {
  PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell
} from 'recharts';
import DialogTitle from '../../DialogTitle';
import { Score } from '../../../types';

const COLORS = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#F86624']; 

const parseScore = (score: Score) => (
  Object.entries(score.target_weights).map(([key, value]: [string, number]) => ({
    name: key,
    value: value,
  }))
);

const CustomTooltip = ({ active, payload }: any) => {

  if (active && payload && payload.length) {
     return (
       <Box bgcolor="white" p={2} borderRadius={2}>
        <Typography>
          {`${payload[0].name} : ${Math.round(payload[0].value * 100)} %`}
        </Typography>
       </Box>
     );
   }
 
   return null;
 };
 

type PropsType = {
  score: Score;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ScoreDialog = ({ score, open, setOpen }: PropsType) => {
  const data = parseScore(score);
  const [dirty, setDirty] = React.useState(false);
  const [opacity, setOpacity] = React.useState(Object.fromEntries(data.map((d) => [d.name, 1])));
  const [answers, setAnswers] = React.useState(null);

  const onMouseEnter = (o: any) => {
    const { value } = o;
    setOpacity({
      ...opacity,
      [value]: 0.5,
    });
  };


  const onMouseLeave = (o: any) => {
    const { value } = o;
    setOpacity({
      ...opacity,
      [value]: 1,
    });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
      <DialogTitle onClose={() => setOpen(false)} />
      <DialogContent sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      >
        <Box sx={{ maxWidth: '900px' }}>
          <Typography>
            Voici un graphique représentant les facteurs qui sont les plus importants pour vous suite aux réponses que vous avez données dans le questionnaire.
          </Typography>
          <br />
          <Typography>
            Les facteurs sont représentés en ordre d’importance : plus la pointe de tarte est grande, plus ce facteur semble important pour vous. En cliquant sur chaque section de la pointe de tarte, vous pouvez également voir le pourcentage que vous avez attribué à chacun des facteurs présentés dans le questionnaire : plus le pourcentage est élevé, plus ce facteur semble important pour vous.
          </Typography>
          <Box height={600} width="100%">
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  dataKey="value"
                  data={data}
                  fill="#8884d8"
                >
                {data.map((entry, index) => (
                  <Cell 
                  key={`cell-${index}`} 
                  opacity={opacity[entry.name]}
                  fill={COLORS[index % COLORS.length]} />
                ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
              </PieChart>
            </ResponsiveContainer>
          </Box>
          <Typography sx={{my: 3}}>
            Pour chacun des facteurs présentés ci-dessus, voici un tableau détaillant les informations pour chaque test de dépistage prénatal. Si vous avez ajouté des facteurs personnalisés au début du questionnaire, il est à noter que les informations détaillées pour ces facteurs ne seront pas présentées dans le tableau. Toutefois, il est tout à fait légitime que ces facteurs soient importants pour vous et ceux-ci devraient être considérés pour prendre votre décision.
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Facteur </b>
                </TableCell>
                <TableCell>
                  <b>
                    Test biochimique avec ou sans clarté nucale
                    <sup>a</sup>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    Test génomique
                    <sup>b</sup>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Semaine de grossesse à laquelle le test est fait
                </TableCell>
                <TableCell>
                  Prise de sang :
                  1ere : 10e – 13e semaine de grossesse
                  2e : 14e – 16e semaine de grossesse
                  Clarté nucale
                  <sup>a</sup>
                  {' '}
                  : 11e – 14e semaine
                </TableCell>
                <TableCell>
                  1 prise de sang entre la 15e et la 17e semaine s’il est effectué après un test biochimique qui a indiqué un risque élevé

                  1 prise de sang dès la 10e semaine s’il est effectué comme premier test de dépistage
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Délai d’attente des résultats
                </TableCell>
                <TableCell>
                  Résultats disponibles entre la 15e et 17e semaine
                </TableCell>
                <TableCell>
                  Entre la 16e et la 18e semaine si le test est effectué après un test biochimique qui a indiqué un risque élevé

                  Dès la 11e semaine s’il est effectué comme premier test de dépistage
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Possibilité d’être inquiétée à tort par les résultats (risque de faux-positif)
                </TableCell>
                <TableCell>
                  2,8 à 3% des femmes
                </TableCell>
                <TableCell>
                  Moins de 1% des femmes
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Coût
                </TableCell>
                <TableCell>
                  Offert gratuitement dans le cadre du Programme québécois de dépistage prénatal
                </TableCell>
                <TableCell>
                  Offert gratuitement dans le cadre du Programme québécois de dépistage prénatal s’il est effectué suite à un test biochimique qui a indiqué un risque élevé ou si la femme répond à certains critères d’éligibilitéb

                  Quelques centaines de dollars dans les cliniques privées si ce test est effectué comme premier test de dépistage et que la femme ne répond pas aux critères d’éligibilité du Programmeb

                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Taux de détection
                </TableCell>
                <TableCell>
                  85 à 90% des cas de trisomie 21 sont détectés
                </TableCell>
                <TableCell>
                  84 à 89% des cas de trisomie sont détectés s’il est effectué après un test biochimique montrant un risque élevé

                  Plus de 99% des cas de trisomie 21 sont détectés s’il est effectué comme premier test de dépistage

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography>
            <sup>a</sup>
            La clarté nucale est offerte lorsque disponible dans la région.
          </Typography>
          <br />
          <Typography>
            <sup>b</sup>
            Dans le cadre du Programme québécois de dépistage prénatal, le test génomique est offert gratuitement aux femmes qui ont obtenu un résultat de test biochimique indiquant un risque élevé de trisomie. Il est aussi offert gratuitement comme premier test de dépistage aux femmes qui répondent aux critères suivants :
          </Typography>
          <ul>
            <li>
              <Typography>
                La femme a eu une grossesse précédente dont le bébé avait la trisomie 21, la trisomie 18 ou la trisomie 13
              </Typography>
            </li>
            <li>
              <Typography>
                La femme sera âgée de 40 ans et plus au moment de l’accouchement
              </Typography>
            </li>
            <li>
              <Typography>
                La femme est enceinte de jumeaux (deux fœtus)
              </Typography>
            </li>
            <li>
              <Typography>
                Le test est prescrit à la suite d’une consultation en médecine génétique
              </Typography>
            </li>
          </ul>
          <Divider sx={{my: 4}}/>
          <Typography>
            <b>
              Compte tenu des informations sur chaque test et des facteurs qui sont les plus importants pour vous, quelle est votre décision concernant le choix du test de dépistage prénatal?
            </b>
          </Typography>
          <FormControl sx={{ mx: 3 }} component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox />
                }
                label="Test biochimique avec ou sans clarté nucale"
              />
              <FormControlLabel
                control={
                  <Checkbox />
                }
                label="Test génomique "
              />
              <FormControlLabel
                control={
                  <Checkbox />
                }
                label="Aucun test"
              />
            </FormGroup>
          </FormControl>
          <Typography>
            Une fois que vous avez fait un choix concernant le test de dépistage, nous vous invitons à compléter le questionnaire suivant afin de vérifier si vous êtes confortable avec votre décision.
          </Typography>
          <Divider sx={{my: 4}}/>
          <Typography>
            <b>
              Êtes-vous confortable avec votre décision?
            </b>
          </Typography>
          <FormControl sx={{ mx: 3 }} onChange={() => setDirty(true)}>
            <FormLabel id="demo-radio-buttons-group-label">
              Êtes-vous certain.e que c’est le meilleur choix pour vous?
            </FormLabel>
            <RadioGroup onChange={(e) => setAnswers(prev => ({ ...prev, question1: e.target.value }))}>
              <FormControlLabel value="yes" control={<Radio />} label="Oui" />
              <FormControlLabel value="no" control={<Radio />} label="Non" />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ mx: 3 }}>
            <FormLabel id="demo-radio-buttons-group-label">
              Est-ce que vous connaissez les bons et les moins bons côtés de chacune des options?
            </FormLabel>
            <RadioGroup onChange={(e) => setAnswers(prev => ({ ...prev, question2: e.target.value }))}>
              <FormControlLabel value="yes" control={<Radio />} label="Oui" />
              <FormControlLabel value="no" control={<Radio />} label="Non" />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ mx: 3 }}>
            <FormLabel>
              Avez-vous le sentiment de savoir ce qui est le plus important pour vous à l’égard des bons et des moins bons côtés associés à chacune des options?
            </FormLabel>
            <RadioGroup onChange={(e) => setAnswers(prev => ({ ...prev, question3: e.target.value }))}>
              <FormControlLabel value="yes" control={<Radio />} label="Oui" />
              <FormControlLabel value="no" control={<Radio />} label="Non" />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ mx: 3 }}>
            <FormLabel>
              Avez-vous assez de soutien pour faire votre choix?
            </FormLabel>
            <RadioGroup onChange={(e) => setAnswers(prev => ({ ...prev, question4: e.target.value }))}>
              <FormControlLabel value="yes" control={<Radio />} label="Oui" />
              <FormControlLabel value="no" control={<Radio />} label="Non" />
            </RadioGroup>
          </FormControl>
        </Box>
        {dirty
        && (
          <Box sx={{ mt: 2 }}>
            {Object.values(answers).includes('no') ? (
              <Typography>
                <b>
                  Vous n’êtes pas confortable avec votre décision? Nous vous conseillons de consulter à nouveau les informations présentées dans cet outil et de discuter avec vos proches et l’équipe de professionnel.les de la santé qui vous accompagne. 
                </b>
              </Typography>
            ) : (
              Object.values(answers).every(answer => answer === 'yes') && <Typography>
                <b>
                  Vous êtes confortable avec votre décision!
                </b>
              </Typography>
            )}
          </Box>
        )}
        <Button onClick={() => setOpen(false)} sx={{ mt: 2 }}>
          Fermer
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ScoreDialog;
