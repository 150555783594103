import React from 'react';
import { DatePicker as MuiDatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormikProps } from 'formik';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface PropsType {
  formik: FormikProps<any>;
  name: string;
  label: string;
  time?: boolean;
  disablePast?: boolean;
  minDate?: moment.Moment;
  openTo?: 'day' | 'year' | 'month';
  disableFuture?: boolean;
}

const DatePicker = (props: PropsType) => {
  const { i18n } = useTranslation();
  const {
    formik, name, label, time = false, disablePast = false,
    disableFuture = false, minDate, openTo = 'day',
  } = props;

  return (
    <Box sx={{ mx: 1 }}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
        {time ? (
          <DateTimePicker
            onChange={(date) => formik.setFieldValue(name, date)}
            sx={{ width: '100%' }}
            disablePast={disablePast}
            disableFuture={disableFuture}
            openTo={openTo}
            ampm={false}
            label={label}
            value={formik.values[name]}
            minDate={minDate}
            slotProps={{
              textField: {
                id: name,
              },
            }}
          />
        ) : (
          <MuiDatePicker
            onChange={(date) => formik.setFieldValue(name, date)}
            sx={{ width: '100%' }}
            disablePast={disablePast}
            disableFuture={disableFuture}
            openTo={openTo}
            label={label}
            value={formik.values[name]}
            minDate={minDate}
            slotProps={{
              textField: {
                id: name,
              },
            }}
          />
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default DatePicker;
