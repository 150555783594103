import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import FormSelect from './FormSelect';

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

const Sex = (props: PropsType) => {
  const { t } = useTranslation();
  return (
    <FormSelect
      {...props}
      name="gender"
      label={t('Sex')}
    >
      <MenuItem value="M">{t('Male')}</MenuItem>
      <MenuItem value="F">{t('Female')}</MenuItem>
      <MenuItem value="U">{t('Not Specified')}</MenuItem>
    </FormSelect>
  );
};

Sex.defaultProps = {
  required: false,
  sx: null,
};

export default Sex;
