import { useTranslation } from 'react-i18next';

export const QUICK_RANGES = ['all', '5m', '15m', '30m', '1h', '3h', '6h', '12h', '24h', '2d', '7d', '14d', '30d', '90d', '6M', '1y', '2y', '5y'];

const getQuickRange = () => {
  const { t } = useTranslation();

  return [
    {
      count: 0,
      id: 'all',
      label: t('Since registration'),
    },
    {
      count: 5,
      unit: 'm',
      id: '5m',
      label: t('Last 5 minutes'),
    },
    {
      count: 15,
      unit: 'm',
      id: '15m',
      label: t('Last 15 minutes'),
    },
    {
      count: 30,
      unit: 'm',
      id: '30m',
      label: t('Last 30 minutes'),
    },
    {
      count: 1,
      unit: 'h',
      id: '1h',
      label: t('Last hour'),
    },
    {
      count: 3,
      unit: 'h',
      id: '3h',
      label: t('Last 3 hours'),
    },
    {
      count: 6,
      unit: 'h',
      id: '6h',
      label: t('Last 6 hours'),
    },
    {
      count: 12,
      unit: 'h',
      id: '12h',
      label: t('Last 12 hours'),
    },
    {
      count: 24,
      unit: 'h',
      id: '24h',
      label: t('Last 24 hours'),
    },
    {
      count: 2,
      unit: 'd',
      id: '2d',
      label: t('Last 2 days'),
    },
    {
      count: 7,
      unit: 'd',
      id: '7d',
      label: t('Last 7 days'),
    },
    {
      count: 14,
      unit: 'd',
      id: '14d',
      label: t('Last 14 days'),
    },
    {
      count: 30,
      unit: 'd',
      id: '30d',
      label: t('Last 30 days'),
    },
    {
      count: 90,
      unit: 'd',
      id: '90d',
      label: t('Last 90 days'),
    },
    {
      count: 6,
      unit: 'M',
      id: '6M',
      label: t('Last 6 months'),
    },
    {
      count: 1,
      unit: 'y',
      id: '1y',
      label: t('Last year'),
    },
    {
      count: 2,
      unit: 'y',
      id: '2y',
      label: t('Last 2 years'),
    },
    {
      count: 5,
      unit: 'y',
      id: '5y',
      label: t('Last 5 years'),
    },
  ];
};

export default getQuickRange;
