import {
  Button, Dialog,
  DialogActions, DialogContent, Select,
  MenuItem, FormControl, InputLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useFormik } from 'formik';
import { DatePicker } from '../../form-inputs';
import Row from '../../Row';
import { greyboxApiActions } from '../../../redux/api';

const GenerateReportDialog = (props) => {
  const {
    open,
    handleClose,
  } = props;
  const { i18n, t } = useTranslation();
  const [reportType, setReportType] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeOpen, setIframeOpen] = useState(false);
  const { patientReport, patientReportClinic } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { uuid } = useParams();
  const [postReport, result] = patientReport.add();
  const { data, isLoading, refetch } = patientReportClinic.list({
    clinic: clinic.id,
  });

  const handleSubmit = async (values) => {
    const body = {
      type: 'pdf',
      report_clinic: reportType,
      patient: uuid,
      language: i18n.resolvedLanguage,
      status: 'pending',
      data: {
        range: {
          start_date: moment(values.startDate).toISOString(),
          end_date: moment(values.endDate).toISOString(),
        },
      },
    };
    const response = await postReport({ body: body });

    if (response.data && response.data.file) {
      setIframeUrl(response.data.file);
      setIframeOpen(true);
    }

    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      startDate: moment().subtract(1, 'months'),
      endDate: moment(),
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const closeIframe = () => {
    setIframeOpen(false);
    setIframeUrl('');
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Row>
            <DatePicker
              label={t('Start Date')}
              formik={formik}
              disableFuture
              name="startDate"
            />
            <DatePicker
              label={t('End Date')}
              disableFuture
              formik={formik}
              name="endDate"
            />
          </Row>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">{t('Report Type')}</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              sx={{ mx: 1, mt: 1 }}
              id="demo-simple-select-outlined"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
              label={t('Report Type')}
            >
              {data && data.map((report) => {
                if (typeof config === 'string') {
                  return <MenuItem key={report.id} value={report.id}>{report.name}</MenuItem>;
                }
                return (
                  <MenuItem key={report.id} value={report.id}>{report.name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={result.isLoading}>
            {t('Cancel')}
          </Button>
          <LoadingButton onClick={formik.handleSubmit} loading={result.isLoading}>
            {t('Generate')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={iframeOpen}
        onClose={closeIframe}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { height: '100%' } }} // This ensures the dialog paper fills the height
      >
        <DialogContent style={{ height: '100%' }}>
          <iframe src={iframeUrl} width="100%" height="100%" title="Report Preview" />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeIframe}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateReportDialog;
