const dosageUnitFormater = (lang, med_ai) => {
  let value = "";
  if (med_ai.strength_unit !== "nan") {
    if (med_ai.strength_unit_fr !== "nan") {
      if (lang === "fr") {
        value += med_ai.strength_unit_fr.toLowerCase();
      } else {
        value += med_ai.strength_unit.toLowerCase();
      }
    }
  }

  if (med_ai.dosage_unit !== "nan") {
    value += " / ";

    if (med_ai.dosage_unit !== "nan") {
      if (lang === "fr") {
        value += med_ai.dosage_unit_fr.toLowerCase();
      } else {
        value += med_ai.dosage_unit.toLowerCase();
      }
    }
  }
  return value;
};

const dosageFormater = (lang, med_ai) => {
  let value = "";
  //rounds to 5 digits after 0 ( only in search display )
  let strength = parseFloat(med_ai.strength).toFixed(6);
  if (med_ai.strength !== "nan" || med_ai.strength_unit !== "nan") {
    if (med_ai.strength !== "nan") {
      value += parseFloat(strength) + " ";
    }
    if (med_ai.strength_unit_fr !== "nan") {
      if (lang === "fr") {
        value += med_ai.strength_unit_fr.toLowerCase();
      } else {
        value += med_ai.strength_unit.toLowerCase();
      }
    }
  }

  if (med_ai.dosage_value !== "nan" || med_ai.dosage_unit !== "nan") {
    value += " / ";
    if (med_ai.dosage_value !== "nan") {
      value += parseFloat(med_ai.dosage_value) + " ";
    }
    if (med_ai.dosage_unit !== "nan") {
      if (lang === "fr") {
        value += med_ai.dosage_unit_fr.toLowerCase();
      } else {
        value += med_ai.dosage_unit.toLowerCase();
      }
    }
  }

  return value;
};
 
const ingredientNameFormater = (lang, med_ai) => {
  return (lang === "en" ?  med_ai.ingredient : med_ai.ingredient_fr === 'nan' ? med_ai.ingredient : med_ai.ingredient_fr).toLowerCase();
};

//todo , use it in ADD medication not only in edit and next step 
const dosageResponse = (posologiesArr, daily_dosage, dose, particularDose, short_code) => {
  let response;
  if (short_code === "Particular") {
    response = {
      daily_dosage,
      dosage_mode: "particular_posology",
      particular_posology: {
        short_code: "particular",
        morning_dosage: +particularDose.Morning,
        noon_dosage: +particularDose.Noon,
        afternoon_dosage: +particularDose.Afternoon,
        night_dosage: +particularDose.Supper,
        evening_dosage: +particularDose.Bedtime,
      },
    };
  } else {
    switch (short_code) {
      case "D.I.E":
        return (response = {
          daily_dosage,
          dosage_mode: "frequency_posology",
          frequency_posology: { ...posologiesArr[0], dosage: +dose },
        });
      case "B.I.D":
        return (response = {
          daily_dosage,
          dosage_mode: "frequency_posology",
          frequency_posology: { ...posologiesArr[1], dosage: +dose },
        });
      case "T.I.D":
        return (response = {
          daily_dosage,
          dosage_mode: "frequency_posology",
          frequency_posology: { ...posologiesArr[2], dosage: +dose },
        });
      case "Q.I.D":
        return (response = {
          daily_dosage,
          dosage_mode: "frequency_posology",
          frequency_posology: { ...posologiesArr[3], dosage: +dose },
        });
      case "default":
        return;
    }
  }
  return response;
};

export { dosageFormater, dosageUnitFormater, ingredientNameFormater, dosageResponse };
