import React, { useContext, useEffect } from 'react';
import {
  FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Box,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { EndingConditionType } from '../../../types';
import FormTextField from '../FormTextField';
import DatePicker from '../DatePicker';

const EndingConditionSelector = (props) => {
  const { formik } = props;
  const { endingCondition } = formik.values;
  const { t } = useTranslation();

  useEffect(() => {
    if (formik.dirty) {
      switch (endingCondition) {
        case EndingConditionType.None:
          formik.setFieldValue('endingOccurrencesNumber', undefined);
          formik.setFieldValue('endDate', null);
          break;
        case EndingConditionType.EndDate:
          formik.setFieldValue('endingOccurrencesNumber', undefined);
          formik.setFieldValue('endDate', undefined);
          break;
        case EndingConditionType.OccurrencesNumber:
          formik.setFieldValue('endDate', null);
          break;
        default:
          break;
      }
    }
  }, [endingCondition]);

  return (
    <FormControl component="fieldset" sx={{ m: 1 }}>
      <FormLabel component="legend">{t('Ends')}</FormLabel>
      <RadioGroup
        name="ends"
        value={endingCondition}
        onChange={(event) => formik.setFieldValue('endingCondition', event.target.value)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
          <FormControlLabel
            value={EndingConditionType.None}
            control={(
              <Radio />
            )}
            label={t('Never')}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
          <FormControlLabel
            value={EndingConditionType.EndDate}
            control={(
              <Radio />
            )}
            label={t('On date')}
          />
          { endingCondition === EndingConditionType.EndDate && (
            <DatePicker
              formik={formik}
              name="endingDate"
              disablePast
              label={t('End date')}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            value={EndingConditionType.OccurrencesNumber}
            control={(
              <Radio />
            )}
            label={t('After')}
          />
          { endingCondition === EndingConditionType.OccurrencesNumber
          && (
            <FormTextField
              name="endingOccurrencesNumber"
              sx={{ width: '100%' }}
              label={t('Occurrences number')}
              formik={formik}
              type="number"
              min={1}
            />
          )}
        </Box>
      </RadioGroup>
    </FormControl>
  );
};
export default EndingConditionSelector;
