import React from 'react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import Invitation from './Invitation';
import Login from './Login';
import SelfRegistration from './SelfRegistration';
import AuthenticateForQuestionnaire from './AuthenticateForQuestionnaire';

/**
 * Pages only accessible when unauthenticated. Everything that wraps this component
 * will be shown when the user is not authenticated wherever in the app.
 */
const UnauthenticatedPages = () => (
  <Switch>
    <Route exact path="/">
      <Login />
    </Route>
    <Route path="/invitation">
      <Invitation />
    </Route>
    <Route path="/self-registration">
      <SelfRegistration />
    </Route>
    <Route path="/questionnaires-token/">
      <AuthenticateForQuestionnaire />
    </Route>
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default UnauthenticatedPages;
