import { createSlice } from '@reduxjs/toolkit';

/**
 * Used when user enter app through an invitation link.
 */
const invitation = createSlice({
  name: 'invitation',
  initialState: {
    isInvitation: false,
  },
  reducers: {
    setIsInvitation: (state, action) => {
      state.isInvitation = true;
    },
  },
});

export const { setIsInvitation } = invitation.actions;
export default invitation.reducer;
