import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import DeleteMedicationModal from './DeleteMedicationModal';
import EditMedicationModal from './EditMedicationModal';
import StopMedicationModal from './StopMedicationModal';

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: ({ visible }) => (visible ? 1 : 0),
    width: 'fit-content',
    margin: '0 5px 0px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    transition: 'all 200ms 100ms ease',
    transform: 'translateY(0px)',
    filter: 'blur(0px)',
    position: 'relative',
  },
}));

const IconActions = (props) => {
  const {
    isItemHovered,
    medInfo,
    setModalOpen,
    isHistory,
    patientUUID,
    selectedMeds,
  } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const classes = useStyles({ visible: isItemHovered });
  const hasSelectedMeds = selectedMeds.length > 0;

  const handleEditModalOpen = (editToggle) => {
    setIsEdit(editToggle);
    setEditModalOpen(true);
  };

  return (
    <div className={classes.root}>
      {!isHistory && !medInfo.isNotSuggested && (
        <IconButton
          size="small"
          onClick={() => handleEditModalOpen(false)}
          disabled={hasSelectedMeds}
        >
          <ArrowForwardIcon />
        </IconButton>
      )}
      {!isHistory && (
        <IconButton
          size="small"
          onClick={() => handleEditModalOpen(true)}
          disabled={hasSelectedMeds}
        >
          <EditIcon />
        </IconButton>
      )}
      {editModalOpen && (
        <EditMedicationModal
          open={editModalOpen}
          handleClose={() => setEditModalOpen(false)}
          edit={isEdit}
          patientUUID={patientUUID}
          medInfo={medInfo}
          setModalOpen={setModalOpen}
          setHovered={null}
        />
      )}
      {!isHistory && (
        <IconButton
          size="small"
          disabled={hasSelectedMeds}
          onClick={() => setStopModalOpen(true)}
        >
          <NotInterestedIcon />
        </IconButton>
      )}
      {stopModalOpen && (
        <StopMedicationModal
          open={stopModalOpen}
          selectedMeds={[medInfo]}
          handleClose={() => setStopModalOpen(false)}
        />
      )}
      <IconButton
        size="small"
        onClick={() => setDeleteModalOpen(true)}
        disabled={hasSelectedMeds}
      >
        <DeleteOutlineIcon />
      </IconButton>
      {deleteModalOpen && (
        <DeleteMedicationModal
          open={deleteModalOpen}
          handleClose={() => setDeleteModalOpen(false)}
          selectedMedications={[medInfo]}
          patientUuid={patientUUID}
        />
      )}
    </div>
  );
};

export default IconActions;
