import { QuestionnaireManagementClinicType } from '../../../types';

const BASE_KEYS = ['role_acc_type', 'order', 'active', 'on_demand', 'recurrences', 'diagnoses', 'is_dashboard'];

type QuestionnaireFormType = {
  name_en: string;
  name_fr: string;
  name_de: string;
  name_es: string;
  name_it: string;
  name_pt: string;
  description_en: string;
  description_fr: string;
  description_de: string;
  description_es: string;
  description_it: string;
  description_pt: string;
  has_score: boolean;
  max_score: number;
  score_type: string;
  on_demand: boolean;
  active: boolean;
  is_dashboard: boolean;
  type: string;
  diagnoses: number[];
  recurrences: string;
  role_access: number[];
};

export const parseQuestionnaireValuesForPost = (
  values: Partial<QuestionnaireFormType>,
  questionnaireId: number,
) => {
  const body = {
    questionnaire: { id: questionnaireId },
  } as Partial<QuestionnaireManagementClinicType>;

  Object.keys(values).forEach((key) => {
    if (BASE_KEYS.includes(key)) {
      body[key] = values[key];
    } else {
      body.questionnaire[key] = values[key];
    }
  });
  return body;
};

export default parseQuestionnaireValuesForPost;
