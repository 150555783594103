import { MsalProvider } from '@azure/msal-react';
import {
  Alert, Snackbar,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { hot } from 'react-hot-loader/root';
import { useDispatch, useSelector } from 'react-redux';
import ErrorFallback from './pages/ErrorFallback';
import PageSelector from './pages/PageSelector';
import { setSnackbarStatus } from './redux/snackbarStatus';
import getTheme from './theme';

/**
 * Main application component. This is where we make sure the user is authenticated or not.
 * Only show Appbar and navigation bar when authenticated.
 */
const App = (props) => {
  const { instance } = props;
  const snackbarStatus = useSelector((state) => state.snackbarStatus);
  const { style } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const theme = getTheme(style);

  const handleSnackBarClose = () => {
    dispatch(setSnackbarStatus({ open: false, message: '' }));
  };

  return (
    <MsalProvider instance={instance}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          // eslint-disable-next-line no-console
          onError={(error, errorInfo) => console.error({ error, errorInfo })}
        >
          <PageSelector />
          {snackbarStatus.open && (
            <Snackbar
              open={snackbarStatus.open}
              autoHideDuration={5000}
              onClose={handleSnackBarClose}
            >
              <Alert onClose={handleSnackBarClose} severity={snackbarStatus.severity}>
                {snackbarStatus.message}
              </Alert>
            </Snackbar>
          )}
        </ErrorBoundary>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default hot(App);
