import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Paper, Button,
} from '@mui/material';
import { QuestionManagementType, QuestionnaireType } from '../../../../types';
import { greyboxApiActions } from '../../../../redux/api';
import QuestionsTableRow from './QuestionsTableRow';
import AddQuestionDialog from './AddQuestionDialog';
import TableSkeleton from '../../../skeletons/TableSkeleton';

type QuestionsTableProps = {
  questionnaire: QuestionnaireType;
};

const QuestionsTable = ({ questionnaire }: QuestionsTableProps) => {
  const { t } = useTranslation();
  const { questionManagement } = greyboxApiActions;
  const { data, isFetching } = questionManagement.list({ questionnaire: questionnaire.id }) as {
    data: QuestionManagementType[];
    isFetching: boolean;
  };
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRow = rows[dragIndex];
    setRows((prevSections) => {
      const newRows = [...prevSections];
      newRows.splice(dragIndex, 1);
      newRows.splice(hoverIndex, 0, dragRow);
      return newRows.filter((row) => row);
    });
  };

  if (isFetching) {
    return <TableSkeleton colCount={4} rowCount={5} />;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          startIcon={<AddIcon />}
          color="primary"
          sx={{ m: 1 }}
        >
          {t('Add')}
        </Button>
        <Table>
          <TableHead sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
            <TableRow>
              <TableCell />
              <TableCell>{t('Question')}</TableCell>
              <TableCell>{t('Type')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((question, index) => (
              <QuestionsTableRow
                key={question.id}
                question={question}
                moveRow={moveRow}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddQuestionDialog
        open={open}
        handleClose={handleClose}
        questionnaire={questionnaire}
      />
    </>
  );
};

export default QuestionsTable;
