import {
  QuestionManagementType, SAQuestion, RAQuestion, MCQuestion,
} from '../../../../types';
import { LANGUAGES } from '../../../../../locales/languages';
import { FormType } from './QuestionForm';

export const ALPHABET = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o'];

export const getQuestionText = (question: QuestionManagementType) => {
  const key = question.question.type_of_q.toLowerCase();
  return question[`${key}_question_text`];
};

export const localizeFields = (name: string, data: Partial<QuestionManagementType>)
  : Partial<QuestionManagementType> => {
  const formikFields = {};
  const type = data?.question.type_of_q.toLowerCase();

  LANGUAGES.forEach((lang) => {
    formikFields[`${name}_${lang.code}`] = '';
  });

  if (data) {
    Object.keys(formikFields).forEach((key) => {
      if (type && data[`${type}_${key}`]) {
        formikFields[key] = data[`${type}_${key}`];
      } else {
        formikFields[key] = data[key];
      }
    });
  }

  return formikFields;
};

export const getMcFields = (data: Partial<QuestionManagementType>) => {
  const formikFields = {} as FormType;
  if (!data || data.question.type_of_q !== 'MC') {
    return formikFields;
  }

  const MCData = data as MCQuestion;

  ALPHABET.forEach((letter) => {
    Object.assign(formikFields, localizeFields(`mc_question_choice_${letter}`, MCData));
  });
  formikFields.multi_select = MCData.multi_select;
  formikFields.mc_question_choice_other = MCData.mc_question_choice_other;

  return formikFields;
};

export const getSAFields = (data: QuestionManagementType) => {
  const formikFields = {} as FormType;
  if (!data || data.question.type_of_q !== 'SA') {
    return formikFields;
  }

  const SAData = data as SAQuestion;

  formikFields.ans_type = SAData.ans_type;
  formikFields.max_length = SAData.max_length;
  formikFields.min = SAData.min;
  formikFields.max = SAData.max;
  formikFields.allow_empty = SAData.allow_empty || false;

  return formikFields;
};

export const getRAFields = (data: QuestionManagementType) => {
  const formikFields = {} as FormType;
  if (!data || data.question.type_of_q !== 'RA') {
    return formikFields;
  }

  const RAData = data as RAQuestion;

  Object.assign(formikFields, localizeFields('start_label', data));
  Object.assign(formikFields, localizeFields('end_label', data));
  formikFields.start_range = RAData.start_range;
  formikFields.end_range = RAData.end_range;
  formikFields.increment = RAData.increment;

  return formikFields;
};

export const usedLetters = (data: QuestionManagementType): string[] => {
  const letters = [];

  ALPHABET.forEach((letter) => {
    if (data[`mc_question_choice_${letter}`]) {
      letters.push(letter);
    }
  });

  return letters;
};

export const getUnusedLetters = (data: string[]): string[] => (
  ALPHABET.filter((letter) => !data.includes(letter))
);

export const parsePointConfig = (pointConfig: { [key: string]: number }): { [key: string]: number } => {
  if (!pointConfig) {
    return {};
  }
  const points = {} as { [key: string]: number };

  if (pointConfig.True) {
    points.trueValue = pointConfig.True;
  }

  if (pointConfig.False) {
    points.falseValue = pointConfig.False;
  }

  ALPHABET.forEach((letter) => {
    const upper = letter.toUpperCase();
    if (pointConfig[upper]) {
      points[`${upper}Value`] = pointConfig[upper];
    }
  });

  return points;
};

export const parseFormikPointConfig = (data: FormType): { [key: string]: number } => {
  const points = {} as { [key: string]: number };

  if (data.trueValue) {
    points.True = data.trueValue;
  }

  if (data.falseValue) {
    points.False = data.falseValue;
  }

  ALPHABET.forEach((letter) => {
    const upper = letter.toUpperCase();
    if (data[`${upper}Value`]) {
      points[upper] = data[`${upper}Value`];
    }
  });

  return points;
};

export const addCustomFields = (
  baseBody: Partial<QuestionManagementType>,
  values: FormType,
): Partial<QuestionManagementType> => {
  if (values.type === 'RA') {
    return ({
      ...baseBody,
      start_range: values.start_range,
      end_range: values.end_range,
      increment: values.increment,
      start_label_en: values.start_label_en,
      start_label_fr: values.start_label_fr,
      start_label_de: values.start_label_de,
      start_label_es: values.start_label_es,
      start_label_it: values.start_label_it,
      start_label_pt: values.start_label_pt,
      end_label_en: values.end_label_en,
      end_label_fr: values.end_label_fr,
      end_label_de: values.end_label_de,
      end_label_es: values.end_label_es,
      end_label_it: values.end_label_it,
      end_label_pt: values.end_label_pt,
    }) as RAQuestion;
  }

  if (values.type === 'SA') {
    return ({
      ...baseBody,
      ans_type: values.ans_type,
      max_length: values.max_length,
      min: values.min,
      max: values.max,
      allow_empty: values.allow_empty,
    }) as SAQuestion;
  }
  if (values.type === 'MC') {
    const body = {
      ...baseBody,
      mc_question_choice_other: values.mc_question_choice_other,
      multi_select: values.multi_select,
    } as MCQuestion;

    LANGUAGES.forEach((lang) => {
      ALPHABET.forEach((letter) => {
        const key = `mc_question_choice_${letter}_${lang.code}`;
        // Explicitly set empty or undefined values to null
        body[key] = values[key] ? values[key] : null;
      });
    });
    return body;
  }
  return baseBody;
};
