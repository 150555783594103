import React from 'react';
import { SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  svgColor: {
    fill: ({ fills }) => theme.palette[fills].main,
  },

}));

const NextDoseOutlined = (props) => {
  const { viewBox = '0 0 30 30', fills = 'info' } = props;
  const classes = useStyles({ fills });
  return (
    <SvgIcon viewBox={viewBox}>
      <g>
        <path
          d="M13.8,3.2c-4.9,0-8.9,4.1-8.9,9v0.1l-2.2,0L5.9,16l3-3.7H6.7v-0.1c0-4,3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2s-3.2,7.2-7.2,7.2
c-1.8,0-3.6-0.7-4.9-2l-0.1-0.1l-1.2,1.2l0.1,0.1C9.4,20.1,11.7,21,14,21c4.9,0,8.9-4,8.9-8.9C22.8,7.2,18.7,3.2,13.8,3.2z"
          className={classes.svgColor}
        />
        <polygon
          points="10,13 15.3,13 12.5,16 13.7,17.2 18.6,12.3 13.7,7.5 12.5,8.6 15.3,11.4 10,11.4"
          className={classes.svgColor}
        />
      </g>
    </SvgIcon>
  );
};

export { NextDoseOutlined };
