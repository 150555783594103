import React from 'react';
import { SvgIcon } from '@mui/material';

export const XIcon = ({ viewBox, fills }) => (
  <SvgIcon viewBox={viewBox} fills={fills}>

    <path id="Path_129" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" data-name="Path 129" fill={fills} />
    <path id="Path_130" d="M0 0h24v24H0z" data-name="Path 130" fill="none" />
  </SvgIcon>
);
