import {
  Paper, DialogTitle, IconButton, Menu, MenuItem,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button,
  TablePagination, Skeleton, Stack, Box, Dialog, DialogContent, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import React, {
  useEffect, useState, useRef, forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NoData } from '../../tk-ui';
import TablePaginationSkeleton from '../../skeletons/TablePaginationSkeleton';
import TableSkeleton from '../../skeletons/TableSkeleton';
import ClinicalNoteModal from './ClinicalNoteModal';
import { greyboxApiActions } from '../../../redux/api';
import { formatPhoneNumber } from '../../../utils';
import TableWrapper from '../../TableWrapper';

const useStyles = makeStyles((theme) => ({
  noData: {
    transform: 'scale(4)',
  },
  container: {
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  addBtn: {
    marginRight: theme.spacing(1),
  },
}));

const PrintableNote = forwardRef(({ note }, ref) => {
  const { t } = useTranslation();
  const { account } = greyboxApiActions;
  const { data, isLoading } = account.get(note?.subject.reference.split('/')[1], { skip: !note });

  if (isLoading || !note) {
    return null;
  }

  return (
    <Box sx={{ p: 4 }} ref={ref}>
      <Box display="flex">
        <div>
          <Typography variant="h5">{note.summary}</Typography>
        </div>
        <Box sx={{ ml: 'auto' }}>
          <Typography variant="caption">
            {`${t('Created on')} ${moment(note.date).format('lll')}`}
            <>
              <br />
              {`${t('By')} ${note.assessor.display.firstName} ${note.assessor.display.lastName}`}
            </>
          </Typography>
          <br />
          <Typography variant="caption">
            {`${t('For')} ${data.firstName} ${data.lastName}`}
            <br />
            {data.birthDate && (
              <>
                {`${t('Birth Date')}: ${moment(data.birthDate).format('ll')}`}
                <br />
              </>
            )}
            {data.phoneNumber && (
              `${t('Phone')}: ${formatPhoneNumber(data.phoneNumber)}`
            )}
          </Typography>
          <br />
          <Typography variant="caption">
            {data.hin && (
              `${data.hin.hin_number}`
            )}
          </Typography>
        </Box>
      </Box>
      <ReactMarkdown>{note?.description}</ReactMarkdown>
    </Box>
  );
});

/**
 * Tab in patient profile to manage clinical notes.
 * Contain a table with paginated list of clinical notes from the API.
 * Modal to create or edit a clinical note will be displayed when clicking on the add button
 * or on the edit button of a clinical note.
 */
const ClinicalNotePanel = (props) => {
  const { uuid, user } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedToPrint, setSelectedToPrint] = useState(null);
  const { t } = useTranslation();
  const { clinicalImpression } = greyboxApiActions;
  const { edit } = useSelector((state) => state.permissions);
  const printRef = useRef();
  const {
    data, isFetching, refetch, isLoading,
  } = clinicalImpression.list({
    subject: `Patient/${uuid}`,
    page: page + 1,
    _sort: '-date',
    _count: rowsPerPage,
  });

  const columns = [
    {
      id: 'summary',
      label: t('Summary'),
    },
    {
      id: 'status',
      label: t('Status'),
      format: (value) => t(value),
    },
    {
      id: 'assessor',
      label: t('Entered by'),
      format: (value) => (value.display ? (
        `${value.display.firstName} ${value.display.lastName}`
      ) : '---'),
    },
    {
      id: 'date',
      label: t('Date'),
      format: (value) => moment(value).format('YYYY-MM-DD HH:mm'),
    },

    {
      id: 'meta',
      label: t('Last updated'),
      format: (value) => moment(value.lastUpdated).format('YYYY-MM-DD HH:mm'),
    },

  ];

  const handleClose = (triggerRefresh = false) => {
    setOpen(false);
    setSelected(null);
    if (triggerRefresh) {
      refetch();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: selectedToPrint?.summary,
  });

  useEffect(() => {
    if (selectedToPrint) {
      handlePrint();
    }
  }, [selectedToPrint]);

  useEffect(() => {
    if (selected) {
      setOpen(true);
    }
  }, [selected]);

  if (isLoading) return null;

  return (
    <Box width="100%">
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
        {t('Clinical Notes')}
      </Typography>
      <Button variant="contained" disabled={!edit} sx={{ mb: 1 }} startIcon={<AddIcon />} onClick={() => setOpen(true)}>
        {t('Add')}
      </Button>
      <TableWrapper
        total={data.total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        maxHeight="80vh"
        isFetching={isFetching}
        colCount={columns.length + 1}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>
                {column.label}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.total === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="200px"
                >
                  <NoData className={classes.noData} />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            data.entry.map((row) => (
              <Row
                key={row.resource.id}
                row={row}
                columns={columns}
                setSelected={setSelected}
                setSelectedToPrint={setSelectedToPrint}
              />
            ))
          )}
        </TableBody>
      </TableWrapper>
      { open && (
        <ClinicalNoteModal
          open={open}
          handleClose={handleClose}
          patientUuid={uuid}
          user={user}
          note={selected}
        />
      )}
      <Box sx={{ display: 'none' }}>
        <PrintableNote
          ref={printRef}
          note={selectedToPrint}
        />
      </Box>
    </Box>
  );
};

function Row(props) {
  const {
    row,
    columns,
    setSelected,
    setSelectedToPrint,
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuActions = (action) => {
    switch (action) {
      case 'edit':
        setSelected(row.resource);
        break;
      case 'print':
        setSelectedToPrint(row.resource);
        break;
      case 'view':
        setOpen(true);
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };

  return (
    <>
      <TableRow
        hover
        sx={{ height: 68 }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {columns.map((column, idx) => (
          <TableCell key={idx}>
            {column.format ? (
              column.format(row.resource[column.id])
            ) : (
              row.resource[column.id]
            )}
          </TableCell>
        ))}
        <TableCell sx={{ minWidth: '35px' }}>
          {(hover || menuOpen) && (
            <>
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => handleMenuActions('edit')}>
                  {t('Edit')}
                </MenuItem>
                <MenuItem onClick={() => handleMenuActions('view')}>
                  {t('View')}
                </MenuItem>
                <MenuItem onClick={() => handleMenuActions('print')}>
                  {t('Print')}
                </MenuItem>
              </Menu>
            </>
          )}
        </TableCell>
      </TableRow>
      {open && (
        <ViewDialog open={open} setOpen={setOpen} note={row} />
      )}
    </>
  );
}

const ViewDialog = (props) => {
  const { open, setOpen, note } = props;
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: note.resource.summary,
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <Box display="flex" sx={{ pr: 2 }}>
        <DialogTitle sx={{ width: '70%' }}>
          {note.resource.summary}
          <IconButton
            onClick={handlePrint}
            id="print-button"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <PrintIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent>
        <ReactMarkdown>
          {note.resource.description}
        </ReactMarkdown>
      </DialogContent>
      <Box sx={{ display: 'none' }}>
        <PrintableNote
          ref={printRef}
          note={note.resource}
        />
      </Box>
    </Dialog>

  );
};

export default ClinicalNotePanel;
