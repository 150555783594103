import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { LANGUAGES } from '../../../locales/languages';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {
    children, value, index,
  } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ p: 1 }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && (
        <Box sx={{ pt: 1, width: '100%' }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

const LocalWrapper = ({ children, name }) => {
  const [value, setValue] = React.useState(0);
  const langs = LANGUAGES.map((lang) => lang.code);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {langs.map((lang) => (
            <Tab label={lang} key={lang} />
          ))}
        </Tabs>
      </Box>
      {langs.map((lang, index) => (
        <CustomTabPanel value={value} index={index} key={lang}>
          {React.cloneElement(children, { name: `${name}_${lang}`, required: lang === 'en' })}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default LocalWrapper;
