import {
  es, enUS, fr, pt, it, de,
} from 'date-fns/locale';

/**
 * Removes national prefix from a phone number and formats it.
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '';
  }
  // Filter only numbers from the input
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumber;
};

export function getMediaTypeFromFileType(fileType: string) {
  const type = fileType.split('/')[0];

  switch (type) {
    case 'image':
      return 'image';
    case 'video':
      return 'video';
    case 'audio':
      return 'audio';
    default:
      return 'other';
  }
}

export function getExtensionValueFromType(extensions, type) {
  const extension = extensions.find((ext) => ext.url.includes(type));
  return extension ? extension.valueId : '';
}

export function getDateFnsLocale(locale: string) {
  switch (locale) {
    case 'en':
      return enUS;
    case 'es':
      return es;
    case 'fr':
      return fr;
    case 'pt':
      return pt;
    case 'it':
      return it;
    case 'de':
      return de;
    default:
      return enUS;
  }
}
