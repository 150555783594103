import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';
import { loginRequest } from '../authConfig';
import Home from '../components/Home';
import { useIsTakecareAuthenticated } from '../hooks';

const LoginContext = () => {
  const isAuthenticated = useIsTakecareAuthenticated();

  return (
    isAuthenticated && <Home />
  );
};

function Login() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <LoginContext />
    </MsalAuthenticationTemplate>
  );
}

export default Login;
