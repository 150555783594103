import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import {
  Button, Checkbox, CircularProgress, IconButton, Typography,
  LinearProgress, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, Box,
  DialogContent,
  Dialog,
  DialogActions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { usePatientWebSocket } from '../../../hooks';
import { greyboxApiActions } from '../../../redux/api';
import GenerateReportDialog from './GenerateReportDialog';
import PdfButton from '../components/PdfButton';
import { EditOutlined } from '@mui/icons-material';

const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(1),
  },
  deleteBtn: {
    borderColor: theme.palette.error,
    color: theme.palette.error,
  },
}));

const DownloadReportButton = (props) => {
  const { link, status } = props;
  const loading = status === 'pending' || status === 'in-progress';
  const [iframeUrl, setIframeUrl] = useState('');

  const closeIframe = () => {
    setIframeUrl('');
  };

  const onClick = () => {
    if (status === 'draft') {
      setIframeUrl(link);
    } else if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <>
      <Dialog
        open={iframeUrl}
        onClose={closeIframe}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { height: '100%' } }} // This ensures the dialog paper fills the height
      >
        <DialogContent style={{ height: '100%' }}>
          <iframe src={iframeUrl} width="100%" height="100%" title="Report Preview" />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeIframe}>Close</Button>
        </DialogActions>
      </Dialog>
      <IconButton disabled={loading} onClick={onClick}>
        {loading ? <CircularProgress size={24} /> : null}
        {status === 'completed' ? <GetAppOutlinedIcon /> : null}
        {status === 'draft' ? <EditOutlined /> : null}
      </IconButton>
    </>
  );
};

const Reports = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { clinic } = useSelector((state) => state.clinic);
  const { patientReport } = greyboxApiActions;
  const { uuid } = useParams();
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteReport, result] = patientReport.delete();
  const { data, isLoading, refetch } = patientReport.list({
    page: page + 1,
    page_size: rowsPerPage,
    patient: uuid,
    clinic: clinic.id,
  });
  const { lastJsonMessage, readyState } = usePatientWebSocket();

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'PatientReport') {
        refetch();
      }
    }
  }, [lastJsonMessage]);

  const handleCheckbox = (value) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };

  const handleDelete = () => {
    selected.forEach((item) => deleteReport(item));
    setSelected([]);
  };

  const columns = [
    {
      id: 'created',
      label: t('Date'),
      format: (value) => moment(value).format('YYYY-MM-DD hh:mm'),
    },
    {
      id: 'name',
      label: t('Type'),
    },
    {
      id: 'created_by',
      label: t('Created by'),
      format: (value) => `${value.first_name} ${value.last_name}`,
    },
    {
      id: 'status',
      label: t('Status'),
      format: (value) => t(value),
    },
    {
      id: 'file',
      label: t('File'),
      align: 'center',
      format: (value, row) => <DownloadReportButton link={value} status={row.status} />,
    },
  ];

  if (isLoading) {
    return (<LinearProgress />);
  }

  return (
    <>
      <Box sx={{ width: '100%', p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Typography variant="h4" sx={{ mr: 1, fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
            {t('Reports')}
          </Typography>
          {clinic.config
            && clinic.config.features_enable
            && clinic.config.features_enable.legacy_pdf_button
            && (<PdfButton patientUuid={uuid} />)}
        </Box>
        <TableContainer component={Paper}>
          <div className={classes.toolbar}>
            <Button
              style={{ marginRight: '8px' }}
              variant="outlined"
              startIcon={<NoteAddOutlinedIcon />}
              size="small"
              color="primary"
              disableElevation
              onClick={() => setOpen(true)}
            >
              {t('Generate')}
            </Button>
            <Button
              disabled={selected.length === 0}
              variant="outlined"
              color="error"
              className={classes.deleteBtn}
              onClick={() => handleDelete()}
              size="small"
              startIcon={<DeleteOutlineIcon />}
            >
              {t('Delete')}
            </Button>
            <div style={{ marginLeft: 'auto' }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data.count}
                onPageChange={(event, newPage) => setPage(newPage)}
                labelRowsPerPage={t('Rows per page')}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
                page={page}
              />
            </div>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align || 'left'}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.results.map((row, idx) => (
                <TableRow key={idx} hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selected.includes(row.id)}
                      onClick={() => handleCheckbox(row.id)}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align || 'left'}>
                      {column.format ? column.format(row[column.id], row) : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <GenerateReportDialog open={open} handleClose={() => setOpen(false)} patientUuid={uuid} />
    </>
  );
};

export default Reports;
