import {
  Box, Paper, Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import 'moment/locale/fr';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Route, Switch, useParams, useRouteMatch,
} from 'react-router-dom';
import AccountTable from '../components/accounts/AccountTable';
import CareTeam from '../components/care-team/CareTeam';
import CareTeamDetails from '../components/care-team/CareTeamDetails';
import ClinicAdminNavigation from '../components/clinic-admin/ClinicAdminNavigation';
import ClinicInformation from '../components/clinic-admin/ClinicInformation';
import InformationMessageTable from '../components/clinic-admin/InformationMessageTable';
import QuestionnaireEditTabs from '../components/clinic-admin/questionnaire/QuestionnaireEditTabs';
import Questionnaires from '../components/clinic-admin/questionnaire/Questionnaires';
import DeviceDetails from '../components/devices/DeviceDetails';
import DeviceTable from '../components/devices/DeviceTable';
import EducationPanel from '../components/education/EducationPanel';
import MedicalActs from '../components/patient-profile/MedicalActs';
import QuestionEdit from '../components/clinic-admin/questionnaire/questions/QuestionEdit';

const SectionMapper = () => {
  const { section } = useParams();

  switch (section) {
    case 'care-team':
      return (
        <CareTeam />
      );
    case 'medical-acts':
      return (
        <MedicalActs isAdmin />
      );
    case 'documentation':
      return (
        <EducationPanel />
      );
    case 'clinic-info':
      return (
        <Box width="100%">
          <ClinicInformation />
          <InformationMessageTable />
        </Box>
      );
    case 'questionnaires':
      return (
        <Questionnaires />
      );
    case 'device':
      return (
        <DeviceTable />
      );
    default:
      return (
        <CareTeam />
      );
  }
};

const ClinicSettings = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display={isMobile ? 'block' : 'flex'}>
      <ClinicAdminNavigation />
      <Switch>
        <Route exact path={path}>
          <SectionMapper />
        </Route>
        <Route path={`${path}/care-team/:id`}>
          <CareTeamDetails />
        </Route>
        <Route exact path={`${path}/questionnaires/:id`}>
          <QuestionnaireEditTabs />
        </Route>
        <Route exact path={`${path}/questionnaires/:id/questions`}>
          <QuestionnaireEditTabs />
        </Route>
        <Route path={`${path}/questionnaires/:id/questions/:questionId`}>
          <QuestionEdit />
        </Route>
        <Route path={`${path}/device/:id`}>
          <Paper sx={{ p: 1, pt: 2 }}>
            <Typography variant="h5">{t('Edit Device Details')}</Typography>
            <DeviceDetails />
          </Paper>
        </Route>
        <Route path={`${path}/accounts/:type`}>
          <AccountTable />
        </Route>
        <Route path={`${path}/:section`}>
          <SectionMapper />
        </Route>
      </Switch>
    </Box>
  );
};

export default ClinicSettings;
