import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect, Route, Switch, useHistory
} from 'react-router-dom';

import TermOfUseDialog from '../components/TermOfUseDialog';
import EducationPanel from '../components/education/EducationPanel';
import { useIsTakecareAuthenticated } from '../hooks';
import { greyboxApiActions } from '../redux/api';
import { setCurrentClinic } from '../redux/clinic';
import { initializeUser } from '../redux/user';
import AuthenticateForQuestionnaire from './AuthenticateForQuestionnaire';
import ClinicSettings from './ClinicSettings';
import CompleteRegistration from './CompleteRegistration';
import Dashboard from './Dashboard';
import Login from './Login';
import MessagesDashboard from './MessagesDashboard';
import OnInvitationQuestionnaire from './OnInvitationQuestionnaire';
import PatientProfile from './PatientProfile';
import PendingInvitations from './PendingInvitations';
import UserInformation from './UserInformation';

/**
 * Pages only accessible when authenticated.
 * Since its the first component where the user is authenticated, we refresh the token in the redux
 * store to be used by API calls. After that, we make calls that have data used early
 * in the application (e.g. the user's company).
 */
const AuthenticatedPages = ({ setIsLoading }) => {
  const { instance } = useMsal();
  const clinic = useSelector((state) => state.clinic);
  const { company, account } = greyboxApiActions;
  const user = useSelector((state) => state.user);
  const { isInvitation } = useSelector((state) => state.invitation);
  const isAuthenticated = useIsTakecareAuthenticated();
  const { authState } = useSelector((state) => state.authorization);
  const companySelector = company.list({}, {
    skip: !isAuthenticated,
  });
  const [showTerms, setShowTerms] = React.useState(false);
  const accountSelector = account.list(
    { self: true, clinic: clinic.clinic?.id, invitations: true },
    { skip: !isAuthenticated || clinic.isLoading || (!user.isUninitialized && !showTerms) },
  );
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();
  const isCompleteQuestionnaire = authState === 'questionnaire';

  // Make sure to set the current clinic based on the user session if any
  useEffect(() => {
    if (!isCompleteQuestionnaire) {
      if (companySelector.isSuccess && accountSelector.isSuccess) {
        const cachedClinic = JSON.parse(localStorage.getItem('clinic'));

        // Check if correct locale is set based on user preferences
        if (moment.locale() !== accountSelector.data[0].language) {
          moment.locale(accountSelector.data[0].language);
        }

        if (accountSelector.data[0].language !== i18n.resolvedLanguage) {
          i18n.changeLanguage(accountSelector.data[0].language);
        }

        if (companySelector.data.length === 0) {
          instance.logoutRedirect({ postLogoutRedirectUri: '/' });
        }

        if (cachedClinic && companySelector.data.find((c) => c.id === cachedClinic)) {
          const selected = companySelector.data.find((c) => c.id === cachedClinic);
          dispatch(setCurrentClinic(selected));
        } else {
          const selected = companySelector.data[0];
          dispatch(setCurrentClinic(selected));
        }

        if (user.isUninitialized) {
          dispatch(initializeUser(accountSelector.data[0]));
        }

        if (isInvitation || accountSelector.data[0].invitations.length > 0) {
          if (accountSelector.data[0].invitations.length > 0) {
            history.push('/complete-registration');
          } else {
            history.push('/');
          }
        }
      }
    }
  }, [companySelector.isLoading, accountSelector.isLoading]);

  const isLoading = (companySelector.isFetching || accountSelector.isFetching || clinic.isLoading);

  // Log out is user don't have an account on this clinic.
  if (!isCompleteQuestionnaire) {
    if (!isLoading && !accountSelector.isUninitialized && !accountSelector.isSuccess) {
      instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    }
  }

  useEffect(() => {
    if (isLoading || (!isCompleteQuestionnaire && clinic.isUninitialized)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoading, clinic.isUninitialized]);

  // Make sure to show the terms of use dialog if the user has not accepted
  // them and not currently registering
  useEffect(() => {
    if (accountSelector.isSuccess
       && accountSelector.data[0]
       && !accountSelector.data[0].accepted_terms_on) {
      setShowTerms(true);
    } else {
      setShowTerms(false);
    }
  }, [accountSelector.data]);

  if (isLoading || (!isCompleteQuestionnaire && clinic.isUninitialized)) {
    return null;
  }

  if (showTerms) {
    return (
      <TermOfUseDialog handleClose={() => setShowTerms(false)} />
    );
  }
  return (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/questionnaires-token/">
        <AuthenticateForQuestionnaire />
      </Route>
      <Route path="/patient-profile/:uuid/vitals/:vital">
        <PatientProfile />
      </Route>
      <Route path="/patient-profile/:uuid/lab-results/:labResultCode">
        <PatientProfile />
      </Route>
      <Route path="/patient-profile/:uuid/clinical-data/:clinicalDataCode">
        <PatientProfile />
      </Route>
      <Route path="/patient-profile/:uuid">
        <PatientProfile />
      </Route>
      <Route path="/your-information">
        <UserInformation />
      </Route>
      <Route path="/pending-invitations">
        {user.access === 'P' && (
          <Redirect to="/" />
        )}
        <PendingInvitations />
      </Route>
      <Route path="/clinic-settings">
        {user.access === 'P' && (
          <Redirect to="/" />
        )}

        <ClinicSettings />
      </Route>
      <Route path="/complete-registration">
        <CompleteRegistration />
      </Route>
      <Route path="/education">
        <EducationPanel readOnly />
      </Route>
      <Route path="/complete-questionnaire/:tokenId">
        <OnInvitationQuestionnaire />
      </Route>
      <Route path="/messages">
        <MessagesDashboard />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default AuthenticatedPages;
