import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Graph_Wrapper from './graphWrapper';
import { greyboxApiActions } from '../../../../redux/api';
import pharma_style from '../pharm_wrapper_style.js';
import { usePatientWebSocket } from '../../../../hooks';

const TitrationSection = (props) => {
  const {
    uuid,
    titration_status_choices,
    decisionStatus,
  } = props;

  const { titrationStatus } = greyboxApiActions;
  const { data, isLoading, refetch } = titrationStatus.list({ patient: uuid });
  const { t } = useTranslation();

  const { lastJsonMessage, readyState } = usePatientWebSocket();

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'Decision_Tree_History') {
        refetch();
      }
    }
  }, [lastJsonMessage]);

  return (
    !isLoading && data.length > 0 && (
      <div style={pharma_style.pharmaPlanWrapper}>
        <div style={pharma_style.pharmaPlanTitle}>{t('Titration Graphs')}</div>
        <div style={pharma_style.pharmaPlanSubTitle}>
          {`${t('Medication with titration plans will appear below')
          }. ${
            t('Titration plans include status, progress and target')
          }.`}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }} data-cy="titration_wrapper">
          {data.map((titrationStatusInfo) => (
            <Graph_Wrapper
              key={titrationStatusInfo.uuid}
              titration_status_choices={titration_status_choices}
              titrationStatusInfo={titrationStatusInfo}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default TitrationSection;
