import React, { useState } from 'react';

import Switch from '@mui/material/Switch';

export const Switchs = (props) => {
  const {
    disabled = false, name = 'switch', styleObj = { transform: 'translateX(12px)' }, onClick, active = false, onChange,
  } = props;
  const [checked, setChecked] = useState(active || false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onChange && onChange();
  };

  return (
    <div style={styleObj}>
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
        onClick={onClick}
        color="primary"
        data-cy={name}
        name={name}
        inputProps={{ 'aria-label': `primary ${name}` }}
      />
    </div>
  );
};
