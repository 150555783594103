import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import MedicationTable from './MedicationTable';
import { currentMed } from './currentMedications_styling';
import { greyboxApiActions } from '../../../../redux/api';
import { isInFuture, isToday } from '../../../../helper-functions';

const parseIngredients = (arrayItem, element) => {
  const obj = {};

  obj.strength = `${parseFloat(arrayItem.strength)} ${arrayItem.strength_unit.toLowerCase()}`;
  obj.strengthFr = `${parseFloat(arrayItem.strength)} ${arrayItem.strength_unit_fr.toLowerCase()}`;
  obj.unit = arrayItem.strength_unit.toLowerCase();
  obj.dosageUnit = arrayItem.dosage_unit.toLowerCase();
  obj.dosageUnitFr = arrayItem.dosage_unit_fr.toLowerCase();
  obj.drugUuid = arrayItem.uuid;
  obj.molecule = arrayItem.ingredient.toLowerCase();
  obj.moleculeFr = arrayItem.ingredient_fr === 'nan' ? arrayItem.ingredient.toLowerCase() : arrayItem.ingredient_fr.toLowerCase();
  // pushing stuff up one level.
  // if null posology we return N.A. , else we concat string when unit and dosage unit is not 'nan'
  element.unit = element.daily !== null
    ? (
      `${arrayItem.strength_unit
      } ${
        arrayItem.dosage_unit === 'nan' ? '' : `/ ${arrayItem.dosage_unit}`}`
    ).toLowerCase()
    : '---';

  element.unitFr = element.daily !== null
    ? (
      `${arrayItem.strength_unit_fr
      } ${
        arrayItem.dosage_unit_fr === 'nan' ? '' : `/ ${arrayItem.dosage_unit_fr}`}`
    ).toLowerCase()
    : '---';
  element.ingredient.push(obj);
};

const medicationParsing = (item, titrationStat = [], suggestedMedications = []) => {
  const aiCodeArr = item.medication.active_ingredient.map((el) => el.active_ingredient_code);
  const suggestedCodes = suggestedMedications.map((el) => el.list).flat();
  // passing the cautions obj {class,contraindications} to the row.
  const asTitrationCaution = suggestedMedications.length > 0
    && aiCodeArr.length > 0
    && suggestedMedications.filter((el, idx, self) => aiCodeArr.some((r) => el.list.includes(r)));

  // returns if a medication isTaken and active - intended
  const isSuggested = suggestedCodes.length > 0
     && aiCodeArr.length > 0
     && suggestedCodes.filter((el) => aiCodeArr.some((r) => el === r));

  const period = item.titration ? moment(item.titration.next_dose_date).diff(moment(Date.now()), 'days') : null;
  const rx = {
    uuid: item.uuid,
    name: item.medication.brand_name,
    medUuid: item.medication.uuid,
    daily: item.dosage.daily_dosage,
    target: !!item.titration && item.titration.target_dose,
    nextDose: !!item.titration && item.titration.next_dose,
    nextDoseDate: !!item.titration && item.titration.next_dose_date,
    nextDoseTime: period ? parseInt(period, 10) : null,
    statusName: !!item.titration && titrationStat[item.titration.status]?.name,
    statusColor: !!item.titration && titrationStat[item.titration.status]?.color_code.bright,
    posology: dosageMode(item.dosage),
    startDate: item.start_date,
    endDate: item.end_date ? item.end_date : '---',
    lastActiveDay: isToday(item.end_date),
    isIntended: isInFuture(item.start_date),
    max_tolerated_dosage: item.max_tolerated_dosage,
    unit: '',
    unitFr: '',
    isNotSuggested: isSuggested ? (
      !isSuggested.length > 0
    ) : (
      true
    ),
    aiCode: item.medication.active_ingredient.map((el) => el.active_ingredient_code),
    cautions: asTitrationCaution && asTitrationCaution[0],
    ingredient: [],
  };

  item.medication.active_ingredient.forEach((med) => {
    parseIngredients(med, rx);
  });
  return rx;
};

const CurrentMedicationsWrapper = (props) => {
  const { titration_status_choices } = props;
  const { uuid } = useParams();
  const { clinic } = useSelector((state) => state.clinic);
  const [isHistory, setIsHistory] = useState(false);
  const [selected, setSelected] = useState([]);
  const { medication, treatmentPlan } = greyboxApiActions;
  const { t } = useTranslation();
  const treatmentPlanSelector = treatmentPlan.list({
    patient: uuid, latest: true, clinic: clinic.id,
  });
  const { data, isFetching } = medication.list({
    account: uuid,
    status: isHistory ? (
      'completed'
    ) : (
      'active,intended'
    ),
  });

  const suggestedMedications = useMemo(() => {
    if (!treatmentPlanSelector.isFetching) {
      const decisions = treatmentPlanSelector.data.map((item) => {
        const output = item.decision_tree_output;
        return output.output_code === 'suggest-medications' ? output.data : null;
      }).flat();
      const parsedMedications = [];
      decisions.forEach((item) => {
        if (Array.isArray(item)) {
          item.forEach((el) => {
            parsedMedications.push(
              {
                class: el.medication_class.en,
                contraindications: el.contraindication,
                list: el.medications.map((med) => med.active_ingredient_codes).flat(),

              },
            );
          });
        } else if (item) {
          parsedMedications.push({
            class: item.medication_class.en,
            contraindications: item.contraindication,
            list: item.medications.map((med) => med.active_ingredient_codes).flat(),
          });
        }
      });

      return parsedMedications;
    }
    return [];
  }, [treatmentPlanSelector.data]);

  const medications = useMemo(() => {
    if (!isFetching && data) {
      return data.map(
        (item) => medicationParsing(item, titration_status_choices, suggestedMedications),
      );
    }
    return [];
  }, [data, titration_status_choices, suggestedMedications]);

  return (
    <div style={isHistory ? currentMed.wrapperHisto : currentMed.wrapper} data-cy="medWrapper">
      <div style={currentMed.title} data-cy="wrapperTitle">
        {t('Medication Table')}
      </div>
      <div style={currentMed.subTitle} data-cy="wrapperSubtitle1">
        {`${t('Only drugs added with TakeCare are displayed')
        }. ${
          t("The patient's pharmacological profile may be incomplete")
        }.`}
      </div>
      <br />
      <div style={isHistory ? currentMed.tabBarHisto : currentMed.tabBar} data-cy="tabBar">
        <div
          style={isHistory ? currentMed.tabCurrentOff : currentMed.tabCurrentOn}
          data-cy="tabActive"
          role="button"
          tabIndex={0}
          onClick={() => {
            setSelected([]);
            setIsHistory(false);
          }}
        >
          {t('Current')}
        </div>
        <div
          style={isHistory ? currentMed.tabHistoryOn : currentMed.tabHistoryOff}
          role="button"
          tabIndex={0}
          onClick={() => {
            setSelected([]);
            setIsHistory(true);
          }}
          data-cy="history"
        >
          {t('History')}
        </div>
      </div>
      <MedicationTable
        meds={medications}
        isHistory={isHistory}
        selected={selected}
        setSelected={setSelected}
        titration_status_choices={titration_status_choices}
      />
    </div>
  );
};

// this returns the right values for posology and dosage mode
const dosageMode = (dosage) => {
  switch (dosage.dosage_mode) {
    case null:
      return {
        daily_dosage: dosage.daily_dosage ? dosage.daily_dosage : '',
        dosage_mode: null,
      };
    case 'frequency_posology':
      return {
        daily_dosage: dosage.daily_dosage,
        dosage_mode: 'frequency_posology',
        frequency_posology: {
          short_code: dosage.frequency_posology.short_code,
          dosage: dosage.frequency_posology.dosage,
        },
      };
    case 'particular_posology':
      return {
        daily_dosage: dosage.daily_dosage,
        dosage_mode: 'particular_posology', // item.dosage.dosage_mode,
        particular_posology: {
          short_code: dosage.particular_posology.short_code,
          morning_dosage: dosage.particular_posology.morning_dosage,
          noon_dosage: dosage.particular_posology.noon_dosage,
          afternoon_dosage: dosage.particular_posology.afternoon_dosage,
          evening_dosage: dosage.particular_posology.evening_dosage,
          night_dosage: dosage.particular_posology.night_dosage,
        },
      };
    default:
      return null;
  }
};

export default CurrentMedicationsWrapper;
