import React, { createContext, useContext } from 'react';
import { reactPlugin } from '../appInsights';

const AppInsightsContext = createContext(reactPlugin);
const useAppInsightsContext = () => useContext(AppInsightsContext);

const AppInsightsContextProvider = ({ children }) => (
  <AppInsightsContext.Provider value={reactPlugin}>
    {children}
  </AppInsightsContext.Provider>
);

export { AppInsightsContext, AppInsightsContextProvider, useAppInsightsContext };
