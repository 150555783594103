/* Ext Lib */
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableRow,
  Typography,
  TableContainer,
  LinearProgress,
} from '@mui/material';
import { Add } from '../../../tk-ui';
import searchMedication_style from './searchMedication_style';
import { dosageFormater, ingredientNameFormater } from './medicationUtils';
import { greyboxApiActions } from '../../../../redux/api';

/*
Functions to parse individual cell within the table
*/
const SelectCell = (props) => {
  const { checked, row, setSelect } = props;
  /*
    - The radio button will be checked when the current selected med is same as the
    current row - check via row uuid against select med uuid
    - setSelect - update selected medication on click on Radio button
    */
  return (
    <div style={searchMedication_style.radioWrapper}>
      <Radio
        data-cy={`radio_${row.uuid}`}
        key={`radio_${row.uuid}`}
        inputProps={{ 'data-cy': `${row.brand_name}_${row.drug_code}` }}
        checked={checked}
        color="primary"
        style={checked ? searchMedication_style.radioBtn : searchMedication_style.radioBtnOff}
        onChange={() => setSelect(row)}
        disableRipple
      />
    </div>
  );
};

const BrandCell = (props) => {
  const { row } = props;
  // Note lang check is not needed here as most product do not have a french name
  return (
    <Typography
      data-cy={`brand_${row.uuid}`}
      key={`key_${row.uuid}`}
      style={searchMedication_style.tableCell_brand_text}
    >
      {row.brand_name.toUpperCase()}
    </Typography>
  );
};

const ActiveIngredientCell = (props) => {
  const { row, r_index } = props;
  const { i18n } = useTranslation();
  /*
    Render all active ingredients within a drug. Since a drug can have
    multiple active ingredients, loop is needed to go throught the
    active ingredient key within the medication object (row).
    */
  return (
    <>
      {row.active_ingredient.map((med_ai) => (
        <Typography
          data-cy={`activeIngredient_row_${r_index}_${med_ai.uuid}`}
          key={`activeIngredient_row_${r_index}_${med_ai.uuid}`}
          style={searchMedication_style.tableCell_text}
        >
          {ingredientNameFormater(i18n.resolvedLanguage, med_ai)}
        </Typography>
      ))}
    </>
  );
};

const DosageCell = (props) => {
  const { row, r_index } = props;
  const { i18n } = useTranslation();
  return (
    <>
      {row.active_ingredient.map((med_ai) => (
        <Typography
          data-cy={`dosage_row_${r_index}_${med_ai.uuid}`}
          key={`dosage_row_${r_index}_${med_ai.uuid}`}
          style={searchMedication_style.tableCell_dosage}
        >
          {dosageFormater(i18n.resolvedLanguage, med_ai)}
        </Typography>
      ))}
    </>
  );
};

const CellSwitcher = (props) => {
  const {
    checked, setSelect, row, columns, r_index,
  } = props;
  // determin which parser function is used for different cell type
  let cell_content;
  let style = {};

  return (
    <TableRow
      hover
      tabIndex={-1}
      style={{
        display: 'flex',
        fontFamily: 'Roboto',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '3px 0',
        transition: 'all 300ms ease',
        margin: '0',
        borderBottom: '1px solid rgba(0,0,0,0.06)',
        // borderLeft: "1px solid rgba(0,0,0,0.06)",
        // borderRight: "1px solid rgba(0,0,0,0.06)",
        background: checked ? '#F3FFFF' : null,
      }}
      key={row.uuid}
      onClick={(event) => setSelect(row)}
      data-cy={`medSearch_row_${r_index}`}
    >
      {columns.map((column, c_index) => {
        switch (column.id) {
          case 'select':
            cell_content = (
              <SelectCell
                key={`selectCellWrapper_${row.uuid}`}
                checked={checked}
                setSelect={setSelect}
                row={row}
              />
            );
            style = {
              minWidth: '8.6%', maxWidth: '8.6%', border: 'none', fontWeight: '500',
            };
            break;

          case 'brand_name':
            cell_content = <BrandCell key={`brandCellWrapper_${row.uuid}`} row={row} />;
            style = { minWidth: '33.5%', maxWidth: '33.5%', border: 'none' };
            break;

          case 'active_ingredient':
            cell_content = (
              <ActiveIngredientCell
                key={`activeIngredientCellWrapper_${row.uuid}`}
                row={row}
                r_index={r_index}
              />
            );
            style = { minWidth: '37.9%', maxWidth: '37.9', border: 'none' };
            break;

          case 'dosage':
            cell_content = (
              <DosageCell
                key={`dosageCellWrapper_${row.uuid}`}
                row={row}
                cell_content={cell_content}
                r_index={r_index}
              />
            );
            style = { minWidth: '20%', maxWidth: '20%', border: 'none' };
            break;
          default:
            break;
        }
        return (
          <TableCell
            key={`${column.id}_${row.uuid}_${c_index}_${r_index}`}
            style={style}
            padding="none"
          >
            {cell_content}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const Med_SearchOutput = (props) => {
  const { searchMed_result, currentSelected, setSelect } = props;
  const { t } = useTranslation();

  const columns = [
    {
      id: 'select',
      label: '',
      style: searchMedication_style.header_text_select,
    },
    {
      id: 'brand_name',
      label: t('Product Name'),
      style: searchMedication_style.header_text,
    },
    {
      id: 'active_ingredient',
      label: t('Active Ingredients'),
      style: searchMedication_style.header_text,
    },
    {
      id: 'dosage',
      label: t('Dose'),
      style: searchMedication_style.header_text,
    },
  ];

  return (
    <TableContainer style={searchMedication_style.table_wrapper} data-cy="medSearch_output">
      {/* Sticky header always stay on top when scroll */}
      <Table data-cy="medSearch_table_output">
        <TableBody>
          {/* Loop through all medication base on API response */}
          {searchMed_result.map((row, r_index) => {
            const checked = currentSelected != null ? row.uuid === currentSelected.uuid : false;
            return (
              <CellSwitcher
                key={`cellSwitch_${row.uuid}`}
                checked={checked}
                setSelect={setSelect}
                columns={columns}
                row={row}
                r_index={r_index}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MedicationSearch = (props) => {
  const {
    currentSelected,
    setSelect,
    search,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  const { medications } = greyboxApiActions;
  const searchMedication = medications.list({ search: searchValue }, { skip: searchValue === '' });

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  return (
    <div style={searchMedication_style.container} data-cy="medSearch_container">
      <TextField
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        autoFocus
      />
      {searchMedication.isFetching ? (
        <div style={searchMedication_style.loader}>
          <LinearProgress />
        </div>
      ) : null}
      {/* Only show table when result is available
      and show No Result test when search do not return anything. */}
      {searchMedication.isUninitialized
      || !searchMedication.data
      || searchMedication.data.length === 0 ? (
        <Typography style={searchMedication_style.noResult_text}>
          {t('No Result')}
        </Typography>
        ) : (
          <div style={{ padding: '5px 0 0 0' }}>
            <div style={searchMedication_style.flexWrapper} data-cy="medSearch_header">
              <span style={{
                marginLeft: '2.4%', width: '6.3%', marginTop: '3px', opacity: 0.5,
              }}
              >
                <Add fills="#95989a" />
              </span>
              <span style={{ width: '33.3%' }}>{t('Product Name')}</span>
              <span style={{ width: '38%' }}>{t('Active Ingredients')}</span>
              <span style={{ width: '19.9%' }}>{t('Dose')}</span>
            </div>
            <Med_SearchOutput
              searchMed_result={
                searchMedication.data.length > 100 ? (
                  searchMedication.data.slice(0, 100)
                ) : (searchMedication.data)
              }
              currentSelected={currentSelected}
              setSelect={setSelect}
            />
          </div>
        )}
    </div>
  );
};

export default MedicationSearch;
