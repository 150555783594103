import {
  Paper,
  Table, TableBody, TableCell, TableHead, TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import AnswerFormatter from './AnswerFormatter';

const PrintableLogbook = forwardRef(({ answers, questionnaire, patient }, ref) => {
  const { t } = useTranslation();

  if (!answers || !questionnaire || !patient) return null;

  return (
    <div style={{ padding: 4 }} ref={ref}>
      <Typography variant="h4" gutterBottom>
        {questionnaire.name}
      </Typography>
      <Typography variant="caption" gutterBottom>
        {`${t('For')} ${patient.firstName} ${patient.lastName}`}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('Date')}</TableCell>
            {questionnaire.questions.map((question, index) => (
              <TableCell key={question.id}>{`Question ${index + 1}`}</TableCell>
            ))}
            {questionnaire.has_score && <TableCell>{t('Score')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Assuming answers is an array of answer objects */}
          {answers.map((answerSet, index) => (
            <TableRow key={index}>
              <TableCell>
                {moment(answerSet.answer_timestamp).format('LL')}
              </TableCell>
              {questionnaire.questions.map((question) => (
                <TableCell key={question.id}>
                  <AnswerFormatter question={question} data={answerSet} />
                </TableCell>
              ))}
              {questionnaire.has_score && answerSet.score && (
                <TableCell>
                  {answerSet.score.value}
                  {questionnaire.max_score && ` / ${questionnaire.max_score}`}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
});

export default PrintableLogbook;
