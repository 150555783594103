import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dialog, DialogActions, DialogContent, Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useMsal } from '@azure/msal-react';
import DialogTitle from './DialogTitle';
import { greyboxApiActions } from '../redux/api';
import { logout } from '../redux/authorization';

const TermOfUseDialog = ({ handleClose }) => {
  const { account } = greyboxApiActions;
  const { t } = useTranslation();
  const { accountId } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const [updateAccount, results] = account.update();
  const [refused, setRefused] = React.useState(false);

  const handleAccept = () => {
    updateAccount({
      id: accountId,
      body: {
        accepted_terms_on: new Date().toISOString(),
      },
    }).then(() => handleClose());
  };

  const handleLogout = () => {
    dispatch(logout());
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  if (refused) {
    return (
      <Dialog open maxWidth="lg" fullWidth onClose={handleLogout}>
        <DialogTitle onClose={handleLogout}>
          {t('Warning')}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t('You cannot use the application if you do not accept the terms of use')}
            {'. '}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRefused(false)} color="secondary">
            {t('Go back')}
          </Button>
          <LoadingButton onClick={handleLogout} variant="contained" color="primary" loading={results.isLoading}>
            {t('Log out')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open maxWidth="lg" fullWidth onClose={() => setRefused(true)}>
      <DialogTitle onClose={() => setRefused(true)}>
        {t('Terms of Use')}
      </DialogTitle>
      <DialogContent>
        <iframe src="https://greybox.ca/terms" title="Terms of Use" width="100%" height="800" />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setRefused(true)} color="primary">
          {t('Refuse')}
        </Button>
        <LoadingButton onClick={handleAccept} variant="contained" color="primary" loading={results.isLoading}>
          {t('Accept')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TermOfUseDialog;
