import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

type DeleteDialogProps = {
  open: boolean;
  handleClose: () => void;
  deleteItem: () => Promise<void>;
};

export default function DeleteDialog({ open, handleClose, deleteItem }: DeleteDialogProps) {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleDeleteItem = async () => {
    setIsDeleting(true);
    await deleteItem();
    setIsDeleting(false);
    handleClose();
  } 

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Are you sure you want to delete this item') + '?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('This action cannot be undone') + '.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <LoadingButton autoFocus loading={isDeleting} onClick={handleDeleteItem} color="error" variant="contained">
            {t('Delete')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
  );
}