import React from 'react';
import { SvgIcon } from '@mui/material';

const AlertBlue = (props) => {
  const { viewBox = '0 0 30 30', fills = '#83dde7' } = props;
  return (
    <SvgIcon viewBox={viewBox} fills={fills}>
      <path
        d="M13.5,19.5h3v3h-3Zm0-12h3v9h-3ZM15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm0,27A12,12,0,1,1,27,15,12,12,0,0,1,15,27Z"
        fill={fills}
      />
    </SvgIcon>
  );
};

export { AlertBlue };
