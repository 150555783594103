import {
  Paper, Table, Button, Stack,
  TableBody, Box, TablePagination,
  TableCell, IconButton, Popper,
  TableContainer, Fade, Typography,
  TableHead, Dialog, DialogActions,
  TableRow, DialogContent, DialogContentText,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import NoteIcon from '@mui/icons-material/Note';
import { greyboxApiActions } from '../../redux/api';
import { NoData } from '../tk-ui';
import RegisterMedicalActDialog from './layout/RegisterMedicalActDialog';
import TableWrapper from '../TableWrapper';

const MedicalActs = ({ isAdmin = false }) => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [note, setNote] = useState(null);
  const [open, setOpen] = useState(false);
  const { medicalAct } = greyboxApiActions;
  const { data, isFetching, isLoading } = medicalAct.list({
    patient: uuid,
    clinic: clinic.id,
    page: page + 1,
    page_size: rowsPerPage,
    expand: uuid ? 'hcp' : 'hcp,patient',
  });

  if (isLoading) return null;

  return (
    <Box width="100%">
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
        {t('Follow-up')}
      </Typography>
      {!isAdmin && (
        <Button variant="contained" sx={{ mb: 1 }} startIcon={<AddIcon />} onClick={() => setOpen(true)}>
          {t('Add')}
        </Button>
      )}
      <TableWrapper
        total={data.count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        maxHeight="80vh"
        isFetching={isFetching}
        colCount={uuid ? 4 : 5}
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('Type')}</TableCell>
            {!uuid && (
              <TableCell>{t('Patient')}</TableCell>
            )}
            <TableCell>{t('Note')}</TableCell>
            <TableCell>{t('Registered by')}</TableCell>
            <TableCell>{t('Date')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.count > 0 ? (
            data.results.map((item) => (
              <TableRow key={item.uuid}>
                <TableCell>{item.medical_act_type_name}</TableCell>
                {!uuid && (
                  <TableCell>
                    {`${item.patient.first_name} ${item.patient.last_name}`}
                  </TableCell>
                )}
                <TableCell>
                  {
                    item.note ? (
                      <Tooltip title={t('View note')}>
                        <IconButton size="small" onClick={() => setNote(item.note)}>
                          <NoteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : '------'
                  }
                </TableCell>
                <TableCell>{`${item.hcp.first_name} ${item.hcp.last_name}`}</TableCell>
                <TableCell>{moment(item.createdDate).format('lll')}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <Box height="30vh" display="flex" justifyContent="center" alignItems="center">
                  <NoData style={{ transform: 'scale(4)' }} />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableWrapper>
      <RegisterMedicalActDialog open={open} handleClose={() => setOpen(false)} />
      <Dialog
        open={Boolean(note)}
        maxWidth="sm"
        fullWidth
        onClose={() => setNote(null)}
      >
        <DialogContent>
          <DialogContentText>
            {note}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNote(null)}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MedicalActs;
