import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { webSocketUrl } from './redux/api';

function useIsTakecareAuthenticated() {
  const authorization = useSelector((state) => state.authorization);
  const isMsalAuthenticated = useIsAuthenticated();

  // check if token start with Token
  if (authorization.token && authorization.token.startsWith('Token ')) {
    return true;
  }

  return isMsalAuthenticated;
}

function usePatientWebSocket(patientId = null) {
  const { uuid } = useParams();
  const { token } = useSelector((state) => state.authorization);
  const { instance } = useMsal();

  const id = uuid || patientId;

  const getSocketUrl = useCallback(() => new Promise((resolve) => {
    if (token && token.startsWith('Token ')) {
      resolve(`${webSocketUrl}/patient/${id}/?Authorization=${token}`);
    } else {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const tokenResponse = instance.acquireTokenSilent({
          scopes: ['openid',
            'https://takecareca.onmicrosoft.com/api/takecare.read',
            'https://takecareca.onmicrosoft.com/api/takecare.write',
          ],
          account: accounts[0],
        }).then((response) => {
          resolve(`${webSocketUrl}/patient/${id}/?Authorization=Bearer ${response.accessToken}`);
        });
      }
    }
  }), [token, instance, id, webSocketUrl]);

  const { lastJsonMessage, readyState } = useWebSocket(getSocketUrl, {
    share: true,
    shouldReconnect: (closeEvent) => true,
  });

  return { lastJsonMessage, readyState };
}

function useThreadWebSocket(threadId) {
  const { token } = useSelector((state) => state.authorization);
  const { instance } = useMsal();

  const getSocketUrl = useCallback(() => new Promise((resolve) => {
    if (token && token.startsWith('Token ')) {
      resolve(`${webSocketUrl}/thread/${threadId}/?Authorization=${token}`);
    } else {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const tokenResponse = instance.acquireTokenSilent({
          scopes: ['openid',
            'https://takecareca.onmicrosoft.com/api/takecare.read',
            'https://takecareca.onmicrosoft.com/api/takecare.write',
          ],
          account: accounts[0],
        }).then((response) => {
          resolve(`${webSocketUrl}/thread/${threadId}/?Authorization=Bearer ${response.accessToken}`);
        });
      }
    }
  }), [token, instance, threadId, webSocketUrl]);

  const { lastJsonMessage, readyState } = useWebSocket(getSocketUrl, {
    share: true,
    shouldReconnect: (closeEvent) => true,
  });

  return { lastJsonMessage, readyState };
}

export {
  useIsTakecareAuthenticated,
  usePatientWebSocket,
  useThreadWebSocket,
};
