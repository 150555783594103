import LoadingButton from '@mui/lab/LoadingButton';
import {
  CardActions, CardContent, CardHeader, Dialog,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import DialogTitle from '../DialogTitle';
import { greyboxApiActions } from '../../redux/api';
import FormTextField from '../form-inputs/FormTextField';
import Language from '../form-inputs/Language';
import PhoneNumber from '../form-inputs/PhoneNumber';
import Row from '../Row';

type EditAccountDialogProps = {
  uuid: string;
  open: boolean;
  handleClose: () => void;
};

const EditAccountDialog = ({ uuid, open, handleClose }: EditAccountDialogProps) => {
  const { t } = useTranslation();
  const { account } = greyboxApiActions;
  const { data = [], isLoading, isError } = account.get(uuid);
  const [updateAccount] = account.update();

  const formik = useFormik({
    initialValues: {
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      email: data.email || '',
      mobile: data.phoneNumber || '',
      language: data.language || 'en',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      firstName: yup.string().required(t('required')),
      lastName: yup.string().required(t('required')),
      email: yup.string().email(),
      mobile: yup.string(),
      language: yup.string().required(t('required')),
    }),
    onSubmit: (values) => {
      // Prevent form sending (+1) and no actual number
      if (values.mobile.length < 7) {
        values.mobile = '';
      }

      updateAccount({ id: uuid, body: values }).unwrap().then(() => {
        handleClose();
      }).catch((error) => {
        formik.setSubmitting(false);
        // Assuming the server returns error details in a predictable format
        if (error.status === 400 && error.data) {
          const errors = error.data;
          formik.setErrors(errors);
        }
      });
    },
  });

  if (isLoading) return null;

  if (isError) {
    handleClose();
  }

  return (
    <Dialog open={open} onClose={() => handleClose} fullWidth maxWidth="md">
      <DialogTitle onClose={handleClose}>
        {t('Edit Account')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <CardContent>
          <Row>
            <FormTextField formik={formik} name="firstName" label={t('First name')} required />
            <FormTextField formik={formik} name="lastName" label={t('Last name')} required />
          </Row>
          <Row>
            <FormTextField formik={formik} name="email" label={t('Email')} />
            <PhoneNumber formik={formik} name="phoneNumber" label={t('Phone Number')} />
          </Row>
          <Row>
            <Language formik={formik} />
          </Row>
        </CardContent>
        <CardActions>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            sx={{ ml: 'auto' }}
            disabled={formik.isSubmitting || !formik.dirty}
            loading={formik.isSubmitting}
          >
            {t('Save')}
          </LoadingButton>
        </CardActions>
      </form>
    </Dialog>
  );
};

export default EditAccountDialog;
