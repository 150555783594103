import { Typography, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Answers, Question } from '../../../../types';

type SimpleAnswerPanelProps = {
  question: Question;
  setAnswers: (id: number, answer: string) => void;
  answers: Answers;
  isCurrent: boolean;
};

const SimpleAnswerPanel = (props: SimpleAnswerPanelProps) => {
  const {
    question, setAnswers, answers, isCurrent,
  } = props;
  const [textValue, setTextValue] = useState<string>('');

  useEffect(() => {
    if (answers[question.id] && textValue !== answers[question.id]) {
      setTextValue(answers[question.id]);
    }
  }, [question]);

  useEffect(() => {
    setAnswers(question.id, textValue);
  }, [textValue]);

  return (
    <>
      <Typography sx={{ width: '100%', mb: 1 }}>
        <ReactMarkdown>
          {question.sa_question.sa_question_text}
        </ReactMarkdown>
      </Typography>
      <TextField
        fullWidth
        data-cy={isCurrent ? 'answer-text' : ''}
        variant="standard"
        type={question.sa_question.ans_type}
        onChange={(event) => setTextValue(event.target.value)}
        value={textValue}
      />
    </>
  );
};

export default SimpleAnswerPanel;
