import React from 'react';
import { SvgIcon } from '@mui/material';

const BloodGlucoseIcon = ({ style }) => (
  <SvgIcon style={style} viewBox="0 0 37 37">
    <g>
      <circle
        fill="#b72b59"
        data-name="Ellipse 6"
        cx="17.5"
        cy="17.5"
        r="17.5"
      />
      <path
        fill="#fff"
        d="M28.978,10c-5.354,7.916-8.8,12.864-8.8,17.677a8.8,8.8,0,1,0,17.6,0C37.775,22.864,34.336,17.916,28.978,10Zm.51,22.512c2.813-1.154,4.533-5.375,3.323-9.735C36.984,28.174,33.636,33.1,29.487,32.512Z"
        transform="translate(-11.46 -5.679)"
      />
    </g>
  </SvgIcon>
);

export { BloodGlucoseIcon };
