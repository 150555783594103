import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { progress } from './progressBar_styling';

// this finds the nearest - lowest - val in an array , according to a given value
const closest = (arr, val) => Math.max.apply(
  null,
  arr.filter((v) => (v <= val)),
);

// Main component, return UI
const Progress_Bar = (props) => {
  // titration status obj from API response
  const { titrationStatusInfo } = props;

  const { t } = useTranslation();

  const { unit } = titrationStatusInfo.titration;

  // Current total dose
  const medDosage = titrationStatusInfo.current_dose;

  // Step to display in progress bar
  const dataSteps = [0, ...titrationStatusInfo.titration.dosage_steps.steps.map((el) => el.dosage)];

  // finds nearest , but alway lowest val in titration step. see closest FN
  const nearVal = closest(dataSteps, medDosage);

  // create % to get width
  const percentageSteps = dataSteps && 100 / (dataSteps.length - 1);

  // get the percentage of width of the latest dosage according to nearest value on progress bar.
  const percentByIndex = percentageSteps * dataSteps.indexOf(nearVal);

  // Number of days that patient has been on current dose, excluding today
  const daysSince = moment().diff(
    moment(titrationStatusInfo.current_dose_started, 'YYYY-MM-DD'), 'days',
  );

  return (
    <>
      <div style={progress.position} data-cy="progressGraph">
        <div style={progress.presentValue} data-cy="progressBarTitle">
          {medDosage}
          {' '}
          {unit}
          {' '}
          {daysSince === 1 ? (
            <span style={progress.period} data-cy="progressBarPeriod">
              {' '}
              (
              {t('Since')}
              {' '}
              {daysSince}
              {' '}
              {t('Day')}
              )
            </span>
          ) : daysSince === 0 ? (
            <span style={progress.period} data-cy="progressBarPeriod2">
              {' '}
              (
              {t('Since today')}
              )
            </span>
          ) : (
            <span style={progress.period} data-cy="progressBarPeriod3">
              {' '}
              (
              {t('Since')}
              {' '}
              {daysSince}
              {' '}
              {t('days')}
              )
            </span>
          )}
        </div>
        <div style={progress.wrapper} data-cy="fullLine">
          <div style={{ width: `calc(${percentByIndex}%)`, ...progress.bar }} data-cy="percentLine" />
        </div>
        <div style={progress.stepWrapper}>
          {dataSteps.map((el, idx) => (
            <div style={progress.steps} key={idx} data-cy={`step-${idx}`}>
              {el}
              {' '}
              {unit}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export { closest };

export default Progress_Bar;
