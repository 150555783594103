import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '20px',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    borderRadius: '5px',
    textAlign: 'center',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  details: {
    whiteSpace: 'pre-wrap',
    marginTop: '10px',
  },
  button: {
    marginTop: '10px',
  },
}));


const ErrorFallbackCard = ({ error, resetErrorBoundary }) => {
  const classes = useStyles();
  const { t } = useTranslation()

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="div">
          {t('Oops, something went wrong.')}
        </Typography>
        <Typography variant="body2" className={classes.details}>
          {t('Please try again or contact support if the issue persists.')}
        </Typography>
        <Button variant="contained" color="primary" onClick={resetErrorBoundary} className={classes.button}>
          {t('Try Again')}
        </Button>
      </CardContent>
    </Card>
  );
};


export default ErrorFallbackCard;
