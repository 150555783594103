import React, { useEffect, useState, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import AddLabThreshWrapper from './addHealthTargets';
import { greyboxApiActions } from '../../../redux/api';

import { emptyObject, diff } from '../../../helper-functions';

const thresholdsConfig = {
  inputType: 'target',
  disable: false,
  asCancelBtn: true,
  style: {},
};

const TargetForm = (props) => {
  const {
    uuid, domain, authToken, latest, lab_results_config,
  } = props;
  const [val, setVal] = useState();
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();
  const { threshold } = greyboxApiActions;
  const [selectedDate, setSelectedDate] = useState(moment());
  const [validDate, setValidDate] = useState(true);
  const { i18n, t } = useTranslation();
  const configArr = Object.values(lab_results_config);
  const [createThreshold] = threshold.add();

  // create initValue shape
  const createInit = (arr) => {
    const init = {};
    arr.forEach((obj) => {
      init[`M_${obj.short_code}`] = {
        lowerRange_1: '',
        upperRange_1: '',
        isMinValid: true,
        isMaxValid: true,
      };
    });
    return init;
  };

  // handle date and validates
  const handleDateChange = (date) => {
    const beforeValidation = moment(date).isSameOrBefore(moment());
    setSelectedDate(date);
    if (date === null) {
      setValidDate(false);
    } else {
      !date.isValid || !beforeValidation ? setValidDate(false) : setValidDate(true);
    }
  };

  // set a bool if error in any fields of the form
  const hasErrors = (obj) => {
    let hasErrorLength;
    if (!emptyObject(obj)) {
      hasErrorLength = Object.values(obj).filter(
        (el) => el.isMinValid === false || el.isMaxValid === false,
      );
    }
    hasErrorLength && hasErrorLength.length > 0 ? setErr(true) : setErr(false);
  };

  // validate on val change.
  useEffect(() => {
    hasErrors(val);
  }, [val]);

  // create initValues obj.
  const initialVals = createInit(configArr);

  // for api
  const requiredInfo = { domain, authToken };

  // return difference between initialValues and values added to form
  const getDiff = (initial, actual) => diff(initial, actual);

  // preparing response for api
  const postInterface = (data, date) => {
    const response = [];
    Object.entries(data).map((el) => {
      response.push({
        function: el[0],
        lowerRange_1: el[1].lowerRange_1 ? parseFloat(el[1].lowerRange_1) : null,
        upperRange_1: el[1].upperRange_1 ? parseFloat(el[1].upperRange_1) : null,
        localAccount: uuid,
        created_date: moment(date).format(),
      });
      return null;
    });
    return response;
  };

  return (
    !emptyObject(initialVals) && (
      <div>
        <Formik
          initialValues={initialVals}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const res = postInterface(getDiff(initialVals, values), selectedDate);
            createThreshold({
              body: [...res],
            });
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          }) => (
            <Form>
              <Field
                name={thresholdsConfig.inputType} // name for form
                component={AddLabThreshWrapper} // component rendered
                setVal={setVal} // values hooked back here
                configArr={configArr} // initial config arr
                handleDateChange={handleDateChange} // change date
                selectedDate={selectedDate} // date
                handleSubmit={handleSubmit} // gives submit function
                handleReset={handleReset} // gives reset function
                validDate={validDate} // returns is date is valid
                err={err} // return if input as error
                latest={latest} // latest values for prefill
                {...props}
              />
            </Form>
          )}
        </Formik>
      </div>
    )
  );
};

export default TargetForm;
