import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import FormSelect from './FormSelect';

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

const EthnicGroup = (props: PropsType) => {
  const { t } = useTranslation();

  return (
    <FormSelect {...props} name="ethnic_group" label={t('Ethnic Group')}>
      <MenuItem value="2028-9">{t('Asian')}</MenuItem>
      <MenuItem value="2054-5">{t('Black or African American')}</MenuItem>
      <MenuItem value="1002-5">{t('Indigenous')}</MenuItem>
      <MenuItem value="2106-3">{t('White')}</MenuItem>
      <MenuItem value="2131-1">{t('Other')}</MenuItem>
      <MenuItem value="U">{t('Not Specified')}</MenuItem>
    </FormSelect>
  );
};

EthnicGroup.defaultProps = {
  required: false,
  sx: null,
};

export default EthnicGroup;
