import { createSlice } from '@reduxjs/toolkit';
import api from './api';

/**
 * Slice for the patient contains only immutable values.
 * (uuid, message thread id, etc.) use this instead of props drilling from first component
 */
const patient = createSlice({
  name: 'patient',
  initialState: {
    uuid: null,
    userId: null,
    isLoading: false,
    isError: false,
    createdDate: null,
    isUninitialized: true,
    features: [],
    calendar: {
      key: null,
      id: null,
    },
  },
  reducers: {
    patientLoading: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    patientError: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    patientLoaded: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isUninitialized = false;
      state.accountId = action.payload.uuid;
      state.createdDate = action.payload.account.createdDate;
      state.userId = action.payload.account.user.id;
    },
  },
});

const { patientLoading, patientLoaded, patientError } = patient.actions;

/**
 * To be called when patient profile is loaded. Since only immutable values (ids) are are
 * used, the reducer can be called from anywhere in the profile to fetch specific data
 * without having to filter on the user or worry about the state.
 */
const initializePatient = (uuid) => async (dispatch) => {
  dispatch(patientLoading());
  const account = await dispatch(api.endpoints.getAccount.initiate(uuid));
  // check if error
  if (account.error) {
    dispatch(patientError(account.error));
    return;
  }

  dispatch(patientLoaded({
    uuid: uuid,
    account: account.data,
  }));
};

export default patient.reducer;
export { initializePatient };
