import React from 'react';
import { SvgIcon } from '@mui/material';

const InactiveIcon = (props) => {
  const { viewBox = '0 0 35 35' } = props;
  return (
    <SvgIcon {...props} viewBox={viewBox}>
      <path
        d="M17.5 0C20.9612 0 24.3446 1.02636 27.2225 2.94928C30.1003 4.87221 32.3434 7.60533 33.6679 10.803C34.9924 14.0007 35.339 17.5194 34.6637 20.9141C33.9885 24.3088 32.3218 27.427 29.8744 29.8744C27.427 32.3218 24.3088 33.9885 20.9141 34.6637C17.5194 35.339 14.0007 34.9924 10.803 33.6679C7.60533 32.3434 4.87221 30.1003 2.94928 27.2225C1.02636 24.3446 0 20.9612 0 17.5C0 12.8587 1.84374 8.40752 5.12563 5.12563C8.40752 1.84374 12.8587 0 17.5 0V0Z"
        fill="#6D757D"
      />
      <path
        d="M17.5511 4.99707C15.0686 4.99687 12.6419 5.73283 10.5777 7.11188C8.51357 8.49092 6.9047 10.4511 5.95462 12.7445C5.00454 15.038 4.75589 17.5617 5.24014 19.9964C5.72439 22.4312 6.91979 24.6676 8.67514 26.423C10.4305 28.1783 12.667 29.3737 15.1017 29.858C17.5365 30.3422 20.0601 30.0936 22.3535 29.1435C24.647 28.1934 26.6072 26.5846 27.9862 24.5204C29.3653 22.4563 30.1012 20.0295 30.101 17.5471C30.101 14.2186 28.7788 11.0265 26.4253 8.67288C24.0717 6.3193 20.8795 4.99707 17.5511 4.99707ZM18.417 19.0821C18.4157 19.1156 18.4125 19.149 18.4071 19.1821C18.405 19.2069 18.4017 19.2316 18.3971 19.2561C18.3911 19.2821 18.3821 19.3071 18.3741 19.3321C18.3661 19.3571 18.355 19.3931 18.343 19.4221L18.3371 19.4371C18.3183 19.4767 18.2966 19.5148 18.2721 19.5511V19.5571C18.2451 19.5967 18.2146 19.6338 18.1811 19.6681C18.1721 19.6781 18.162 19.6861 18.153 19.6951C18.125 19.723 18.0945 19.7484 18.062 19.7711C18.052 19.7781 18.0431 19.7861 18.0321 19.7931C17.9907 19.8202 17.9472 19.8439 17.9021 19.8641L17.872 19.8741C17.8338 19.8884 17.7947 19.9004 17.755 19.9101L17.7121 19.9191C17.6607 19.9292 17.6084 19.9346 17.5561 19.9351C17.5451 19.9351 17.534 19.9351 17.524 19.9351C17.474 19.9343 17.4241 19.9289 17.375 19.9191C17.361 19.9191 17.346 19.9091 17.331 19.9051C17.2866 19.8937 17.2432 19.879 17.201 19.8611H17.187L12.3741 17.5911C12.1657 17.4928 12.005 17.3158 11.9272 17.099C11.8493 16.8822 11.8608 16.6434 11.9591 16.4351C12.0573 16.2267 12.2343 16.066 12.4511 15.9882C12.6679 15.9103 12.9067 15.9218 13.115 16.0201L16.6891 17.7051V10.2081C16.6891 9.97786 16.7805 9.75708 16.9433 9.5943C17.106 9.43152 17.3268 9.34007 17.557 9.34007C17.7872 9.34007 18.008 9.43152 18.1708 9.5943C18.3336 9.75708 18.425 9.97786 18.425 10.2081V19.0691L18.417 19.0821Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export { InactiveIcon };
