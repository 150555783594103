import React, { useEffect, useMemo, useState } from 'react';
import {
  SpeedDial, Snackbar, Button, useMediaQuery, Paper,
  SpeedDialAction, useTheme, Box, Stack, Alert, IconButton, Menu,
} from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import QuizIcon from '@mui/icons-material/Quiz';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SourceIcon from '@mui/icons-material/Source';
import MenuIcon from '@mui/icons-material/Menu';
import 'moment/locale/fr';
import { useTranslation } from 'react-i18next';
import {
  Redirect, Route, Switch, useParams, useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initializePatient } from '../redux/patient';
import { initializeDateRange } from '../redux/patientProfileDateRange';
import { getPermissions } from '../redux/permissions';
import {
  Calendar, Reports, ClinicalDataTable, DateRangePicker, PatientInformations, Overview,
  MedicalActs, ClinicalNoteModal, ClinicalNotePanel, MedicalActSummary, PatientActionPanel,
  ClinicalDataDetails, PatientProfileNavigation, QuestionnaireSelector, QuestionnaireDetails,
  AddClinicalDataModal, LabResultsWrapper, MedicationTab, LabResultDetails, Documents,
} from '../components/patient-profile';
import { domain, greyboxApiActions } from '../redux/api';
import AddVitalsDialog from '../components/vitals/AddVitalsDialog';
import VitalDetails from '../components/vitals/VitalDetails';
import AlertDetails from '../components/alert/AlertDetails';
import CareTeam from '../components/care-team/CareTeam';
import CareTeamDetails from '../components/care-team/CareTeamDetails';
import EducationPanel from '../components/education/EducationPanel';

const StaticMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { clinic } = useSelector((state) => state.clinic);
  const { access } = useSelector((state) => state.user);

  return (
    !isMobile && (
      <Stack sx={{ minWidth: '275px', mr: 1, maxWidth: '300px' }} spacing={1}>
        <PatientActionPanel />
        {clinic.config.features_enable.medical_acts
         && access === 'PT' && <MedicalActSummary />}
        <PatientProfileNavigation />
      </Stack>
    )
  );
};

const PatientProfileLayout = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { path } = useRouteMatch();
  const { uuid } = useParams();
  const { t } = useTranslation();

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [addVitalsOpen, setAddVitalsOpen] = useState(false);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [questionnaireOpen, setQuestionnaireOpen] = useState(false);
  const [addDataOpen, setAddDataOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const [consultationRecorded, setConsultationRecorded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { clinic } = useSelector((state) => state.clinic);
  const { access, accountId } = useSelector((state) => state.user);
  const { edit } = useSelector((state) => state.permissions);
  const dateRange = useSelector((state) => state.patientProfileDateRange);
  const patient = useSelector((state) => state.patient);

  const { medicalAct } = greyboxApiActions;
  const [addMedicalAct] = medicalAct.add();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(initializePatient(uuid));
    dispatch(getPermissions(accountId, uuid));
  }, [uuid]);

  useEffect(() => {
    if (patient.createdDate) {
      dispatch(initializeDateRange(clinic.id, clinic.config.features_enable.date_range, patient.createdDate));
    }
  }, [patient.createdDate]);

  const handleQuestionnaireClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setQuestionnaireOpen(false);
    }
  };

  // When user click yes on the top snackbar
  const handleMedicalActRegistration = () => {
    addMedicalAct({
      body: {
        patient: uuid,
        hcp: accountId,
      },
    }).then(() => {
      setConsultationRecorded(true);
    });
  };

  const speedDialActions = [
    {
      icon: <LocalHospitalIcon />,
      name: 'Add vitals',
      onClick: () => setAddVitalsOpen(true),
      access: 'ALL',
    },
    {
      icon: <QuizIcon />,
      name: 'Fill a questionnaire',
      onClick: () => setQuestionnaireOpen(true),
      access: 'ALL',
    },
    {
      icon: <ListAltIcon />,
      name: 'Add clinical data',
      onClick: () => setAddDataOpen(true),
      access: ['PT'],
    },
    {
      icon: <SourceIcon />,
      name: 'Add clinical Note',
      onClick: () => setAddNoteOpen(true),
      access: ['PT'],
    },
  ];

  if (patient.isLoading || dateRange.range === null) {
    return null;
  }

  // check if error loading the patient
  if (patient.isError) {
    return <Redirect to="/" />;
  }

  return (
    <Box>
      {isMobile && (
        <Box width="100%" display="flex">
          <IconButton
            color="primary"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            sx={{ ml: 'auto', mr: 1, my: 1 }}
          >
            <MenuIcon />
          </IconButton>
          <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
            <PatientProfileNavigation />
          </Menu>
        </Box>
      )}
      {clinic.config.features_enable.medical_acts && access === 'PT' && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setSnackbarOpen(false)}
        >
          {consultationRecorded ? (
            <Alert onClose={() => setSnackbarOpen(false)} severity="success">
              {t('Consultation recorded')}
            </Alert>
          ) : (
            <Alert
              action={(
                <>
                  <Button onClick={() => setSnackbarOpen(false)}>
                    {t('No')}
                  </Button>
                  <Button onClick={() => handleMedicalActRegistration()}>
                    {t('Yes')}
                  </Button>
                </>
              )}
              onClose={() => setSnackbarOpen(false)}
              severity="info"
            >
              {`${t('Do you want to record a patient file consultation')} ?`}
            </Alert>
          )}
        </Snackbar>
      )}
      <Box display="flex">
        <StaticMenu />
        <Switch>
          <Route exact path={path}>
            <SectionMapper />
          </Route>
          <Route path={`${path}/questionnaire/:questionnaireId`}>
            <QuestionnaireDetails uuid={uuid} />
          </Route>
          <Route path={`${path}/vitals/:vital`}>
            <VitalDetails />
          </Route>
          <Route path={`${path}/lab-results/:labResultCode`}>
            <LabResultDetails uuid={uuid} />
          </Route>
          <Route path={`${path}/clinical-data/:clinicalDataCode`}>
            <ClinicalDataDetails />
          </Route>
          <Route path={`${path}/care-team/:id`}>
            <CareTeamDetails />
          </Route>
          <Route path={`${path}/:section`}>
            <SectionMapper />
          </Route>
        </Switch>
      </Box>
      {edit && clinic.config.features_enable.speed_dial && (
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{ position: 'fixed', bottom: '8%', right: '5%' }}
          icon={<SpeedDialIcon />}
          onClose={() => setSpeedDialOpen(false)}
          onOpen={() => setSpeedDialOpen(true)}
          open={speedDialOpen}
        >
          {speedDialActions.map((action) => (
            (action.access === 'ALL' || action.access.includes(access)) && (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={t(action.name)}
                onClick={action.onClick}
              />
            )
          ))}
        </SpeedDial>
      )}
      {addVitalsOpen && (
        <AddVitalsDialog open={addVitalsOpen} handleClose={() => setAddVitalsOpen(false)} />
      )}
      <QuestionnaireSelector
        patientUuid={uuid}
        open={questionnaireOpen}
        handleClose={handleQuestionnaireClose}
      />
      {access !== 'P' && (
        <>
          <ClinicalNoteModal open={addNoteOpen} handleClose={() => setAddNoteOpen(false)} />
          <AddClinicalDataModal open={addDataOpen} handleClose={() => setAddDataOpen(false)} />
        </>
      )}
    </Box>
  );
};

const SectionMapper = () => {
  const {
    uuid, section, vital, labResultCode, clinicalDataCode,
  } = useParams();
  const { token } = useSelector((state) => state.authorization);

  // When navigating from dashboard, the section wont be in params, but the id is.
  if (vital) {
    return <VitalDetails />;
  }

  if (labResultCode) {
    return <LabResultDetails uuid={uuid} />;
  }

  if (clinicalDataCode) {
    return <ClinicalDataDetails />;
  }

  switch (section) {
    case 'clinical-data':
      return <ClinicalDataTable uuid={uuid} />;
    case 'lab-results':
      return <LabResultsWrapper uuid={uuid} domain={`https://${domain}`} authToken={token} />;
    case 'medications':
      return <MedicationTab uuid={uuid} />;
    case 'reports':
      return <Reports patientUuid={uuid} />;
    case 'calendar':
      return <Calendar patientUuid={uuid} />;
    case 'clinical-note':
      return <ClinicalNotePanel uuid={uuid} />;
    case 'care-team':
      return <CareTeam uuid={uuid} />;
    case 'overview':
      return (
        <Box sx={{ width: '100%' }}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <DateRangePicker />
          </Box>
          <Overview />
        </Box>
      );
    case 'alert':
      return <AlertDetails type="alert" />;
    case 'reminder':
      return <AlertDetails type="reminder" />;
    case 'information':
      return <PatientInformations />;
    case 'medical-acts':
      return <MedicalActs />;
    case 'education':
      return <EducationPanel />;
    case 'documents':
      return <Documents />;

    default:
      return (
        <Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <DateRangePicker />
          </Box>
          <Overview />
        </Box>
      );
  }
};

export default PatientProfileLayout;
