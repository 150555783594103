import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { apiEndpoints } from './endpointsConfig';
import { msalConfig } from '../authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

// Fhir endpoints have no slash at the end of the path
const parse = (path, id) => {
  if (path.startsWith('fhir')) {
    return `${path}/${id}`;
  }

  if (!id) return path;

  return `${path}${id}/`;
};

const domainConfigs = {
  localhost: 'dev-ca.takecareapi.com',
  'dev.greybox.ca': 'dev-ca.takecareapi.com',
  'dev-ca.greybox.fr': 'dev-ca.takecareapi.com',
  'dev.greybox.fr': 'dev-eu.takecareapi.com',
  'staging.greybox.ca': 'staging-ca.takecareapi.com',
  'hotfix.greybox.ca': 'ca.takecareapi.com',
  'staging.greybox.fr': 'staging-eu.takecareapi.com',
  'takecare.greybox.ca': 'ca.takecareapi.com',
  'takecare.greybox.fr': 'eu.takecareapi.com',
};

const domain = domainConfigs[window.location.hostname];
const webSocketUrl = `wss://${domain}/ws`;

const url = `https://${domain}`;

const actionsMapper = {
  LIST: (builder, path, tag) => builder.query({
    query: ({ id, ...params }) => {
      // Remove undefined values
      Object.keys(params).forEach((key) => (params[key] === undefined) && delete params[key]);
      const queryString = Object.keys(params)
        .map((key) => (Array.isArray(params[key])
          ? params[key].map((value) => `${key}=${value}`).join('&')
          : `${key}=${params[key]}`))
        .join('&');

      return {
        url: `${path}${id ? `${id}/` : ''}?${queryString}`,
        method: 'GET',
      };
    },
    providesTags: tag,
  }),
  POST: (builder, path, tag) => (
    builder.mutation({
      query: ({ id, body }) => ({
        url: `${path}${id ? `${id}/` : ''}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: tag,
    })
  ),
  PATCH: (builder, path, tag) => (
    builder.mutation({
      query: ({ id, body }) => ({
        url: parse(path, id),
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: tag,
    })
  ),
  PUT: (builder, path, tag) => (
    builder.mutation({
      query: ({ id, body }) => ({
        url: parse(path, id),
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: tag,
    })
  ),
  DELETE: (builder, path, tag) => (
    builder.mutation({
      query: (id) => ({
        url: parse(path, id),
        method: 'DELETE',
      }),
      invalidatesTags: tag,
      transformResponse: (response) => (response || { status: 204 }),
    })
  ),
};

const actionsNames = {
  POST: 'add',
  PATCH: 'update',
  DELETE: 'delete',
  PUT: 'put',
  LIST: 'list',
};

/**
 * All api hooks are created automatically by createApi, we normally export them directly.
 * However, we dynamically create api hooks for each endpoint and won't know the name
 * of the endpoint until it is created. The following function applies the same naming convention
 * as RTK with the given name of the endpoint. With this, we can extract given hooks to be exported.
 */
const actionHookName = (action, name) => {
  switch (action) {
    case 'POST':
      return `useAdd${name}Mutation`;
    case 'PATCH':
      return `useUpdate${name}Mutation`;
    case 'PUT':
      return `usePut${name}Mutation`;
    case 'DELETE':
      return `useDelete${name}Mutation`;
    case 'LIST':
      return `useList${name}Query`;
    default:
      return null;
  }
};

const createEndpoints = (builder) => {
  const endpoints = {};
  apiEndpoints.forEach((endpoint) => {
    const {
      name, path, actions, tags = [],
    } = endpoint;
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

    actions.forEach((action) => {
      endpoints[`${actionsNames[action]}${capitalizedName}`] = actionsMapper[action](builder, path, [name, ...tags]);
    });

    endpoints[`get${capitalizedName}`] = builder.query({
      query: (id) => ({
        url: parse(path, id),
        method: 'GET',
      }),
      providesTags: [name, ...tags],
    });
  });
  return endpoints;
};

const api = createApi({
  reducerPath: 'takecareApi',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: async (headers, { getState }) => {
      const state = getState();
      const { token } = state.authorization;
      if (token && token.startsWith('Token ')) {
        headers.set('Authorization', token);
        return headers;
      }

      // Use msal to retrieve the authentication token
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const tokenResponse = await msalInstance.acquireTokenSilent({
          scopes: ['openid',
            'https://takecareca.onmicrosoft.com/api/takecare.read',
            'https://takecareca.onmicrosoft.com/api/takecare.write',
          ],
          account: accounts[0],
        });
        headers.set('Authorization', `Bearer ${tokenResponse.accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: apiEndpoints.map((endpoint) => (endpoint.name)),
  endpoints: (builder) => createEndpoints(builder),
});

const greyboxApiActions = {};

apiEndpoints.forEach((endpoint) => {
  const capitalizedName = endpoint.name.charAt(0).toUpperCase() + endpoint.name.slice(1);
  greyboxApiActions[endpoint.name] = { get: api[`useGet${capitalizedName}Query`] };
  endpoint.actions.forEach((action) => {
    greyboxApiActions[endpoint.name] = {
      ...greyboxApiActions[endpoint.name],
      [actionsNames[action]]: api[actionHookName(action, capitalizedName)],
    };
  });
});

export {
  greyboxApiActions,
  domainConfigs,
  domain,
  webSocketUrl,
};
export default api;
