import {
  List, ListItemButton, ListItemText,
  Paper,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SubpathMenu from './SubpathMenu';

type NavConfig = {
  id: string;
  label: string;
  url: string;
  clinicAccess?: string[];
  access?: string[];
  subPaths?: { id: string; label: string; url: string }[];
};

type NavigationMenuProps = {
  navConfig: NavConfig[];
};

/**
 * Displays a list of paths that the current user has access to.
 * A subpath is displayed as a nested list under parent.
 */
const NavigationMenu = ({ navConfig }: NavigationMenuProps) => {
  const { access } = useSelector((state) => state.user);

  return (
    <Paper>
      <List>
        {navConfig.map((value) => {
          if (!value.access || value.access.some((role) => role === access)) {
            return (
              value.subPaths ? (
                <SubpathMenu
                  key={value.id}
                  id={value.id}
                  label={value.label}
                  subPaths={value.subPaths}
                />
              ) : (
                <ListItemButton
                  key={value.id}
                  data-cy={value.id}
                  component={Link}
                  selected={window.location.pathname.split('/').some((segment) => segment === value.id)}
                  to={value.url}
                >
                  <ListItemText primary={value.label} />
                </ListItemButton>
              )
            );
          }
          return null;
        })}
      </List>
    </Paper>
  );
};

export default NavigationMenu;
