import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dialog, DialogActions, DialogContent, Typography,
  Autocomplete, TextField, MenuItem, Box, CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import Row from './Row';
import FormTextField from './form-inputs/FormTextField';
import PhoneNumber from './form-inputs/PhoneNumber';
import DialogTitle from './DialogTitle';
import { greyboxApiActions } from '../redux/api';

const comparePharmacy = (values, data) => {
  if (values.pharmacy.label !== data.name) return false;
  if (values.address !== data.address) return false;
  if (values.phone !== data.phone) return false;
  if (!(values.fax === '' && data.fax === null) && values.fax !== data.fax) return false;
  return true;
};

/**
 *
 * Used to create or update a pharmacy, and link it to an account to be modified
 * by parent component.
 */
const PharmacyDialog = (props) => {
  const {
    open,
    handleClose,
    accountFormik,
    id = null,
  } = props;
  const { t } = useTranslation();
  const { pharmacy, account } = greyboxApiActions;
  const startingPharmacy = pharmacy.get(id, { skip: !id });
  const [createPharmacy] = pharmacy.add();
  const [updatePharmacy] = pharmacy.put();
  const { clinic } = useSelector((state) => state.clinic);
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [location, setLocation] = React.useState('45.5017,-73.5673'); // Montreal
  const { data = [], isFetching } = pharmacy.list(
    { search: searchInput, count: 10, location: location },
    { skip: searchInput.length < 3 },
  );

  React.useEffect(() => {
    if (clinic) {
      if (Number(clinic.lat) === 0 && Number(clinic.lng) === 0) {
        // get coordinates from browser
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            setLocation(`${latitude},${longitude}`);
          });
        } else {
          setLocation(`${clinic.lat},${clinic.lng}`);
        }
      }
    }
  }, [clinic]);

  const handlePharmacyChange = (value) => {
    formik.setFieldValue('pharmacy', { id: value.id, label: value.label });
    formik.setFieldValue('address', value.address);
    formik.setFieldValue('phone', value.phone);
    formik.setFieldValue('fax', value.fax);
  };

  const handleTextChange = (e) => {
    setSearchInput(e.target.value);
    formik.setFieldValue('pharmacy', { id: null, label: e.target.value });
  };

  const formik = useFormik({
    initialValues: {
      pharmacy: {
        label: (id && startingPharmacy.data?.name) || '',
        id: (id && startingPharmacy.data?.id) || '',
      },
      address: (id && startingPharmacy.data?.address) || '',
      phone: (id && startingPharmacy.data?.phone) || '',
      fax: (id && startingPharmacy.data?.fax) || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      pharmacy: Yup.object().required(t('Required')),
      address: Yup.string().required(t('Required')),
      phone: Yup.string().required(t('Required')),
      fax: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      if (id && (id === values.pharmacy.id)) {
        await updatePharmacy({
          id: values.pharmacy.id,
          body: {
            name: values.pharmacy.label,
            address: values.address,
            phone: values.phone,
            fax: values.fax,
          },
        });
      }
      // Create a new pharmacy and update the account.
      // If id is null but the form is valid, then all required fields are filled
      if (values.pharmacy.id === null) {
        await createPharmacy({
          body: {
            name: values.pharmacy.label,
            address: values.address,
            phone: values.phone,
            fax: values.fax,
          },
        }).then((res) => {
          accountFormik.setFieldValue('pharmacy', res.data.id);
        });
      } else {
        if (id === null || id !== values.pharmacy.id) {
          if (!comparePharmacy(values, data
            .find((p) => p.id === values.pharmacy.id))) {
            await updatePharmacy(
              {
                id: values.pharmacy.id,
                body: {
                  name: values.pharmacy.label,
                  address: values.address,
                  phone: values.phone,
                  fax: values.fax,
                },
              },
            );
          }
          accountFormik.setFieldValue('pharmacy', values.pharmacy.id);
        }
        // do nothing if the pharmacy id is the same since it's not needed in the account
        formik.setSubmitting(false);
        closeDialog();
      }
    },
  });

  const closeDialog = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
      <DialogTitle onClose={closeDialog}>
        {id ? t('Edit Pharmacy') : t('Add Pharmacy')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Row>
            <Autocomplete
              sx={{ mx: 1 }}
              freeSolo
              noOptionsText={t('No options')}
              open={autocompleteOpen}
              value={formik.values.pharmacy}
              getOptionLabel={(option) => option.label}
              filterOptions={(options, state) => options}
              isOptionEqualToValue={(option, value) => option.id === formik.values.pharmacy.id}
              onChange={(e, value) => handlePharmacyChange(value)}
              onOpen={() => {
                setAutocompleteOpen(true);
              }}
              onClose={() => {
                setAutocompleteOpen(false);
              }}
              options={
                [...data.map((item, index) => (
                  {
                    id: item.id,
                    index: index,
                    label: item.name,
                    address: item.address,
                    phone: item.phone,
                    fax: item.fax,
                  }
                ))]
              }
              renderOption={(p, option) => (
                <MenuItem {...p} key={option.id} data-cy={`option-${option.index}`}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {option.label}
                    <Typography variant="caption">
                      {option.address}
                    </Typography>
                  </Box>
                </MenuItem>
              )}
              loading={isFetching}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Pharmacy')}
                  data-cy="pharmacy-search"
                  onChange={handleTextChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <FormTextField required formik={formik} name="address" label={t('Address')} />
          </Row>
          <Row>
            <PhoneNumber required formik={formik} name="phone" />
            <PhoneNumber formik={formik} name="fax" label={t('Fax')} />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{t('Cancel')}</Button>
          <LoadingButton
            type="submit"
            data-cy="pharmacy-submit"
            loading={formik.isSubmitting}
            variant="contained"
            disabled={!formik.dirty}
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PharmacyDialog;
