export const jsonComparatorParser = (var1, op, var2) => {
  switch (op) {
    case 'OR':
      return var1 || var2;
    case 'AND':
      return var1 && var2;
    case 'XOR':
      return var1 !== var2;
    case 'IN':
      return Array.isArray(var2) && var2.includes(var1);
    case 'NOT IN':
      return Array.isArray(var2) && !var2.includes(var1);
    case '=':
      return var1 === var2;
    case '!=':
      return var1 !== var2;
    case '>':
      return var1 > var2;
    case '<':
      return var1 < var2;
    case '>=':
      return var1 >= var2;
    case '<=':
      return var1 <= var2;
    default:
      return false;
  }
};

/**
 * @param {*} jsonObject
 * @returns {boolean} Answer of the comparison inside the jsonObject
 * The jsonObject has the following structure:
 * {
 *  "var1": var or object,
 *  "var2": var or object,
 *  "condition": string
 * }
 * If one of the var is an object, it is another condition to be evaluated.
 * Hence the recursion
 */
export const computeJson = (jsonObject) => {
  let { var1, var2 } = jsonObject;
  const { op } = jsonObject;

  if (var1 === null || var2 === null) {
    return false;
  }

  if (typeof var1 === 'object' && !Array.isArray(var1)) {
    var1 = computeJson(var1);
  }

  if (typeof var2 === 'object' && !Array.isArray(var2)) {
    var2 = computeJson(var2);
  }

  return jsonComparatorParser(var1, op, var2);
};
