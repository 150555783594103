import React from 'react';
import { SvgIcon } from '@mui/material';

const TimeOutlined = (props) => {
  const { viewBox = '0 0 30 30', fills = '#83dde7' } = props;
  return (
    <SvgIcon viewBox={viewBox} fills={fills}>

      <g>
        <path
          d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8
  S16.4,20,12,20z"
          fill={fills}
        />
        <path
          d="M16.7,14.5l-4-2.4V7.3c0-0.3-0.2-0.5-0.5-0.5h-1c-0.3,0-0.5,0.2-0.5,0.5v5.6c0,0.2,0.1,0.4,0.3,0.5l4.7,2.9
  c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.2l0.5-0.9C17,15,17,14.9,17,14.8C16.9,14.6,16.9,14.5,16.7,14.5z"
          fill={fills}
        />
      </g>
    </SvgIcon>
  );
};

export { TimeOutlined };
