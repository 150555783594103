import React, { useEffect } from 'react';
import {
  Alert, Box, Collapse, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { RRule } from 'rrule';
import { greyboxApiActions } from '../redux/api';
import { InformationMessageApiType } from '../types';

function isCurrentDateInRecurrenceRule(
  rRuleStr: string, timeStart: string, timeEnd: string,
): boolean {
  const rRule = RRule.fromString(rRuleStr);
  const now = moment();
  const start = moment(timeStart);

  const nextOccurrence = rRule.after(start.toDate(), true);

  if (now.isBefore(timeEnd, 'h') && now.isAfter(nextOccurrence)) {
    return true;
  }

  return false;
}

const typeToSeverity = {
  information: 'info',
  alert: 'warning',
};

const AlertMessage = ({ data }) => {
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const isDismissed = localStorage.getItem(`dismissed-${data.id}`);
  const { access } = useSelector((state) => state.user);
  const {
    active, dtstart, dtend, recurrences,
  } = data;

  useEffect(() => {
    if (active) {
      if (moment().isBefore(dtend) && moment().isAfter(dtstart)) {
        setShow(true);
      } else if (recurrences) {
        setShow(isCurrentDateInRecurrenceRule(recurrences, dtstart, dtend));
      }
    }
  }, [active]);

  if (!data || !data.text) {
    return null;
  }

  if (!show || access !== 'P' || isDismissed === 'true') {
    return null;
  }

  const handleCloseDismissible = (id) => {
    localStorage.setItem(`dismissed-${id}`, 'true');
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          key={data.id}
          severity={typeToSeverity[data.type]}
          icon={false}
          sx={{ fontFamily: (theme) => theme.typography.fontFamily }}
          action={
            data.dismissible && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleCloseDismissible(data.id);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
        >
          <ReactMarkdown>{data.text}</ReactMarkdown>
        </Alert>
      </Collapse>
    </Box>
  );
};

const InformationMessageBar = () => {
  const { informationMessage } = greyboxApiActions;
  const clinicSelector = useSelector((state) => state.clinic);
  const { data = [], isLoading } = informationMessage.list(
    { clinic: clinicSelector.clinic?.id }, { skip: !clinicSelector.clinic?.id },
  ) as { data: InformationMessageApiType[]; isLoading: boolean };

  if (isLoading || !data.length) {
    return null;
  }

  return (
    data.map((message) => (
      <AlertMessage key={message.id} data={message} />
    ))
  );
};

export default InformationMessageBar;
