import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { QUICK_RANGES } from '../components/patient-profile/dateRangePicker/quickRanges';

/**
 * State used to feed start and end date to charts in patient profile.
 */
const patientProfileDateRange = createSlice({
  name: 'patientProfileDateRange',
  initialState: {
    startDate: null,
    endDate: null,
    range: null, // Formatted duration used for API calls
    isLoading: false,
    value: null, // Formatted duration used for display
    clinic: null,
  },
  reducers: {
    setDateRange: (state, action) => {
      localStorage.setItem('dateRange', JSON.stringify(action.payload));

      let end = moment();
      if (action.payload.endDate) {
        end = action.payload.endDate;
      }

      state.value = action.payload.value;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.range = `${moment(action.payload.startDate).toISOString()},${moment(end).toISOString()}`;
      state.isLoading = false;
      state.clinic = action.payload.clinic;
    },
    setIsLoading: (state) => {
      state.isLoading = true;
    },
  },
});

const { setDateRange, setIsLoading } = patientProfileDateRange.actions;

/**
 * Called when user open patient profile. Checks if a date range already
 * has been entered for this user in localStorage. If not use default
 */
const initializeDateRange = (clinicId, defaultRange, createdDate) => async (dispatch) => {
  dispatch(setIsLoading());
  const storedRange = JSON.parse(localStorage.getItem('dateRange'));
  if (storedRange && storedRange.value && storedRange.clinic === clinicId) {
    dispatch(setDateRange(
      {
        startDate: storedRange.value === 'all' ? moment(createdDate).valueOf() : storedRange.startDate, // 'all' is a special case for 'since patient creation'
        endDate: storedRange.endDate,
        value: storedRange.value,
        clinic: clinicId,
      },
    ));
  } else if (defaultRange && QUICK_RANGES.includes(defaultRange)) {
    const start = defaultRange === 'all' ? moment(createdDate) : (
      moment().subtract(
        defaultRange.substring(
          0, defaultRange.length - 1,
        ), defaultRange.substring(defaultRange.length - 1),
      )
    );
    dispatch(setDateRange(
      {
        startDate: start,
        endDate: null,
        value: defaultRange,
        clinic: clinicId,
      },
    ));
  } else {
    dispatch(setDateRange(
      {
        startDate: (moment().subtract(14, 'days')).valueOf(),
        endDate: null,
        value: '14d',
        clinic: clinicId,
      },
    ));
  }
};

export default patientProfileDateRange.reducer;
export { initializeDateRange, setDateRange };
