import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material';

/**
 * Basic modal to display a warning when the user is about to leave a page without saving.
 */
const UnsavedWarning = (props) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle>
        {t('Your changes will be lost')}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {`${t('Are you sure you want to leave this page')}?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>
          {t('Go back')}
        </Button>
        <Button onClick={() => handleClose(true)} color="primary">
          {t('Discard changes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedWarning;
