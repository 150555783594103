import React from 'react';
import { SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  color: {
    fill: theme.palette.error,
  },
  size: {
    transform: 'scale(0.7)',
  },
}));

const BelowThreshold = (props) => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.size} viewBox="3 3 18 18">
      <g id="prefix__Composant_215_118" data-name="Composant 215 – 118" transform="rotate(180 12 12)">
        <path id="prefix__Box" d="M0 0H24V24H0z" fill="#fff" opacity="0" />
        <path
          className={classes.color}
          id="prefix__Path_1084"
          d="M135.294 71.168a1.086 1.086 0 1 1 1.085-1.087 1.085 1.085 0 0 1-1.085 1.085zm4.84-11.005a1.086 1.086 0 0 1-1.536-.008l-2.169-2.2.023 8.045a1.086 1.086 0 0 1-1.082 1.089 1.083 1.083 0 0 1-1.085-1.082l-.025-8.159-2.276 2.3a1.086 1.086 0 1 1-1.546-1.526l4.063-4.1a1.083 1.083 0 0 1 .768-.322 1.19 1.19 0 0 1 .218.022 1.084 1.084 0 0 1 .6.306l4.059 4.1a1.086 1.086 0 0 1-.012 1.535z"
          data-name="Path 1084"
          transform="translate(-123.125 -50.686)"
        />
      </g>
    </SvgIcon>
  );
};

export { BelowThreshold };
