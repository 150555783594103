import { createSlice } from '@reduxjs/toolkit';
import api from './api';

const permissions = createSlice({
  name: 'permissions',
  initialState: {
    isLoading: false,
    archive: false,
    edit: false,
    view: false,
    clinicModify: false,
  },
  reducers: {
    permissionsLoading: (state) => {
      state.isLoading = true;
    },
    permissionsLoaded: (state, action) => {
      state.isLoading = false;
      state.isUninitialized = false;
      state.archive = action.payload.data.includes('delete_patient');
      state.edit = action.payload.data.includes('change_patient');
      state.view = action.payload.data.includes('view_patient');
    },
  },
});

const { permissionsLoading, permissionsLoaded } = permissions.actions;

/**
 * To be called when patient profile is loaded.
 */
const getPermissions = (currentUuid, patientUuid) => async (dispatch) => {
  dispatch(permissionsLoading());
  const p = await dispatch(api.endpoints.listPermissions.initiate({
    current_user_uuid: currentUuid,
    on_user_uuid: patientUuid,
  }));

  dispatch(permissionsLoaded({ data: p.data }));
};

export default permissions.reducer;
export { getPermissions };
