import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@mui/material';
import { XIcon } from '../../../tk-ui';
import { Popovers } from '../../../tk-ui/popover';
import { WarningInfoIcon, NoMedications } from '../../img';
import { none_style, missing_inputs_style, consult } from './suggestedMedications_style';

/**
 * Warning displayed when patient has no need for medications ( if patient NYHA initial
 * as a certain score... tbd , and lvef > 40)
 */

function SuggestedNone() {
  const { t } = useTranslation();

  return (
    <>
      <div style={none_style.wrapper} data-cy="noSuggestedMedsInfobox">
        <div style={none_style.box}>
          <div style={{ position: 'absolute', top: '10px', left: '11px' }}>
            <NoMedications data-cy="WarningInfoIcon" />
          </div>
          <Typography style={none_style.errorMessage}>
            {`${t('No suggested treatment plan for this patient')}.`}
          </Typography>

          <Typography style={none_style.errorSuggestion}>
            {t(
              'Based on the available data, TAKECARE does not suggest any medication in the treatment plan',
            )}
            .
          </Typography>
        </div>
      </div>
    </>
  );
}

function DisplayInformation() {
  const { t } = useTranslation();

  return (
    <>
      <div style={none_style.wrapper} data-cy="noSuggestedMedsInfobox">
        <div style={none_style.box}>
          <div style={{ position: 'absolute', top: '10px', left: '11px' }}>
            <NoMedications data-cy="WarningInfoIcon" />
          </div>
          <Typography style={none_style.errorMessage}>
            {t('Guideline information')}
          </Typography>

          <Typography style={none_style.errorSuggestion}>
            <ul>
              <Trans i18nKey="HF_GUIDELINE" t={t}>
                <li>Identification and treatment of underlying factors and comorbidities.</li>
                <li>Minimum effective diuretic dose to maintain euvolemia.</li>
                <li>An MRA like spironolactone should be considered.</li>
              </Trans>
            </ul>
          </Typography>
        </div>
      </div>
    </>
  );
}

// box to warn for missing data
function MissingInputs(props) {
  const { requiredInputs } = props;
  const { t, i18n } = useTranslation();

  return (
    <>
      <div style={missing_inputs_style.wrapper} data-cy="missingDecisionTreeInputsInfobox">
        <div style={missing_inputs_style.box}>
          <div style={missing_inputs_style.icon}>
            <WarningInfoIcon data-cy="WarningInfoIcon" />
          </div>
          <Typography style={missing_inputs_style.errorMessage}>
            {`${t('Cannot suggest a treatment plan')}.`}
          </Typography>

          <Typography style={missing_inputs_style.errorSuggestion}>
            {t('Based on the available data, TAKECARE cannot suggest a treatment plan')}
            .
            {' '}
            {' '}
            {t('Make sure to complete the clinical data')}
            {requiredInputs ? ':' : '.'}
            {requiredInputs
                        && (
                          <ul>
                            {
                              requiredInputs.map((i, idx) => (
                                <li key={idx}>
                                  {i.map((or) => or[i18n.resolvedLanguage]).join(t(' or '))}
                                </li>
                              ))
                            }
                          </ul>
                        )}
          </Typography>
        </div>
      </div>
    </>
  );
}

function Consultation() {
  const { t } = useTranslation();

  return (
    <>
      <div style={consult.wrapper} data-cy="Consultation">
        <div
          style={consult.headerLight}
        >
          <Typography
            style={consult.wrapperHeader}
            data-cy="ConsultationTitle"
          >
            {t('Consultation')}
          </Typography>
          <div style={consult.positionning}>
            <Popovers
              pointer={false}
              borderRadius="3px"
              popTopPos="-10px"
              anchorVertical="top"
              text={t('CAUTION')}
              anchorHorizontal="left"
              transformVertical="bottom"
              transformHorizontal="left"
              ownStyle={consult.popBtn}
            >
              {(closePop) => (
                <div style={consult.popBox} data-cy="ConsultationBox">
                  <div style={consult.popBoxHeader}>
                    <span style={consult.popBoxHeaderTitle}>
                      {t('Consultation - Endocrinologist')}
                    </span>
                    <div
                      onClick={closePop}
                      style={consult.popBoxHeaderBtn}
                      role="button"
                      tabIndex={0}
                    >
                      <XIcon fills="#babdbf" />
                    </div>
                  </div>
                  <div style={consult.textBox}>
                    <Typography style={consult.textStyle} data-cy="ConsultationText">
                      {t(
                        'Based on available data, TAKECARE suggests consulting an endocrinologist to define the patient\'s treatment plan',
                      )}
                      .
                    </Typography>
                  </div>
                </div>
              )}
            </Popovers>
          </div>
        </div>
        <div style={consult.category} data-cy="ConsultationName">{t('Endocrinologist')}</div>
      </div>
    </>
  );
}

export {
  SuggestedNone, MissingInputs, Consultation, DisplayInformation,
};
