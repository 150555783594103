import { Button, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import QuestionnaireAnswers from './QuestionnaireAnswers';

const dateIsOutdated = (date) => moment().diff(moment(date), 'hour') > 24;

const isHex = (color) => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

const colorMapping = (colorName) => {
  switch (colorName) {
    case 'Red':
      return 'error';
    case 'Yellow':
      return 'warning';
    case 'Green':
      return 'success';
    case 'Blue':
      return 'info';
    default:
      return 'info';
  }
};

const useStyles = makeStyles(() => ({
  custom: ({ color }) => ({
    borderColor: color,
    color: color,
  }),
}));

/**
 * Display a color coded status of selected questionnaire or alert.\
 * Default is:
 * - red = not feeling well
 * - green = feeling well
 * - gray = outdated
 * Will display all questions with their answers on click.
 */
const QuestionnaireSummaryButton = (props) => {
  const { i18n, t } = useTranslation();
  const {
    recordId, date, score, recordIndex, hasScore,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = score && score.display ? useStyles(score.display[0]) : useStyles();
  let className = '';
  let color = 'secondary';
  let text = '';
  let logo = null;
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  if (!hasScore || score === null) {
    if (location.pathname.includes('/dashboard')) {
      text = moment(date).format('ll');
    } else {
      text = t('Show answers');
    }
  } else if (score.display) {
    text = score.display[0].value[i18n.resolvedLanguage] || '---';
    if (isHex(score.display[0].color)) {
      className = classes.custom;
    } else {
      color = colorMapping(score.display[0].color);
    }
  } else if (score.value_text === 'high') {
    text = moment(date).format('ll');
    logo = <WarningIcon />;
    color = 'error';
  } else {
    text = moment(date).format('ll');
    logo = <CheckCircleOutlineIcon />;
    color = 'success';
  }

  if (date && dateIsOutdated(date) && hasScore) {
    className = '';
    color = 'disabled';
  }

  return (
    <>
      <Button
        className={className}
        color={color}
        variant="outlined"
        startIcon={logo}
        size="small"
        onClick={(event) => handleClick(event)}
      >
        {text}
      </Button>
      {open && (
        <Popper
          placement="bottom"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disablePortal={false}
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]}
        >
          <QuestionnaireAnswers
            recordIndex={recordIndex}
            recordId={recordId}
            handleClose={handleClose}
          />
        </Popper>
      )}
    </>
  );
};

QuestionnaireSummaryButton.propTypes = {
  recordId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  score: PropTypes.object,
  recordIndex: PropTypes.number,
  hasScore: PropTypes.bool.isRequired,
};

QuestionnaireSummaryButton.defaultProps = {
  recordIndex: null,
  score: null,
};

export default QuestionnaireSummaryButton;
