import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Box, Button, List, ListItemButton, ListItemText, Popover, TextField, Typography, IconButton,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange } from '../../../redux/patientProfileDateRange';
import getQuickRange from './quickRanges';

const CalendarButton = (props) => {
  const {
    setOpen,
    ref,
  } = props;

  return (
    <IconButton ref={ref} size="small" onClick={(e) => setOpen(e.currentTarget)}>
      <EventIcon />
    </IconButton>
  );
};

const DateRangePicker = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { startDate, endDate, value } = useSelector((state) => state.patientProfileDateRange);
  const quickRanges = getQuickRange();

  const handlePopoverOpen = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <Button
        sx={{ mr: 1 }}
        startIcon={<AccessTimeIcon />}
        endIcon={!anchorEl ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        onClick={handlePopoverOpen}
      >
        {quickRanges.find((range) => range.id === value)?.label
        || `${moment(startDate).format('ll')} - ${moment(endDate).format('ll')}`}
      </Button>
      <DateRangePopover
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
      />
    </>
  );
};

const DateRangePopover = (props) => {
  const {
    handleClose,
    anchorEl,
  } = props;
  const [startText, setStartText] = useState('');
  const { clinic } = useSelector((state) => state.clinic);
  const { createdDate } = useSelector((state) => state.patient);
  const [endText, setEndText] = useState('');
  const [startOpen, setStartOpen] = useState(null);
  const [endOpen, setEndOpen] = useState(null);
  const { startDate, endDate, value } = useSelector((state) => state.patientProfileDateRange);
  const [customStartDate, setCustomStartDate] = useState(moment(startDate));
  const [customEndDate, setCustomEndDate] = endDate ? useState(moment(endDate)) : useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleStartDateChange = (date) => {
    setStartText(moment(date).format('lll'));
    setCustomStartDate(date);
    setStartOpen(null);
  };

  const handleEndDateChange = (date) => {
    setEndText(moment(date).format('lll'));
    setCustomEndDate(date);
    setEndOpen(null);
  };

  const handleCustomButtonClick = () => {
    dispatch(setDateRange({
      startDate: moment(customStartDate).valueOf(),
      endDate: moment(customEndDate).valueOf(),
      value: 'custom',
      clinic: clinic.id,
    }));
    handleClose();
  };

  const handleDateSelect = (count, unit, id) => {
    const start = count === 0 ? (
      moment(createdDate).valueOf()) : moment().subtract(count, unit).valueOf();
    const end = null;
    dispatch(setDateRange({
      startDate: start, endDate: end, value: id, clinic: clinic.id,
    }));

    setStartText(id === 'all' ? moment(startDate).format('lll') : `-${id}`);
    setEndText(t('now'));
    handleClose();
  };

  useEffect(() => {
    if (value === 'custom') {
      setStartText(moment(startDate).format('lll'));
      setEndText(moment(endDate).format('lll'));
    } else {
      setStartText(value === 'all' ? moment(startDate).format('lll') : `-${value}`);
      setEndText(t('now'));
    }
  }, [startDate, endDate]);

  const QUICK_RANGES = getQuickRange();

  return (
    <Popover
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box display="flex" height="350px">
        <Box
          display="flex"
          flexDirection="column"
          overflow="hidden"
          padding={1}
          borderRight={(theme) => `1px solid ${theme.palette.divider}`}
        >
          <Typography>
            {t('From')}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TextField
              value={startText}
              InputProps={{
                endAdornment: <DateTimePicker
                  slots={{ field: CalendarButton }}
                  slotProps={{ field: { setOpen: setStartOpen }, popper: { anchorEl: startOpen } }}
                  open={Boolean(startOpen)}
                  onClose={() => setStartOpen(null)}
                  onAccept={handleStartDateChange}
                  value={moment(startDate)}
                />,
              }}
            />
            <Typography>
              {t('To')}
            </Typography>
            <TextField
              value={endText}
              InputProps={{
                endAdornment: <DateTimePicker
                  slots={{ field: CalendarButton }}
                  slotProps={{ field: { setOpen: setEndOpen }, popper: { anchorEl: endOpen } }}
                  open={Boolean(endOpen)}
                  onClose={() => setEndOpen(null)}
                  onAccept={handleEndDateChange}
                  value={moment(endDate)}
                />,
              }}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            onClick={handleCustomButtonClick}
            disableElevation
            size="small"
            sx={{ width: 'fit-content', mt: 1 }}
          >
            {t('Apply time range')}
          </Button>
        </Box>
        <Box sx={{ overflowY: 'auto', maxHeight: '100%', minWidth: '200px' }}>
          <List>
            {QUICK_RANGES.map(({
              count, unit, label, id,
            }) => (
              <ListItemButton
                key={`${id}`}
                selected={value === id}
                onClick={() => handleDateSelect(count, unit, id)}
              >
                <ListItemText primary={label} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Box>
    </Popover>
  );
};

export default DateRangePicker;
