import { DragHandle, MoreVert } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions,
  DialogContent, IconButton, Menu, MenuItem, Typography, TextField, Skeleton,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';
import FormTextField from '../form-inputs/FormTextField';
import Row from '../Row';
import AddDocument from './AddDocument';
import EducationSection from './EducationSection';

const AddSectionDialog = (props) => {
  const {
    open, onClose, lastOrder, sectionId,
  } = props;
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { educationSection, company } = greyboxApiActions;
  const [addSection] = educationSection.add();
  const [updateSection] = educationSection.put();
  const selectedSection = educationSection.get(sectionId, { skip: !sectionId });
  const { data = [], isLoading } = company.list({});

  const formik = useFormik({
    initialValues: {
      title_en: selectedSection?.data?.title_en || '',
      title_fr: selectedSection?.data?.title_fr || '',
      title_de: selectedSection?.data?.title_de || '',
      title_es: selectedSection?.data?.title_es || '',
      title_it: selectedSection?.data?.title_it || '',
      title_pt: selectedSection?.data?.title_pt || '',
    },
    enableReinitialize: true,
    validation: (values) => {
      const errors = {};
      if (!values.title_en
        && !values.title_fr
        && !values.title_de
        && !values.title_es
        && !values.title_it
        && !values.title_pt) {
        errors.title_en = t('Required');
      }
      return errors;
    },
    validationSchema: Yup.object({
      title_en: Yup.string(),
      title_fr: Yup.string(),
      title_de: Yup.string(),
      title_es: Yup.string(),
      title_it: Yup.string(),
      title_pt: Yup.string(),
    }),
    onSubmit: async (values) => {
      // Keep only the values that are not empty string
      const nonEmptyValues = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== '') {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      const body = {
        ...nonEmptyValues,
        clinics: [{ clinic: clinic.id, order: lastOrder + 1 }],
        clinic_owner: clinic.id,
      };

      if (sectionId) {
        await updateSection({ id: sectionId, body });
      } else {
        await addSection({ body });
      }

      onClose();
    },
  });

  if (isLoading || selectedSection.isLoading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>
        <Box display="flex" flexDirection="column">
          {sectionId ? t('Edit section') : t('Add section')}
          <Typography variant="caption" color="textSecondary">
            {t('Enter at least one language')}
          </Typography>
        </Box>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Row>
            <FormTextField formik={formik} name="title_en" label={`${t('Name')} (${t('English')})`} />
            <FormTextField formik={formik} name="title_fr" label={`${t('Name')} (${t('French')})`} />
            <FormTextField formik={formik} name="title_de" label={`${t('Name')} (${t('German')})`} />
          </Row>
          <Row>
            <FormTextField formik={formik} name="title_it" label={`${t('Name')} (${t('Italian')})`} />
            <FormTextField formik={formik} name="title_es" label={`${t('Name')} (${t('Spanish')})`} />
            <FormTextField formik={formik} name="title_pt" label={`${t('Name')} (${t('Portuguese')})`} />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('Cancel')}</Button>
          <LoadingButton
            data-cy="section-submit"
            type="submit"
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const EducationPanel = ({ readOnly = false }) => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const { clinic } = useSelector((state) => state.clinic);
  const { educationSection } = greyboxApiActions;
  const {
    data, isFetching, isError, refetch,
  } = educationSection.list({ clinic: clinic.id });
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [addSectionOpen, setAddSectionOpen] = React.useState(false);
  const [removeSection] = educationSection.delete();
  const [addDocumentOpen, setAddDocumentOpen] = React.useState(false);
  const [sections, setSections] = React.useState([]);
  const [orders, setOrders] = React.useState([]);

  useEffect(() => {
    if (data) {
      const temp = [...data];
      setSections(
        temp.sort(
          (a, b) => a.clinics
            .find((c) => c.clinic === clinic.id).order - b.clinics
            .find((c) => c.clinic === clinic.id).order,
        ),
      );
      setOrders(temp.map((item) => item.clinics.find((c) => c.clinic === clinic.id).order).sort());
    }
  }, [data]);

  const moveSection = (dragIndex, hoverIndex) => {
    const dragSection = sections[dragIndex];
    const { id } = dragSection.clinics.find((c) => c.clinic === clinic.id);
    setSections((prevSections) => {
      const newSections = [...prevSections];
      newSections.splice(dragIndex, 1);
      newSections.splice(hoverIndex, 0, dragSection);
      return newSections;
    });
  };

  if (isFetching) {
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={t('Education documents')}
          subheader={uuid ? (
            t('Consult education documents')
          ) : (
            `${t('Add')}, ${t('edit')} ${t('and')} ${t('delete')} ${t('education documents')} ${t('and')} ${t('their sections')}`
          )}
        />
        <CardContent>
          <Box sx={{
            p: 2,
            mb: 1,
            height: '300px',
            borderRadius: 2,
            border: (theme) => (`1px solid ${theme.palette.divider}`),
          }}
          >
            <Skeleton variant="rectangular" width="30%" height="10px" />
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={t('Education documents')}
          subheader={uuid ? (
            t('Consult education documents')
          ) : (
            `${t('Add')}, ${t('edit')} ${t('and')} ${t('delete')} ${t('education documents')} ${t('and')} ${t('their sections')}`
          )}
        />
        <CardContent>
          error
        </CardContent>
      </Card>
    );
  }

  const handleMenuClick = async (action, section) => {
    switch (action) {
      case 'edit':
        setSelectedSection(section);
        setAddSectionOpen(true);
        break;
      case 'delete':
        try {
          await removeSection(section.uuid);
        } catch (error) {
          console.error('Error deleting post:', error);
        }
        break;
      case 'add':
        setSelectedSection(section);
        setAddDocumentOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={t('Education documents')}
        subheader={readOnly ? (
          t('Consult education documents')
        ) : (
          `${t('Add')}, ${t('edit')} ${t('and')} ${t('delete')} ${t('education documents')} ${t('and')} ${t('their sections')}`
        )}
      />
      { !readOnly && (
        <Button
          onClick={() => setAddSectionOpen(true)}
          data-cy="add-section"
          variant="outlined"
          sx={{ ml: 2 }}
          startIcon={<AddIcon />}
        >
          {t('Add section')}
        </Button>
      )}
      {addSectionOpen && (
        <AddSectionDialog
          open={addSectionOpen}
          onClose={() => {
            setAddSectionOpen(false);
            setSelectedSection(null);
          }}
          lastOrder={
            sections[sections.length - 1]?.clinics
            .find((c) => c.clinic === clinic.id)?.order || 0
          }
          sectionId={selectedSection?.uuid}
        />
      )}
      <CardContent>
        {sections.map((section, index) => (
          readOnly ? (
            <EducationCard
              key={section.uuid}
              section={section}
              idx={index}
              handleMenuClick={handleMenuClick}
              readOnly
            />
          ) : (
            <DraggableEducationCard
              key={section.uuid}
              section={section}
              orders={orders}
              index={index}
              refetch={refetch}
              moveSection={moveSection}
              handleMenuClick={handleMenuClick}
            />
          )
        ))}
        {addDocumentOpen && (
          <AddDocument
            open={addDocumentOpen}
            handleClose={() => {
              setAddDocumentOpen(false);
              setSelectedSection(null);
              refetch();
            }}
            section={selectedSection}
          />
        )}
      </CardContent>
    </Card>
  );
};

const DraggableEducationCard = (props) => {
  const { educationSectionOrder } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const {
    section, index, moveSection, handleMenuClick, orders,
    refetch,
  } = props;
  const [updateSectionOrder] = educationSectionOrder.update();
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'education',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item) {
      updateSectionOrder({
        id: section.clinics.find((c) => c.clinic === clinic.id).id,
        body: { order: orders[item.index], clinic: clinic.id },
      }).then(() => {
        refetch();
      });
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'education',
    item: () => ({ id: section.uuid, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <EducationCard
      ref={ref}
      section={section}
      idx={index}
      handleMenuClick={handleMenuClick}
      data-handler-id={handlerId}
      draggable
      sx={{
        opacity,
        cursor: isDragging ? 'grabbing' : 'grab',
        borderRadius: 2,
        border: (theme) => (`1px solid ${theme.palette.divider}`),
      }}
    />
  );
};

const EducationCard = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    section, handleMenuClick, sx, idx, draggable = false, readOnly = false,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const { uuid } = useParams();

  const handleClick = (action) => {
    handleMenuClick(action, section);
    setAnchorEl(null);
  };

  if (section.articles.length === 0 && uuid) return null;

  return (
    <Box sx={{ p: 2, mb: 1, ...sx }} ref={ref}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {draggable && <DragHandleIcon sx={{ mr: 1 }} />}
        <Typography variant="h6">{section.title}</Typography>
        {!readOnly
        && (
          <IconButton data-cy={`menu-${idx}`} onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ ml: 'auto' }}>
            <MoreVert />
          </IconButton>
        )}

        <Menu open={menuOpen} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => handleClick('add', section)}>
            {t('Add document')}
          </MenuItem>
          <MenuItem onClick={() => handleClick('edit', section)}>
            {t('Edit section')}
          </MenuItem>
          <MenuItem data-cy={`menu-delete-${idx}`} onClick={() => handleClick('delete', section)}>
            {t('Delete section')}
          </MenuItem>
        </Menu>
      </Box>
      { !readOnly && (
        <Button
          onClick={() => handleMenuClick('add', section)}
          variant="outlined"
          data-cy={`add-document-${idx}`}
          size="small"
          sx={{ mt: 1 }}
          startIcon={<AddIcon />}
        >
          {t('Add document')}
        </Button>
      )}
      <EducationSection sectionId={section.uuid} readOnly={readOnly} />
    </Box>
  );
});

export default EducationPanel;
