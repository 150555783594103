import React from 'react';
import { SvgIcon } from '@mui/material';

const HealthScore = ({ style }) => (
  // <SvgIcon {...props}>
  <SvgIcon style={style} viewBox="1 0.5 29 31">

    {/* //reverse */}
    <g>
      <path
        fill="#1E145F"
        d="M12.7,9.5c-0.2-0.2-0.6-0.3-0.9,0l-2,1.9L9,10.5c-0.2-0.2-0.6-0.3-0.9,0c-0.2,0.3-0.2,0.7,0,0.9l1.3,1.3
c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l2.4-2.3C12.9,10.1,12.9,9.7,12.7,9.5C12.7,9.5,12.7,9.5,12.7,9.5z"
      />
      <path
        fill="#1E145F"
        d="M12.7,14.5c-0.2-0.2-0.6-0.3-0.9,0l-2,1.9L9,15.5c-0.2-0.2-0.6-0.3-0.9,0c-0.2,0.3-0.2,0.7,0,0.9l1.3,1.3
c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l2.4-2.3C12.9,15.1,12.9,14.7,12.7,14.5C12.7,14.5,12.7,14.5,12.7,14.5z"
      />
      <path fill="#1E145F" d="M21.6,20.8h-7.2c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h7.2c0.3,0,0.6-0.3,0.6-0.6S22,20.8,21.6,20.8z" />
      <path
        fill="#1E145F"
        d="M12.7,19.4c-0.2-0.2-0.6-0.3-0.9,0l-2,1.9L9,20.4c-0.2-0.2-0.6-0.3-0.9,0c-0.2,0.3-0.2,0.7,0,0.9l1.3,1.3
c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l2.4-2.3C12.9,20.1,12.9,19.7,12.7,19.4C12.7,19.5,12.7,19.5,12.7,19.4z"
      />
      <path
        fill="#1E145F"
        d="M15,0.3C6.9,0.3,0.3,6.9,0.3,15c0,8.1,6.6,14.7,14.7,14.7c8.1,0,14.7-6.6,14.7-14.7C29.7,6.9,23.1,0.3,15,0.3z
M11,3.6h1.7c0.3,0,0.5-0.3,0.6-0.6c0.2-0.8,0.9-1.4,1.7-1.4c0.8,0,1.5,0.6,1.7,1.4c0.1,0.3,0.3,0.5,0.6,0.6h1.8v2.5H11V3.6z
M22.8,25.5H7.4C6.7,25.5,6,25,6,24.4V6.5c0.1-0.7,0.7-1.3,1.4-1.3h2.4v1.6c0,0.3,0.3,0.6,0.7,0.6h9.2c0.4,0,0.7-0.2,0.7-0.6V5.2
h2.4c0.7,0,1.3,0.6,1.4,1.3v17.9h0C24.1,25,23.5,25.5,22.8,25.5z"
      />
      <path
        fill="#1E145F"
        d="M21.6,10.8h-7.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h7.2c0.3,0,0.6-0.3,0.6-0.6
C22.3,11.1,22,10.8,21.6,10.8z"
      />
      <path
        fill="#1E145F"
        d="M21.6,15.8h-7.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h7.2c0.3,0,0.6-0.3,0.6-0.6
C22.3,16.1,22,15.8,21.6,15.8z"
      />
    </g>
  </SvgIcon>
);

export { HealthScore };
