import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import { Label } from '../../types';

interface PropsType {
  formik: any;
  sx?: any;
  required?: boolean;
}

const Labels = (props: PropsType) => {
  const { t } = useTranslation();
  const { label } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { formik } = props;
  const [search, setSearch] = React.useState('');
  const [addLabel, results] = label.add();
  const [selectedLabels, setSelectedLabels] = React.useState<Label[]>([]);
  const [open, setOpen] = React.useState(false);

  const { data, isFetching } = label.list({
    is_root_node: true,
    clinic: clinic.id,
    name__icontains: search,
    system: false,
  }, { skip: search === '' });

  const availableOptions = data?.filter(
    (opt: Label) => !selectedLabels.some((l: Label) => l.id === opt.id)) || [];

  const adjustedOptions = search && data?.length === 0 ? (
    [...availableOptions, { specialOption: true, name: search }]
  ) : availableOptions;

  React.useEffect(() => {
    setSelectedLabels(formik.values.labels);
  }, [formik.values.labels]);

  React.useEffect(() => {
    setSearch('');
    setOpen(false);
  }, [formik.isSubmitting]);

  return (
    <Autocomplete
      multiple
      sx={{ mx: 1 }}
      value={selectedLabels}
      onBlur={() => setOpen(false)}
      options={adjustedOptions}
      isOptionEqualToValue={(option: Label, value: Label) => option.id === value.id}
      loading={isFetching || results.isLoading}
      open={open}
      onOpen={() => {
        if (search) setOpen(true);
      }}
      getOptionLabel={(option: any) => {
        if (option.specialOption) {
          return `${search} (${t('Create new label')})`;
        }
        return option.name;
      }}
      onChange={(event, value) => {
        const newValue = value[value.length - 1];
        if (newValue?.specialOption) {
          addLabel({
            body: {
              name: search,
              clinic: clinic.id,
            },
          }).then((response) => {
            const newLabel: Label = response.data;
            setSearch('');
            setOpen(false);
            formik.setFieldValue('labels', [...value.slice(0, -1), newLabel]);
          });
        } else {
          formik.setFieldValue('labels', value.map((l) => l));
        }
      }}
      renderInput={(params) => <TextField {...params} onChange={(e) => setSearch(e.target.value)} label={t('Labels')} />}
    />
  );
};

Labels.defaultProps = {
  required: false,
  sx: null,
};

export default Labels;
