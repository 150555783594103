import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';

const Logo = () => {
  const fillColor = useTheme().palette.text.primary;
  return (
    <SvgIcon
      sx={{
        width: '120px', height: '19px', ml: 1,
      }}
      viewBox="0 0 180 24"
    >
      <g id="prefix__Group_399" data-name="Group 399">
        <path id="prefix__Path_358" d="M297.951 234.494h-7.187v-4.235h19.571v4.235h-7.187v18.223h-5.2z" fill={fillColor} data-name="Path 358" transform="translate(-290.764 -229.874)" />
        <path id="prefix__Path_359" d="M342.6 247.905h-10.427l-1.989 4.813h-5.326l10.01-22.459H340l10.042 22.459h-5.454zm-1.636-3.946l-3.561-8.6-3.562 8.6z" fill={fillColor} data-name="Path 359" transform="translate(-306.57 -229.874)" />
        <path id="prefix__Path_360" d="M384.358 243.9l-3.016 3.144v5.679h-5.165v-22.464h5.165v10.491l9.946-10.491h5.776l-9.3 10.01 9.85 12.449h-6.064z" fill={fillColor} data-name="Path 360" transform="translate(-330.363 -229.874)" />
        <path id="prefix__Path_361" d="M437.827 248.547v4.171h-17.389v-22.459h16.972v4.171H425.6v4.877h10.43v4.042H425.6v5.2z" fill={fillColor} data-name="Path 361" transform="translate(-350.883 -229.874)" />
        <path id="prefix__Path_362" d="M457.821 241.155c0-6.77 5.2-11.614 12.192-11.614a11.6 11.6 0 0 1 9.24 3.978l-3.337 3.08a7.226 7.226 0 0 0-5.647-2.631 7.187 7.187 0 1 0 0 14.374 7.157 7.157 0 0 0 5.647-2.663l3.337 3.08a11.541 11.541 0 0 1-9.273 4.01c-6.962.001-12.159-4.844-12.159-11.614z" fill={fillColor} data-name="Path 362" transform="translate(-368.214 -229.541)" />
        <path id="prefix__Path_363" d="M515.7 247.905h-10.43l-1.989 4.813h-5.325l10.01-22.459h5.134l10.042 22.459h-5.454zm-1.637-3.946l-3.562-8.6-3.561 8.6z" fill={fillColor} data-name="Path 363" transform="translate(-386.821 -229.874)" />
        <path id="prefix__Path_364" d="M563.583 252.718l-4.331-6.256h-4.781v6.256h-5.2v-22.459H559c6 0 9.753 3.112 9.753 8.149a7.3 7.3 0 0 1-4.62 7.09l5.037 7.219zm-4.877-18.223h-4.236v7.828h4.236c3.176 0 4.78-1.476 4.78-3.914.001-2.471-1.603-3.915-4.779-3.915z" fill={fillColor} data-name="Path 364" transform="translate(-410.613 -229.874)" />
        <path id="prefix__Path_365" d="M610.625 248.547v4.171h-17.389v-22.459h16.972v4.171H598.4v4.877h10.427v4.042H598.4v5.2z" fill={fillColor} data-name="Path 365" transform="translate(-430.994 -229.874)" />
      </g>
    </SvgIcon>

  );
};

export default Logo;
