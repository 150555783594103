import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Skeleton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

/* Length represent num of line in chart and value 2 is
 *  for the threshold lines
*/
const fillArray = (length1, length2, value1, value2) => {
  if (length1 === 0) {
    return [value2, value2];
  }
  return Array(length1).fill(value1).concat(Array(length2).fill(value2));
};

const getOptions = (numOfLines, thresholdsCount, chartConfig) => {
  const config = {
    stroke: {
      curve: fillArray(numOfLines, thresholdsCount, 'straight', 'stepline'),
      width: fillArray(numOfLines, thresholdsCount, 3, 1),
      dashArray: fillArray(numOfLines, thresholdsCount, 0, 5),
    },
    markers: {
      size: fillArray(numOfLines, thresholdsCount, 3, 0),
      strokeWidth: 3,
      shape: 'circle',
    },
  };

  if (chartConfig) {
    config.colors = chartConfig.colors;
    config.markers.strokeColor = chartConfig.colors;
  }

  return config;
};

const LineChart = (props) => {
  const {
    series,
    linesCount,
    thresholdsCount,
    isLoading,
    chartConfig,
    autoHeight = false,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery((s) => s.breakpoints.down('md'));

  const getHeights = () => {
    if (isMobile) {
      return '280px';
    } if (autoHeight) {
      return '400px';
    }
    return '100%';
  };

  if (isLoading || !series) {
    return <Skeleton variant="rect" width="100%" height="30vh" />;
  }

  const options = {
    series: series,
    dataLabels: {
      enabled: false,
    },
    ...getOptions(linesCount, thresholdsCount, chartConfig),
    chart: {
      foreColor: theme.palette.text.primary,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => val.toFixed(0),
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    tooltip: {
      shared: true,
      enabledOnSeries: [0],
      x: {
        format: 'dd MMM HH:mm',
      },
      y: {
        formatter: (value) => {
          if (chartConfig?.unit) {
            return `${value} ${chartConfig.unit}`;
          }
          return value;
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      height={getHeights()}
    />
  );
};

export default LineChart;
