import React from 'react';
import { Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
  goNext: () => void;
  hasBeenCompleted: boolean;
  setShowSummary: (showSummary: boolean) => void;
  hasAnswered: boolean;
  isCurrent: boolean;
  allowEmpty?: boolean;
};

const PanelNavigation: React.FC<Props> = ({
  children,
  goNext,
  hasBeenCompleted,
  setShowSummary,
  hasAnswered,
  isCurrent,
  allowEmpty = false,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" maxHeight="100%" width="90%" maxWidth="lg">
      {hasBeenCompleted && (
        <Button
          variant="outlined"
          sx={{ width: 'fit-content', alignSelf: 'flex-start', mb: 2 }}
          color="success"
          startIcon={<ArrowBackIcon />}
          onClick={() => setShowSummary(true)}
        >
          {t('Back to summary')}
        </Button>
      )}
      {children}
      <div>
        <Button
          onClick={goNext}
          disabled={!allowEmpty && !hasAnswered}
          sx={{ mt: 1 }}
          size="large"
          data-cy={isCurrent ? 'ok-button' : ''}
          variant="outlined"
          endIcon={<CheckIcon />}
        >
          {allowEmpty && !hasAnswered ? t('Skip') : 'OK'}
        </Button>
      </div>
    </Box>
  );
};

export default PanelNavigation;
